import Image from 'next/image';

import Modal from '@/components/Modals/Core';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';
import { PLACEHOLDER_IMG_URL } from '@/constants/images';

import { useAddToPlaylist } from './context';
import Form from './form';
import { AddToPlaylistModalScreenName } from './types';

const AddToPlaylistModalScreens = () => {
  const animationProps = useDirectionalAnimation();
  const { details } = useAddToPlaylist();

  if (!details) {
    return null;
  }

  return (
    <Modal.Screen
      screenName={AddToPlaylistModalScreenName.AddToPlaylist}
      className="px-6 py-12"
      {...animationProps}
    >
      <Modal.Title className="text-xl mb-4">Add to playlist</Modal.Title>
      <div className="flex flex-col mb-6">
        <div className="flex mb-3">
          <div className="h-[60px] w-[60px] flex-shrink-0">
            <Image
              alt={details.track.artist.name}
              src={details.track.artist.image ?? PLACEHOLDER_IMG_URL}
              width={60}
              height={60}
              sizes="60px"
              className="rounded-full w-full"
            />
          </div>
          <div className="flex flex-col ml-2 justify-center">
            <span className="font-medium text-base line-clamp-1">
              {details.track.name}
            </span>
            <span className="font-medium text-xs text-gray-dark-100 dark:text-gray-medium-400 line-clamp-1">
              {details.track.artist.name}
            </span>
          </div>
        </div>
      </div>
      <Form details={details} />
    </Modal.Screen>
  );
};

export default AddToPlaylistModalScreens;
