import Switch from '@/components/Switch';
import { useLocalSettings } from '@/providers/LocalSettingsProvider';

const ToggleTaxonomyData = () => {
  const { localSettings, setLocalSettings } = useLocalSettings();

  let { isShowTaxonomyData } = localSettings;

  const onToggleTaxonomyData = () => {
    isShowTaxonomyData = !isShowTaxonomyData;
    setLocalSettings({
      ...localSettings,
      isShowTaxonomyData,
    });
  };

  return (
    <Switch
      onCheckedChange={onToggleTaxonomyData}
      checked={!!isShowTaxonomyData}
      id="admin-taxonomy"
      label="Show Taxonomy Data"
    />
  );
};

export default ToggleTaxonomyData;
