import { getAuthToken } from '@/auth';

const handleActiveCampaignError = (error: unknown) => {
  if (!process.env.NEXT_PUBLIC_API?.includes('hades')) {
    console.warn(error);
  } else {
    // eslint-disable-next-line no-console
    console.log(
      'Active campaign errored because it is not running on Hades: ',
      error,
    );
  }
};

export const updateActiveCampaignContact = () => {
  const authToken = getAuthToken();

  if (!authToken?.length ?? authToken?.length <= 5) {
    return;
  }

  void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Import/ByUserId`, {
    credentials: 'include',
    method: 'POST',
  }).catch((err: unknown) => handleActiveCampaignError(err));

  return;
};

export const trackEvent = (type: string, info: string) => {
  void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      requesttype: type,
      eventinfo: info,
    }),
  }).catch((err: unknown) => handleActiveCampaignError(err));
};

export const autoRenewACEvent = (renewStatus: string) => {
  void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      requesttype: 'autorenewstatus',
      eventinfo: `${renewStatus}`,
    }),
  }).catch((err: unknown) => handleActiveCampaignError(err));
};

export const updateActiveCampaignEmail = () => {
  void fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/UpdateEmail`, {
    credentials: 'include',
    method: 'POST',
  }).catch((err: unknown) => handleActiveCampaignError(err));
};

export const channelWhitelisted = (channelName: string, channelId: string) => {
  return fetch(
    `${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        requesttype: 'channelwhitelisted',
        eventinfo: `Channel Whitelisted: ${channelName} Channel ID: ${channelId}`,
      }),
    },
  )
    .then((res) => res.json())
    .catch((err: unknown) => handleActiveCampaignError(err));
};
