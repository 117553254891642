import {
  dismissExpirationNotice,
  setHasExpiredNotice,
} from '@/actions/subscriptionAction';
import A from '@/components/A';
import { UBAlert } from '@/components/UBAlert';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useIsClient } from '@/hooks/useIsClient';
import { useSettings } from '@/hooks/useSettings';

export const UBAlerts = () => {
  const { settings } = useSettings();
  const subscriptionData = settings?.subscriptionData;
  const dispatch = useAppDispatch();
  const isClient = useIsClient();

  const handleDismissExpirationNotice = () => {
    void dismissExpirationNotice();
    dispatch(setHasExpiredNotice(false));
  };

  if (!isClient) {
    return null;
  }

  if (subscriptionData?.overduePayment?.id) {
    return (
      <UBAlert>
        <p>
          Heads up! Your latest subscription payment failed.{' '}
          <A href={`/payments/${subscriptionData.overduePayment.id}`}>
            Resolve now
          </A>
        </p>
      </UBAlert>
    );
  }

  if (subscriptionData?.hasExpiredNotice) {
    return (
      <UBAlert>
        <p>
          Heads up! Your subscription has expired.{' '}
          <span
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleDismissExpirationNotice}
          >
            Dismiss
          </span>
          .
        </p>
      </UBAlert>
    );
  }

  if (subscriptionData?.isPaused) {
    return (
      <UBAlert>
        <p>
          Your subscription has been paused. To resume your subscription early,
          please <A href="/contact/general-inquiry">contact us</A>.
        </p>
      </UBAlert>
    );
  }

  return null;
};
