import { useRouter } from 'next/router';
import { useState } from 'react';

import { getVideo } from './videoList';
import { VideoPlayer } from './VideoPlayer';

export const VideoFromQuery = () => {
  const router = useRouter();
  const [showVideo, setShowVideo] = useState(true);

  const videoType = router.query.v?.toString();

  if (!videoType) {
    return null;
  }

  const video = getVideo(videoType) ?? getVideo(videoType, 'id');

  if (!video || !showVideo) {
    return null;
  }

  return (
    <VideoPlayer
      id={video.id}
      playInline={false}
      hideVideo={() => setShowVideo(false)}
    />
  );
};
