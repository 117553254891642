const INITIAL_STATE = {
  track: false,
};


const downloadIntent = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_DOWNLOAD_INTENT':
      return Object.assign({}, state, { track: action.track });
    case 'CLEAR_DOWNLOAD_INTENT':
      return {
        track: false,
      };
    default:
      return state;
  }
};

export default downloadIntent;
