import Modal from '@/components/Modals/Core';
import Welcome from '@/components/Modals/CreateAccount/welcome';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';

import { VerifyEmailModalScreenName } from './types';

const VerifyEmailModalScreens = () => {
  const animationProps = useDirectionalAnimation();

  return (
    <Modal.Screen
      screenName={VerifyEmailModalScreenName.VerifyEmail}
      className="px-6 py-12"
      {...animationProps}
    >
      <Welcome attemptedDownload />
    </Modal.Screen>
  );
};

export default VerifyEmailModalScreens;
