import { drip } from '../utils/drip';
import { addNewEngagement } from './favouriteAction';
import { addModal } from './authenticationAction';
import { getAuthToken } from '../auth';

export function downloadPreview(primaryTrack, version) {
  return function (dispatch) {
    if (getAuthToken()) {
      addNewEngagement('preview', primaryTrack.id, primaryTrack.current_context.id);
      drip("Downloaded Track Preview");
      if (version !== undefined && version.download_preview_mp3) {
        window.location = version.download_preview_mp3;
      } else if (primaryTrack.track_version?.[0].download_preview_mp3) {
        window.location = primaryTrack.track_version?.[0].download_preview_mp3;
      }
    } else {
      dispatch(addModal('playSignup', 'download_preview'));
    }
  };
}

export function addPlaylistTrack(track, version) {
  return { type: 'ADD_PLAYLIST', track, version };
}

export function removePlaylist() {
  return { type: 'REMOVE_PLAYLIST' };
}
