import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useSettings } from '@/hooks/useSettings';

import { getUUID } from './getUUID';

interface ABProviderProps {
  children: React.ReactNode;
}

const gbuuid = getUUID();

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: 'sdk-B9LPZk9tXd5Mj4i3',
  // enableDevMode: process.env.NODE_ENV === 'development',
  // Leaving this enabled means that any data in Growthbook should be treated as publically accessible.
  enableDevMode: true,
  subscribeToChanges: false,
  trackingCallback: (experiment, result) => {
    if (window.gtag) {
      window.gtag('event', 'experiment_viewed', {
        event_category: 'experiment',
        experiment_id: experiment.key,
        variation_id: result.variationId,
        gb_user_id: gbuuid,
      });
    }
  },
});

export const ABProvider: React.FC<ABProviderProps> = ({ children }) => {
  const { settings } = useSettings();

  const { asPath, isReady } = useRouter();

  useEffect(() => {
    if (!settings || !isReady) {
      return;
    }

    // Set user attributes for targeting (from cookie, auth system, etc.)
    void growthbook.setAttributes({
      id: gbuuid,
      email: settings?.user.email,
      isBusiness: settings?.isBusiness ?? false,
      isPremium: settings?.isPremium ?? false,
      isGoogleUser: settings?.user.isGoogleUser ?? false,
      creditsCurrent: settings.credits.credits_current,
      isArtist: settings?.user.hasAccountArtist ?? false,
      isAdmin: settings?.user.hasAccountAdmin ?? false,
      slug: asPath,
    });
  }, [settings, isReady, asPath]);

  useEffect(() => {
    // Load features asynchronously when the app renders
    void growthbook.loadFeatures();
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};
