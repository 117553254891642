import Image from 'next/image';
import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toggleFavouriteTrackVersion } from '../../actions/favouriteAction';
import { addLicense } from '../../actions/licenseAction';
import { framelessPlaylist } from '../../actions/playlistAction';
import { addTrackData } from '../../actions/trackAction';
import {
  buttonToModal,
  setCurrentModal,
  setCurrentStep,
} from '../../actions/ubModalAction';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppState } from '../../hooks/useAppState';
import { useSettings } from '../../hooks/useSettings';
import { useSignInOrSignUp } from '../../hooks/useSignInOrSignUp';
import { useTranslate } from '../../hooks/useTranslate';
import { UserStateContext } from '../../providers/UserStateProvider';
import { trackInternalEvent } from '../../utils/tracking';
import { DynamicLink } from '../DynamicLink';
import IconInfo from '../IconInfo';
import IconPlus from '../IconPlus';
import { AccountModalNames, ModalConversionPoints, useAccountModals } from '../Modals/Account';
import PlaylistModal from '../PlaylistModal';
import UBButton from '../UBButton';
import UBModalHeader from '../UBModal/UBModalHeader';

const UBTrackModal = (props) => {
  const modals = 2;
  const dispatch = useAppDispatch();
  const userAuth = useAppState('userAuth');
  const { userState } = useContext(UserStateContext);
  const { signInOrSignUp } = useSignInOrSignUp();
  const { t } = useTranslate('common');
  const { settings } = useSettings();

  const {
    setCurrentModal,
    mobileTrackMenu,
    ubModalStep,
    playlist,
    framelessPlaylist,
    onRemoveModal,
    favorites,
  } = props;

  const { setCurrentModal: setCurrentAccountModal, openModalWithConversionPoint } = useAccountModals();

  const [commercialLicense, setCommercialLicense] = useState(
    mobileTrackMenu.trackData.commercialLicenseLink,
  );

  let isFavorite = favorites.includes(
    parseInt(mobileTrackMenu.trackData.mainVersionId),
  );

  const isPremiumTrack = mobileTrackMenu.trackData.track.is_premium;
  const isFreeUser = !(userAuth.isPremium || userAuth.isBusiness);
  const [isFavoriteTrack, setIsFavoriteTrack] = useState(isFavorite);
  const isSfx = mobileTrackMenu.trackData.track?.is_sfx;

  const getLicense = (id) => {
    fetch(`${process.env.NEXT_PUBLIC_MUSIC_VINE_API}mvTrackUrl/${id}`)
      .then((res) => res.json())
      .then((result) => {
        if (result.link) {
          setCommercialLicense(result.link);
        } else {
          setCommercialLicense(false);
        }
      });
  };

  useEffect(() => {
    getLicense(mobileTrackMenu.trackData.track.musicvine_track_id);
  }, []);

  useEffect(() => {
    if (ubModalStep.currentStep === 0) {
      setCurrentModal(1);
      dispatch(setCurrentStep(1));
    } else if (ubModalStep.currentStep === 2) {
      setCurrentModal(2);
      dispatch(setCurrentStep(2));
    }
  }, [ubModalStep.currentStep]);

  const handleModalOpen = (cb) => {
    // If the track is premium, open the premium download modal
    if (isPremiumTrack) {
      // If the user is not logged in, open the premium signup modal
      if (!userAuth?.isLoggedIn) {
        openModalWithConversionPoint(
          AccountModalNames.SignUpPremium,
          ModalConversionPoints.SignUp,
        );
        return;
      }

      // If the user is free, open the premium upgrade modal
      if (isFreeUser) {
        openModalWithConversionPoint(
          isSfx
            ? AccountModalNames.GoPremiumSFXDownload
            : AccountModalNames.GoPremiumTrackDownload,
          ModalConversionPoints.DownloadPremium,
        );

        return;
      }

      cb();
      dispatch(buttonToModal('premium-download-button'));

      return;
    }

    // If the user is not logged in, open the signup modal
    if (!userAuth?.isLoggedIn) {
      openModalWithConversionPoint(
        AccountModalNames.SignUp,
        ModalConversionPoints.SignUp,
      );

      return;
    }

    // Check how many credits the user has
    const credits = settings?.credits?.credits_current ?? 0;

    // If the user is free and has credits, open the download confirmation dialog
    if (isFreeUser && credits > 0) {
      cb();
      dispatch(buttonToModal('download-button'));
    } else {
      // If the user doesn't have credits, open the old download modal
      // (This will trigger the "no credits" message in the modal)
      // If the user is premium / business, download the track
      cb();
      dispatch(buttonToModal('download-button'));
    }
  };

  const favoritesLabel = isFavoriteTrack
    ? t('Remove from favorites')
    : t('Add to favorites');

  return (
    <div className="ub-modal ub-track-modal">
      <UBModalHeader
        tabs={[
          <div className="ub-modal-header_tab">
            <span>
              <img
                src="https://cdn.uppbeat.io/images/logo/logo-marque.svg"
                alt="Uppbeat logo"
                className="uppbeat-logo "
              />
            </span>
          </div>,
        ]}
        onClose={onRemoveModal}
      />
      <div
        className={`ub-modal-container-wrapper ub-modal-container-wrapper-${modals} modal-${ubModalStep.currentModal}`}
      >
        <div className="ub-modal-container" style={{ width: `${100 / 2}%` }}>
          <div className="ub-track-modal-top-wrapper">
            <div className="ub-track-modal-top">
              {mobileTrackMenu.trackData.track.is_premium && (
                <div className="ub-track-premium">
                  <Image
                    unoptimized
                    src="https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Premium_Star_White.svg"
                    alt="Premium Track"
                    width={16}
                    height={16}
                  />
                </div>
              )}
              <img
                className="ub-track-modal-image"
                src={mobileTrackMenu.trackData.image}
                alt={mobileTrackMenu.trackData.track.artist.name}
              />
              <h1>{mobileTrackMenu.trackData.track.name}</h1>
              <DynamicLink
                onClick={onRemoveModal}
                href={`/browse/artist/${
                  mobileTrackMenu.trackData.track.artist.slug
                    ? mobileTrackMenu.trackData.track.artist.slug
                    : mobileTrackMenu.trackData.track.artist.id
                }`}
              >
                {mobileTrackMenu.trackData.track.artist.name}
              </DynamicLink>

              {mobileTrackMenu.trackData.isMyDownloadsPage ? (
                <>
                  <span>
                    Download Date -{' '}
                    <span>{mobileTrackMenu.trackData.dateDownloaded}</span>
                  </span>
                  <UBButton
                    variant="secondary"
                    width="100%"
                    label={t('View Credit')}
                    onClick={() => {
                      mobileTrackMenu.trackData.viewCredit(
                        mobileTrackMenu.trackData.track,
                      );
                      onRemoveModal();
                    }}
                    ariaLabel={t('View Credit')}
                  />
                  <UBButton
                    variant="uppbeat"
                    width="100%"
                    label={t('Download Again')}
                    onClick={() => {
                      onRemoveModal();
                      handleModalOpen(() => {
                        props.onAddLicense(mobileTrackMenu.trackData.track);
                      });
                    }}
                    ariaLabel={t('Download Again')}
                  />
                </>
              ) : (
                <UBButton
                  variant="uppbeat"
                  width="100%"
                  label={t('Download')}
                  onClick={() => {
                    onRemoveModal();
                    handleModalOpen(() => {
                      props.onAddLicense(mobileTrackMenu.trackData.track);
                    });
                  }}
                  ariaLabel={t('Download')}
                />
              )}
            </div>
          </div>
          <div className="ub-track-modal-bottom-wrapper">
            <div className="ub-track-modal-bottom">
              {mobileTrackMenu.trackData.similarLink && (
                <Link
                  prefetch={false}
                  href={mobileTrackMenu.trackData.similarLink}
                  onClick={() => onRemoveModal()}
                >
                  <UBButton
                    variant="primary"
                    width="100%"
                    label={t('More like this')}
                    icon={
                      <img
                        src="https://cdn.uppbeat.io/images/more-like.svg"
                        alt="More icon"
                      />
                    }
                    ariaLabel="More"
                  />
                </Link>
              )}
              <UBButton
                variant="primary"
                width="100%"
                label={t('addToPlaylist', 'Add to playlist', {
                  ns: 'common',
                })}
                icon={<IconPlus />}
                onMouseUp={() => {
                  if (userAuth?.isLoggedIn) {
                    mobileTrackMenu.trackData.addToPlaylist();
                    setCurrentModal(2);
                    framelessPlaylist();
                  } else if (userState.hasLoggedIn) {
                    setCurrentAccountModal(AccountModalNames.Login);
                  } else {
                    openModalWithConversionPoint(
                      AccountModalNames.Signup,
                      ModalConversionPoints.SignUp,
                    );
                  }
                }}
                ariaLabel={t('Add to playlist')}
              />
              {!mobileTrackMenu.trackData.isMyDownloadsPage && (
                <a
                  onClick={() => {
                    if (!userAuth?.isLoggedIn) {
                      signInOrSignUp();

                      return;
                    }

                    if (!isFavoriteTrack) {
                      void trackInternalEvent({
                        type: 'favorite',
                      });
                    }

                    dispatch(
                      toggleFavouriteTrackVersion(
                        mobileTrackMenu.trackData.track.track_version?.[0].id,
                        mobileTrackMenu.trackData.track
                          .current_engagement_group,
                      ),
                    );
                    setIsFavoriteTrack(!isFavoriteTrack);
                  }}
                >
                  <UBButton
                    variant="primary"
                    width="100%"
                    label={favoritesLabel}
                    icon={
                      isFavoriteTrack ? (
                        <img
                          src="https://cdn.uppbeat.io/images/icons/favourite-true.svg"
                          alt="Favorite true"
                        />
                      ) : (
                        <img
                          src="https://cdn.uppbeat.io/images/icons/favourite-false.svg"
                          alt="Favorite false"
                        />
                      )
                    }
                    ariaLabel={favoritesLabel}
                  />
                </a>
              )}
              <div className="ub-track-modal-button-group">
                <a href={commercialLicense}>
                  <UBButton
                    variant="primary"
                    width="100%"
                    disabled={commercialLicense ? false : true}
                    label={
                      commercialLicense
                        ? t('moreLicenseOptions', 'More license options', {
                            ns: 'common',
                          })
                        : commercialLicense === false &&
                          commercialLicense !== null
                        ? t(
                            'licenseUnavailable',
                            'Commercial license unavailable',
                          )
                        : t('moreLicenseOptions', 'More license options', {
                            ns: 'common',
                          })
                    }
                    ariaLabel="Commercial license"
                    icon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1108.4 792"
                      >
                        <path d="M536.7 53.6c-88 11.2-117.1 74.9-117.1 74.9s-8.1 16.3 13.8 26.8c27.2 13 25.5-33.9 64.9-27.3 44.8 7.5 29.7 77.9 29.7 77.9l-41.8 194.2s-24.5 177.6-70.4 210.5-21.2 57.8-8.5 53.3 67.8-23.4 102.7-29.6c34.9-6.2 62.6-23.1 70.8-82.4 5.8-41.9 63.2-326.2 72.7-386.4 9.4-60.2-28.8-123.1-116.8-111.9z"></path>
                        <path d="M286.8 110.6C119.6 123.2-4.3 257.2 7.7 416.4c6.3 83.3 47.6 103.9 80.7 101.4 52.4-3.9 97.8-56.8 94.8-96.3-1.4-18.2-8.3-24.1-15.7-23.5-13.9 1-21.7 26.3-46.2 28.2-19.2 1.4-44.5-14-45-69.7-.6-64.9 73.9-148.6 151.2-164.7h.1c5.6-1.2 10.5-.8 14.9.3 3 .9 5.7 2 8.2 3.4 5.2 3.1 8.9 7.1 11 9.6 19.6 25.3 10.6 74.2 10.6 74.2l-52.6 214.1s-32.9 196.2-84.9 231.3c-51.9 35.1-25.4 63.4-11.1 58.8 14.2-4.6 76-23.7 114.9-29.5s70.2-23.6 81.2-89.1c7.8-46.3 80.6-359.9 93.1-426.3 12.3-66.6-28.1-137.6-126.1-128zM1100.4 354.9c-1.2-2.7-3.3-4.3-5.5-5.6-3-1.5-20.1-9.4-22.6-10.8-6.3-3.1-12.8-7.2-19.5-12.3-7.1-5.9-13.1-12.5-18.1-19.9 29.6-48.9 48.8-88.1 57.8-119.5 32.7-112.9-39-140.4-60.8-144-35.7-5.9-71 10-88.8 55.5-24.1 61.7-17.3 127 14.2 187.6 2.3 4.4 4.8 8.6 7.5 12.6-34.7 50.7-106.4 126.7-106.4 126.7s-24.4 26-37.4 28.4c-16.2 2.9-25-7.2-25.9-24.3.1-7.9 1.2-15.6 2.3-21.6.9-3.7 1.8-7.5 2.8-11.5 20.5-85.1 60.7-244.2 69.3-285 11-52.2-19.7-109-97.2-103.1S666.3 68.4 666.3 68.4s-7.5 13.3 10.9 24c22.8 13.3 23.7-28.1 58-21 38.5 7.9 22.5 69.5 22.5 69.5L712.5 309s-26.5 99.4-26 156.9c0 0 0 5.1.7 13.6.5 6.5 3.6 15.3 4.5 17.3 5.4 13.8 17.6 27.2 37 38.5 12.7 7.1 29.1 12.3 50 12.8 0 0 90 3.6 145-78.3l84.2-122.4c7.8 5.2 16.7 9.7 26.4 13.5 9.4 3.5 19.1 5.8 28.9 6.9 1.3.2 15.9 2.3 28.7 1.1 4.2-.4 7.1-2.5 8.6-6.5 1.3-2.2 1.2-4.8-.1-7.5zM998.3 246.1c-5.9-15.6-10.9-36.3-12.7-58.9-3.1-39.4 11.4-76.1 32.9-65.5 38.8 19.1 9.5 74.9-20.2 124.4z"></path>
                      </svg>
                    }
                  />
                </a>
                <Link
                  prefetch={false}
                  href={'/commercial-licenses'}
                  onClick={() => onRemoveModal()}
                >
                  <UBButton
                    variant="primary"
                    icon={<IconInfo />}
                    ariaLabel="Commercial license info"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ub-modal-container playlist-modal-container"
          style={{ width: `${100 / 2}%` }}
        >
          {playlist.active && (
            <PlaylistModal
              playlist={playlist}
              onRemoveModal={onRemoveModal}
              t={t}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// Map redux actions to local props.
const mapDispatchToProps = (dispatch) => ({
  onAddLicense: (track) => {
    dispatch(addLicense(track));
  },
  addTrackData: (array) => dispatch(addTrackData(array)),
  framelessPlaylist: () => dispatch(framelessPlaylist()),
  setCurrentModal: (number) => {
    dispatch(setCurrentModal(number));
  },
});

const mapStateToProps = (state) => ({
  mobileTrackMenu: state.mobileTrackMenu,
  ubModalStep: state.ubModalStep,
  playlist: state.playlist,
  favorites: state.favorites,
});

export default connect(mapStateToProps, mapDispatchToProps)(UBTrackModal);
