import * as React from 'react';
import { twMerge } from 'tailwind-merge';

interface PremiumStarProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  showBackground?: boolean;
}

const PremiumStar = (props: PremiumStarProps) => {
  const { showBackground = true, className } = props;

  return (
    <div
      className={twMerge(
        'rounded-full flex justify-center items-center aspect-square p-0.5 text-white',
        showBackground && 'bg-uppbeat',
        className,
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8 8"
        {...props}
        className="flex justify-center items-center w-full h-full"
      >
        <path
          className="!fill-current"
          d="M7.4 3.1l-2.3-.2-1-2.2C4.1.6 4 .6 4 .6l-.1.1-1 2.2-2.3.2c-.1 0-.1.1-.1.1v.1l1.8 1.6-.5 2.3c0 .1 0 .1.1.1H2l2-1.1 2.1 1.2h.1v-.1l-.5-2.4 1.8-1.6c.1 0 .1-.1-.1-.2h0z"
        />
      </svg>
    </div>
  );
};

export default React.memo(PremiumStar);
