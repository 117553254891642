import React from 'react';
import { twMerge } from 'tailwind-merge';

const defaultClassName = 'w-full grid grid-col-1 gap-4';

type FormProps =
  | (React.HTMLAttributes<HTMLDivElement> & { dummy: true })
  | (React.FormHTMLAttributes<HTMLFormElement> & { dummy?: false });

const Form = ({ className, dummy, ...props }: FormProps) => {
  if (dummy) {
    return (
      <div
        className={twMerge(defaultClassName, className)}
        {...(props as React.HTMLAttributes<HTMLDivElement>)}
      />
    );
  }

  return (
    <form
      className={twMerge(defaultClassName, className)}
      {...(props as React.FormHTMLAttributes<HTMLFormElement>)}
    />
  );
};

export default Form;
