import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetCross from '../../styles/svg-components/CrossSVG.js';
import { removeTrackStyle } from '../../actions/favouriteAction';
import Spinner from '../../styles/svg-components/Spinner';
import { PLACEHOLDER_IMG_URL } from '../../constants/images';

class RemoveStyleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            selectedStyles: [],
            isLoading: false,
        };
        this.handleParentClick = this.handleParentClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    onChange(event) {
        const { selectedStyles } = this.state;
        const value = event.target.value;
        if (event.target.checked) {
            selectedStyles.push(value);
        } else {
            selectedStyles.splice(selectedStyles.indexOf(value), 1);
        }
        this.setState({ selectedStyles });
    }

    handleParentClick(event) {
        const { page } = this.props.tracks;
        const { subStyles } = page;
        if (event.target.checked) {
            this.setState({ disabled: true, selectedStyles: subStyles.map((item) => item.id) });
        } else {
            this.setState({ disabled: false, selectedStyles: [] });
        }
    }

    handleSubmit() {
        const { track, onRemoveTrackStyle } = this.props;
        const { selectedStyles } = this.state;
        if (track && selectedStyles.length > 0) {
            this.setState({
                isLoading: true,
            });
            onRemoveTrackStyle(track.id, selectedStyles).then((data) => {
                this.setState({ isLoading: false });
                if (data) {
                    this.closeModal();
                    track.reloaded();
                }
            });
        }
    }

    closeModal() {
        this.props.onRemoveModal();
    }

    render() {
        const { tracks, track } = this.props;
        const { page } = tracks;
        const { subStyles } = page;
        const parentStyleRows = [];
        const subStyleRows = [];

        subStyles.map((item, index) => {
            const defaultChecked = (!this.state.disabled && this.state.selectedStyles.indexOf(String(item.id)) !== -1) || false;
            if (index === 0) {
                parentStyleRows.push(
                    <label key={index}>
                        <input
                            onChange={this.onChange}
                            type="checkbox"
                            value={item.id}
                            checked={defaultChecked}
                            disabled={this.state.disabled}
                        />{' '}
                        {item.name}
                    </label>
                );
                return false;
            }
            subStyleRows.push(
                <label key={index}>
                    <input
                        onChange={this.onChange}
                        type="checkbox"
                        value={item.id}
                        checked={defaultChecked}
                        disabled={this.state.disabled}
                    />{' '}
                    {item.name}
                </label>
            );
        });

        let artistThumb = <img src={PLACEHOLDER_IMG_URL} />;
        const artist = this.props.track.artist || {};

        if (artist && artist.avatar_image) {
            artistThumb = <img alt={artist.name} src={artist.avatar_image.image_aws_url} />;
        }

        return (
            <div className="modal-primary add-to-playlist-active">
                <div id={'add-to-playlist-step-1'}>
                    <div className="svg-container svg-xxs gray add-to-playlist-modal__close" onClick={this.closeModal}>
                        <AssetCross />
                    </div>
                    <div className="add-to-playlist-modal__top">
                        <h3 className="add-to-playlist-modal__title">Remove Styles</h3>
                    </div>
                    <div className="add-to-playlist-modal__create">
                        {this.state.isLoading && <Spinner />}
                        <div className="add-to-playlist-modal__content">
                            <div className="add-to-playlist-modal__left">
                                {artistThumb}
                            </div>
                            <div className="add-to-playlist-modal__right">
                                <div className="add-to-playlist-modal__info">
                                    <h4 className="add-to-playlist-modal__info-title">{track.name || ''}</h4>
                                    {artist &&
                                        <span className="add-to-playlist-modal__author">{artist.name || ''}</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="remove-styles-modal">
                            <div className="license__checkboxes">
                                <label>
                                    <input
                                        type="checkbox"
                                        onChange={this.handleParentClick}
                                    />{' '}
                                    Remove from parent style & all sub-styles
                                </label>
                                <div className="remove_style_checkboxes">
                                    <div className="remove_parent_styles">
                                        <div className="remove_styles-title">
                                            <h4>Parent Style</h4>
                                        </div>
                                        {parentStyleRows}
                                    </div>
                                    <div className="remove_sub_styles">
                                        <div className="remove_styles-title">
                                            <h4>Sub-styles</h4>
                                        </div>
                                        {subStyleRows}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="add-to-playlist-modal__save"
                        type={'button'}
                        onClick={this.handleSubmit}
                    >
                        Save
                    </button>
                    {this.state.isLoading && <div className="spinner-overlay" />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    tracks: state.tracks,
});

const mapDispatchToProps = (dispatch) => ({
    onRemoveTrackStyle: (trackId, styles) => dispatch(removeTrackStyle(trackId, styles)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RemoveStyleModal);
