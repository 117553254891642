import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';

interface DeferredComponentProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  delay?: number;
}

const Loading = <p>Loading...</p>;

const DeferredComponent = ({
  children,
  fallback = Loading,
  delay = 0,
}: DeferredComponentProps) => {
  const [shouldRender, setShouldRender] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, { threshold: 0.1 });

  const isInView = useMemo(
    () => intersection && intersection.intersectionRatio < 1,
    [intersection],
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isInView) {
      timer = setTimeout(() => {
        setShouldRender(true);
      }, delay);
    } else {
      // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
      setShouldRender(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isInView, delay]);

  return (
    <div className="contents" ref={intersectionRef}>
      {shouldRender ? children : fallback}
    </div>
  );
};

export default DeferredComponent;
