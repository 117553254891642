/*
 * ====================
 * SteppedDialog
 * ====================
 * A dialog component that allows for multiple screens
 * Built on top of @radix-ui/react-dialog
 * See https://www.radix-ui.com/primitives/docs/components/dialog
 * API is the same as Dialog, but with the addition of a Screen component
 * and a ScreenContainer component.
 *
 * Screen:
 * A component that represents a screen in the dialog,
 * with a name prop that is used to identify the screen.
 *
 * ScreenContainer:
 * A component that wraps the Screen components and provides a context
 * to manage the current screen.
 *
 * Usage:
 * Wrap the Screen components in a ScreenContainer component and use the
 * setScreen function to switch between screens.
 *
 * <SteppedDialog.Root>
 *   <SteppedDialog.Trigger>Open Dialog</SteppedDialog.Trigger>
 *   <SteppedDialog.Content>
 *     <SteppedDialog.ScreenContainer>
 *       {({ currentScreen, setScreen }) => (
 *         <>
 *           <SteppedDialog.Screen screenName="screen1">
 *             <h1>Screen 1</h1>
 *             <button onClick={() => setScreen('screen2')}>Next</button>
 *           </SteppedDialog.Screen>
 *           <SteppedDialog.Screen screenName="screen2">
 *             <h1>Screen 2</h1>
 *             <button onClick={() => setScreen('screen1')}>Back</button>
 *           </SteppedDialog.Screen>
 *         </>
 *       )}
 *     </SteppedDialog.ScreenContainer>
 *   </SteppedDialog.Content>
 * </SteppedDialog.Root>
 *
 * Built by: @sambeevors
 */

import * as Dialog from '@radix-ui/react-dialog';
import { Slot } from '@radix-ui/react-slot';
import React, { useEffect } from 'react';

import { ScreenProvider, useScreenContext } from './context/ScreenContext';

export interface DialogScreenProps {
  screenName: string;
  children: React.ReactNode;
  asChild?: boolean;
  className?: string;
}

const DialogScreen = ({
  screenName,
  children,
  asChild = false,
  className = '',
}: DialogScreenProps) => {
  const { currentScreen, registerScreen } = useScreenContext();

  useEffect(() => {
    registerScreen(screenName);
  }, [screenName, registerScreen]);

  const isVisible = currentScreen === screenName;

  // If asChild is true, render the Slot component
  // This is used to render the screen into it's immediate child
  const Screen = asChild ? Slot : 'div';

  if (!isVisible) {
    return null;
  }

  return (
    <Screen
      data-dialog-screen={screenName}
      data-state={isVisible ? 'open' : 'closed'}
      className={className}
    >
      {children}
    </Screen>
  );
};

export interface DialogScreenContainerProps {
  children: React.ReactNode;
  initial?: string;
}

const DialogScreenContainer = ({
  children,
  initial,
}: DialogScreenContainerProps) => {
  return <ScreenProvider initial={initial}>{children}</ScreenProvider>;
};

/*
 * Export the SteppedDialog components along with the Dialog components
 * from Radix. This allows for the same API as Dialog, but with the addition of
 * Screen and ScreenContainer components.
 */
const SteppedDialog = {
  ...Dialog,
  Screen: DialogScreen,
  ScreenContainer: DialogScreenContainer,
};

export default SteppedDialog;
