import { X } from 'lucide-react';
import React from 'react';

import Modal from '@/components/Modals/Core';
import { useSidebarHeaderContext } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';
import { Button } from '@/components/ui/button';

const SidebarHeader = () => {
  const { tabs } = useSidebarHeaderContext();

  return (
    <div className="flex justify-between items-center after:width-full after:bg-white after:h-px after:absolute after:inset-x-0 after:bottom-0 after:content-[''] after:z-20 overflow-hidden dark:after:bg-gray-dark-300 sticky top-0 z-10">
      {tabs.length > 1 ? (
        <div className="h-16 w-full flex items-center p-4 bg-gray-light-100 dark:bg-gray-dark-300 relative">
          {tabs}
        </div>
      ) : (
        tabs
      )}
      <Modal.Close asChild>
        <Button
          variant="secondary"
          borderRadius="full"
          className="absolute top-3 right-3 !z-30"
          size="icon"
        >
          <X />
        </Button>
      </Modal.Close>
    </div>
  );
};

export default SidebarHeader;
