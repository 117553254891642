const searchCount = (state = {
  music: -1,
  sfx: -1
}, action) => {
  switch (action.type) {
    case 'UPDATE_SEARCH_RESULT_COUNTS':
      return Object.assign({}, state, action.result_counts);
    case 'RESET_SEARCH_RESULT_COUNTS':
      return Object.assign({}, state, {
        music: -1,
        sfx: -1
      });
    default:
      return state;
  }
};

export default searchCount;

