import Modal from '@/components/Modals/Core';
import { GoPremiumModalHeader } from '@/components/Modals/GoPremium/header';
import GoPremiumModalScreens from '@/components/Modals/GoPremium/screens';
import {
  GoPremiumModalScreenName,
  GoPremiumModalVariants,
} from '@/components/Modals/GoPremium/types';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';

interface GoBusinessModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initial?: string;
  variant?: GoPremiumModalVariants;
}

const GoBusinessModal = ({
  open,
  onOpenChange,
  children,
  initial,
  variant = GoPremiumModalVariants.Default,
}: GoBusinessModalProps) => {
  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange} trigger={children}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer
          initial={initial ?? GoPremiumModalScreenName.GoBusiness}
        >
          <GoPremiumModalHeader />
          <GoPremiumModalScreens variant={variant} />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default GoBusinessModal;
