declare global {
  interface Window {
    ttq?: {
      track: (e: string) => void;
      page: () => void;
    };
  }
}

export const addTikTokEvent = (event: string) => {
  if (window.ttq === undefined) {
    return;
  }

  window.ttq.track(event);
};
