import { urlJoin } from '@/utils/url-join';

import { isExcludedFromCdn, shouldAvoidCdn } from './cdn';
import { FetchError } from './error';

import type { NextIncomingMessage } from '@/types/request';

type BodyInit = Blob | Buffer | URLSearchParams | FormData | string;
type HeadersInit = [string, string][] | Record<string, string> | Headers;

type FetchOptions = RequestInit & {
  method?: string;
  headers?: HeadersInit;
  credentials?: 'include' | 'omit';
  body?: BodyInit | null;
  token?: string;
  tokenHeader?: 'X-Auth-Token' | 'token';
  req?: NextIncomingMessage;
  skipCDN?: boolean;
};

const isAbsolute = (endpoint: string) => /^https?:\/\//i.test(endpoint);

const getFullUrl = (endpoint: string, useCdn?: boolean) => {
  if (isAbsolute(endpoint)) {
    return endpoint;
  }

  // The API should really control which endpoints are cached or not, but for now
  // it seems the solution is to call the uncached API for user-specific things.
  const baseUrl = useCdn
    ? process.env.NEXT_PUBLIC_CDN_API
    : process.env.NEXT_PUBLIC_API;

  if (!baseUrl) {
    throw new Error(
      `the API/CDN_API env vars must be set to make the call to ${endpoint}`,
    );
  }

  return urlJoin(baseUrl, endpoint);
};

export const fetchJson = async <T extends object | string>(
  endpoint: string,
  {
    token,
    tokenHeader = 'X-Auth-Token',
    req,
    skipCDN,
    ...fetchOptions
  }: FetchOptions = {},
): Promise<T> => {
  // Use the CDN for unauthenticated GET requests only, unless we have toggled
  // the setting to avoid the CDN.
  const useCdn =
    !skipCDN &&
    !token &&
    !shouldAvoidCdn(req) &&
    !isExcludedFromCdn(endpoint) &&
    (fetchOptions.method ?? 'GET').toUpperCase() === 'GET';

  const url = getFullUrl(endpoint, useCdn);

  const res = await fetch(url, {
    ...fetchOptions,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { [tokenHeader]: token }),
      ...fetchOptions?.headers,
      // Disable disk caching while skipping CDN.
      ...(skipCDN && { 'Cache-Control': 'no-store' }),
    },
  });

  if (!res.ok) {
    throw new FetchError(res.status, res.statusText);
  }

  if (res.status === 204) {
    return {
      error: {
        status: 404,
        message: 'Content not found.',
      },
    } as T;
  }

  return res.json() as T;
};
