import { getAuthToken } from '@/auth';

import type {
  WhitelistYouTubeChannelResponse,
  YoutubeApiResponse,
  YouTubeChannelSnippet,
} from './types';

export const searchYoutubeChannel = async (searchTerm: string) => {
  const res = await fetch(
    `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${searchTerm}&type=channel&key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}`,
    {
      method: 'GET',
    },
  );

  const json = await res.json();

  return json as YoutubeApiResponse;
};

export const findYoutubeChannel = async (channelID: string) => {
  const res = await fetch(`/api/safelist/channel-data?channelID=${channelID}`, {
    method: 'GET',
  });

  const json = await res.json();

  return json as YouTubeChannelSnippet;
};

export const whitelistYouTubeChannel = async (
  channelId: string,
  channelName: string,
) => {
  const authToken = getAuthToken();

  if (!authToken) {
    return Promise.reject(new Error('No auth token found'));
  }

  const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_add`;
  const formData = new FormData();

  formData.append('channel_id', channelId);
  formData.append('channel_name', channelName);

  const res = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      token: authToken,
    },
    body: formData,
  });

  const json = await res.json();

  return json as WhitelistYouTubeChannelResponse;
};

export const removeWhitelistedYouTubeChannel = async (id: number) => {
  const authToken = getAuthToken();

  if (!authToken) {
    return Promise.reject(new Error('No auth token found'));
  }

  const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_delete`;
  const formData = new FormData();

  formData.append('id', id.toString());

  const res = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      token: authToken,
    },
    body: formData,
  });

  const json = await res.json();

  return json as { success: boolean };
};

export const fetchWhitelistedYouTubeChannels = async () => {
  const authToken = getAuthToken();

  if (!authToken) {
    return Promise.reject(new Error('No auth token found'));
  }

  const apiUrl = `${process.env.NEXT_PUBLIC_API}customer/whitelisted_channel_list`;

  const res = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      token: authToken,
    },
  });

  const json = await res.json();

  return json as WhitelistYouTubeChannelResponse;
};
