import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';

import { FileFormatContext } from '@/providers/FileFormatProvider';

import { CACHE_KEY } from './useSettings';

import type { FileFormat } from '@/types/file-format';
import type { Settings } from '@/types/settings';

const useFileFormat = () => {
  const context = useContext(FileFormatContext);

  if (!context) {
    throw new Error('useFileFormat must be used within a FileFormatProvider');
  }

  const { fileFormat, setFileFormat } = context;

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (newFormat: FileFormat) =>
      axios.post(
        'admin/user/updatefileformat',
        { file_format: newFormat },
        { baseURL: process.env.NEXT_PUBLIC_API, withCredentials: true },
      ),
    onMutate: async (newFormat: FileFormat) => {
      await queryClient.cancelQueries({ queryKey: CACHE_KEY });

      const previousSettings = queryClient.getQueryData<Settings>(CACHE_KEY);

      queryClient.setQueryData<Settings>(CACHE_KEY, (old) => {
        if (!old) {
          return old;
        }

        return {
          ...old,
          preferences: {
            ...old.preferences,
            file_format: newFormat,
          },
        };
      });

      return { previousSettings };
    },
    onError: (_err, _newFormat, ctx) => {
      if (ctx?.previousSettings) {
        queryClient.setQueryData<Settings>(CACHE_KEY, ctx.previousSettings);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: CACHE_KEY });
      setFileFormat(null);
    },
  });

  return {
    fileFormat,
    setFileFormat,
    mutation,
  };
};

export default useFileFormat;
