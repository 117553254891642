import * as RadixSwitch from '@radix-ui/react-switch';
import { forwardRef, useId } from 'react';
import { twMerge } from 'tailwind-merge';

type SwitchProps = RadixSwitch.SwitchProps & {
  thumbClassName?: string;
  label?: string;
};

const Switch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
  const { className, thumbClassName, label, ...rest } = props;
  const id = useId();

  return (
    <div className="flex items-center gap-2">
      <RadixSwitch.Root
        {...rest}
        ref={ref}
        id={id}
        className={twMerge(
          'w-9 h-5 bg-gray-medium-100 rounded-full relative data-[state=checked]:bg-uppbeat outline-none cursor-default transition-all duration-200',
          className,
        )}
      >
        <RadixSwitch.Thumb
          className={twMerge(
            'absolute top-1/2 left-0.5 -translate-y-1/2 rounded-full h-4 aspect-square bg-white data-[state=checked]:translate-x-full will-change-transform transition-transform duration-200',
            thumbClassName,
          )}
        />
      </RadixSwitch.Root>
      <label className="mt-1" htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export default Switch;
