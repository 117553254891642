import Modal from '@/components/Modals/Core';
import CreateAccountModalScreens from '@/components/Modals/CreateAccount/screens';
import LoginModalScreens from '@/components/Modals/Login/screens';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';

import { SignUpModalHeader } from './header';
import SignUpModalScreens from './screens';
import { SignUpModalScreenName } from './types';

interface SignUpModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initial?: SignUpModalScreenName;
}

const SignUpModal = ({
  open,
  onOpenChange,
  children,
  initial = SignUpModalScreenName.SignUpCreator,
}: SignUpModalProps) => {
  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange} trigger={children}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer initial={initial}>
          <SignUpModalHeader />
          <SignUpModalScreens />
          <CreateAccountModalScreens />
          <LoginModalScreens />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default SignUpModal;
