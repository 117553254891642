import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar6AccountSettings extends React.Component {
    render() {
        const Sidebar6AccountSettings = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
<g>
\t<path d="M7.2,4.5V3.5L6.28,3.42C6.23,3.2,6.14,2.99,6.03,2.8l0.59-0.7L5.9,1.38L5.2,1.97c-0.19-0.11-0.4-0.2-0.62-0.26
\t\tL4.5,0.8H3.5L3.42,1.72C3.2,1.77,2.99,1.86,2.8,1.97L2.1,1.38L1.38,2.1l0.59,0.7c-0.11,0.19-0.2,0.4-0.26,0.62L0.8,3.5V4.5
\t\tl0.91,0.08C1.77,4.8,1.86,5.01,1.97,5.2L1.38,5.9L2.1,6.62l0.7-0.59c0.19,0.11,0.4,0.2,0.62,0.26L3.5,7.2H4.5l0.08-0.91
\t\tC4.8,6.23,5.01,6.14,5.2,6.03l0.7,0.59L6.62,5.9L6.03,5.2c0.11-0.19,0.2-0.4,0.26-0.62L7.2,4.5z M4,5.09C3.4,5.09,2.91,4.6,2.91,4
\t\tC2.91,3.4,3.4,2.91,4,2.91S5.09,3.4,5.09,4C5.09,4.6,4.6,5.09,4,5.09z"/>
</g>
</svg>
`;
        return (
            <InlineSVG src={Sidebar6AccountSettings} />
        );
    }
}

export default Sidebar6AccountSettings;
