import { useEffect } from 'react';

import Loader from '@/styles/svg-components/Loader';

const PendingPayment = ({
  checkSubscriptionStatus,
}: {
  checkSubscriptionStatus: () => void;
}) => {
  useEffect(() => {
    checkSubscriptionStatus();
  }, [checkSubscriptionStatus]);

  return (
    <div className="flex  flex-col items-center justify-center gap-2">
      <Loader className="size-8 stroke-uppbeat" />
      <p className="text-center text-sm font-medium text-gray-light-200">
        We are processing your payment...
      </p>
    </div>
  );
};

export default PendingPayment;
