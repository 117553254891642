import { createContext, useContext, useMemo, useState } from 'react';

import type { AddToPlaylistContextType, Details } from './types';

const AddToPlaylistContext = createContext<AddToPlaylistContextType | null>(
  null,
);

export const AddToPlaylistProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, onOpenChange] = useState(false);
  const [details, setDetails] = useState<Details | null>(null);

  const value = useMemo(() => {
    return { details, setDetails, open, onOpenChange };
  }, [details, setDetails, open, onOpenChange]);

  return (
    <AddToPlaylistContext.Provider value={value}>
      {children}
    </AddToPlaylistContext.Provider>
  );
};

export const useAddToPlaylist = () => {
  const context = useContext(AddToPlaylistContext);

  if (!context) {
    throw new Error(
      'useAddToPlaylist must be used within a AddToPlaylistProvider',
    );
  }

  return context;
};
