import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { fetchWhitelistedYouTubeChannels } from '@/actions/subscriptionAction';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';

import type {
  WhitelistYouTubeChannelResponse,
  YouTubeChannel,
} from '@/components/UBWhitelist/types';

export const useWhitelistedChannels = () => {
  const userAuth = useAppState('userAuth');
  const { settings } = useSettings();

  return useQuery<YouTubeChannel[]>({
    queryKey: ['channelWhitelist', settings?.user.id ?? ''],
    queryFn: async () => {
      const response =
        (await fetchWhitelistedYouTubeChannels()) as WhitelistYouTubeChannelResponse;

      return response.list;
    },
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    enabled: !!userAuth,
  });
};
