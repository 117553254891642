import React from 'react';

import type { ChangeEvent, FocusEvent, KeyboardEvent, ReactNode } from 'react';

interface Option {
  key?: string;
  id?: string;
  value?: string;
  text?: string;
  name?: string;
}

interface InputFieldProps {
  type: 'text' | 'password' | 'single-select';
  name?: string;
  id?: string;
  className?: string;
  placeholder?: string;
  autoComplete?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  autoFocus?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void; // deprecated
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  options?: Option[];
  hasChildrenAsOption?: boolean;
  children?: ReactNode;
  ref?: React.Ref<HTMLInputElement | HTMLSelectElement>;
  maxLength?: number;
}

export const InputField: React.FC<InputFieldProps> = ({
  type,
  name = '',
  id = '',
  className = 'input',
  placeholder = '',
  autoComplete = 'on',
  value = '',
  defaultValue,
  onChange = () => null,
  onFocus = () => null,
  autoFocus = false,
  onBlur = () => null,
  onKeyPress = () => null,
  onKeyUp,
  disabled = false,
  options = [],
  hasChildrenAsOption = false,
  children,
  ref,
  maxLength,
}) => {
  switch (type) {
    case 'text':
      return (
        <input
          type="text"
          name={name}
          {...(id && { id })}
          className={className}
          placeholder={placeholder}
          {...(value && { value })}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref as React.Ref<HTMLInputElement>}
          maxLength={maxLength}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
        />
      );
    case 'password':
      return (
        <input
          type="password"
          name={name}
          {...(id && { id })}
          className={className}
          placeholder={placeholder}
          {...(value && { value })}
          defaultValue={defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref as React.Ref<HTMLInputElement>}
          maxLength={maxLength}
        />
      );
    case 'single-select':
      return (
        <select
          {...(id && { id })}
          name={name}
          className={className}
          onChange={onChange}
          {...(value && { value })}
        >
          {hasChildrenAsOption
            ? children
            : options.map((option) => (
                <option
                  key={option.key ?? option.id}
                  value={option.value ?? option.id}
                >
                  {option.text ?? option.name}
                </option>
              ))}
        </select>
      );

    default:
      return null;
  }
};
