import cleanDeep from 'clean-deep';
import Cookies from 'universal-cookie';

import getHost from './utils/host/get-host';

import type { NextIncomingMessage } from './types/request';
import type { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

/**
 * Get a cookie value.
 *
 * On the client this takes the cookie from `document.cookie`, on the server it
 * takes it from the Next request object.
 */
export const getUniversalCookie = <
  T extends string | number | boolean | object,
>(
  cookieName: string,
  {
    req,
    options,
  }: {
    req?: NextIncomingMessage;
    options?: CookieGetOptions;
  } = {},
): T | undefined => {
  if (typeof window !== 'undefined') {
    return new Cookies(document.cookie).get<T>(cookieName, options);
  }

  if (!req) {
    return undefined;
  }

  return new Cookies(req.headers?.cookie).get<T>(cookieName, options);
};

/**
 * Set a cookie value.
 */
export const setUniversalCookie = (
  cookieName: string,
  value: string | number | boolean | object,
  {
    options,
    req,
  }: {
    options?: CookieSetOptions;
    req?: NextIncomingMessage;
  } = {},
) => {
  const finalOptions = cleanDeep<CookieSetOptions>({
    path: '/',
    domain: getHost(req),
    ...options,
  });

  if (typeof window !== 'undefined') {
    new Cookies(document.cookie).set(cookieName, value, finalOptions);

    return;
  }

  if (!req) {
    throw new Error(
      'A Next.js request object is required to set a cookie from the server.',
    );
  }

  new Cookies(req.headers?.cookie).set(cookieName, value, finalOptions);
};

/**
 * Remove a cookie value.
 */
export const removeUniversalCookie = (
  cookieName: string,
  {
    req,
    options,
  }: {
    req?: NextIncomingMessage;
    options?: CookieSetOptions;
  } = {},
) => {
  const finalOptions = cleanDeep<CookieSetOptions>({
    path: '/',
    domain: getHost(req),
    ...options,
  });

  const finalOptionsApi = cleanDeep<CookieSetOptions>({
    path: '/',
    domain: 'api.uppbeat.io',
    ...options,
  });

  if (typeof window !== 'undefined') {
    new Cookies(document.cookie).remove(cookieName, finalOptions);
    new Cookies(document.cookie).remove(cookieName, finalOptionsApi);

    return;
  }

  if (!req) {
    throw new Error(
      'A Next.js request object is required to remove a cookie from the server.',
    );
  }

  new Cookies(req.headers?.cookie).remove(cookieName, finalOptions);
};
