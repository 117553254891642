import Modal from '@/components/Modals/Core';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';

import { GoPremiumModalHeader } from './header';
import GoPremiumModalScreens from './screens';
import { GoPremiumModalScreenName, GoPremiumModalVariants } from './types';

interface GoPremiumModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initial?: string;
  variant?: GoPremiumModalVariants;
}

const GoPremiumModal = ({
  open,
  onOpenChange,
  children,
  initial,
  variant = GoPremiumModalVariants.Default,
}: GoPremiumModalProps) => {
  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange} trigger={children}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer
          initial={initial ?? GoPremiumModalScreenName.GoPremium}
        >
          <GoPremiumModalHeader />
          <GoPremiumModalScreens variant={variant} />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default GoPremiumModal;
