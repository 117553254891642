export enum GoPremiumModalScreenName {
  GoPremium = 'go-premium',
  GoBusiness = 'go-business',
  GoPremiumCheckout = 'go-premium-checkout',
  GoBusinessCheckout = 'go-business-checkout',
  GoPremiumSafelist = 'go-premium-safelist',
  GoBusinessSafelist = 'go-business-safelist',
  GoPremiumWelcome = 'go-premium-welcome',
  GoBusinessWelcome = 'go-business-welcome',
}

export enum GoPremiumModalVariants {
  Default = 'default',
  TrackDownload = 'track-download',
  SFXDownload = 'sfx-download',
  NoCredits = 'no-credits',
}
