import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Portal } from '@/components/Portal';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';

import type { ReactNode } from 'react';

interface SideDrawerProps {
  children: ReactNode;
  onRemoveModal: () => void;
}

const isParentDialog = (target: HTMLElement) => {
  let node = target.parentNode as Element;

  // keep iterating unless null
  while (node != null) {
    if (node?.tagName?.toLowerCase() === 'dialog') {
      return true;
    }

    node = node.parentNode as Element;
  }

  return false;
};

const SideDrawer = ({
  children,
  onRemoveModal = () => {},
}: SideDrawerProps) => (
  <Portal>
    <TNamespaceProvider value="modal">
      <div className="side-drawer-wrapper">
        <div className="side-drawer-main">
          <OutsideClickHandler
            onOutsideClick={(e) => {
              const target = e.target as HTMLElement;

              const isClickInsideModal = isParentDialog(target);

              // To make compatible with nested modals, we shouldn't close if clicking inside a dialog.
              // This can be removed when we migrate these modals.
              if (
                target?.tagName?.toLowerCase() !== 'dialog' &&
                !isClickInsideModal
              ) {
                onRemoveModal();
              }
            }}
          >
            {children}
          </OutsideClickHandler>
        </div>
      </div>
    </TNamespaceProvider>
  </Portal>
);

export default SideDrawer;
