export enum SignUpModalScreenName {
  SignUpBusiness = 'signup-business',
  SignUpCreator = 'signup-creator',
  SignUpFree = 'signup-free',
  SignUpPremium = 'signup-premium',
  SignUpBusinessAlt = 'signup-business-alt',
}

export enum CreateAccountType {
  Free = 'free',
  Premium = 'premium',
}
