import { createContext, useCallback, useMemo, useState } from 'react';

import { USER_STATE_COOKIE_NAME } from '@/constants/cookies';
import { setUniversalCookie } from '@/cookies';

import type { FC, ReactNode } from 'react';

export interface UserState {
  hasLoggedIn?: string;
  isPremium?: string;
  isBusiness?: string;
  selectedBillingMode?: 'monthly' | 'yearly';
  abSeed?: number;
  abTimestamp?: number;
  avoidCdn?: number;
}

type UserStateKey = keyof UserState;

export interface IUserStateContext {
  setUserState: <T extends UserStateKey>(key: T, value: UserState[T]) => void;
  removeUserState: (key: UserStateKey) => void;
  clearUserState: () => void;
  /** @deprecated userState is unreliable and inconsistent */
  userState: UserState;
}

interface UserStateProviderProps {
  children: ReactNode;
  initialUserState?: UserState;
}

export const UserStateContext = createContext<IUserStateContext>({
  setUserState: () => {},
  removeUserState: () => undefined,
  userState: {},
  clearUserState: () => undefined,
});

const userStateDefaults: UserState = {
  selectedBillingMode: 'yearly',
};

/**
 * A provider for storing local user state.
 *
 * User state is stored in cookies so it is available on the server for
 * when we subsequently server-side render a page. It is also stored in
 * local React state for the case where we want to trigger a re-render when
 * a value is updated.
 */
export const UserStateProvider: FC<UserStateProviderProps> = ({
  children,
  initialUserState = {},
}) => {
  const [localUserState, setLocalUserState] = useState<UserState>({
    ...userStateDefaults,
    ...initialUserState,
  });

  const setUserState = useCallback(
    <T extends keyof UserState>(key: T, value: UserState[T]) => {
      const newUserState = { ...localUserState, [key]: value };

      if (typeof window !== 'undefined') {
        setUniversalCookie(USER_STATE_COOKIE_NAME, newUserState);
        localStorage.setItem('user', JSON.stringify(newUserState));
      }

      setLocalUserState(newUserState);
    },
    [localUserState],
  );

  const removeUserState = useCallback(
    (key: UserStateKey) => {
      const newUserState = { ...localUserState };

      delete newUserState[key];

      if (typeof window !== 'undefined') {
        setUniversalCookie(USER_STATE_COOKIE_NAME, newUserState);
      }

      setLocalUserState(newUserState);
    },
    [localUserState],
  );

  const clearUserState = useCallback(() => {
    const newUserState = { ...initialUserState };

    setUniversalCookie(USER_STATE_COOKIE_NAME, newUserState);
    localStorage.removeItem('user');

    setLocalUserState(newUserState);
  }, [initialUserState]);

  const ctx = useMemo(
    (): IUserStateContext => ({
      setUserState,
      removeUserState,
      clearUserState,
      userState: localUserState,
    }),
    [setUserState, removeUserState, localUserState, clearUserState],
  );

  return (
    <UserStateContext.Provider value={ctx}>
      {children}
    </UserStateContext.Provider>
  );
};
