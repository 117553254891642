import { T } from '@/components/T';

interface PriceProps {
  symbolLeft: string;
  integer: string;
  decimals: string;
}

const Price = ({ symbolLeft, integer, decimals }: PriceProps) => {
  return (
    <div className="flex justify-between py-1.5">
      <span className="text-sm flex-1">
        <T keyName="modal.checkout.total">Total to pay now:</T>
      </span>
      <p className="relative mr-9 !text-black dark:!text-white flex-shrink-0">
        <span className="text-base align-text-bottom !text-gray-medium-400 dark:!text-gray-medium-100">
          {symbolLeft}
        </span>
        <span className="font-heading text-[3.375rem] leading-none relative !text-inherit">
          {integer}
          <sup className="text-base absolute top-4 whitespace-nowrap !text-inherit">
            {decimals}
          </sup>
        </span>
      </p>
    </div>
  );
};

export default Price;
