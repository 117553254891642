import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useMeasure } from 'react-use';

import Toolbar from './Toolbar';

import type { CSSProperties } from 'react';

const AdminBarContainer = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const [toolbarChildrenRef, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Toolbar.Root asChild>
      <motion.div
        drag
        dragMomentum={false}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        whileDrag={{ scale: 1.1 }}
        className={clsx(
          'bg-gray-dark-200 dark:bg-gray-dark-400 text-white absolute right-3 bottom-3 pointer-events-auto rounded-full shadow-xl border-solid border-gray-dark-100 flex justify-center items-center overflow-hidden',
          isDragging && 'cursor-grab',
        )}
      >
        <div
          className={clsx(
            'h-full flex justify-center items-stretch text-nowrap z-50 p-1',
            isDragging && 'pointer-events-none cursor-grab',
          )}
        >
          <div
            className={clsx(
              'h-full flex justify-start items-stretch gap-2 text-nowrap will-change-transform transition-[width,opacity,filter] ease-in-out duration-500 w-[var(--toolbar-width)] overflow-hidden',
              isOpen ? 'opacity-100' : 'opacity-0',
              isOpen ? 'blur-none' : 'blur-sm',
            )}
            style={
              {
                '--toolbar-width': isOpen ? `${width}px` : '0px',
              } as CSSProperties
            }
          >
            <div
              ref={toolbarChildrenRef}
              className="h-full flex justify-center items-stretch gap-2 text-nowrap will-change-transform "
            >
              {children}
              <Toolbar.Separator className="mr-2" />
            </div>
          </div>

          <Toolbar.Button
            onClick={() => setIsOpen(!isOpen)}
            label={isOpen ? 'Close menu' : 'Open menu'}
          >
            MV
          </Toolbar.Button>
        </div>
      </motion.div>
    </Toolbar.Root>
  );
};

export default AdminBarContainer;
