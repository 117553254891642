import { useCallback, useContext } from 'react';

import {
  AccountModalNames,
  ModalConversionPoints,
  useAccountModals,
} from '@/components/Modals/Account';
import { UserStateContext } from '@/providers/UserStateProvider';

export const useSignInOrSignUp = () => {
  const { userState } = useContext(UserStateContext);
  const { setCurrentModal, openModalWithConversionPoint } = useAccountModals();

  const signInOrSignUp = useCallback(() => {
    return userState.hasLoggedIn
      ? setCurrentModal(AccountModalNames.Login)
      : openModalWithConversionPoint(
          AccountModalNames.SignUp,
          ModalConversionPoints.SignUp,
        );
  }, [openModalWithConversionPoint, setCurrentModal, userState.hasLoggedIn]);

  return { signInOrSignUp };
};
