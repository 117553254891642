const INITIAL_STATE = {
  error: false,
  showLoader: false,
  showRecaptchaV2: false,
  isSignUpSuccess: false,
};

const modal = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SIGNUP_ERROR':
      return Object.assign({}, state, { error: action.error });
    case 'SIGNUP_SHOW_LOADER':
      return Object.assign({}, state, { showLoader: action.showLoader });
    case 'SHOW_RECAPTCHA_V2':
      return Object.assign({}, state, { showRecaptchaV2: action.showRecaptchaV2 });

    case 'SIGNUP_SUCCESS':
      return Object.assign({}, state,
        {
          isSignUpSuccess: action.payload,
        });

    default:
      return state;
  }
};

export default modal;
