import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Portal } from '@/components/Portal';

import type { ReactNode } from 'react';

interface UBButtonDropdownFixedProps {
  buttonWidth: number | undefined;
  children: ReactNode;
  className?: string;
  columns: number;
  offset: DOMRect;
  onOutsideClick: () => void;
  position: string;
}

const UBButtonDropdownFixed = ({
  buttonWidth,
  children,
  className,
  columns,
  offset,
  onOutsideClick,
  position,
}: UBButtonDropdownFixedProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [style, setStyle] = useState({});

  const ubBtnDropdownClasses = clsx('ub-btn-dropdown', `columns-${columns}`, {
    [className as string]: className,
    [position]: position,
  });

  useEffect(() => {
    const initialStyle = {
      '--ubbtn-calc-width': `${buttonWidth}px`,
    };

    if (dropdownRef.current !== null) {
      if (position === 'bottom-left') {
        setStyle({
          ...initialStyle,
          top: offset.top + offset.height + 12,
          right: window.innerWidth - offset.right,
        });
      }

      if (position === 'bottom-center' && buttonWidth) {
        setStyle({
          ...initialStyle,
          top: offset.top + offset.height + 12,
          left:
            offset.right -
            buttonWidth / 2 -
            dropdownRef.current.clientWidth / 2 -
            1,
        });
      }

      if (position === 'bottom-right') {
        setStyle({
          ...initialStyle,
          top: offset.top + offset.height + 12,
          left: offset.left,
        });
      }

      if (position === 'top-left') {
        setStyle({
          ...initialStyle,
          top: offset.top - dropdownRef.current.clientHeight - 12,
          right: window.innerWidth - offset.right,
        });
      }

      if (position === 'top-center' && buttonWidth) {
        setStyle({
          ...initialStyle,
          top: offset.top - dropdownRef.current.clientHeight - 12,
          left:
            offset.right -
            buttonWidth / 2 -
            dropdownRef.current.clientWidth / 2 -
            1,
        });
      }

      if (position === 'top-right') {
        setStyle({
          ...initialStyle,
          top: offset.top - dropdownRef.current.clientHeight - 12,
          left: offset.left,
        });
      }
    }
  }, [
    offset.height,
    offset.left,
    offset.right,
    offset.top,
    position,
    buttonWidth,
  ]);

  return (
    <Portal>
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <div className="ub-btn-dropdown-container">
          <div
            className={ubBtnDropdownClasses}
            style={style}
            ref={dropdownRef}
            onClick={onOutsideClick}
          >
            {children}
          </div>
        </div>
      </OutsideClickHandler>
    </Portal>
  );
};

export default UBButtonDropdownFixed;
