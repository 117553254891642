import Toolbar from '@/components/AdminBar/Toolbar';
import { useIsClient } from '@/hooks/useIsClient';
import { useLocalSettings } from '@/providers/LocalSettingsProvider';

import EditModeIcon from './Icon';

const EditMode = () => {
  const { localSettings, setLocalSettings } = useLocalSettings();

  const isClient = useIsClient();

  const isEditMode = localSettings.editMode;

  if (!isClient) {
    return null;
  }

  return (
    <Toolbar.ToggleGroup
      type="single"
      value={isEditMode ? 'edit' : ''}
      onValueChange={(value) =>
        setLocalSettings({ ...localSettings, editMode: value === 'edit' })
      }
    >
      <Toolbar.ToggleItem value="edit" label="Edit mode">
        <EditModeIcon />
      </Toolbar.ToggleItem>
    </Toolbar.ToggleGroup>
  );
};

export default EditMode;
