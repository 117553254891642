import { createAccountModalTabs } from '@/components/Modals/CreateAccount/tabs';
import { loginModalTabs } from '@/components/Modals/Login/tabs';
import SidebarHeader from '@/components/Modals/Sidebar/components/SidebarHeader';
import useDynamicSidebarHeader from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

import { signUpModalTabs } from './tabs';

const SignUpModalHeader = () => {
  // Used for controlling the sidebar header when specific screens are active
  useDynamicSidebarHeader({
    dynamicTabs: [
      ...signUpModalTabs,
      ...loginModalTabs,
      ...createAccountModalTabs,
    ],
  });

  return <SidebarHeader />;
};

export { SignUpModalHeader };
