import clsx from 'clsx';
import { useState } from 'react';

import { T } from '@/components/T';
import Tooltip from '@/components/Tooltip';
import UBButton from '@/components/UBButton';
import { useTranslate } from '@/hooks/useTranslate';

import styles from './styles.module.css';

interface ChannelIDInputProps {
  handleSubmitChannelID: (id: string) => void;
}

export const ChannelIDInput: React.FC<ChannelIDInputProps> = ({
  handleSubmitChannelID,
}) => {
  const [channelID, setChannelID] = useState('');
  const { t } = useTranslate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setChannelID(value);
  };

  const channelIDLength = channelID.length;

  return (
    <div
      className={clsx(
        styles.whitelistChannelID,
        'mt-2 border-solid border-0 border-t border-t-gray-light-400 dark:border-t-gray-dark-100',
      )}
    >
      <div className={styles.whitelistInfo}>
        <T>Can’t find your channel?</T>
        <br />
        <T>Enter your channel ID below</T>
        <UBButton
          className={styles.whitelistInfoButton}
          variant="uppbeat"
          href="https://support.google.com/youtube/answer/3250431?hl=en-GB"
          target="_blank"
          rel="noreferrer"
          icon={
            <svg
              width="6"
              height="8"
              viewBox="0 0 6 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.20007 4.86667C2.13823 4.86667 2.08412 4.84815 2.03775 4.81111C1.9991 4.76667 1.97978 4.71481 1.97978 4.65556V4.18889C1.97978 3.98148 2.06094 3.77778 2.22326 3.57778C2.3933 3.37778 2.57881 3.21111 2.77978 3.07778C2.98074 2.94444 3.16239 2.79259 3.3247 2.62222C3.49475 2.45185 3.57978 2.28889 3.57978 2.13333C3.57978 1.95556 3.49475 1.8037 3.3247 1.67778C3.16239 1.54444 2.95755 1.47778 2.71021 1.47778C2.48606 1.47778 2.28123 1.53704 2.09572 1.65556C1.91021 1.76667 1.76335 1.85926 1.65514 1.93333C1.55466 2 1.46577 2 1.38847 1.93333L0.681227 1.26667C0.573014 1.17778 0.573014 1.08148 0.681227 0.977778C0.704415 0.948148 0.739198 0.914815 0.785574 0.877778C0.831951 0.833333 0.92857 0.755556 1.07543 0.644445C1.23002 0.525926 1.39234 0.425926 1.56239 0.344445C1.73243 0.255556 1.94886 0.177778 2.21166 0.111111C2.48219 0.037037 2.75659 0 3.03485 0C3.66867 0 4.22132 0.188889 4.69282 0.566667C5.16432 0.937037 5.40007 1.3963 5.40007 1.94444C5.40007 2.21111 5.33823 2.45185 5.21456 2.66667C5.09862 2.88148 4.95562 3.05926 4.78557 3.2C4.61553 3.33333 4.44548 3.46667 4.27543 3.6C4.10538 3.72593 3.95852 3.87778 3.83485 4.05556C3.71891 4.23333 3.66094 4.43333 3.66094 4.65556C3.66094 4.71481 3.63775 4.76667 3.59137 4.81111C3.55272 4.84815 3.50248 4.86667 3.44065 4.86667H2.20007ZM1.7247 6.95556C1.7247 6.66667 1.83292 6.41852 2.04934 6.21111C2.26577 5.9963 2.52084 5.88889 2.81456 5.88889C3.11601 5.88889 3.37495 5.9963 3.59137 6.21111C3.81553 6.41852 3.9276 6.66667 3.9276 6.95556C3.9276 7.23704 3.81553 7.48148 3.59137 7.68889C3.37495 7.8963 3.11601 8 2.81456 8C2.52084 8 2.26577 7.8963 2.04934 7.68889C1.83292 7.48148 1.7247 7.23704 1.7247 6.95556Z"
                fill="white"
              />
            </svg>
          }
          aria-label={t('How to find your channel ID?')}
          borderRadius="full"
          size="xsmall"
        />
      </div>
      <div className={styles.channelIDInputWrapper}>
        <div className={clsx(styles.inputWrapper, 'input-wrapper')}>
          <input
            className={clsx(styles.input, 'input')}
            type="text"
            placeholder={t('Channel ID')}
            onChange={handleChange}
            value={channelID}
            maxLength={24}
          />
        </div>
        <Tooltip
          className={styles.tooltip}
          label={`${t('Please confirm your ID is correct')}: ${channelID}`}
        >
          <UBButton
            className={styles.confirmButton}
            variant="uppbeat"
            label={t('Confirm')}
            padding="0"
            borderRadius="full"
            disabled={channelIDLength !== 24}
            width="84px"
            onClick={() => handleSubmitChannelID(channelID)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
