const initialState = true;

const sidebarCollapsed = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_SIDEBAR':
			return false;
		case 'COLLAPSE_SIDEBAR':
			return true;
		default:
			return state;
	}
};

export default sidebarCollapsed;
