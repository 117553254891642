import { type ClassValue, clsx } from 'clsx';
import { marked } from 'marked';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const stripMarkdown = (markdown: string): string => {
  const html = marked.parse(markdown, {
    async: false,
  });

  return html.replace(/<\/?[^>]+(>|$)/g, '').trim();
};
