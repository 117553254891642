import { USER_STATE_COOKIE_NAME } from '@/constants/cookies';
import { getUniversalCookie } from '@/cookies';

import type { UserState } from '@/providers/UserStateProvider';
import type { NextIncomingMessage } from '@/types/request';

export const isExcludedFromCdn = (endpoint: string) => {
  let isEndpointExcludedFromCdn = false;

  if (endpoint.startsWith('/setup_frontend')) {
    isEndpointExcludedFromCdn = true;
  }

  return isEndpointExcludedFromCdn;
};

export const shouldAvoidCdn = (req?: NextIncomingMessage) =>
  !!getUniversalCookie<UserState>(USER_STATE_COOKIE_NAME, { req })?.avoidCdn;

export const conditionallyGetCdnApi = (req?: NextIncomingMessage) =>
  shouldAvoidCdn(req)
    ? process.env.NEXT_PUBLIC_API
    : process.env.NEXT_PUBLIC_CDN_API;
