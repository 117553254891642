import * as Portal from '@radix-ui/react-portal';
import { useEffect, useRef, useState } from 'react';

import Toolbar from '@/components/AdminBar/Toolbar';

import ReactQueryIcon from './Icon';
import { ReactQueryDevTools } from './ReactQueryDevTools';

const TanstackQuery = () => {
  const [isDevtoolsOpen, setIsDevtoolsOpen] = useState<string>('');

  const portalNode = useRef<HTMLElement | null>(null);

  useEffect(() => {
    portalNode.current = document.getElementById('__next');
  }, []);

  return (
    <>
      <Toolbar.ToggleGroup
        type="single"
        value={isDevtoolsOpen}
        onValueChange={(e: string) => setIsDevtoolsOpen(e)}
      >
        <Toolbar.ToggleItem value="devtools" label="React Query">
          <ReactQueryIcon />
        </Toolbar.ToggleItem>
      </Toolbar.ToggleGroup>
      {!!isDevtoolsOpen && (
        <Portal.Root container={portalNode.current}>
          <ReactQueryDevTools onClose={() => setIsDevtoolsOpen('')} />
        </Portal.Root>
      )}
    </>
  );
};

export default TanstackQuery;
