import * as React from 'react';

const _ChevronDown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 16 9" fill="none" {...props}>
      <path
        d="M7.241 8.707a1 1 0 001.414 0l6.364-6.364A1 1 0 0013.605.93L7.948 6.586 2.291.929A1 1 0 00.877 2.343l6.364 6.364zM6.948 6.5V8h2V6.5h-2z"
        fill="currentColor"
      />
    </svg>
  );
};

const ChevronDown = React.memo(_ChevronDown);

export default ChevronDown;
