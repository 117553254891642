import { twMerge } from 'tailwind-merge';

interface ThemeIconProps {
  children: React.ReactNode;
  className?: string;
}

const ThemeIcon = ({ children, className }: ThemeIconProps) => {
  return (
    <div
      className={twMerge(
        'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center opacity-100 transition-all duration-300',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ThemeIcon;
