import { createContext, useMemo, useState } from 'react';

import { useSettings } from '@/hooks/useSettings';
import { FileFormat } from '@/types/file-format';

interface FileFormatContextProps {
  fileFormat: FileFormat | null;
  setFileFormat: (fileFormat: FileFormat | null) => void;
}

interface FileFormatProviderProps {
  children: React.ReactNode;
}

const FileFormatContext = createContext<FileFormatContextProps>({
  fileFormat: null,
  setFileFormat: () => {},
});

const FileFormatProvider = ({ children }: FileFormatProviderProps) => {
  const { settings } = useSettings();
  const [fileFormat, setFileFormat] = useState<FileFormat | null>(null);

  const defaultFormat = settings?.isBusiness
    ? settings?.preferences.file_format ?? FileFormat.AskEveryTime
    : FileFormat.MP3;

  const value = useMemo(
    () => ({ fileFormat: fileFormat ?? defaultFormat, setFileFormat }),
    [fileFormat, setFileFormat, defaultFormat],
  );

  return (
    <FileFormatContext.Provider value={value}>
      {children}
    </FileFormatContext.Provider>
  );
};

export { FileFormatContext, FileFormatProvider };
