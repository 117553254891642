const INITIAL_STATE = {
  id: 1,
  symbol_left: '$',
  value: 1,
  code: 'USD',
  isAmbiguous: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_CURRENCY_DATA':
      action.currency.isAmbiguous = (action.currency.symbol_left === '$');
      return Object.assign({}, state, action.currency);
    default:
      return state;
  }
};
