const INITIAL_STATE = {
  finishAction: false,
};

const loginFlow = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_LOGIN_FINISH_ACTION':
      return Object.assign({}, state, { finishAction: action.finishAction });
    default:
      return state;
  }
};

export default loginFlow;
