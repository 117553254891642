import React from 'react';
import InlineSVG from 'svg-inline-react';

class Spinner extends React.Component {
  render() {
    const assetSpinner = `<svg class="spinner" width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
   <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>`;
    return (
      <InlineSVG src={assetSpinner} />
    );
  }
}

export default Spinner;
