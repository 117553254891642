import { createContext, useContext } from 'react';

interface PopoverContextType {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const PopoverContext = createContext<PopoverContextType | undefined>(undefined);

export const usePopover = () => {
  const context = useContext(PopoverContext);

  if (context === undefined) {
    throw new Error('usePopover must be used within a PopoverProvider');
  }

  return context;
};

export const PopoverProvider = PopoverContext.Provider;
