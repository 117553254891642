/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import tracks from './reducers/tracksReducer';
import allTracks from './reducers/allTracksReducer';
import trackIds from './reducers/trackIdsReducer';
import allTrackIds from './reducers/allTrackIdsReducer';
import subscriptionCart from './reducers/cartReducer';
import currency from './reducers/currencyReducer';
import country from './reducers/countryReducer';
import license from './reducers/licenseReducer';
import searchCount from './reducers/searchCountReducer';
import collections from './reducers/collectionsReducer';
import carousels from './reducers/carouselsReducer';
import playlist from './reducers/playlistReducer';
import playlistFrame from './reducers/playlistFrameReducer';
import deals from './reducers/dealsReducer';
import modal from './reducers/modalReducer';
import signup from './reducers/signupReducer';
import loginFlow from './reducers/loginReducer';
import headerNotifications from './reducers/headerReducer';
import favorites from './reducers/favoritesReducer';
import following from './reducers/followingReducer';
import subscriptionData from './reducers/subscriptionReducer';
import footerPlayer from './reducers/footerPlayerReducer';
import userAuth from './reducers/authenticationReducer';
import adminBar from './reducers/adminBarReducer';
import jumpBackInContext from './reducers/jumpBackInContextReducer';
import sidebarCollapsed from './reducers/sidebarReducer';
import searchVisible from './reducers/searchReducer';
import downloadIntent from './reducers/downloadIntentReducer';
import premiumDownloadIntent from './reducers/premiumDownloadIntentReducer';
import temporaryAnchor from './reducers/anchorReducer';
import ubModalStep from './reducers/ubModalReducer';
import mobileTrackMenu from './reducers/mobileTrackMenuReducer';
import waves from './reducers/wavesReducer';
import { combineReducers } from 'redux';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    tracks,
    trackIds,
    allTracks,
    allTrackIds,
    subscriptionCart,
    currency,
    country,
    license,
    collections,
    searchCount,
    temporaryAnchor,
    downloadIntent,
    premiumDownloadIntent,
    carousels,
    jumpBackInContext,
    playlist,
    playlistFrame,
    deals,
    modal,
    userAuth,
    adminBar,
    signup,
    loginFlow,
    headerNotifications,
    favorites,
    following,
    subscriptionData,
    footerPlayer,
    sidebarCollapsed,
    ubModalStep,
    mobileTrackMenu,
    waves,
    searchVisible,
    ...asyncReducers,
  });
}
