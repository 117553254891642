import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { searchYoutubeChannel } from '@/components/UBWhitelist/api';

import type { YoutubeResponseItem } from '@/components/UBWhitelist/types';

export const useSearchYoutubeChannel = (inputValue: string) => {
  return useQuery<YoutubeResponseItem[]>({
    queryKey: ['searchYoutubeChannel', inputValue],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (inputValue.length < 3) {
        return [];
      }

      const results = await searchYoutubeChannel(inputValue);

      return results.items;
    },
  });
};
