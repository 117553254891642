import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import { AnimatePresence, motion } from 'framer-motion';
import { CircleAlert } from 'lucide-react';
import { useSessionStorage } from 'react-use';

import { Button } from './ui/button';

const IssueBanner = () => {
  const issueBannerIsOn = useFeatureIsOn('issue-banner');
  const issueBannerCopy = useFeatureValue(
    'issue-banner-text',
    "We're working to resolve an issue with Uppbeat Credits & safelisting channels. Thanks for your patience.",
  );

  const [hasDismissedIssueBanner, setHasDismissedIssueBanner] =
    useSessionStorage('has-dismissed-issue-banner', false);

  const showIssueBanner = issueBannerIsOn && !hasDismissedIssueBanner;

  const dismissBanner = () => {
    setHasDismissedIssueBanner(true);
  };

  return (
    <AnimatePresence>
      {showIssueBanner && (
        <motion.div
          className="bg-red-lighter/80 dark:bg-red-dark/80 backdrop-blur-lg px-container ~p-3/4 text-center text-black flex justify-between items-center fixed bottom-4 inset-x-4 z-50 rounded-btn border-1 border-solid border-red  flex-col sm:flex-row ~gap-2/8 lg:max-w-fit mx-auto [.footer-player-active_&]:bottom-20"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex items-center ~gap-3/2">
            <CircleAlert className="size-5 fill-none text-red dark:text-red-lighter flex-shrink-0" />
            <p className="text-red dark:text-red-lighter m-0 text-balance ~text-sm/base font-sans text-left">
              {issueBannerCopy}
            </p>
          </div>
          <Button
            variant="destructive"
            className="w-full sm:w-auto"
            onClick={dismissBanner}
          >
            Dismiss
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default IssueBanner;
