import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { useSettings } from '@/hooks/useSettings';
import { createTrackingSession, getPageType } from '@/utils/tracking';

import type { InitializeTrackingParams } from '@/utils/tracking/types';

interface TrackingProviderProps {
  children: React.ReactNode;
}

const TrackingContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initializeTracking: async (_params: InitializeTrackingParams) => {},
});

export const useTracking = () => {
  const ctx = useContext(TrackingContext);

  return ctx;
};

const sessionId = createTrackingSession();

export const TrackingProvider = ({ children }: TrackingProviderProps) => {
  const router = useRouter();
  const { settings } = useSettings();

  const user = settings?.user;

  const shouldIgnoreTracking = user?.hasAccountAdmin ?? user?.hasAccountArtist;

  const initializeTracking = useCallback(
    async ({
      content,
      access,
      endSlug,
      assetType,
      contentType,
    }: InitializeTrackingParams) => {
      if (shouldIgnoreTracking) {
        return;
      }

      if (!sessionId) {
        return;
      }

      // If we don't have an asset type or content type, try to infer it from the slug.
      if ((!assetType || !contentType) && endSlug) {
        const inferredData = getPageType(endSlug);

        // If we can't infer the asset type or content type, don't track.
        if (inferredData == null) {
          return;
        }

        assetType = inferredData.assetType;
        contentType = inferredData.contentType;
      }

      if (content === 'Playlist generator') {
        content = 'AI Playlist Generator';
      }

      const userId = settings?.user?.id ?? null;

      // 0 = Free, 1 = Premium, 2 = Business, null = logged out
      let accountType = userId ? 0 : null;

      if (settings?.isPremium) {
        accountType = 1;
      }

      if (settings?.isBusiness) {
        accountType = 2;
      }

      if (sessionId) {
        try {
          const data = await fetch(
            `${process.env.NEXT_PUBLIC_API}tracking/initiate`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                session_id: sessionId,
                user_id: userId,
                start_url: router.asPath,
                fulfilment_event_media: assetType,
                fulfilment_event_content: content,
                fulfilment_event_content_type: contentType,
                fulfilment_event_access: access,
                subscription_type: accountType,
              }),
            },
          );

          const { uuid } = await data.json();

          if (typeof uuid === 'string') {
            try {
              sessionStorage.setItem('uuid', uuid);
            } catch (e) {
              console.warn(e);
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    },
    [
      router.asPath,
      settings?.isBusiness,
      settings?.isPremium,
      settings?.user?.id,
      shouldIgnoreTracking,
    ],
  );

  return (
    <TrackingContext.Provider
      value={useMemo(() => ({ initializeTracking }), [initializeTracking])}
    >
      {children}
    </TrackingContext.Provider>
  );
};
