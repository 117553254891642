import { createContext, useMemo } from 'react';

import { useSettings } from '@/hooks/useSettings';

import type { Settings } from '@/types/settings';
import type { ReactNode } from 'react';

export interface ISettingsContext {
  settings?: Settings;
  invalidateSettings: () => void;
}

interface SettingsProviderProps {
  children: ReactNode;
  initialSettings?: Settings;
}

export const SettingsContext = createContext<ISettingsContext>({
  invalidateSettings: () => {},
});

/**
 * A provider for global site settings.
 *
 * The global site settings are initially loaded by calling the setup frontend
 * endpoint on the server, via the `getInitialProps`of the `_app.tsx` page.
 * These settings are then hydrated in the browser. Once the query becomes
 * stale the settings will be fetched again.
 * @see https://nextjs.org/docs/api-reference/data-fetching/get-initial-props
 * @see https://tanstack.com/query/v4/docs/react/guides/ssr
 */
export const SettingsProvider: React.FC<SettingsProviderProps> = ({
  children,
  initialSettings,
}: SettingsProviderProps) => {
  const { settings, invalidateSettings } = useSettings({
    initialData: initialSettings,
  });

  const ctx = useMemo(
    (): ISettingsContext => ({ settings, invalidateSettings }),
    [settings, invalidateSettings],
  );

  return (
    <SettingsContext.Provider value={ctx}>{children}</SettingsContext.Provider>
  );
};
