import { memo } from 'react';

const Card = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.59095C0 1.68721 0.732625 0.95459 1.63636 0.95459H16.3636C17.2674 0.95459 18 1.68721 18 2.59095V12.4091C18 13.3129 17.2674 14.0455 16.3636 14.0455H1.63636C0.732625 14.0455 0 13.3129 0 12.4091V2.59095Z"
        fill="#F23D75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3636 2.18186H1.63636C1.41043 2.18186 1.22727 2.36502 1.22727 2.59095V12.4091C1.22727 12.6351 1.41043 12.8182 1.63636 12.8182H16.3636C16.5896 12.8182 16.7727 12.6351 16.7727 12.4091V2.59095C16.7727 2.36502 16.5896 2.18186 16.3636 2.18186ZM1.63636 0.95459C0.732625 0.95459 0 1.68721 0 2.59095V12.4091C0 13.3129 0.732625 14.0455 1.63636 14.0455H16.3636C17.2674 14.0455 18 13.3129 18 12.4091V2.59095C18 1.68721 17.2674 0.95459 16.3636 0.95459H1.63636Z"
        fill="white"
      />
      <path
        d="M0.818176 3.81812H17.1818V7.09084H0.818176V3.81812Z"
        fill="white"
      />
      <path d="M2.45453 8.31812H9.81817V9.1363H2.45453V8.31812Z" fill="white" />
    </svg>
  );
};

export default memo(Card);
