
import { conditionallyGetCdnApi } from '../api/cdn';

import { getAuthToken } from "../auth";

export function fetchTrackCount() {
  return fetch(`${conditionallyGetCdnApi()}tracks`, {
    method: "GET",
    // mode: 'CORS',
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function fetchTrack(artistId, trackId) {
  let apiUrl = `${conditionallyGetCdnApi()}tracks/${artistId}/artists/${trackId}/track`;

  if (!trackId) {
    apiUrl = `${process.env.NEXT_PUBLIC_API}tracks/${artistId}`;
  }

  return fetch(apiUrl, {
    method: "GET",
    // mode: 'CORS',
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function fetchPlaylistTracks(playlistId, page = 1) {
  return function (dispatch) {
    dispatch(requestTracks());
    return fetch(`${process.env.NEXT_PUBLIC_API}tracks/${playlistId}/playlists/${page}`, {
      method: "GET",
      cache: 'no-store'
    })
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((json) => {
        dispatch(
          receiveTracks(json),
          dispatch(receiveTracksIds(page, json.result_ids))
        );
      });
  };
}

export function fetchFavouriteTracks(page = 1) {
  const authKey = getAuthToken();
  return function (dispatch) {
    if (page >= 2) {
      dispatch(showMoreTracksLoading(true));
    } else {
      dispatch(requestTracks());
    }
    return fetch(`${process.env.NEXT_PUBLIC_API}tracks/${page}/favourite`, {
      method: "GET",
      headers: {
        "X-Auth-Token": authKey || null,
      },
    })
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((json) => {
        const results = json.results ? Object.values(json.results) : [];

        if (page >= 2) {
          dispatch(receiveTracksIds(page, json.result_ids));
          dispatch(showMoreTracksLoading(false));
          dispatch(receiveFetchMoreTracks(results));
          if ((json.results ?? []).length < 1) {
            dispatch({
              type: "SHOW_MORE_TRACK_HIDE",
            });
          }
        } else {
          dispatch(
            receiveTracks({
              ...json,
              results,
            }),
            dispatch(receiveTracksIds(page, json.result_ids))
          );
        }
      });
  };
}

export function fetchTracks( options ) {
  const authToken = getAuthToken();
  if (options.page > 1) {
    options.merge = true;
    fetchAllTracks( options );
    return;
  }
  return function (dispatch) {
    dispatch(requestTracks());
    return fetch(`${ conditionallyGetCdnApi() }tracks/${options.type}/types/${options.id}/ids/weighted`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": authToken || null,
      },
    })
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((response) => {
        if (response.error) {
          throw new Error("Something failed");
        } else {
          return response;
        }
      })
      .then((json) => {
        for (let i = 0; i < json.results.length; i++) {
          json.results[i].current_engagement_group = json.result_ids[i].group;
        }

        options.excluded = json.result_ids;

        dispatch(
          receiveTracks(json),
          dispatch(receiveTracksIds(1, json.result_ids)),
          dispatch(moreTracksLoading(true)),
          dispatch(receiveAllTracksIds(json.result_ids)),
          dispatch(fetchAllTracks( options ))
        );
      })
      .catch(async (e) => {
        setMusicLoadError();
      });
  };
}

export function setMusicLoadError() {
  const circleLoader = document.getElementsByClassName("circle-loader");
  [].forEach.call(circleLoader, function(element) {
    element.style.display = "none";
  });

  const musicLoadingMessageTitle = document.getElementsByClassName("music-loading-message-title");
  [].forEach.call(musicLoadingMessageTitle, function(element) {
    element.textContent = "Something went wrong. Please reload the page or try again later.";
  });
}

export function resetTrackArray() {
  return function (dispatch) {
      dispatch(moreTracksLoading(true));
  }
}


export function setTrackArray( tracks ) {
  let timestamp = Date.now();

  let resultsJson = {
    results: tracks,
    result_ids: [],
  };

  tracks.forEach(element => resultsJson.result_ids.push(element.id));

  return function (dispatch) {
    dispatch(requestTracks());
    dispatch(setLatestCallTimestamp(timestamp));
    dispatch(
      receiveTracks(resultsJson, timestamp),

      dispatch(moreTracksLoading(false)),
      dispatch(receiveTracksIds(1, resultsJson.result_ids, timestamp))
    );
  }
}



export function setTracks( tracks ) {
  let timestamp = Date.now();

  return function (dispatch) {
    dispatch(requestTracks());
    dispatch(setLatestCallTimestamp(timestamp));
    dispatch(
      receiveTracks(tracks, timestamp),

      dispatch(moreTracksLoading(false)),
      dispatch(receiveTracksIds(1, tracks.result_ids, timestamp))
    );
  }
}

const defaultTrackOptions = {
  type: '',
  id: 1,
  energy: 0,
  vocal: 0,
  sort: "Relevance",
  duration: 0,
  styleFilters: [],
  page: 1,
  excluded: [],
  limit: -1,
  merge: false,
  freeOnly: 0
}

export function fetchAllTracks( options ) {
  const authToken = getAuthToken();
  let JsonStyleFilters = "[]";
  let timestamp = Date.now();

  options = {...defaultTrackOptions, ...options};

  // Override "As Curated" to return relevancy results without changing the filter name.
  if (options.sort === "As Curated") {
    options.sort = "CollectionDefault";
  }

  // console.log(
  //   "fetchAllTracks",
  //   options.type,
  //   options.id,
  //   options.energy,
  //   options.vocal,
  //   options.sort,
  //   options.duration,
  //   options.styleFilters,
  //   options.page,
  //   options.excluded,
  //   options.limit,
  //   options.merge,
  //   options.freeOnly
  // );

  const {
    type,
    id,
    energy,
    vocal,
    sort,
    duration,
    styleFilters,
    page,
    excluded,
    limit,
    merge,
    freeOnly
  } = options;

  if (styleFilters != null) {
    JsonStyleFilters = JSON.stringify(styleFilters);
  }
  let JsonExcluded = [];
  if (excluded != null) {
    for (let i = 0; i < excluded.length; i++) {
      JsonExcluded[i] = [];
      JsonExcluded[i][0] = parseInt(excluded[i].id);
      JsonExcluded[i][1] = parseInt(excluded[i].group);
    }
    JsonExcluded = JSON.stringify(JsonExcluded);
  }


  let isPremium = 0;

  if ( sessionStorage.getItem('is_premium_track_list') === "1" ) {
    isPremium = 1;
  }

  return function (dispatch) {
    if (!merge) {
      dispatch(requestTracks());
    }

    dispatch(setLatestCallTimestamp(timestamp));

    /* slowing down the second call for testing purposes */
    // return window.setTimeout( () => {
    /* slowing down the second call for testing purposes */
    return fetch(
      `${ conditionallyGetCdnApi() }tracks/${type}/types/${id}/ids/${energy}/energies/${vocal}/vocals/${sort}/sorts/${duration ?? 0}/durations/${JsonStyleFilters}/stylefilters/${limit}/limits/${JsonExcluded}/excludes/${isPremium}/premium/${freeOnly}/freeonly/${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": authToken || null,
        },
      }
    )
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((response) => {
        if (response.error) {
          throw new Error("Something failed");
        } else {
          return response;
        }
      })
      .then((json) => {
        dispatch(
          receiveTracks(json, timestamp),

          dispatch(moreTracksLoading(false)),
          dispatch(receiveTracksIds(page, json.result_ids, timestamp))
        );
      })
      .catch(async (e) => {
        setMusicLoadError();
      });

    /* end of slowing down the second call for testing purposes */
    // }, 10000 );
    /* end of slowing down the second call for testing purposes */
  };
}

export function fetchMoreTracks(
  currentContextId,
  type,
  trackIds
) {
  const authToken = getAuthToken();
  return function (dispatch) {
    dispatch(showMoreTracksLoading(true));
    return fetch(`${conditionallyGetCdnApi()}tracks/fetchDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": authToken || null,
      },
      body: JSON.stringify({
        trackIds,
        type,
        contextId: currentContextId || 1,
      }),
    })
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((response) => {
        if (response.error) {
          throw new Error("Something failed");
        } else {
          return response;
        }
      })
      .then((json) => {
        // Filters out any results with broken info
        json.results = json.results.filter((item) => item.artist);

        dispatch(showMoreTracksLoading(false));
        dispatch(receiveFetchMoreTracks(json.results));

        if ((json.results ?? []).length < 1) {
          dispatch({
            type: "SHOW_MORE_TRACK_HIDE",
          });
        }
      })
      .catch((e) => {
        dispatch(showMoreTracksLoading(false));
        console.log(e);
      });
  };
}

export function fetchSimilarTracks(artistId, trackId, includeSourceTrack) {
  return function (dispatch) {
    dispatch(requestTracks());

    if (!trackId) {
      trackId = artistId;
      artistId = 0;
    }

    includeSourceTrack = includeSourceTrack ? 1 : 0;

    return fetch(
      `${conditionallyGetCdnApi()}searches/${artistId}/artists/${trackId}/tracks/${includeSourceTrack}/include/similar`,
      {
        method: "GET",
      }
    )
      .then(
        (response) => response.json(),
        (error) => console.log("An error occurred.", error)
      )
      .then((json) => {
        dispatch(
          receiveSimilarTracks(json),
          dispatch(receiveAllSimilarTracksIds(json.result_ids)),
          dispatch(receiveSimilarTracksIds(json.result_ids))
        );
      });
  };
}
export function fetchSubscriptionTracks({ page }) {
  return (dispatch) => {
    if (page > 1) {
      dispatch(showMoreTracksLoading(true));
    } else {
      dispatch(requestTracks());
    }
    const authToken = getAuthToken();
    return fetch(`${process.env.NEXT_PUBLIC_API}api-customer/license-history/subscription`, {
      method: "POST",
      body: JSON.stringify({
        token: authToken,
        page,
      }),
      headers: {
        "Content-Type": "application/json",
        token: authToken || null,
        page,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        const tracks = Object.assign({}, json);
        const showRelevancy = sessionStorage.getItem("showDebugRData") === "1";
        let resultIdsIndex = -1;
        let resultToRemove = -1;

        /* Remove any broken tracks */
        for (let i = 0; i < tracks.results.length; i++) {
          if (!showRelevancy && !tracks.results[i].isValid) {
            resultToRemove = tracks.results[i].id;
            resultIdsIndex = tracks.result_ids.indexOf(resultToRemove);

            tracks.results.splice(i, 1);
            tracks.result_ids.splice(resultIdsIndex, 1);
          }
        }

        if (page > 1) {
          dispatch(showMoreTracksLoading(false));
          dispatch(
            receiveFetchMoreTracks(
              tracks.results.map((track) => ({
                ...track,
                ...track.track,
              }))
            )
          );
          if ((tracks.results ?? []).length < 1) {
            dispatch({
              type: "SHOW_MORE_TRACK_HIDE",
            });
          }
        } else {
          dispatch({
            type: "RECEIVE_TRACKS",
            tracks: {
              ...tracks,
              results: tracks.results.map((track) => ({
                ...track,
                ...track.track,
              })),
            },
          });
          dispatch(moreTracksLoading(false));
          dispatch(receiveTracksIds(page, tracks.result_ids));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function shouldFetchTopTracks(options) {
  let shouldFetchTopTracks = true;
  if (
    /* Don't return top tracks for anything other than the first page */
    options.page !== 1 ||
    /* Or for any sorting other than Relevance */
    options.sort !== "Relevance" ||
    /* Or with any filters applied */
    !!options.energy ||
    !!options.vocal ||
    !!options.duration ||
    options.styleFilters.length > 0 ||
    !options.isPremium ||
    options.disableWeighting
  ) {
    shouldFetchTopTracks = false;
  }

  return shouldFetchTopTracks;
}

// ACTIONS
export function addPageTrack(track) {
  return { type: "ADD_PAGE_TRACKS", track };
}

export function addPageTracksId(trackId) {
  return { type: "ADD_PAGE_TRACK_IDS", trackId };
}

export function addAllPageTracksId(trackId) {
  return { type: "ADD_ALL_PAGE_TRACK_IDS", trackId };
}

export function addPageTrackWave(waveform) {
  return { type: "ADD_PAGE_TRACKS_WAVE", waveform };
}

function receiveSimilarTracks(json) {
  const tracks = Object.assign({}, json);
  delete tracks.result_ids;
  return { type: "RECEIVE_SIMILAR_TRACKS", tracks };
}

function receiveSimilarTracksIds(trackIds) {
  return { type: "RECEIVE_SIMILAR_TRACK_IDS", trackIds };
}

function receiveAllSimilarTracksIds(tracks) {
  return { type: "RECEIVE_ALL_SIMILAR_TRACK_IDS", trackIds: tracks };
}

function requestTracks() {
  return { type: "REQUEST_TRACKS" };
}

function moreTracksLoading(isFetchingMore) {
  return { type: "MORE_TRACKS_LOADING", isFetchingMore };
}

function setLatestCallTimestamp(timestamp) {
  return { type: "SET_LATEST_CALL_TIMESTAMP", timestamp };
}

function showMoreTracksLoading(isShowFetchingMore) {
  return { type: "SHOW_MORE_TRACKS_LOADING", isShowFetchingMore };
}

function receiveTracks(json, timestamp) {
  const tracks = Object.assign({}, json);
  let resultIdsIndex = -1;
  let resultToRemove = -1;

  /* Remove any broken tracks */
  for (let i = 0; i < tracks.results.length; i++) {
    if (!tracks.results[i].artist) {
      resultToRemove = tracks.results[i].id;
      resultIdsIndex = tracks.result_ids.indexOf(resultToRemove);

      tracks.results.splice(i, 1);
      tracks.result_ids.splice(resultIdsIndex, 1);
    }
  }

  return { type: "RECEIVE_TRACKS", tracks, timestamp };
}

function receiveFetchMoreTracks(tracks) {
  return { type: "RECEIVE_SHOW_MORE_TRACKS", tracks };
}

function receiveTracksIds(page, tracks, timestamp) {
  const start = page * 30 - 30;
  const end = start + 30;
  // const trackIds = tracks.slice(start, end);
  const trackIds = [1];

  return { type: "RECEIVE_TRACK_IDS", trackIds, timestamp };
}

function receiveAllTracksIds(tracks) {
  return { type: "RECEIVE_ALL_TRACK_IDS", trackIds: tracks };
}

export function setJumpBackInContext(type, content) {
  return { type: "SET_JBI_CONTEXT", context: { type, content } };
}

export function resetJumpBackInContext() {
  return { type: "RESET_JBI_CONTEXT" };
}

export function setJumpBackIn(jumpBackInContext) {
  const newContext = "" + jumpBackInContext.type + jumpBackInContext.content;
  const lastContext = localStorage.getItem('jbi_context');
  const headers = {
    'X-Auth-Token': getAuthToken(),
  };

  // Quit out if context isn't set
  if (!jumpBackInContext.type || !jumpBackInContext.content) {
    return;
  }

  if (getAuthToken() && newContext !== lastContext) {
    localStorage.setItem('jbi_context', newContext);

    return fetch(`${process.env.NEXT_PUBLIC_API}carousels/${jumpBackInContext.type}/types/${jumpBackInContext.content}/content`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .catch((err) => err);
  }
}

export function addTrackData(array) {
	return { type: 'ADD_DATA', array };
}

export function clearTracks() {
	return { type: 'CLEAR_TRACKS' };
}
