
import { getAuthToken } from '../auth';
import { conditionallyGetCdnApi } from '../api/cdn';
import getConfig from 'next/config';

export function setupSite() {
  const url = `${process.env.NEXT_PUBLIC_API}setup_frontend`;
  const authKey = getAuthToken();

  return fetch(url, {
    method: 'GET',
		// mode: 'CORS',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': authKey || null,
    },
  })
		.then((res) => res.json())
		.catch((err) => err);
}

export function newSetupSite() {
  let seed = localStorage.getItem("ab_seed");
  let timestamp = localStorage.getItem("ab_ts");

  if (!seed) {
    seed = Math.floor(Math.random() * 1000000);
    localStorage.setItem("ab_seed", String(seed));
  }

  if (!timestamp) {
    timestamp = Math.floor(Date.now() / 1000);
    localStorage.setItem("ab_ts", String(timestamp));
  }

  const site_version = `uppbeat-next@${
    process.env.NEXT_PUBLIC_VERSION ?? 'unknown'
  }`;

  const url = `${process.env.NEXT_PUBLIC_API}setup_frontend?ab_seed=${seed}&ab_timestamp=${timestamp}&fev=${site_version}`;
  const authKey = getAuthToken();

  return fetch(url, {
    method: 'GET',
    // mode: 'CORS',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': authKey || null,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function addTemporaryAnchor(anchor) {
  return { type: 'ADD_TEMPORARY_ANCHOR', anchor };
}

export function removeTemporaryAnchor(anchor) {
  return { type: 'REMOVE_TEMPORARY_ANCHOR', anchor };
}

export function fetchDealBanners(banner_id) {
  return fetch(`${process.env.NEXT_PUBLIC_API}deal/getBanner/${banner_id}`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function getCurrentSiteVersion() {
  return fetch(`${conditionallyGetCdnApi()}get_site_version`, {
    method: 'GET',
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function playlistCreated(playlistName) {
  return fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        'requesttype': 'playlistcreated',
        'eventinfo': `${playlistName}`,
    })
  }).then((res) => res.json())
  .catch((err) => err);
}
