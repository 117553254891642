import clsx from 'clsx';
import * as React from 'react';

const _Warning = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={clsx('fill-none', className)}
    >
      <path
        d="M4.92396 2.40909C5.62442 1.19697 7.37558 1.19697 8.07604 2.40909L11.7535 8.77273C12.4539 9.98485 11.5784 11.5 10.1774 11.5H2.82257C1.42164 11.5 0.546067 9.98485 1.24653 8.77273L4.92396 2.40909Z"
        className="!fill-[#FF9B34] stroke-gray-light-300"
      />
      <path
        d="M6.63409 7.45305C6.66389 7.45305 6.69156 7.44167 6.71711 7.41891C6.73839 7.39615 6.75116 7.37111 6.75542 7.34379L7.06831 5.43854V4.66005C7.06831 4.62818 7.05766 4.59859 7.03638 4.57128C7.01084 4.54396 6.98104 4.5303 6.94698 4.5303H6.05302C6.01896 4.5303 5.99129 4.54396 5.97001 4.57128C5.94447 4.59859 5.93169 4.62818 5.93169 4.66005V5.42488L6.24458 7.34379C6.24884 7.37111 6.26374 7.39615 6.28928 7.41891C6.31056 7.44167 6.33611 7.45305 6.36591 7.45305H6.63409ZM7.10662 8.73688C7.10662 8.55933 7.04702 8.40682 6.92783 8.27934C6.80863 8.14732 6.66815 8.08131 6.50639 8.08131C6.34036 8.08131 6.19776 8.14732 6.07856 8.27934C5.95511 8.40682 5.89338 8.55933 5.89338 8.73688C5.89338 8.90987 5.95511 9.06011 6.07856 9.18758C6.19776 9.31505 6.34036 9.37879 6.50639 9.37879C6.66815 9.37879 6.80863 9.31505 6.92783 9.18758C7.04702 9.06011 7.10662 8.90987 7.10662 8.73688Z"
        className="!fill-white"
      />
    </svg>
  );
};

const Warning = React.memo(_Warning);

export default Warning;
