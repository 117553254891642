export enum AddToPlaylistModalScreenName {
  AddToPlaylist = 'add-to-playlist',
}

export interface ArtistInfo {
  name: string;
  image: string;
}

export interface TrackInfo {
  id: string;
  name: string;
  artist: ArtistInfo;
}

export interface Details {
  track: TrackInfo;
  contextId: string;
}

export interface AddToPlaylistContextType {
  details: Details | null;
  setDetails: (details: Details) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export enum PlaylistType {
  NEW = 'new',
  EXISTING = 'existing',
}

export interface Playlist {
  uuid: string;
  name: string;
}
