import * as React from 'react';

const _YouTube = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0_17_47)">
        <path
          d="M47.044 12.37A6.033 6.033 0 0042.8 8.1c-3.752-1.01-18.752-1.01-18.752-1.01s-15 0-18.753 1.01a6.033 6.033 0 00-4.244 4.27C.048 16.14.048 24 .048 24s0 7.86 1.003 11.63a6.033 6.033 0 004.244 4.27c3.753 1.01 18.753 1.01 18.753 1.01s15 0 18.752-1.01a6.033 6.033 0 004.244-4.27C48.048 31.86 48.048 24 48.048 24s0-7.86-1.004-11.63z"
          fill="#FF0302"
        />
        <path
          d="M19.139 31.137V16.863L31.684 24l-12.546 7.137z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_17_47">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const YouTube = React.memo(_YouTube);

export default YouTube;
