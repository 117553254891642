import Image from 'next/image';
import { createContext, useContext, useMemo, useState } from 'react';

import TabContainer from '@/components/Modals/Sidebar/components/Tab';

/*
 * WARNING: TypeScript doesn't actually check keys, but we require
 * them to prevent re-rendering issues.
 */
export type Tab = React.ReactElement<{ key: string }>;
export interface SidebarHeaderContextType {
  tabs: Tab[];
  setTabs: React.Dispatch<React.SetStateAction<Tab[]>>;
  disabled: boolean;
  triggerCooldown: (duration?: number) => void;
}

export const SidebarHeaderContext = createContext<SidebarHeaderContextType>({
  tabs: [],
  setTabs: () => {},
  disabled: false,
  triggerCooldown: () => {},
});

export const useSidebarHeaderContext = () => {
  const context = useContext(SidebarHeaderContext);

  if (!context) {
    throw new Error(
      'useSidebarHeaderContext must be used within a SidebarHeaderProvider',
    );
  }

  return context;
};

export const DefaultTab = () => {
  return (
    <TabContainer key="default-header-tab">
      <Image
        src="https://cdn.uppbeat.io/images/logo/logo-marque.svg"
        alt="Uppbeat logo"
        className="h-8 w-auto scale-110 origin-"
        height={32}
        width={74}
      />
    </TabContainer>
  );
};

export const SidebarHeaderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [tabs, setTabs] = useState<Tab[]>([<DefaultTab />]);
  const [disabled, setDisabled] = useState(false);

  const triggerCooldown = (duration = 350) => {
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, duration);
  };

  const value = useMemo(
    () => ({ tabs, setTabs, disabled, triggerCooldown }),
    [disabled, tabs],
  );

  return (
    <SidebarHeaderContext.Provider value={value}>
      {children}
    </SidebarHeaderContext.Provider>
  );
};
