import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';
import { USER_STATE_COOKIE_NAME } from '@/constants/cookies';
import { getUniversalCookie, setUniversalCookie } from '@/cookies';

import type { UserState } from '@/providers/UserStateProvider';
import type { NextIncomingMessage } from '@/types/request';
import type { Settings } from '@/types/settings';

export const fallbackSettings: Settings = {
  auth_token: false,
  credits: {
    ab_seed: 558781,
    ab_timestamp: '2023-06-28 10:07:37',
    ab_source: 'client_generated',
    ab_group: 31,
    credits_initial: 3,
    credits_max: 3,
    credits_per_month: 1,
    credits_current: 0,
  },
  isPaymentTabDisplay: false,
  status: 'success',
  siteVersion: 1,
  country: {
    id: 239,
    iso_code_2: 'US',
    vat_rate: 0,
  },
  currency: {
    id: 1,
    code: 'USD',
    symbol_left: '$',
    value: '1.00',
  },
  playlists_data: [],
  enteredProfession: false,
  subscriptionData: null,
  chatgpt: {
    allocation: 0,
    remaining: 0,
    used: 0,
  },
  theme_preference: -1,
  user: {
    is_authenticated: false,
  },
  preferences: {},
};

export const setupFrontend = async ({
  req,
}: { req?: NextIncomingMessage } = {}): Promise<Settings> => {
  const token = getAuthToken(req);

  const userState =
    getUniversalCookie<UserState>(USER_STATE_COOKIE_NAME, { req }) ?? {};

  let { abSeed, abTimestamp } = userState;

  if (!abSeed) {
    abSeed = Math.floor(Math.random() * 1000000);
  }

  if (!abTimestamp) {
    abTimestamp = Math.floor(Date.now() / 1000);
  }

  setUniversalCookie(
    USER_STATE_COOKIE_NAME,
    { ...userState, abSeed, abTimestamp },
    { req },
  );

  const site_version = `uppbeat-next@${
    process.env.NEXT_PUBLIC_VERSION ?? 'unknown'
  }`;

  // Return generic information if running on the server
  if (typeof window === 'undefined') {
    return new Promise<Settings>((resolve) => {
      resolve(fallbackSettings);
    });
  }

  return fetchJson<Settings>(
    `/setup_frontend?ab_seed=${abSeed}&ab_timestamp=${abTimestamp}&fev=${site_version}`,
    { token, req },
  );
};
