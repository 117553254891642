import Link from 'next/link';
import { forwardRef, memo, useMemo } from 'react';

import { useAppState } from '@/hooks/useAppState';

import type { LinkProps } from 'next/link';
import type { HTMLProps } from 'react';

export const personalizedRoutes = ['/sfx/', '/browse/', '/music/'];
export const personalizedRoutePrefix = '/personalized';

type DynamicLinkProps = LinkProps & {
  children?: React.ReactNode;
  external?: boolean;
} & React.RefAttributes<HTMLAnchorElement> &
  HTMLProps<HTMLAnchorElement> &
  LinkProps;

// Link which gets href without having to go through the middleware.
const DynamicLinkRef: React.FC<DynamicLinkProps> = forwardRef((props, ref) => {
  const useAuth = useAppState('userAuth');
  const isLoggedIn = !!useAuth?.isLoggedIn;

  const href = useMemo(() => {
    const route = props.href.toString();

    // Return the original href if the user is not logged in
    if (!isLoggedIn) {
      return route;
    }

    const isSearchPage = route.indexOf('/search/') !== -1;

    if (isSearchPage) {
      return route;
    }

    // If the route is a personalized route, return the route with the personalized prefix.
    if (
      route === '/' ||
      route === '/sfx' ||
      personalizedRoutes.some((r) => route.startsWith(r))
    ) {
      return personalizedRoutePrefix + route;
    }

    return route;
  }, [props.href, isLoggedIn]);

  if (props.external) {
    return (
      <a {...props} href={href} ref={ref}>
        {props.children}
      </a>
    );
  }

  return (
    <Link
      {...props}
      prefetch={props.prefetch === true || false}
      as={props.href}
      href={href}
      ref={ref}
    >
      {props.children}
    </Link>
  );
});

DynamicLinkRef.displayName = 'DynamicLinkRef';

export const DynamicLink = memo(DynamicLinkRef);
