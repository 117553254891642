import { createContext, useContext, useMemo } from 'react';

interface TNamespaceProviderProps {
  children: React.ReactNode;
  value: string;
}

const TNamespaceContext = createContext<string>('common');

export const useTNamespace = () => {
  const ctx = useContext(TNamespaceContext);

  return ctx;
};

export const TNamespaceProvider = ({
  children,
  value,
}: TNamespaceProviderProps) =>
  useMemo(
    () => (
      <TNamespaceContext.Provider value={value}>
        {children}
      </TNamespaceContext.Provider>
    ),
    [children, value],
  );
