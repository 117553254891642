import clsx from 'clsx';
import { usePresence } from 'framer-motion';
import { useEffect } from 'react';

import Modal from '@/components/Modals/Core';

const Overlay = () => {
  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (isPresent) {
      return () => {};
    }

    const timeout = setTimeout(safeToRemove, 350);

    return () => clearTimeout(timeout);
  }, [isPresent, safeToRemove]);

  return (
    <Modal.Overlay asChild forceMount>
      <div
        key="overlay"
        className={clsx(
          'bg-black/50 fixed inset-0 z-[9998] backdrop-blur-px will-change-[opacity]',
          {
            'animate-fadeOut': !isPresent,
            'animate-fadeIn': isPresent,
          },
        )}
      />
    </Modal.Overlay>
  );
};

export default Overlay;
