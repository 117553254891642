import * as React from 'react';

const _Tick = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 11" fill="none" {...props}>
      <path
        d="M4.727 9.496a.887.887 0 010-1.195l6.265-6.704a.753.753 0 011.117 0 .887.887 0 010 1.195L5.844 9.496a.753.753 0 01-1.117 0z"
        fill="currentColor"
      />
      <path
        d="M4.044 7.57L10.31.866a1.675 1.675 0 012.481 0 1.972 1.972 0 010 2.656l-6.265 6.704a1.675 1.675 0 01-2.482 0 1.972 1.972 0 010-2.656zm.683.731a.887.887 0 000 1.195c.309.33.808.33 1.117 0l6.265-6.704a.887.887 0 000-1.195.753.753 0 00-1.117 0L4.727 8.301z"
        fill="currentColor"
      />
      <path
        d="M1.558 4.935a.753.753 0 011.117 0L5.84 8.324a.887.887 0 010 1.194.753.753 0 01-1.116 0L1.558 6.13a.887.887 0 010-1.195z"
        fill="currentColor"
      />
      <path
        d="M3.357 4.204l3.167 3.389a1.972 1.972 0 010 2.656 1.675 1.675 0 01-2.482 0L.875 6.86a1.972 1.972 0 010-2.656 1.675 1.675 0 012.482 0zm-.682.731a.753.753 0 00-1.117 0 .887.887 0 000 1.195l3.167 3.388c.308.33.808.33 1.116 0a.887.887 0 000-1.194L2.675 4.935z"
        fill="currentColor"
      />
    </svg>
  );
};

const Tick = React.memo(_Tick);

export default Tick;
