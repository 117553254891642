/* eslint-disable @next/next/no-img-element */
import { useRouter } from 'next/router';
import { useMemo } from 'react';

type TwoCharacterUppercaseString = `${Uppercase<string>}${Uppercase<string>}`;

interface TrustPilotImageProps {
  lang: TwoCharacterUppercaseString;
}

const TrustPilotImage = ({ lang }: TrustPilotImageProps) =>
  useMemo(
    () => (
      <>
        <img
          loading="lazy"
          decoding="async"
          width={500}
          height={42}
          src={`//fastly-f.uppbeat.io/api/trustpilot.png?theme=light&lang=${lang}`}
          className="object-contain dark:hidden"
          alt="Check out our Trust Pilot reviews"
        />
        <img
          decoding="async"
          loading="lazy"
          width={500}
          height={42}
          src={`//fastly-f.uppbeat.io/api/trustpilot.png?theme=dark&lang=${lang}`}
          className="object-contain hidden dark:block"
          alt="Check out our Trust Pilot reviews"
        />
      </>
    ),
    [lang],
  );

const UBTrustBoxContainer = () => {
  const { locale: activeLocale } = useRouter();
  const lang = (
    activeLocale ?? 'en'
  ).toUpperCase() as TwoCharacterUppercaseString;

  return (
    <a
      href="https://uk.trustpilot.com/review/uppbeat.io"
      target="_blank"
      className="flex items-center justify-center"
      rel="noreferrer noopener"
    >
      <TrustPilotImage lang={lang} />
    </a>
  );
};

export default UBTrustBoxContainer;
