import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import type { RecaptchaVersion } from '@/hooks/useRecaptcha';

export interface CreateSubscriptionRequest {
  currency: number;
  paymentMethod: string;
  deal: string;
  stripe_token: string;
  plan: number;
  period: number;
  banner_id: string;
  conversion_source: string;
  country: number;
  recaptcha_version: RecaptchaVersion;
  recaptcha_token: string;
  force_fail_recaptcha?: string;
  conversions?: Conversion[];
  channel: string;
  channelName: string;
  channelLocation: string;
  enteredComp: string;
  agreedToCommit: string;
  poNumber: string;
}

export interface Conversion {
  conversion_event: string;
  conversion_context_t1: number;
  conversion_context_t2: string;
  conversion_tag?: number;
}

export interface CreateSubscriptionResponse {
  error?: ResponseError;
  clientSecret?: string;
  order_id: number;
  plan_type?: string;
  success: boolean;
  order_value: number;
  order_value_annual: number;
  order_tax: number;
  order_currency_code?: string;
  order_native_value?: string;
  adwords_conversion_id?: string;
  adwords_conversion_label?: string;
  reasons?: UserDataConversionReason[];
}

export interface ResponseError {
  code: number;
  message: string;
  showRecaptcha: boolean;
}

export interface UserDataConversionReason {
  id: number;
  name?: string;
  icon?: string;
  iconSvg?: string;
}

const createSubscription = async (body: CreateSubscriptionRequest) =>
  axios
    .post<CreateSubscriptionResponse>(
      'v2/subscription/create',

      body,
      {
        baseURL: process.env.NEXT_PUBLIC_API,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
      },
    )
    .catch((err) => {
      if (err?.response?.data?.error?.message) {
        throw new Error(err.response.data.error.message as string | undefined);
      }

      if (err instanceof Error) {
        throw new Error(err.message);
      }

      throw new Error('An unknown error occurred');
    });

const useCreateSubscription = () => {
  const mutation = useMutation({
    mutationKey: ['createSubscription'],
    mutationFn: async (body: CreateSubscriptionRequest) => {
      const response = await createSubscription(body);

      if (response.data.error) {
        throw new Error(response.data.error.message);
      }

      return response.data;
    },
  });

  return mutation;
};

export default useCreateSubscription;
