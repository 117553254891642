export default (state = [], action) => {
  switch (action.type) {
    case 'LOAD_FAVORITES':
      return [...action.favorites];
    case 'TOGGLE_FAVORITES':
      if ( state.includes(action.trackId) ) {
        return state.filter((fav) => fav !== action.trackId);
      } else {
        return [...state, action.trackId];
      }
    default:
      return state;
  }
};
