import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';
import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';

import type { NextIncomingMessage } from '@/types/request';
import type { Theme } from '@/types/theme';

export const setThemePreference = async (
  theme: Theme,
  { req }: { req?: NextIncomingMessage } = {},
) => {
  const token = getAuthToken(req);

  if (!token) {
    return;
  }

  const themeNumber = theme === 'light' ? 0 : 1;

  await fetchJson(`set_theme_preference?pref=${themeNumber}`, {
    req,
    method: 'GET',
    tokenHeader: 'token',
    token,
  });

  updateActiveCampaignContact();
};
