/* eslint-disable react/button-has-type */
import { clsx } from 'clsx';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { DynamicLink } from '@/components/DynamicLink';

import UBButtonDropdownFixed from './UBButtonDropdownFixed';
import UBButtonDropdownRelative from './UBButtonDropdownRelative';

import type {
  borderRadiusTypes,
  dropdownPositionTypes,
  dropdownTypeTypes,
  iconPositionTypes,
  menuTypes,
  sizeTypes,
  typeTypes,
  variantTypes,
} from '@/types/UBButtonTypes';
import type { MutableRefObject, ReactNode } from 'react';

interface ButtonProps {
  href?: undefined;
  target?: undefined;
  rel?: undefined;
  prefetch?: undefined;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  shallow?: undefined;
}

interface LinkProps {
  href?: string;
  target?: string;
  rel?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  prefetch?: boolean;
  shallow?: boolean;
  onMouseUp?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

type LinkButton = LinkProps | ButtonProps;

export type UBButtonProps = LinkButton & {
  ariaLabel?: string;
  ariaHidden?: boolean;
  ariaCurrent?: boolean;
  background?: string; // color code
  border?: boolean;
  borderRadius?: borderRadiusTypes;
  children?: ReactNode;
  className?: string;
  color?: string; // color code
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dropdown?: any; // UBButtons as children
  dropdownInline?: boolean;
  dropdownPosition?: dropdownPositionTypes;
  dropdownType?: dropdownTypeTypes;
  icon?: ReactNode; // SVG/Image variable
  iconPosition?: iconPositionTypes;
  id?: string;
  label?: ReactNode;
  menu?: menuTypes;
  padding?: string;
  size?: sizeTypes;
  target?: string;
  title?: string;
  type?: typeTypes;
  onMouseUp?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  width?: string;
  variant?: variantTypes;
  dummyButton?: boolean;
  disableHover?: boolean;
};

const UBButton = forwardRef(function UBButton(
  {
    ariaLabel,
    ariaHidden = false,
    ariaCurrent,
    background,
    border = false,
    borderRadius = 'half',
    children,
    className,
    color,
    disabled = false,
    dropdown,
    dropdownInline = false,
    dropdownPosition = 'bottom-center',
    dropdownType = 'fixed',
    icon,
    iconPosition = 'left',
    id,
    label,
    menu,
    padding,
    size = 'medium',
    title,
    type = 'button',
    onClick,
    onMouseUp,
    onMouseEnter,
    onMouseLeave,
    width,
    variant = 'primary',
    href,
    target,
    rel,
    dummyButton,
    disableHover,
    ...props
  }: UBButtonProps,
  ref,
) {
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [dropdownColumns, setDropdownColumns] = useState<number>(1);
  const [dropdownOffset, setDropdownOffset] = useState<DOMRect>();

  const [buttonWidth, setButtonWidth] = useState<number | undefined>(0);

  const buttonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  useImperativeHandle(ref, () => buttonRef.current!, []);

  const handleScroll = () => {
    setDropdownVisible(false);
    setDropdownOffset(buttonRef.current?.getBoundingClientRect());
  };

  useEffect(() => {
    if (dropdownInline) {
      setDropdownColumns(0);
    } else if (dropdown && dropdown.length > 5 && dropdown.length < 20) {
      if (dropdown.length % 3 === 0) {
        setDropdownColumns(3);
      } else {
        setDropdownColumns(2);
      }
    } else {
      setDropdownColumns(3);
    }

    if (dropdown) {
      const scrollArea = document.getElementsByClassName(
        'main-content-section',
      )[0];

      scrollArea?.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      setButtonWidth(buttonRef.current?.clientWidth);
      setDropdownOffset(buttonRef.current?.getBoundingClientRect());
    }

    return () => {
      window.removeEventListener('resize', handleScroll);

      const scrollArea = document.getElementsByClassName(
        'main-content-section',
      )[0];

      scrollArea?.removeEventListener('scroll', handleScroll);
    };
  }, [dropdown, dropdownInline]);

  const handleDropdown = () => {
    setButtonWidth(buttonRef.current?.clientWidth);
    setDropdownOffset(buttonRef.current?.getBoundingClientRect());
    setDropdownVisible(!dropdownVisible);
  };

  const ubBtnStyles = {
    '--ubbtn-background': background,
    '--ubbtn-color': color,
    '--ubbtn-width': width,
    '--ubbtn-padding': padding,
    '--ubbtn-border-radius': borderRadius,
  } as React.CSSProperties;

  const ubBtnClasses = clsx('ub-btn', {
    'disable-hover': disableHover,
    'menu back': menu === 'back',
    'menu close': menu === 'close',
    'menu open': menu === 'open',
    'icon-only': icon && !label,
    [className as string]: className,
    [variant]: variant,
    bordered: border,
    [borderRadius]: borderRadius === 'full' || 'half',
    [iconPosition]: iconPosition && icon,
    [size as string]: size,
    dropdown,
  });

  if (dummyButton === true) {
    return (
      <div className={ubBtnClasses} style={ubBtnStyles} id={id}>
        {!!icon && <span className="ub-btn-icon">{icon}</span>}
        {!!label && <span className="ub-btn-label">{label}</span>}
        {!!menu && <span className={`ub-btn-menu-icon ${menu}`} />}
        {children}
      </div>
    );
  }

  if (typeof href !== 'undefined') {
    return (
      <DynamicLink
        {...props}
        className={ubBtnClasses}
        href={href}
        target={target}
        rel={rel}
        onClick={onClick}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={ubBtnStyles}
        id={id}
        type={type}
        aria-label={ariaLabel ?? (menu ? `menu ${menu}` : undefined)}
        aria-hidden={ariaHidden}
        aria-current={ariaCurrent}
        tabIndex={ariaHidden ? 1000 : undefined}
        title={title ?? (typeof label === 'string' ? label : undefined)}
        ref={buttonRef as MutableRefObject<HTMLAnchorElement>}
      >
        {!!icon && <span className="ub-btn-icon">{icon}</span>}
        {!!label && <span className="ub-btn-label">{label}</span>}
        {!!menu && <span className={`ub-btn-menu-icon ${menu}`} />}
        {children}
      </DynamicLink>
    );
  }

  return dropdown && dropdownType === 'relative' && dropdownOffset ? (
    <OutsideClickHandler onOutsideClick={() => setDropdownVisible(false)}>
      <span className="ub-btn-dropdown-wrapper">
        <button
          {...props}
          className={ubBtnClasses}
          onClick={() => handleDropdown()}
          onMouseUp={onMouseUp}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          disabled={disabled}
          style={ubBtnStyles}
          id={id}
          type={type}
          aria-label={ariaLabel ?? (menu ? `menu ${menu}` : undefined)}
          aria-hidden={ariaHidden}
          tabIndex={ariaHidden ? 1000 : undefined}
          title={title ?? (typeof label === 'string' ? label : undefined)}
          ref={buttonRef as MutableRefObject<HTMLButtonElement>}
        >
          {!!icon && <span className="ub-btn-icon">{icon}</span>}
          {!!label && <span className="ub-btn-label">{label}</span>}
          {!!menu && <span className={`ub-btn-menu-icon ${menu}`} />}
          {children}
        </button>
        <UBButtonDropdownRelative
          className={dropdownVisible ? 'visible' : 'hidden'}
          columns={dropdownColumns}
          offset={dropdownOffset}
          position={dropdownPosition}
        >
          {dropdown}
        </UBButtonDropdownRelative>
      </span>
    </OutsideClickHandler>
  ) : (
    <>
      <button
        {...props}
        className={ubBtnClasses}
        onClick={
          dropdown
            ? () => handleDropdown()
            : (e?: React.MouseEvent<HTMLButtonElement>) => {
                const buttonClick = onClick as ButtonProps['onClick'];

                if (buttonClick) {
                  buttonClick(e) as ButtonProps['onClick'];
                }
              }
        }
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        style={ubBtnStyles}
        id={id}
        type={type}
        aria-label={ariaLabel ?? (menu ? `menu ${menu}` : undefined)}
        aria-hidden={ariaHidden}
        tabIndex={ariaHidden ? 1000 : undefined}
        title={title ?? (typeof label === 'string' ? label : undefined)}
        ref={buttonRef as MutableRefObject<HTMLButtonElement>}
      >
        {!!icon && <span className="ub-btn-icon">{icon}</span>}
        {!!label && <span className="ub-btn-label">{label}</span>}
        {!!menu && <span className={`ub-btn-menu-icon ${menu}`} />}
        {children}
      </button>
      {dropdown && dropdownVisible && dropdownOffset && (
        <UBButtonDropdownFixed
          columns={dropdownColumns}
          offset={dropdownOffset}
          buttonWidth={buttonWidth}
          position={dropdownPosition}
          onOutsideClick={() => setDropdownVisible(false)}
        >
          {dropdown}
        </UBButtonDropdownFixed>
      )}
    </>
  );
});

export default React.memo(UBButton);
