import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react'; // Import the X icon from lucide-react
import * as React from 'react';

import { cn } from '@/lib/utils';

const inputWrapperVariants = cva('relative flex items-center w-full', {
  variants: {
    size: {
      default: 'h-10',
      sm: 'h-7',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

const inputVariants = cva(
  [
    'w-full antialiased text-sm outline-0 font-sans shadow-none touch-manipulation self-stretch',
    'text-gray-dark-400 bg-gray-light-200 border-2 border-solid border-gray-light-200 rounded-[.625rem]',
    'enabled:hover:bg-gray-light-300 enabled:hover:border-gray-light-300',
    'focus:bg-gray-light-300 focus:border-uppbeat transition duration-75',
    'dark:bg-gray-dark-200 dark:border-gray-dark-200 dark:text-white',
    'dark:enabled:hover:bg-gray-dark-100 dark:enabled:hover:border-gray-dark-100',
    'dark:focus:bg-gray-dark-100 dark:focus:border-uppbeat',
    'placeholder-gray-dark-400/70 dark:placeholder-gray-light-400/50',
    'disabled:bg-gray-light-400 disabled:border-gray-light-400 disabled:text-gray-dark-400/70 disabled:cursor-not-allowed',
    'dark:disabled:bg-gray-dark-300 dark:disabled:border-gray-dark-300 dark:disabled:text-gray-light-400/50 dark:disabled:cursor-not-allowed',
    '[&::-webkit-search-cancel-button]:hidden', // Hide the default clear button
  ],
  {
    variants: {
      size: {
        default: 'px-4',
        sm: 'px-3',
      },
      hasLeftIcon: {
        true: '',
        false: '',
      },
      hasRightIcon: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        hasLeftIcon: true,
        size: 'default',
        class: 'pl-10',
      },
      {
        hasLeftIcon: true,
        size: 'sm',
        class: 'pl-8',
      },
      {
        hasRightIcon: true,
        size: 'default',
        class: 'pr-10',
      },
      {
        hasRightIcon: true,
        size: 'sm',
        class: 'pr-8',
      },
    ],
    defaultVariants: {
      size: 'default',
      hasLeftIcon: false,
      hasRightIcon: false,
    },
  },
);

type InputPropsWithoutSize = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size'
>;

type InputVariantsProps = VariantProps<typeof inputVariants>;

// Omit hasLeftIcon and hasRightIcon from the public API
type PublicInputVariantsProps = Omit<
  InputVariantsProps,
  'hasLeftIcon' | 'hasRightIcon'
>;

export interface InputProps
  extends InputPropsWithoutSize,
    PublicInputVariantsProps {
  htmlSize?: number;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, type, htmlSize, leftIcon, rightIcon, ...props }, ref) => {
    return (
      <div className={cn(inputWrapperVariants({ size }))}>
        {!!leftIcon && (
          <Slot
            className={cn(
              'absolute top-1/2 -translate-y-1/2 text-gray-dark-400 dark:text-gray-light-400 [&:is(svg)]:pointer-events-none',
              size === 'sm' ? 'left-3 size-3' : 'left-4 size-4',
            )}
          >
            {leftIcon}
          </Slot>
        )}
        <input
          type={type}
          className={cn(
            inputVariants({
              size,
              hasLeftIcon: !!leftIcon,
              hasRightIcon: !!rightIcon,
              className,
            }),
          )}
          ref={ref}
          size={htmlSize}
          {...props}
        />
        {!!rightIcon && (
          <Slot
            className={cn(
              'absolute top-1/2 -translate-y-1/2 text-gray-dark-400 dark:text-gray-light-400 [&:is(svg)]:pointer-events-none',
              size === 'sm' ? 'right-3 size-3' : 'right-4 size-4',
            )}
          >
            {rightIcon}
          </Slot>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

type ClearInputButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ClearInputButton = React.forwardRef<
  HTMLButtonElement,
  ClearInputButtonProps
>(({ className, ...props }, ref) => {
  return (
    <button
      type="button"
      ref={ref}
      className={cn(
        'absolute top-1/2 -translate-y-1/2 text-gray-dark-400 dark:text-gray-light-400',
        'focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-offset-2 focus-visible:ring-offset-gray-light-200',
        'dark:focus-visible:ring-uppbeat dark:focus-visible:ring-offset-gray-dark-200 rounded-full focus-visible:outline-none',
        className,
      )}
      {...props}
    >
      <X className="fill-none size-full absolute inset-0" strokeWidth={3} />
    </button>
  );
});

ClearInputButton.displayName = 'ClearInputButton';

export { Input, inputVariants, ClearInputButton };
