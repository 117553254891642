
export const fetchAdvert = () => (dispatch) => {
  dispatch({
    type: "IS_AUDIO_READY",
    isReady: false,
  });
  return fetch(`${process.env.NEXT_PUBLIC_API}uppbeataa/fetch`, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((response) => {
      dispatch({
        type: "IS_AUDIO_READY",
        isReady: true,
      });

      dispatch({
        type: "SET_SECONDARY_TRACK",
        payload: response.data,
      });

      // dispatch({ 	// Keyboard-control logic
      //   type: "DISABLE_KEYBOARD_CONTROLS",
      // });
    })
    .catch((err) => err);
};

export const fetchWaveform = (version) => () => {
  const formData = new FormData();

  let versionId = version.id;
  let apiRoute = "";

  if (version.is_sfx) {
    apiRoute = "sfxes/fetchWavefrom";
    formData.append("variantId", versionId);
  } else {
    apiRoute = "tracks/fetchWavefrom";
    formData.append("versionId", versionId);
  }

  return new Promise((resolve, reject) => {
    fetch(`${process.env.NEXT_PUBLIC_API}${apiRoute}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.variantId) {
          result.versionId = result.variantId;
        }
        resolve( result );
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const playerActive = () => (dispatch) => {
  dispatch({
    type: "PLAYER_ACTIVE",
  });
};

export const playerInActive = () => (dispatch) => {
  dispatch({
    type: "PLAYER_IN_ACTIVE",
  });
};

export const changeMode = (mode) => (dispatch) => {
  dispatch({
    type: "CHANGE_MODE",
    mode,
  });
};

export const onTogglePlay = (playing) => (dispatch) => {
  dispatch({
    type: "TOGGLE_PLAY",
    playing,
  });
};
export const playActiveTrack = () => (dispatch) => {
  dispatch({
    type: "PLAY_ACTIVE_TRACK",
    playing: true,
  });
};
export const pausedActiveTrack = () => (dispatch) => {
  dispatch({
    type: "PAUSED_ACTIVE_TRACK",
    playing: false,
  });
};
export const onChangeTrack = (track, direction) => (dispatch) => {
  dispatch({
    type: "ON_CHANGE_TRACK",
    track,
    direction,
  });
};

export const addActiveTrack = (track) => (dispatch) => {
  dispatch({
    type: "ADD_ACTIVE_TRACK",
    track,
  });
};

export const addPrimaryTrack = (track) => (dispatch) => {
  dispatch({
    type: "ADD_PRIMARY_TRACK",
    track,
  });
};

export const isAudioReady = (audio) => (dispatch) => {
  dispatch({
    type: "IS_AUDIO_READY",
    isReady: audio,
  });
};

export const playerActiveTrack = (track) => (dispatch) => {
  dispatch({
    type: "PLAYER_ACTIVE_TRACK",
    track,
  });
};
export const addTrackWave = (tracks, page) => (dispatch) => {
  dispatch({
    type: "ADD_TRACK_WAVE",
    tracks,
    page,
  });
};
export const mergeTrackWave = (tracks, page) => (dispatch) => {
  dispatch({
    type: "MERGE_TRACK_WAVE",
    tracks,
    page,
  });
};
export const flushNewTrackWave = () => (dispatch) => {
  dispatch({
    type: "FLUSH_NEW_TRACK_WAVE",
  });
};
export const addPageTrackWave = (tracks) => (dispatch) => {
  dispatch({
    type: "ADD_PAGE_TRACK_WAVE",
    tracks,
  });
};
export const addTrackPageWave = (track) => (dispatch) => {
  dispatch({
    type: "ADD_TRACK_PAGE_WAVE",
    track,
  });
};
export const flushTrackWave = () => (dispatch) => {
  dispatch({
    type: "FLUSH_TRACK_WAVE",
  });
};
export const setPlayerPosition = (position) => (dispatch) => {
  dispatch({
    type: "SET_PLAYER_POSITION",
    position,
  });
};
export const setTrackPosition = (position) => (dispatch) => {
  dispatch({
    type: "SET_TRACK_POSTION",
    position,
  });
};
export const updateTrackProgress = (progress) => (dispatch) => {
  dispatch({
    type: "UPDATE_TRACK_PROGRESS",
    progress,
  });
};
export const setVolume = (volume) => (dispatch) => {
  dispatch({
    type: "SET_PLAYER_VOLUME",
    volume,
  });
};
export const toggleMute = () => (dispatch) => {
  dispatch({
    type: "TOGGLE_MUTE",
  });
};

export const enableKeyboardControls = () => (dispatch) => {
  dispatch({
    type: "ENABLE_KEYBOARD_CONTROLS",
  });
};

export const disableKeyboardControls = () => (dispatch) => {
  dispatch({
    type: "DISABLE_KEYBOARD_CONTROLS",
  });
};

export const setPlayedTracks = () => ({
  type: "COUNT_PLAYED_TRACKS",
});

export const toogleDisabledControl = (disabled) => ({
  type: "TOGGLE_DISABLED_CONTROLS",
  payload: disabled,
});
export const onTogglePlayAdvert = (playing) => (dispatch) => {
  dispatch({
    type: "TOGGLE_PLAY_ADVERT",
    playing,
  });
};

export const onToggleMutePlay = (playing) => (dispatch) => {
  dispatch({
    type: "TOGGLE_UNMUTE_PLAY_ADVERT",
    playing,
  });
};

export const playAdvertVolume = (payload) => (dispatch) => {
  dispatch({
    type: "PLAY_ADVERT_VOLUME",
    payload,
  });
};
export const pauseAdvertVolume = (payload) => (dispatch) => {
  dispatch({
    type: "PAUSE_ADVERT_VOLUME",
    payload,
  });
};
export const setClickedEvent = () => ({
  type: "CLICKED_CONTROLLED_ACTION",
});

export const setSecondaryTrack = (selectedValue) => ({
  type: "SET_SECONDARY_TRACK",
  payload: selectedValue,
});

export const flushSecondaryTrack = () => ({
  type: "FLUSH_SECONDARY_TRACK",
});

export const startAdvertTimer = () => ({
  type: "START_ADVERT_TIMER",
});

export const flushAdvertTimer = () => ({
  type: "FLUSH_ADVERT_TIMER",
});

export const advertTimerCompleted = () => ({
  type: "ADVERT_TIMER_COMPLETED",
});
