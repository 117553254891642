import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { findYoutubeChannel } from '@/components/UBWhitelist/api';

import type { YouTubeChannelData } from '@/components/UBWhitelist/types';

export const useYoutubeChannelData = (selectedChannel: string) => {
  return useQuery<YouTubeChannelData | null>({
    queryKey: ['youtube-channel-data', selectedChannel],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      if (!selectedChannel) {
        return null;
      }

      const results = await findYoutubeChannel(selectedChannel);

      return {
        ...results,
        channelId: selectedChannel,
      };
    },
  });
};
