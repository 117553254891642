export class FetchError extends Error {
  statusCode: number;

  isFetchError: boolean;

  constructor(statusCode: number, message: string) {
    super(message);

    this.isFetchError = true;
    this.statusCode = statusCode;
  }
}
