// Use a suffix in non-production environments to avoid clashes between
// uat.uppbeat.io and uppbeat.io (if they have the same name the cookies for
// the latter will also be applied to the former).
const SUFFIX =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? ''
    : `_${process.env.NEXT_PUBLIC_ENV}`;

export const AUTH_TOKEN_COOKIE_NAME = `auth_token${SUFFIX}`;
export const USER_STATE_COOKIE_NAME = `uppbeat_user${SUFFIX}`;
