import SidebarHeader from '@/components/Modals/Sidebar/components/SidebarHeader';
import useDynamicSidebarHeader from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';
import { signUpModalTabs } from '@/components/Modals/SignUp/tabs';

import { loginModalTabs } from './tabs';

const LoginModalHeader = () => {
  // Used for controlling the sidebar header when specific screens are active
  useDynamicSidebarHeader({
    dynamicTabs: [...loginModalTabs, ...signUpModalTabs],
  });

  return <SidebarHeader />;
};

export { LoginModalHeader };
