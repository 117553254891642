import SidebarHeader from '@/components/Modals/Sidebar/components/SidebarHeader';
import useDynamicSidebarHeader from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

import { verifyEmailTabs } from './tabs';

const VerifyEmailModalHeader = () => {
  // Used for controlling the sidebar header when specific screens are active
  useDynamicSidebarHeader({
    dynamicTabs: [...verifyEmailTabs],
  });

  return <SidebarHeader />;
};

export { VerifyEmailModalHeader };
