import { twMerge } from 'tailwind-merge';

const Error = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span className={twMerge('text-xs text-red', className)} {...props} />;
};

export default Error;
