import Image from 'next/image';

const Icons = {
  Free: () => (
    <Image
      unoptimized
      className="free w-4 h-4"
      src="https://cdn.uppbeat.io/icons/static-icons/account-free.svg"
      width={14}
      height={14}
      alt=""
    />
  ),
  Premium: () => (
    <Image
      unoptimized
      className="free w-4 h-4"
      src="https://cdn.uppbeat.io/icons/static-icons/premium_star.svg"
      width={14}
      height={14}
      alt=""
    />
  ),
  Business: () => (
    <svg
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-4 h-4"
    >
      <path
        d="M9.86452 0H0V15.9998H9.86452V0ZM4.45106 12.9622H3.42852V11.0976H4.45106V12.9622ZM4.45106 9.20287H3.42852V7.33824H4.45106V9.20287ZM4.45106 5.4736H3.42852V3.60897H4.45106V5.4736ZM7.54876 12.9622H6.52622V11.0976H7.54876V12.9622ZM7.54876 9.20287H6.52622V7.33824H7.54876V9.20287ZM7.54876 5.4736H6.52622V3.60897H7.54876V5.4736Z"
        fill="#FBBF26"
      />
      <path
        d="M16.6318 13.9851V6.55664H10.707V16.0001H14.6167C15.7295 16.0001 16.6318 15.0979 16.6318 13.9851ZM14.3461 12.9926H12.3611V12.0002H14.3461V12.9926ZM14.3461 10.4964H12.3611V9.47389H14.3461V10.4964Z"
        fill="#FBBF26"
      />
    </svg>
  ),
};

export default Icons;
