import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';

import type { ReactNode } from 'react';

interface UBButtonDropdownRelativeProps {
  children: ReactNode;
  className?: string;
  columns: number;
  offset: DOMRect;
  position: string;
}

const UBButtonDropdownRelative = ({
  children,
  className,
  columns,
  offset,
  position,
}: UBButtonDropdownRelativeProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState({});

  const ubBtnDropdownClasses = clsx('ub-btn-dropdown', `columns-${columns}`, {
    [className as string]: className,
    [position]: position,
  });

  useEffect(() => {
    const initialStyle = {
      '--ubbtn-calc-width': '22px',
    };

    const dropdownHeight = dropdownRef?.current?.clientHeight;

    if (offset && dropdownHeight) {
      if (position === 'bottom-left') {
        setStyle({
          ...initialStyle,
          top: offset.height + 12,
          left: '100%',
          right: 'unset',
          transform: 'translateX(-100%)',
        });
      }

      if (position === 'bottom-center') {
        setStyle({
          ...initialStyle,
          top: offset.height + 12,
          left: '50%',
          right: 'unset',
          transform: 'translateX(-50%)',
        });
      }

      if (position === 'bottom-right') {
        setStyle({
          ...initialStyle,
          top: offset.height + 12,
          right: '100%',
          left: 'unset',
          transform: 'translateX(100%)',
        });
      }

      if (position === 'top-left') {
        setStyle({
          ...initialStyle,
          top: -dropdownHeight - 12,
          left: '100%',
          right: 'unset',
          transform: 'translateX(-100%)',
        });
      }

      if (position === 'top-center') {
        setStyle({
          ...initialStyle,
          top: -dropdownHeight - 12,
          left: '50%',
          right: 'unset',
          transform: 'translateX(-50%)',
        });
      }

      if (position === 'top-right') {
        setStyle({
          ...initialStyle,
          top: -dropdownHeight - 12,
          right: '100%',
          left: 'unset',
          transform: 'translateX(100%)',
        });
      }
    }
  }, [offset.height, offset, position]);

  return (
    <div className={ubBtnDropdownClasses} style={style} ref={dropdownRef}>
      {children}
    </div>
  );
};

export default UBButtonDropdownRelative;
