/* eslint-disable jsx-a11y/no-autofocus */
import clsx from 'clsx';
import { Trash } from 'lucide-react';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';
import ChannelImage from '@/components/YouTubeChannelImage';

import { ChannelResults } from './ChannelResults';
import { useSearchYoutubeChannel } from './hooks/useSearchYoutubeChannel';
import { useYoutubeChannelData } from './hooks/useYoutubeChannelData';
import styles from './styles.module.css';

import type { YouTubeChannelRequest } from './types';
import type { ChangeEventHandler } from 'react';

interface ChannelWhitelistProps {
  isEditing: boolean;
  handleRemoveChannel: (channelID: number) => void;
  selectedChannel: string;
  whitelistId?: number;
  whitelistChannel: (channel: YouTubeChannelRequest) => void;
  isFetching?: boolean;
  className?: string;
  compact?: boolean;
}

export const ChannelWhitelist: React.FC<ChannelWhitelistProps> = React.memo(
  ({
    handleRemoveChannel,
    isEditing,
    selectedChannel,
    whitelistId,
    whitelistChannel,
    isFetching,
    className,
    compact,
  }) => {
    const [inputValue, setInputValue] = useState('');

    const { data: searchResults } = useSearchYoutubeChannel(inputValue);

    const { data: channelData } = useYoutubeChannelData(selectedChannel);

    const handleSelectChannel = (selectedValue: YouTubeChannelRequest) => {
      whitelistChannel(selectedValue);
      setInputValue('');
    };

    const handleSubmitChannelID = (id: string) => {
      const selectedValue = {
        channelId: id,
        channelTitle: 'Unknown',
      };

      whitelistChannel(selectedValue);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      if (typeof event?.target?.value === 'string') {
        setInputValue(event.target.value);
      }
    };

    const showResults = searchResults && searchResults?.length > 0;

    const hasError = !channelData;

    if (showResults || isEditing) {
      return (
        <ChannelResults
          compact={compact}
          inputValue={inputValue}
          handleChange={handleChange}
          searchResults={searchResults ?? []}
          handleSelectChannel={handleSelectChannel}
          handleSubmitChannelID={handleSubmitChannelID}
        />
      );
    }

    return (
      <div className={clsx(styles.whitelistedChannel, className)}>
        <ChannelImage
          channel={{
            channel_id: channelData?.channelId ?? '',
            channel_name: channelData?.title ?? '',
          }}
        />
        <div className={styles.whitelistedDetails}>
          {!hasError ? (
            <>
              <a
                href={`https://www.youtube.com/channel/${channelData.channelId}`}
                target="_blank"
                rel="noreferrer"
                className={clsx(styles.channelName, 'hover:underline')}
              >
                {channelData.title}
              </a>
              <p className={styles.channelId}>
                {channelData.customUrl
                  ? channelData.customUrl
                  : channelData.channelId}
              </p>
            </>
          ) : (
            <p className={styles.channelName}>Unknown Channel</p>
          )}
        </div>
        {(!!whitelistId || isFetching) && (
          <Button
            variant="white"
            borderRadius="full"
            size="icon"
            onClick={() => {
              if (whitelistId) {
                handleRemoveChannel(whitelistId);
              }
            }}
            disabled={isFetching}
          >
            <Trash className="size-4 fill-none" />
            <span className="sr-only">Remove</span>
          </Button>
        )}
      </div>
    );
  },
);

ChannelWhitelist.displayName = 'ChannelWhitelist';
