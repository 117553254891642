export type VideoTypes = (typeof videoList)[number]['type'];

const videoList = [
  {
    id: 'DjwSzXcpgtY',
    name: 'Welcome to Uppbeat',
    length: '1:21',
    type: 'welcome-to-uppbeat' as const,
  },
  {
    id: 'ZirMenr1xns',
    name: 'Welcome to Uppbeat Premium',
    length: '2:21',
    type: 'welcome-to-premium' as const,
  },
  {
    id: '6P7CbOZ6e0Y',
    name: 'Uppbeat Free vs Premium',
    length: '1:58',
    type: 'free-vs-premium' as const,
  },
  {
    id: 'LaeBk7K0k28',
    name: 'Uppbeat Premium vs Business',
    length: '2:08',
    type: 'premium-vs-business' as const,
  },
  {
    id: 'WVVQOchqKLc',
    name: 'AI Playlist Generator',
    length: '4:00',
    type: 'ai-playlist-generator' as const,
  },
];

export const getVideo = (
  videoType: string,
  searchType: keyof (typeof videoList)[number] = 'type',
) => videoList.find((video) => video[searchType] === videoType);
