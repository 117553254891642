import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { resendVerificationEmail } from '@/actions/authenticationAction';
import { getAuthToken } from '@/auth';
import TextArea from '@/components/Form/TextArea';
import Modal from '@/components/Modals/Core';
import StackedTitles from '@/components/Modals/Sidebar/components/StackedTitles';
import { useSidebarModalContext } from '@/components/Modals/Sidebar/context/SidebarModalContext';
import { T } from '@/components/T';
import { Button, Loading } from '@/components/ui/button';
import { Video } from '@/components/Video';
import { useAppState } from '@/hooks/useAppState';
import { useTranslate } from '@/hooks/useTranslate';

import { AccountType } from './types';

import type { SubmitHandler } from 'react-hook-form';

const schema = z.object({
  howDidYouHear: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

const welcomeMessage = {
  [AccountType.Free]:
    'Your free account gives you 3 downloads (renewed monthly) and access to 30% of the entire Uppbeat catalog.',
  [AccountType.Premium]: (
    <T keyName="modal.welcomeToPremium.subtitle">
      It's time to take your content to the next level with unlimited and
      uninterrupted access to Uppbeat!
    </T>
  ),
  [AccountType.Business]: (
    <T keyName="modal.welcomeToPremium.subtitle">
      It's time to take your content to the next level with unlimited and
      uninterrupted access to Uppbeat!
    </T>
  ),
};

interface WelcomeProps {
  attemptedDownload?: boolean;
}

const Welcome = ({ attemptedDownload = false }: WelcomeProps) => {
  const [resendEmailSuccessMsg, setResendEmailSuccessMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendEmailError, setResendEmailError] = useState(false);

  const { onOpenChange } = useSidebarModalContext();
  const { t } = useTranslate();
  const userAuth = useAppState('userAuth');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const isGoogleUser = userAuth?.isGoogleUser ?? false;

  const onSubmit: SubmitHandler<FormData> = async ({ howDidYouHear }) => {
    if (howDidYouHear) {
      try {
        const authToken = getAuthToken();
        const formdata = new FormData();

        formdata.append('reason', howDidYouHear);
        await fetch(`${process.env.NEXT_PUBLIC_API}set_hear_about_us`, {
          method: 'POST',
          ...(authToken ? { headers: { token: authToken } } : {}),
          body: formdata,
        })
          .then((res) => res.json())
          // eslint-disable-next-line no-console
          .then(console.log);
      } catch (error) {
        console.warn('Could not send reason', error);
      }
    }

    onOpenChange(false);
  };

  const resendVerification = () => {
    if (!resendEmailSuccessMsg) {
      setLoading(true);
      setResendEmailError(false);

      resendVerificationEmail()
        .then((res: { success: boolean; message: string }) => {
          if (res.success) {
            setResendEmailSuccessMsg(res.message);
          } else {
            setResendEmailError(true);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setResendEmailError(true);
          console.warn('Error resending verification email', error);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isGoogleUser ? (
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          <T keyName="modal.welcome.title">Welcome to Uppbeat!</T>
        </Modal.Title>
      ) : attemptedDownload ? (
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          <T>Verify your email to start downloading!</T>
        </Modal.Title>
      ) : (
        <StackedTitles
          title="Welcome to Uppbeat!"
          subtitle="Verify your email to start downloading!"
        />
      )}
      <Modal.Description className="mb-4 text-base">
        {isGoogleUser ? (
          welcomeMessage[
            userAuth?.isBusiness
              ? AccountType.Business
              : userAuth?.isPremium
              ? AccountType.Premium
              : AccountType.Free
          ]
        ) : attemptedDownload ? (
          <T keyName="modal.verifyEmail.clickLinkOnJoin">
            To verify your email address, please click the link in the email we
            sent to you when you joined.
          </T>
        ) : (
          <T keyName="modal.verifyEmail.clickLink">
            To verify your email address, please click the link in the email we
            just sent to you.
          </T>
        )}
      </Modal.Description>
      {!isGoogleUser && (
        <p className="mb-6 text-base">
          <T keyName="modal.verifyEmail.checkSpamIfNoEmail">
            If you can’t see an email within a few minutes, check your spam
            filter
          </T>
          {!resendEmailSuccessMsg && (
            <>
              &nbsp;<T keyName="modal.verifyEmail.orSendAnother">or</T>{' '}
              <a onClick={resendVerification}>
                <T keyName="modal.verifyEmail.sendAnotherEmail">
                  send another email
                </T>
              </a>
            </>
          )}
          .
        </p>
      )}
      {attemptedDownload ? (
        <Button
          type="button"
          variant="secondary"
          onClick={() => onOpenChange(false)}
          className="w-full"
        >
          Dismiss
        </Button>
      ) : (
        <>
          <Video type="welcome-to-uppbeat" />
          <div className="my-6 p-4 rounded-lg border border-dashed border-gray-medium-100 dark:border-gray-medium-400">
            <h3 className="text-base font-semibold text-gray-900 mb-2">
              <T keyName="modal.verifyEmail.howDidYouHear">
                How did you hear about us? 🤔
              </T>
            </h3>
            <TextArea
              name="howDidYouHear"
              register={register}
              hasError={!!errors.howDidYouHear}
              placeholder={t('verifyEmail.letUsKnow', 'Let us know here')}
              maxLength={500}
              className="p-2"
            />
          </div>
          <Button
            type="submit"
            variant="uppbeat"
            id="close-signup-email"
            disabled={loading}
            className="w-full"
          >
            <Loading visible={loading} />
            Let's get started!
          </Button>
        </>
      )}

      {!!resendEmailSuccessMsg && (
        <div className="alert-general-success mt-4">
          <span>
            Success!
            <br />A verification email has been sent to{' '}
            {userAuth?.email ?? 'your email'}.
          </span>
          <span>
            {' '}
            <T keyName="modal.verifyEmail.checkSpamAfterSuccess">
              If you don’t see an email within a few minutes, please check your
              spam filter
            </T>
          </span>
        </div>
      )}
      {resendEmailError && (
        <span className="alert-general-error block mt-4">
          <T keyName="modal.verifyEmail.error">
            Sorry, something went wrong. Please try again later
          </T>
        </span>
      )}
    </form>
  );
};

export default Welcome;
