import { addFBEvent } from './facebook/addFBEvent';
import { addAdEvent } from './google/addAdEvent';
import { addGAEvent } from './google/addGAEvent';
import { addUETEvent } from './microsoft/addUETEvent';
import { addTikTokEvent } from './tiktok/addTikTokEvent';

export const trackRegistrationComplete = () => {
  try {
    // GA.
    addGAEvent('signup-email');

    // Ads.
    addAdEvent('Z1XuCNvNqfEBENvO5dUB');

    // Meta.
    addFBEvent('Signup-Free-email');

    // TikTok.
    addTikTokEvent('CompleteRegistrationEmail');

    // UET.
    addUETEvent('event', 'Free_email', {
      event_category: 'Signup',
    });
    addUETEvent('event', 'for_audience', {
      event_category: 'Signup',
    });
  } catch (e) {
    console.warn('Error tracking registration', e);
  }
};

export const trackRegistrationGoogle = () => {
  try {
    // GA.
    addGAEvent('signup-google');

    // Ads.
    addAdEvent('VseNCKfkqfEBENvO5dUB');

    // Meta.
    addFBEvent('Signup-Free-google');

    // TikTok.
    addTikTokEvent('CompleteRegistrationGoogle');

    // UET.
    addUETEvent('event', 'Free_google', { event_category: 'Signup' });
    addUETEvent('event', 'for_audience', { event_category: 'Signup' });
  } catch (e) {
    console.warn('Error tracking registration', e);
  }
};

const trackPremiumPurchase = (length: 'Annual' | 'Monthly') => {
  if (length === 'Annual') {
    // GA.
    addGAEvent('subscription-annual');

    // Ads.
    addAdEvent('k5fuCKq3m_EBENvO5dUB');
  } else if (length === 'Monthly') {
    // Ads.
    addAdEvent('80vjCKqGifEBENvO5dUB');

    // GA.
    // Typo is intentional to match GA event.
    addGAEvent('subscription-monhtly');
  }

  // Meta.
  addFBEvent(`Subscription-${length}`);

  // TikTok.
  addTikTokEvent(`Premium_${length}`);

  // UET.
  addUETEvent('event', length, { event_category: 'Subscription' });
};

const trackBusinessPurchase = (length: 'Annual' | 'Monthly') => {
  if (length === 'Annual') {
    // GA.
    addGAEvent('business-subscription-annual');

    // Ads.
    addAdEvent('dREnCNfJ5Y0YENvO5dUB');
  } else if (length === 'Monthly') {
    // GA.
    addGAEvent('business-subscription-monthly');

    // Ads.
    addAdEvent('E0CzCPvY5Y0YENvO5dUB');
  }

  // Meta.
  addFBEvent(`Subscription-${length}-Business`);

  // TikTok.
  addTikTokEvent(`Business_${length}`);

  // UET.
  addUETEvent('event', `Business ${length}`, {
    event_category: 'Subscription',
  });
};

export const trackPurchase = (
  type: 'Premium' | 'Business',
  length: 'Annual' | 'Monthly',
) => {
  try {
    if (type === 'Premium') {
      trackPremiumPurchase(length);
    } else if (type === 'Business') {
      trackBusinessPurchase(length);
    }
  } catch (e) {
    console.warn('Error tracking purchase', e);
  }
};

export const trackDownload = () => {
  try {
    // GA.
    addGAEvent('click-download');

    // Ads.
    addAdEvent('48KACPWHmPABENvO5dUB');

    // Meta.
    addFBEvent('Click-download-button');

    // TikTok.
    addTikTokEvent('Download');

    // UET.
    addUETEvent('event', 'Download_button', { event_category: 'Click' });
    addUETEvent('event', 'for_audience', {
      event_category: 'microconversions',
    });
  } catch (e) {
    console.warn('Error tracking download', e);
  }
};

export const trackSFXDownload = () => {
  try {
    // GA.
    addGAEvent('downloaded-Sfx');

    // Ads.
    addAdEvent('48KACPWHmPABENvO5dUB');

    // Meta.
    addFBEvent('Download-sfx');

    // TikTok.
    addTikTokEvent('DownloadSfx');
  } catch (e) {
    console.warn('Error tracking SFX download', e);
  }
};

export const trackPlayEngagement = () => {
  try {
    // GA.
    addGAEvent('engagement-musicplayer');

    // Ads.
    addAdEvent('7Jz0COiDt_ABENvO5dUB');

    // Meta.
    addFBEvent('Used-musicplayer');

    // TikTok.
    addTikTokEvent('PlayMusic');

    // UET.
    addUETEvent('event', 'musicplayer', { event_category: 'Used' });
    addUETEvent('event', 'for_audience', {
      event_category: 'microconversions',
    });
  } catch (e) {
    console.warn('Error tracking play engagement', e);
  }
};

export const trackSearchEngagement = (term: string) => {
  try {
    // GA.
    addGAEvent('engagement-search', { value: term });

    // Ads.
    addAdEvent('U8HjCKKKhfABENvO5dUB');

    // Meta.
    addFBEvent('Used-search');

    // TikTok.
    addTikTokEvent('Search');

    // UET.
    addUETEvent('event', 'search', { event_category: 'Used' });
    addUETEvent('event', 'for_audience', {
      event_category: 'microconversions',
    });
  } catch (e) {
    console.warn('Error tracking search engagement', e);
  }
};
