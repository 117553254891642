import React from "react";
import InlineSVG from 'svg-inline-react';

class CrossSVG extends React.Component {
    render() {
        const crossSVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.8 217.8"><path fill="none" stroke-width="13.205" stroke-miterlimit="10" d="M14 14l189.8 189.8M203.8 14L14 203.8"/></svg>
`;
        return (
            <InlineSVG src={crossSVG} />
        );
    }
}

export default CrossSVG;
