import Modal from '@/components/Modals/Core';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';

import { VerifyEmailModalHeader } from './header';
import VerifyEmailModalScreens from './screens';
import { VerifyEmailModalScreenName } from './types';

interface VerifyEmailModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initial?: VerifyEmailModalScreenName;
}

const VerifyEmailModal = ({
  open,
  onOpenChange,
  children,
  initial = VerifyEmailModalScreenName.VerifyEmail,
}: VerifyEmailModalProps) => {
  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange} trigger={children}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer initial={initial}>
          <VerifyEmailModalHeader />
          <VerifyEmailModalScreens />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default VerifyEmailModal;
