import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

const UET_ID = process.env.NEXT_PUBLIC_UET_ID;

export const MicrosoftUET = () => {
  const [loaded, setLoaded] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!loaded) {
      return;
    }

    // https://bingadsuet.azurewebsites.net/SPA_Index.html
    const uetq = window.uetq ?? [];

    uetq.push('event', 'page_view', { page_path: router.asPath });
  }, [router.asPath, loaded]);

  return (
    <>
      <Script
        id="microsoft-uet"
        strategy="afterInteractive"
        onReady={() => setLoaded(true)}
      >
        {`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${UET_ID}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}
      </Script>

      <Script id="uet-consent" strategy="afterInteractive">
        {`
        window.uetq = window.uetq || [];
        window.uetq.push('consent', 'default', {
          'ad_storage': 'denied'
        });
      `}
      </Script>
    </>
  );
};
