import { getAuthToken } from '@/auth';

import type { NextIncomingMessage } from '@/types/request';

export interface AuthenticationResponse {
  authorization_token: string;
  authorization_token_expiry: string;
}

export const getAuthorizationToken = async ({
  req,
}: {
  req?: NextIncomingMessage;
} = {}): Promise<Response | null> => {
  const token = getAuthToken(req);

  if (token && token.length > 5) {
    return fetch(`${process.env.NEXT_PUBLIC_API}authentication/refresh-token`, {
      credentials: 'include',
      method: 'POST',
    });
  }

  return null;
};
