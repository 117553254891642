import optionallySkipRecaptcha from '@/utils/optionallySkipRecaptcha';

function getJsonFromFromData(formData: FormData) {
  const object: Record<string, FormDataEntryValue> = {};

  formData.forEach((value, key) => {
    object[key] = value;
  });

  return JSON.stringify(object);
}

interface LoginData {
  _username: string;
  _password: string;
  recaptchaVersion: string;
  recaptchaToken: string;
}

const onMakeLoginAttempt = async ({
  _username,
  _password,
  recaptchaVersion,
  recaptchaToken,
}: LoginData) => {
  const formData = new FormData();

  formData.append('_username', _username);
  formData.append('_password', _password);
  formData.append('recaptcha_version', recaptchaVersion);
  formData.append('recaptcha_token', recaptchaToken);
  formData.append('create_legacy', 'true');

  // Force fail recaptcha if value set in localstorage
  if (localStorage.getItem('force_fail_recaptcha')) {
    formData.append('force_fail_recaptcha', '1');
  }

  localStorage.setItem('is_google_user', '0');
  localStorage.setItem('quick_login', _username);

  return fetch(`${process.env.NEXT_PUBLIC_API}authentication/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      ...optionallySkipRecaptcha(),
    },
    body: getJsonFromFromData(formData),
  }).then((res) => res.json());
};

export default onMakeLoginAttempt;
