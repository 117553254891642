import { useContext } from 'react';

import Switch from '@/components/Switch';
import { UserStateContext } from '@/providers/UserStateProvider';

const ToggleCDN = () => {
  const { setUserState, userState } = useContext(UserStateContext);

  const isCDNDisabled = userState.avoidCdn === 1;

  const onToggleCDN = (e: boolean) => {
    setUserState('avoidCdn', e ? 1 : 0);
  };

  return (
    <Switch
      onCheckedChange={onToggleCDN}
      checked={isCDNDisabled}
      id="admin-cdn"
      label="Use CDN"
    />
  );
};

export default ToggleCDN;
