import * as Dialog from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import {
  Children,
  cloneElement,
  isValidElement,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import type { PanInfo } from 'framer-motion';
import type { ReactNode } from 'react';

interface DrawerProps {
  className?: string;
  children: ReactNode;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  isCard?: boolean;
}

interface SlotWithChildren {
  children: React.ReactNode;
}

const DrawerTrigger = ({ children }: SlotWithChildren) => {
  return children;
};

const DrawerContent = ({ children }: SlotWithChildren) => {
  return children;
};

const cardAnimations = {
  initial: {
    opacity: 0,
    y: '50%',
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: '50%',
  },
};

const DrawerContainer = memo(
  ({
    children,
    isOpen: controlledIsOpen,
    setIsOpen: controlledSetIsOpen,
    isCard,
    className,
  }: DrawerProps) => {
    const [_isOpen, _setIsOpen] = useState(false);
    const isOpen = controlledIsOpen ?? _isOpen;
    const setIsOpen = controlledSetIsOpen ?? _setIsOpen;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const [scope, animate] = useAnimate();

    const resetAnimation = useCallback(() => {
      if (scope.current) {
        void animate(scope.current, { opacity: 1 }, { duration: 0.3 });
      }
    }, [animate, scope]);

    useEffect(() => {
      if (isOpen) {
        resetAnimation();
      }
    }, [resetAnimation, isOpen]);

    const handleDragEnd = async (_event: MouseEvent, info: PanInfo) => {
      const offset = info.offset.y;
      const velocity = info.velocity.y;

      if (offset > 75 || velocity > 200) {
        void animate('.drag', { y: '150%', opacity: 1 }, { duration: 0.3 });
        await animate(scope.current, { opacity: 0 }, { duration: 0.3 });
        setIsOpen(false);
      } else {
        resetAnimation();
        await animate('.drag', { y: 0, opacity: 1 }, { duration: 0.3 });
      }
    };

    const handleDrag = (_event: MouseEvent, info: PanInfo) => {
      const opacity = Math.max(1 - info.offset.y / 250, 0.1); // Calculate opacity based on drag distance

      void animate(scope.current, { opacity }, { duration: 0 }); // Apply the new opacity
    };

    const handleOpenChange = (shouldOpen: boolean) => {
      setIsOpen(shouldOpen);

      if (!shouldOpen) {
        buttonRef.current?.focus();
      }
    };

    let trigger: ReactNode;
    let content: ReactNode;

    // Grab the trigger and content components.
    Children.forEach(children, (child) => {
      if (trigger && content) {
        return;
      }

      if (isValidElement(child)) {
        // Check if the child is a DrawerTrigger component
        if (child.type === DrawerTrigger) {
          // Clone the child element and add necessary props
          trigger = cloneElement(child.props.children as React.ReactElement, {
            ref: buttonRef,
            onClick: () => setIsOpen(true),
          });
        } else if (child.type === DrawerContent) {
          content = child;
        }
      }
    });

    if (!trigger || !content) {
      throw new Error('Drawer requires a trigger and content');
    }

    return (
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange}>
        <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
        <Dialog.Portal>
          <motion.div ref={scope} className="relative z-[9999]">
            <AnimatePresence mode="sync">
              {isOpen && (
                <Dialog.Overlay key="overlay" asChild>
                  <motion.div
                    className="fixed bg-black z-10 inset-0 "
                    initial={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      backdropFilter: 'blur(0px)',
                    }}
                    animate={{
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      backdropFilter: 'blur(1px)',
                    }}
                    exit={{
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      backdropFilter: 'blur(0px)',
                    }}
                    transition={{ duration: 0.1 }}
                  />
                </Dialog.Overlay>
              )}
              {isOpen && (
                <Dialog.Content key="content" asChild>
                  <div
                    className={clsx(
                      'fixed rounded-[0.625rem] z-10 bottom-0 left-1/2 -translate-x-1/2 w-full max-h-screen',
                      isCard && 'w-[calc(100%-4rem)]',
                    )}
                  >
                    <motion.div
                      key="hover-card-content"
                      drag="y"
                      dragSnapToOrigin
                      dragConstraints={{
                        top: 0,
                      }}
                      dragElastic={0.05}
                      whileTap={{ cursor: 'grabbing', scale: 0.97 }}
                      onDragEnd={handleDragEnd}
                      onDrag={handleDrag}
                      className={twMerge(
                        'drag -mb-20 overflow-hidden rounded-t-[2rem] p-5 pt-8 w-screen bg-white dark:bg-gray-dark-300 shadow-sm dark:text-white',
                        isCard && 'rounded-2xl mb-4 mx-auto w-full',
                        className,
                      )}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      variants={cardAnimations}
                      transition={{ type: 'spring', duration: 0.4, bounce: 0 }}
                      style={{
                        transformOrigin:
                          'var(--radix-dialog-content-transform-origin)',
                      }}
                    >
                      <div className="absolute top-3 left-1/2 -translate-x-1/2 bg-black bg-opacity-50 h-1 w-10 rounded-full contrast-200 brightness-200 opacity-50" />
                      {content}
                      {!isCard && <div className="h-20" />}
                    </motion.div>
                  </div>
                </Dialog.Content>
              )}
            </AnimatePresence>
          </motion.div>
        </Dialog.Portal>
      </Dialog.Root>
    );
  },
);

DrawerContainer.displayName = 'DrawerContainer';

const Drawer = {
  Container: DrawerContainer,
  Trigger: DrawerTrigger,
  Content: DrawerContent,
};

export default Drawer;
