import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar9HelpCenter extends React.Component {
    render() {
        const Sidebar9HelpCenter = `<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8065 2.19995C9.87942 -0.730768 5.13072 -0.733682 2.19996 2.19341C-0.73076 5.12057 -0.733709 9.86929 2.19345 12.8001C5.12054 15.7308 9.86931 15.7337 12.8001 12.8066C15.7308 9.87947 15.7337 5.13068 12.8065 2.19995ZM7.50232 12.0435C7.10561 12.0435 6.78401 11.722 6.78401 11.3252C6.78401 10.9285 7.10557 10.6069 7.50232 10.6069C7.89903 10.6069 8.22063 10.9284 8.22063 11.3252C8.22059 11.7219 7.89903 12.0435 7.50232 12.0435ZM8.67015 7.71857C8.25984 7.95844 8.22477 8.25353 8.223 9.10382C8.22289 9.16005 8.22271 9.21678 8.22239 9.27383C8.21984 9.67572 7.89335 9.99977 7.49199 9.99977H7.48728C7.08384 9.99721 6.7589 9.6681 6.76142 9.26462C6.76178 9.20966 6.76189 9.15501 6.76199 9.10087C6.76379 8.22698 6.76599 7.13948 7.93281 6.45743C8.86904 5.91014 8.98703 5.55062 8.87724 5.07887C8.74825 4.52475 8.65385 4.03846 8.07692 4.03846C8.07692 4.03846 6.34615 4.03846 6.32294 5.07887C6.32294 5.48224 6.17267 5.76923 5.76923 5.76923C5.19231 5.76923 4.87594 5.15107 4.87594 4.74771C4.87594 4.03846 6.15636 3.14974 7.68064 2.97488C8.94689 2.82963 10.0234 3.55858 10.3 4.74771C10.7136 6.52409 9.22906 7.3919 8.67015 7.71857Z" />
        </svg>`;

        return (
            <InlineSVG src={Sidebar9HelpCenter} />
        );
    }
}

export default Sidebar9HelpCenter;
