import clsx from 'clsx';
import * as React from 'react';

interface ShieldTickProps extends React.SVGProps<SVGSVGElement> {
  hasStroke?: boolean;
}

const ShieldTick = React.memo(({ hasStroke, ...props }: ShieldTickProps) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.10249 0.182563C4.74158 1.61414 2.90953 2.47248 0.964939 2.58956C0.837912 2.60037 0.719516 2.65995 0.633347 2.75642C0.547179 2.8529 0.499567 2.97919 0.500003 3.11012V8.77893C0.500003 12.5996 3.28963 14.8872 6.33496 15.9761C6.44344 16.0143 6.56123 16.0143 6.66972 15.9761C9.70576 14.8872 12.5 12.5757 12.5 8.77893V3.11012C12.5005 2.97919 12.4529 2.8529 12.3667 2.75642C12.2805 2.65995 12.1621 2.60037 12.0351 2.58956C10.0914 2.47875 8.25789 1.6273 6.89289 0.201666C6.8463 0.14049 6.78708 0.0906865 6.71951 0.0558402C6.65193 0.0209939 6.57768 0.0019723 6.50209 0.000145365C6.4265 0.00168157 6.35145 0.0137318 6.28235 0.0452747C6.21325 0.0768176 6.15183 0.123706 6.10249 0.182563Z"
        className={clsx(
          '!fill-green',
          hasStroke && 'stroke stroke-gray-light-300 dark:stroke-gray-dark-200',
        )}
      />
      <path
        d="M5.6962 10.6407C5.63083 10.6431 5.56566 10.6318 5.50475 10.6073C5.44383 10.5828 5.38847 10.5457 5.34209 10.4984L3.56129 8.66912C3.45625 8.58301 3.38828 8.45809 3.37196 8.32109C3.35565 8.18409 3.3923 8.04597 3.47404 7.93642C3.55771 7.82999 3.67836 7.76117 3.81051 7.74444C3.94265 7.72772 4.07595 7.76445 4.18226 7.84679C4.2178 7.87104 4.24904 7.90137 4.27464 7.93642L5.6962 9.39133L8.83183 6.18097C8.93061 6.11154 9.04996 6.07975 9.16916 6.09113C9.28835 6.10251 9.39991 6.15632 9.48452 6.24327C9.5692 6.33022 9.62155 6.44482 9.63269 6.56725C9.64376 6.68968 9.61283 6.81224 9.54521 6.91371L6.04518 10.4984C5.99995 10.5458 5.94552 10.583 5.8854 10.6076C5.82529 10.6321 5.76083 10.6434 5.6962 10.6407Z"
        className="!fill-white"
      />
    </svg>
  );
});

ShieldTick.displayName = 'ShieldTick';

export default ShieldTick;
