const INITIAL_STATE = {
	anchor: null,
};

const temporaryAnchor = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'ADD_TEMPORARY_ANCHOR':
			return Object.assign({}, state, { anchor: action.anchor });
		case 'REMOVE_TEMPORARY_ANCHOR':
			return {
				anchor: null,
			};
		default:
			return state;
	}
};

export default temporaryAnchor;