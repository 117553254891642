import { twMerge } from 'tailwind-merge';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  srOnly?: boolean;
}

const Label = ({ className, srOnly = false, ...props }: LabelProps) => {
  // Note: Hiding the label is bad for accessibility, but this is already
  // better than the original implementation which didn't have a label at all.
  return (
    <label className={twMerge(srOnly && 'sr-only', className)} {...props} />
  );
};

export default Label;
