import * as React from 'react';

const _Twitter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M36.653 3.808H43.4L28.66 20.655 46 43.58H32.422L21.788 29.676 9.62 43.58H2.869l15.766-18.02L2 3.808h13.922l9.613 12.709 11.118-12.71zM34.285 39.54h3.738L13.891 7.634H9.879l24.406 31.907z"
        className="text-black dark:text-white fill-current"
      />
    </svg>
  );
};

const Twitter = React.memo(_Twitter);

export default Twitter;
