import clsx from 'clsx';
import { motion } from 'framer-motion';
import Image from 'next/image';

import UBButton from '@/components/UBButton';
import { useTranslate } from '@/hooks/useTranslate';

import { ChannelIDInput } from './ChannelIDInput';
import styles from './styles.module.css';

import type { YouTubeChannelRequest, YoutubeResponseItem } from './types';

interface ChannelResultProps {
  inputValue: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchResults: YoutubeResponseItem[];
  handleSelectChannel: (selectedValue: YouTubeChannelRequest) => void;
  handleSubmitChannelID: (id: string) => void;
  compact?: boolean;
}

export const ChannelResults: React.FC<ChannelResultProps> = ({
  inputValue,
  handleChange,
  searchResults,
  handleSelectChannel,
  handleSubmitChannelID,
  compact,
}) => {
  const { t } = useTranslate('modal');

  const displayedResults = compact ? searchResults.slice(0, 4) : searchResults;

  const resultsHeight =
    displayedResults.length * 52 + (displayedResults.length ? 12 : 0);

  return (
    <div
      className={clsx(
        styles.resultsContainer,
        'min-h-full flex flex-col p-4 transition-all justify-center',
        searchResults.length && !compact && 'shadow-card',
        compact && '!relative overflow-auto !p-3',
      )}
    >
      <div className={clsx(styles.inputWrapper, 'input-wrapper !py-0')}>
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={clsx(styles.input, 'input')}
          type="text"
          name="youtube_channel"
          placeholder={t('Enter your channel name')}
          value={inputValue}
          onChange={handleChange}
          autoComplete="off"
        />
        <UBButton
          size="xsmall"
          variant="secondary"
          className={styles.searchButton}
          type="button"
          aria-label="Search Button"
          icon={
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src="https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Search.svg"
              alt="Search icon"
            />
          }
        />
      </div>

      <motion.div
        className={clsx(
          styles.results,
          'flex flex-col gap-2 overflow-hidden',
          compact && '!border-0',
        )}
        initial={{ height: 0, padding: '0' }}
        animate={{
          height: resultsHeight,
          paddingTop: displayedResults.length ? 12 : 0,
        }}
        exit={{ height: 0, padding: '0 0' }}
        transition={{
          type: 'spring',
          duration: 0.6,
          bounce: 0,
        }}
      >
        {displayedResults.map((value) => (
          <button
            className={styles.channelDetails}
            key={value.snippet?.channelId}
            onClick={() => {
              handleSelectChannel(value.snippet);
            }}
          >
            <Image
              width="40"
              height="40"
              alt={`Channel thumbnail for ${value.snippet?.title}`}
              src={value.snippet?.thumbnails?.default?.url}
              className="bg-gray-light-400 dark:bg-gray-dark-100"
            />
            <p>{value.snippet?.title}</p>
          </button>
        ))}
      </motion.div>
      {!compact && Array.isArray(searchResults) && searchResults.length > 0 && (
        <ChannelIDInput handleSubmitChannelID={handleSubmitChannelID} />
      )}
    </div>
  );
};
