import React from "react";
import InlineSVG from 'svg-inline-react';

class Facebook extends React.Component {
    render() {
        const assetFacebook = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124 254"><style>.st33{display:none}.st32{fill:#a6a6a6}</style><path class="st33" d="M-87 182.4h658.4V609H-87z"/><path class="st32" d="M118.5 48.3H90.3c-5.3 0-9.5 4.2-9.5 9.5v25.7h37.6v44H80.8v121.3h-44V127.5H4.4v-44H37V42.4C37 21.3 54 4.3 75.1 4.3H118.8l-.3 44z"/></svg>
`;
        return (
            <InlineSVG src={assetFacebook} />
        );
    }
}

export default Facebook;
