import React from "react";
import InlineSVG from 'svg-inline-react';

class Instagram extends React.Component {
    render() {
        const assetInstagram = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><style>.st98{fill:none;stroke:#a6a6a6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round}</style><path class="st98" d="M7 2h10c2.8 0 5 2.2 5 5v10c0 2.8-2.2 5-5 5H7c-2.8 0-5-2.2-5-5V7c0-2.8 2.2-5 5-5z"/><path class="st98" d="M16 11.4c.3 2.2-1.2 4.2-3.4 4.5-2.2.3-4.2-1.2-4.5-3.4-.3-2.1 1.2-4.2 3.4-4.5.4-.1.8-.1 1.2 0 1.7.3 3 1.6 3.3 3.4zM17.5 6.5"/></svg>
`;
        return (
            <InlineSVG src={assetInstagram} />
        );
    }
}

export default Instagram;
