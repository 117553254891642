import React from 'react';
import { T } from '../T';

class RecaptchaBranding extends React.Component {
    render() {
        const { className } = this.props

        return (
          <span className={`recaptcha-branding ${className}`}>
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href="https://policies.google.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            apply.
          </span>
        );
    }
}

RecaptchaBranding.defaultProps = {
    className: ""
}

export default RecaptchaBranding;
