import clsx from 'clsx';

import { useAppState } from '@/hooks/useAppState';

import type { ReactNode } from 'react';

interface MainLayoutProps {
  children: ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const footerPlayer = useAppState('footerPlayer');

  return (
    <div
      className={clsx(
        'main-layout relative',
        footerPlayer?.playerActive && 'footer-player-active',
      )}
    >
      {children}
    </div>
  );
};
