import * as React from 'react';

const _Twitch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M41.144 22.286l-6.857 6.857H27.43l-6 6v-6h-7.714V3.429h27.428v18.857z"
        fill="#fff"
      />
      <path
        d="M12.002 0L3.43 8.571V39.43h10.286V48l8.571-8.571h6.857L44.573 24V0H12zm29.142 22.286l-6.857 6.857H27.43l-6 6v-6h-7.714V3.429h27.428v18.857z"
        fill="#9146FF"
      />
      <path
        d="M36.001 9.429h-3.428v10.285H36V9.43zM26.573 9.429h-3.429v10.285h3.429V9.43z"
        fill="#9146FF"
      />
    </svg>
  );
};

const Twitch = React.memo(_Twitch);

export default Twitch;
