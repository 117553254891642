const trackIds = (state = [], action) => {
  let newState = [];
  switch (action.type) {
    case 'RECEIVE_TRACK_IDS':
      // return action.trackIds;
      return [1];
    case 'RECEIVE_SIMILAR_TRACK_IDS':
      newState = state.concat(action.trackIds);
      return [1];
      // return newState;
    case 'ADD_PAGE_TRACK_IDS':
      newState.push(action.trackId);
      return [1];
      // return newState;
    default:
      return state;
  }
};

export default trackIds;
