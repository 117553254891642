const INITIAL_STATE = {
  hasValidVatNumber: false,
  discount: false,
  plan: {},
  deal: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_SUBSCRIPTION_TO_CART':
      return Object.assign({}, state, {plan: action.plan});
    case 'EMPTY_SUBSCRIPTION_CART':
      return Object.assign({}, state, {plan: false});
    case 'SET_CART_HAS_VALID_VAT_NUMBER':
      return Object.assign({}, state, {hasValidVatNumber: action.hasValidVatNumber});
    case 'SET_SUBSCRIPTION_DISCOUNT':
      return Object.assign({}, state, {discount: action.discount});
    case 'SET_CHOSEN_DEAL':
      let deal = action.deal;
      return Object.assign({}, state, {deal});
    default:
      return state;
  }
};
