import Modal from '@/components/Modals/Core';
import { T } from '@/components/T';

const StackedTitles = ({
  title,
  subtitle,
}: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
}) => {
  return (
    <>
      <Modal.Title className="text-gray-medium-100 dark:text-gray-medium-400 mb-6 text-xl">
        {typeof title === 'string' ? <T>{title}</T> : title}
      </Modal.Title>
      <hr className="border-0 border-b border-solid border-gray-medium-100 dark:border-gray-medium-400 mb-6" />
      <h3 className="text-black dark:text-white mb-6 text-xl">
        {typeof subtitle === 'string' ? <T>{subtitle}</T> : subtitle}
      </h3>
    </>
  );
};

export default StackedTitles;
