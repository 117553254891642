import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface ScreenContextType {
  currentScreen: string;
  previousScreen: string;
  direction: 'forward' | 'backward';
  setScreen: (screenName: string) => void;
  registerScreen: (screenName: string) => void;
}

export interface ScreenProviderProps {
  children: React.ReactNode;
  initial?: string;
}

const ScreenContext = createContext<ScreenContextType | undefined>(undefined);

export const useScreenContext = () => {
  const context = useContext(ScreenContext);

  if (!context) {
    throw new Error('useScreenContext must be used within a ScreenProvider');
  }

  return context;
};

export const ScreenProvider = ({
  children,
  initial = '',
}: ScreenProviderProps) => {
  const [currentScreen, setCurrentScreen] = useState<string>(initial);
  const [previousScreen, setPreviousScreen] = useState<string>('');
  const [direction, setDirection] = useState<'forward' | 'backward'>('forward');
  const [registeredScreens, setRegisteredScreens] = useState<string[]>([]);

  const registerScreen = useCallback((screenName: string) => {
    setRegisteredScreens((prev) => {
      if (!prev.includes(screenName)) {
        return [...prev, screenName];
      }

      return prev;
    });
  }, []);

  const resetParentScroll = (screenName: string) => {
    const screen = document.querySelector(
      `[data-dialog-screen="${screenName}"]`,
    );

    const screenParent = screen?.parentElement;

    if (screenParent && screenParent.scrollTop > 0) {
      screenParent.scrollTo(0, 0);
    }
  };

  const determineDirection = useCallback(
    (from: string, to: string) => {
      const fromIndex = registeredScreens.indexOf(from);
      const toIndex = registeredScreens.indexOf(to);

      if (fromIndex === toIndex) {
        return;
      }

      if (fromIndex < toIndex) {
        setDirection('forward');
      } else {
        setDirection('backward');
      }
    },
    [registeredScreens],
  );

  const setScreen = useCallback(
    (screenName: string) => {
      // Check screen is registered
      if (!registeredScreens.includes(screenName)) {
        throw new Error(`Screen "${screenName}" is not registered`);
      }

      const from = currentScreen;
      const to = screenName;

      if (from === to) {
        return;
      }

      resetParentScroll(from);
      determineDirection(from, to);
      // This is a hack to ensure the direction is set before the screen changes
      setTimeout(() => {
        setPreviousScreen(from);
        setCurrentScreen(to);
      }, 0);
    },
    [currentScreen, determineDirection, registeredScreens],
  );

  useEffect(() => {
    if (registeredScreens.length > 0 && !currentScreen) {
      // Set the first registered screen as the default
      setCurrentScreen(registeredScreens[0]);
    }
  }, [registeredScreens, currentScreen]);

  const value = useMemo(
    () => ({
      currentScreen,
      previousScreen,
      setScreen,
      registerScreen,
      direction,
    }),
    [currentScreen, previousScreen, setScreen, registerScreen, direction],
  );

  return (
    <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  );
};
