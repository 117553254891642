import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;

export const FacebookPixel = () => {
  const [loaded, setLoaded] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!loaded || window.fbq === undefined) {
      return;
    }

    fbq('track', 'PageView');
  }, [router.asPath, loaded]);

  return (
    <Script
      id="fb-pixel"
      src="/scripts/pixel.js"
      strategy="afterInteractive"
      onReady={() => setLoaded(true)}
      data-pixel-id={FB_PIXEL_ID}
    />
  );
};
