import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
const ADS_TRACKING_ID = process.env.NEXT_PUBLIC_ADS_TRACKING_ID;

// Contains the tracking ID for Google Analytics and Google Ads.
export const GTag = () => {
  const [loaded, setLoaded] = useState(false);

  const router = useRouter();

  useEffect(() => {
    if (!loaded || typeof gtag === 'undefined') {
      return;
    }

    if (!GA_TRACKING_ID) {
      throw new Error('Missing GA_TRACKING_ID');
    }

    // Disabled as Gtag as this is automatically handled by Gtag.

    // gtag('config', GA_TRACKING_ID, {
    //   page_path: window.location.href,
    // });
  }, [router.asPath, loaded]);

  const debugOptions: Record<string, boolean> = {
    allow_enhanced_conversions: true,
  };

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    debugOptions.debug_mode = true;
  }

  const stringifiedOptions = JSON.stringify(debugOptions);

  return (
    <>
      <script
        data-cookieconsent="ignore"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("consent", "default", {
            ad_personalization: "denied",
            ad_storage: "denied",
            ad_user_data: "denied",
            analytics_storage: "denied",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
            wait_for_update: 500,
          });
          gtag("set", "ads_data_redaction", true);
          gtag("set", "url_passthrough", true);`,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        id="gtag"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
        onReady={() => setLoaded(true)}
      />

      <Script id="google-analytics" strategy="afterInteractive">
        {`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${GA_TRACKING_ID}', ${stringifiedOptions});
					gtag('config', '${ADS_TRACKING_ID}', ${stringifiedOptions});
          gtag('set', 'linker', {
            'accept_incoming': true
          });
				`}
      </Script>
    </>
  );
};
