export default (state = [], action) => {
  switch (action.type) {
    case 'LOAD_FOLLOWING_DATA':
      return [...action.following];
    case 'FOLLOW_ARTIST':
      return [...state, action.ID];
    case 'UNFOLLOW_ARTIST':
      return state.filter((follow) => follow !== action.ID);
    default:
      return state;
  }
};
