import { GoogleLogin } from '@react-oauth/google';
import { useContext, useState } from 'react';

import {
  googleSignIn,
  googleSignInActions,
} from '@/actions/authenticationAction';
import { getContentTypes, getVibeTypes } from '@/actions/dataCaptureActions';
import { signUpTypeEmail } from '@/actions/ubModalAction';
import Tick from '@/components/Icons/Tick';
import Modal from '@/components/Modals/Core';
import { AgreeCheckbox } from '@/components/Modals/CreateAccount/form';
import { TickList } from '@/components/Modals/CreateAccount/screens';
import { CreateAccountModalScreenName } from '@/components/Modals/CreateAccount/types';
import { LoginModalScreenName } from '@/components/Modals/Login/types';
import { useSidebarModalContext } from '@/components/Modals/Sidebar/context/SidebarModalContext';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { T } from '@/components/T';
import { Button } from '@/components/ui/button';
import { Video } from '@/components/Video';
import { getUniversalCookie } from '@/cookies';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';
import { useTranslate } from '@/hooks/useTranslate';
import { planData } from '@/plan-data';
import { UserStateContext } from '@/providers/UserStateProvider';
import Sidebar7Pricing from '@/styles/svg-components/Sidebar7Pricing';
import { getReferralCode, validateReferralCode } from '@/utils/helpers';

import { CreateAccountType, SignUpModalScreenName } from './types';

import type { CredentialResponse } from '@react-oauth/google';

const startedCheckout = () => {
  return (
    fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        requesttype: 'checkoutprocessstarted',
        eventinfo: 'Business Checkout Started',
      }),
    })
      .then((res) => res.json())
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .catch((err) => err)
  );
};

const SignupModalScreens = () => {
  const { setScreen } = useScreenContext();
  const { onOpenChange } = useSidebarModalContext();
  const animationProps = useDirectionalAnimation();
  const { settings, invalidateSettings } = useSettings();
  const subscriptionData = settings?.subscriptionData;
  const userAuth = useAppState('userAuth');
  const currency = settings?.currency;
  const { t } = useTranslate();
  const [accountType, setAccountType] = useState<CreateAccountType>(
    CreateAccountType.Premium,
  );

  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const { premium: premiumPlanData } = planData;
  const { setUserState, userState } = useContext(UserStateContext);

  const price = String(planData.business.monthly[currency?.code ?? 'GBP']);
  const pointIndex = price?.indexOf('.');
  const integer = price?.slice(0, pointIndex);
  const decimals = price?.slice(pointIndex);

  const [googleLoading, setGoogleLoading] = useState(false);
  const [googleLoginFailure, setGoogleLoginFailure] = useState('');

  const googleLoginErrorMessage =
    googleLoginFailure || userAuth?.googleLoginFailure;

  const handleGoogleSignInSuccess = async (
    response: CredentialResponse,
    nextScreen: CreateAccountModalScreenName,
  ) => {
    const { clientId, credential, select_by } = response;

    setGoogleLoading(true);

    const payload = {
      client_id: clientId,
      credential,
      select_by,
      access_token: 'accessToken',
      affiliate_code: localStorage.getItem('as_code'),
      partner_code: localStorage.getItem('partner_code'),
      user_referral_code: getReferralCode(),
      rcm: validateReferralCode(),
      share_name_referrer: sessionStorage.getItem('name_consent'),
      ab_seed: userState.abSeed,
      ab_timestamp: userState.abTimestamp,
      rt_code: localStorage.getItem('rt_code'),
      email: 'email',
      impact_clickid: localStorage.getItem('irclickid'),
      partnerstack_key: getUniversalCookie('ps_partner_key'),
      partner_stack_referrer_id: getUniversalCookie('ps_xid'),
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    await googleSignIn(payload).then((response) => {
      if (response.status === 'success') {
        const { contents, vibes, email } = response;

        const loginPayload = {
          credential,
          email,
        };

        fetch(
          `${process.env.NEXT_PUBLIC_API}authentication/googleloginsimple`,
          {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(loginPayload),
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
          .then((res) => {
            if (res.ok) {
              getContentTypes(contents);
              getVibeTypes(vibes);

              googleSignInActions(response, setUserState);

              invalidateSettings();

              setGoogleLoading(false);

              if (response.isNewUser) {
                // If the user is new, we want to show the next screen
                setScreen(nextScreen);
              } else {
                // If the user is not new, we want to close the modal and log them in
                onOpenChange(false);
              }
            } else {
              setGoogleLoading(false);
              setGoogleLoginFailure('Something went wrong, please try again.');
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .catch((err) => err);
      } else {
        setGoogleLoading(false);
        setGoogleLoginFailure(
          (response.message ?? 'Something went wrong') as string,
        );
      }
    });
  };

  return (
    <>
      <Modal.Screen
        screenName={SignUpModalScreenName.SignUpCreator}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccount.createAccount">Create account</T>
        </Modal.Title>
        <Modal.Description className="sr-only">
          Sign up as a creator
        </Modal.Description>
        <div className="ub-modal-toggle ub-modal-account-toggle">
          <label
            htmlFor="checkout-free"
            className={`ub-modal-toggle-option ${
              accountType === CreateAccountType.Free ? 'active' : ''
            }`}
          >
            <div>
              <input
                id="checkout-free"
                className="checkbox-round"
                type="checkbox"
                checked={accountType === CreateAccountType.Free}
                onChange={(e) => {
                  setAccountType(
                    e.target.checked
                      ? CreateAccountType.Free
                      : CreateAccountType.Premium,
                  );
                }}
              />
              <div className="ub-modal-toggle-option-text">
                <h3>{t('Free')}</h3>
                <ul>
                  <li>
                    <Tick className="w-3.5 text-green" /> {userAuth?.creditsMax}{' '}
                    {t('downloads (renewed monthly)')}
                  </li>
                  <li>
                    <Tick className="w-3.5 text-green" />{' '}
                    {t('No YouTube copyright claims')}
                  </li>
                  <li>
                    <Tick className="w-3.5 text-green" />{' '}
                    {t('30% catalog access')}
                  </li>
                </ul>
              </div>
            </div>
          </label>
          <label
            htmlFor="checkout-premium"
            className={`ub-modal-toggle-option ${
              accountType === CreateAccountType.Premium ? 'active' : ''
            }`}
          >
            <div>
              <input
                id="checkout-premium"
                className="checkbox-round"
                type="checkbox"
                checked={accountType === CreateAccountType.Premium}
                onChange={(e) => {
                  setAccountType(
                    e.target.checked
                      ? CreateAccountType.Premium
                      : CreateAccountType.Free,
                  );
                }}
              />
              <div className="ub-modal-toggle-option-text">
                <h3>
                  <Sidebar7Pricing />
                  Premium
                </h3>
                <ul>
                  <li>
                    <Tick className="w-3.5 text-green" />{' '}
                    {t('Unlimited music & SFX downloads')}
                  </li>
                  <li>
                    <Tick className="w-3.5 text-green" />{' '}
                    {t('Safelist your YouTube channel')}
                  </li>
                  <li>
                    <Tick className="w-3.5 text-green" />{' '}
                    {t('100% catalog access')}
                  </li>
                </ul>
              </div>
              <div className="signup-option-price">
                <span className="currency">{currency?.symbol_left}</span>
                <span className="price">
                  {premiumPlanData.monthly[currency?.code ?? 'GBP']}
                </span>
                <span className="term"> /{t('month')}</span>
              </div>
            </div>
          </label>
        </div>
        <AgreeCheckbox
          agree={agree}
          setAgree={setAgree}
          agreeError={agreeError}
          setAgreeError={setAgreeError}
        />
        <div className="ub-modal-google-wrapper">
          <GoogleLogin
            onSuccess={(data) => {
              if (agree) {
                void handleGoogleSignInSuccess(
                  data,
                  accountType === CreateAccountType.Free
                    ? CreateAccountModalScreenName.WhatsYourThemeFree
                    : CreateAccountModalScreenName.CheckoutPremium,
                );
              } else {
                setAgreeError(true);
              }
            }}
            theme="filled_blue"
            shape="pill"
            text="signup_with"
            size="large"
            type="standard"
            width="292px"
            itp_support={false}
          />
        </div>
        {!!googleLoginErrorMessage && (
          <div className="alert-general-error login-error mb-4">
            <span>{googleLoginErrorMessage}</span>
          </div>
        )}
        <div className="flex flex-nowrap my-4 justify-center">
          <Button
            className="w-full border"
            variant="secondary"
            id="signup-es1"
            disabled={googleLoading}
            onClick={() => {
              if (agree) {
                signUpTypeEmail();
                setScreen(
                  accountType === CreateAccountType.Free
                    ? CreateAccountModalScreenName.Free
                    : CreateAccountModalScreenName.Premium,
                );
              } else {
                setAgreeError(true);
              }
            }}
          >
            Sign up with email
          </Button>
        </div>
        <p className="text-center text-sm mt-4">
          <T keyName="modal.upsellBusiness.alreadyHaveAccountText">
            Already have an Uppbeat account?
          </T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="modal.upsellBusiness.logInText">Log in</T>
          </button>
        </p>
      </Modal.Screen>
      <Modal.Screen
        screenName={SignUpModalScreenName.SignUpFree}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountFree.title">Create Free Account</T>
        </Modal.Title>
        <TickList
          className="mt-4"
          items={[
            t('3 downloads (renewed monthly)'),
            t('No YouTube copyright claims'),
            t('30% catalog access'),
          ]}
        />
        <hr className="border-0 border-b border-solid border-gray-medium-100 dark:border-gray-medium-400 my-6" />
        <AgreeCheckbox
          agree={agree}
          setAgree={setAgree}
          agreeError={agreeError}
          setAgreeError={setAgreeError}
        />

        <div className="ub-modal-google-wrapper">
          <GoogleLogin
            onSuccess={(data) => {
              if (agree) {
                void handleGoogleSignInSuccess(
                  data,
                  CreateAccountModalScreenName.WhatsYourThemeFree,
                );
              } else {
                setAgreeError(true);
              }
            }}
            theme="filled_blue"
            shape="pill"
            text="signup_with"
            size="large"
            type="standard"
            width="292px"
            itp_support={false}
          />
        </div>
        {!!googleLoginErrorMessage && (
          <div className="alert-general-error login-error mb-4">
            <span>{googleLoginErrorMessage}</span>
          </div>
        )}
        <div className="flex flex-nowrap my-4 justify-center">
          <Button
            className="w-full border-gray-light-200 text-uppbeat"
            variant="transparent"
            id="signup-es1"
            disabled={googleLoading}
            onClick={() => {
              if (agree) {
                signUpTypeEmail();
                setScreen(CreateAccountModalScreenName.Free);
              } else {
                setAgreeError(true);
              }
            }}
          >
            Sign up with email
          </Button>
        </div>
        <p className="text-center text-sm mt-4">
          <T keyName="modal.upsellBusiness.alreadyHaveAccountText">
            Already have an Uppbeat account?
          </T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="modal.upsellBusiness.logInText">Log in</T>
          </button>
        </p>
      </Modal.Screen>
      <Modal.Screen
        screenName={SignUpModalScreenName.SignUpPremium}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountPremium.title">
            Create Premium Account
          </T>
        </Modal.Title>
        <TickList
          className="mt-4"
          items={[
            t('Unlimited music & SFX downloads'),
            t('Safelist your YouTube channel'),
            t('100% catalog access'),
          ]}
        />
        <hr className="border-0 border-b border-solid border-gray-medium-100 dark:border-gray-medium-400 my-6" />
        <AgreeCheckbox
          agree={agree}
          setAgree={setAgree}
          agreeError={agreeError}
          setAgreeError={setAgreeError}
        />
        <div className="ub-modal-google-wrapper">
          <GoogleLogin
            onSuccess={(data) => {
              if (agree) {
                void handleGoogleSignInSuccess(
                  data,
                  CreateAccountModalScreenName.CheckoutPremium,
                );
              } else {
                setAgreeError(true);
              }
            }}
            theme="filled_blue"
            shape="pill"
            text="signup_with"
            size="large"
            type="standard"
            width="292px"
            itp_support={false}
          />
        </div>
        {!!googleLoginErrorMessage && (
          <div className="alert-general-error login-error mb-4">
            <span>{googleLoginErrorMessage}</span>
          </div>
        )}
        <div className="flex flex-nowrap my-4 justify-center">
          <Button
            className="w-full border-gray-light-200 text-uppbeat"
            variant="transparent"
            id="signup-es1"
            disabled={googleLoading}
            onClick={() => {
              if (agree) {
                signUpTypeEmail();
                setScreen(CreateAccountModalScreenName.Premium);
              } else {
                setAgreeError(true);
              }
            }}
          >
            Sign up with email
          </Button>
        </div>
        <p className="text-center text-sm mt-4">
          <T keyName="modal.upsellBusiness.alreadyHaveAccountText">
            Already have an Uppbeat account?
          </T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="modal.upsellBusiness.logInText">Log in</T>
          </button>
        </p>
      </Modal.Screen>
      <Modal.Screen
        screenName={SignUpModalScreenName.SignUpBusinessAlt}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-xl">
          <T keyName="modal.createAccountBusiness.title">
            Create Business Account
          </T>
        </Modal.Title>
        <p className="font-semibold text-black dark:text-white">
          <T keyName="modal.upsellBusiness.allBenefitsOfPremium">
            All the benefits of Premium, plus
          </T>
        </p>
        <TickList
          className="mt-4"
          items={[
            t('License for organizations of any scale'),
            t('Covers digital ads & client content'),
            t('Safelist up to 10 YouTube channels'),
          ]}
        />
        <hr className="border-0 border-b border-solid border-gray-medium-100 dark:border-gray-medium-400 my-6" />
        <AgreeCheckbox
          agree={agree}
          setAgree={setAgree}
          agreeError={agreeError}
          setAgreeError={setAgreeError}
        />
        <div className="ub-modal-google-wrapper">
          <GoogleLogin
            onSuccess={(data) => {
              if (agree) {
                void handleGoogleSignInSuccess(
                  data,
                  CreateAccountModalScreenName.CheckoutBusiness,
                );
              } else {
                setAgreeError(true);
              }
            }}
            theme="filled_blue"
            shape="pill"
            text="signup_with"
            size="large"
            type="standard"
            width="292px"
            itp_support={false}
          />
        </div>
        {!!googleLoginErrorMessage && (
          <div className="alert-general-error login-error mb-4">
            <span>{googleLoginErrorMessage}</span>
          </div>
        )}
        <div className="flex flex-nowrap my-4 justify-center">
          <Button
            className="w-full border-gray-light-200 text-uppbeat"
            variant="transparent"
            id="signup-es1"
            disabled={googleLoading}
            onClick={() => {
              if (agree) {
                signUpTypeEmail();
                setScreen(CreateAccountModalScreenName.Business);
              } else {
                setAgreeError(true);
              }
            }}
          >
            Sign up with email
          </Button>
        </div>
        <p className="text-center text-sm mt-4">
          <T keyName="modal.upsellBusiness.alreadyHaveAccountText">
            Already have an Uppbeat account?
          </T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="modal.upsellBusiness.logInText">Log in</T>
          </button>
        </p>
      </Modal.Screen>
      <Modal.Screen
        screenName={SignUpModalScreenName.SignUpBusiness}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          The ultimate
          <br />
          creative subscription <br />
          for your{' '}
          <span className="text-business hover:text-business">Business</span>
        </Modal.Title>
        <Modal.Description className="sr-only">
          <T keyName="modal.upsellBusiness.description">
            Sign up for a bussiness account
          </T>
        </Modal.Description>
        <p className="font-semibold text-black dark:text-white">
          <T keyName="modal.upsellBusiness.allBenefitsOfPremium">
            All the benefits of Premium, plus
          </T>
        </p>
        <TickList
          className="mt-4"
          items={[
            t('License for organizations of any scale'),
            t('Covers digital ads & client content'),
            t('Safelist up to 10 YouTube channels'),
          ]}
        />
        <Video type="premium-vs-business" className="mt-6" />
        <div className="font-medium text-sm mt-5">
          {subscriptionData?.overduePayment && (
            <>
              <p>
                <T keyName="modal.upsellBusiness.activeSubscriptionIssue">
                  You currently have an active Uppbeat Premium subscription, but
                  there was a problem with payment.
                </T>
              </p>
              <Button variant="uppbeat" className="w-full" asChild>
                <a href={`/payments/${subscriptionData?.overduePayment.id}`}>
                  Resolve Payment
                </a>
              </Button>
            </>
          )}
          {userAuth?.isArtist && (
            <div className="alert-general-error">
              <T keyName="modal.upsellBusiness.artistAccountRestriction">
                Sorry, you can't subscribe to Uppbeat Premium with an artist
                account.
              </T>
            </div>
          )}
          {!userAuth?.isArtist && !subscriptionData?.overduePayment && (
            <p className="mb-6 text-black dark:text-white font-heading">
              <sup className="text-lg inline-block !text-inherit">
                {currency?.symbol_left}
              </sup>
              <span className="text-4xl leading-none mr-1 !text-inherit">
                {integer}
                <sup className="text-lg !text-inherit">{decimals}</sup>
              </span>
              <span className="text-sm font-sans -ml-8 mr-1 !text-inherit">
                <T keyName="modal.upsellBusiness.perMonth">/month</T>
              </span>
              <span className="!text-gray-medium-200 font-normal !dark:text-gray-medium-400 font-sans">
                <T keyName="modal.upsellBusiness.cancelAnytime">
                  (cancel anytime)
                </T>
              </span>
            </p>
          )}
        </div>
        <Button
          variant="uppbeat"
          className="w-full"
          onClick={() => {
            void startedCheckout();
            setScreen(CreateAccountModalScreenName.Business);
          }}
        >
          Start now
        </Button>
        <p className="text-center text-sm mt-4">
          <T keyName="modal.upsellBusiness.alreadyHaveAccountText">
            Already have an Uppbeat account?
          </T>{' '}
          <button
            className="text-sm text-uppbeat focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50 rounded-full px-2 -mx-2"
            onClick={() => {
              setScreen(LoginModalScreenName.LogIn);
            }}
          >
            <T keyName="modal.upsellBusiness.logInText">Log in</T>
          </button>
        </p>
      </Modal.Screen>
    </>
  );
};

export default SignupModalScreens;
