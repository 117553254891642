declare global {
  interface Window {
    uetq?: unknown[];
  }
}

type Options =
  | {
      event_category: string;
      event_label: string;
      event_value: string;
    }
  | object;

export const addUETEvent = (
  type: string,
  name: string,
  options: Options = {},
) => {
  window.uetq = window.uetq ?? [];

  window.uetq.push(type, name, options);
};
