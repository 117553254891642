import { useContext, useMemo } from 'react';

import Price from '@/components/Modals/CreateAccount/checkout/price';
import { AccountType } from '@/components/Modals/CreateAccount/types';
import { useSettings } from '@/hooks/useSettings';
import { planData } from '@/plan-data';
import { UserStateContext } from '@/providers/UserStateProvider';

interface usePricingProps {
  accountType: AccountType;
}

const usePricing = ({ accountType }: usePricingProps) => {
  const { settings } = useSettings();
  const { userState } = useContext(UserStateContext);
  const { business: businessPlanData, premium: premiumPlanData } = planData;
  const currencyCode = settings?.currency.code ?? 'GBP';

  const pricing = useMemo(() => {
    const premiumMonthlyPrice = String(premiumPlanData?.monthly[currencyCode]);
    const premiumMonthlyPointIndex = premiumMonthlyPrice?.indexOf('.');
    const premiumMonthlyInteger = premiumMonthlyPrice?.slice(
      0,
      premiumMonthlyPointIndex,
    );

    const premiumMonthlyDecimals = premiumMonthlyPrice?.slice(
      premiumMonthlyPointIndex,
    );

    const premiumYearlyMonthlyPrice = String(
      premiumPlanData?.monthly_equivalent[currencyCode],
    );

    const premiumYearlyMonthlyPointIndex =
      premiumYearlyMonthlyPrice?.indexOf('.');

    const premiumYearlyMonthlyInteger = premiumYearlyMonthlyPrice?.slice(
      0,
      premiumYearlyMonthlyPointIndex,
    );

    const premiumYearlyMonthlyDecimals = premiumYearlyMonthlyPrice?.slice(
      premiumYearlyMonthlyPointIndex,
    );

    const premiumYearlyPrice = String(premiumPlanData?.yearly[currencyCode]);
    const premiumYearlyPointIndex = premiumYearlyPrice?.indexOf('.');
    const premiumYearlyInteger = premiumYearlyPrice?.slice(
      0,
      premiumYearlyPointIndex,
    );

    const premiumYearlyDecimals = premiumYearlyPrice?.slice(
      premiumYearlyPointIndex,
    );

    const businessMonthlyPrice = String(
      businessPlanData?.monthly[currencyCode],
    );

    const businessMonthlyPointIndex = businessMonthlyPrice?.indexOf('.');
    const businessMonthlyInteger = businessMonthlyPrice?.slice(
      0,
      businessMonthlyPointIndex,
    );

    const businessMonthlyDecimals = businessMonthlyPrice?.slice(
      businessMonthlyPointIndex,
    );

    const businessYearlyPrice = String(businessPlanData?.yearly[currencyCode]);
    const businessYearlyPointIndex = businessYearlyPrice?.indexOf('.');
    const businessYearlyInteger = businessYearlyPrice?.slice(
      0,
      businessYearlyPointIndex,
    );

    const businessYearlyDecimals = businessYearlyPrice?.slice(
      businessYearlyPointIndex,
    );

    const businessYearlyMonthlyPrice = String(
      businessPlanData?.monthly_equivalent[currencyCode],
    );

    const businessYearlyMonthlyPointIndex =
      businessYearlyMonthlyPrice?.indexOf('.');

    const businessYearlyMonthlyInteger = businessYearlyMonthlyPrice?.slice(
      0,
      businessYearlyMonthlyPointIndex,
    );

    const businessYearlyMonthlyDecimals = businessYearlyMonthlyPrice?.slice(
      businessYearlyMonthlyPointIndex,
    );

    return {
      premium: {
        monthly: {
          monthlyPrice: premiumMonthlyPrice,
          monthlyPointIndex: premiumMonthlyPointIndex,
          monthlyInteger: premiumMonthlyInteger,
          monthlyDecimals: premiumMonthlyDecimals,
        },
        yearly: {
          yearlyPrice: premiumYearlyPrice,
          yearlyPointIndex: premiumYearlyPointIndex,
          yearlyInteger: premiumYearlyInteger,
          yearlyDecimals: premiumYearlyDecimals,
          monthlyEquivalent: {
            monthlyEquivalentPrice: premiumYearlyMonthlyPrice,
            monthlyEquivalentPointIndex: premiumYearlyMonthlyPointIndex,
            monthlyEquivalentInteger: premiumYearlyMonthlyInteger,
            monthlyEquivalentDecimals: premiumYearlyMonthlyDecimals,
          },
        },
      },
      business: {
        monthly: {
          monthlyPrice: businessMonthlyPrice,
          monthlyPointIndex: businessMonthlyPointIndex,
          monthlyInteger: businessMonthlyInteger,
          monthlyDecimals: businessMonthlyDecimals,
        },
        yearly: {
          yearlyPrice: businessYearlyPrice,
          yearlyPointIndex: businessYearlyPointIndex,
          yearlyInteger: businessYearlyInteger,
          yearlyDecimals: businessYearlyDecimals,
          monthlyEquivalent: {
            monthlyEquivalentPrice: businessYearlyMonthlyPrice,
            monthlyEquivalentPointIndex: businessYearlyMonthlyPointIndex,
            monthlyEquivalentInteger: businessYearlyMonthlyInteger,
            monthlyEquivalentDecimals: businessYearlyMonthlyDecimals,
          },
        },
      },
    };
  }, [
    businessPlanData?.monthly,
    businessPlanData?.monthly_equivalent,
    businessPlanData?.yearly,
    currencyCode,
    premiumPlanData?.monthly,
    premiumPlanData?.monthly_equivalent,
    premiumPlanData?.yearly,
  ]);

  const pricingTag = useMemo(() => {
    if (accountType === AccountType.Premium) {
      if (userState.selectedBillingMode === 'monthly') {
        return (
          // Premium monthly
          <Price
            symbolLeft={settings?.currency.symbol_left ?? ''}
            integer={pricing.premium.monthly.monthlyInteger}
            decimals={pricing.premium.monthly.monthlyDecimals}
          />
        );
      }

      return (
        // Premium yearly
        <Price
          symbolLeft={settings?.currency.symbol_left ?? ''}
          integer={pricing.premium.yearly.yearlyInteger}
          decimals={pricing.premium.yearly.yearlyDecimals}
        />
      );
    }

    if (userState.selectedBillingMode === 'monthly') {
      return (
        // Business monthly
        <Price
          symbolLeft={settings?.currency.symbol_left ?? ''}
          integer={pricing.business.monthly.monthlyInteger}
          decimals={pricing.business.monthly.monthlyDecimals}
        />
      );
    }

    return (
      // Business yearly
      <Price
        symbolLeft={settings?.currency.symbol_left ?? ''}
        integer={pricing.business.yearly.yearlyInteger}
        decimals={pricing.business.yearly.yearlyDecimals}
      />
    );
  }, [
    accountType,
    userState.selectedBillingMode,
    settings?.currency.symbol_left,
    pricing,
  ]);

  return { ...pricing, pricingTag };
};

export default usePricing;
