import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { emptySubscriptionCart } from '@/actions/cartAction';
import {
  flushSecondaryTrack,
  playerInActive,
} from '@/actions/footerPlayerAction';
import { loadSubscriptionData } from '@/actions/subscriptionAction';
import {
  trackEvent,
  updateActiveCampaignContact,
} from '@/api/activecampaign/update-contact';
import api from '@/api/legacyApi';
import { trackReferralRegistration } from '@/api/partnerstack/trackReferralRegistration';
import { getAuthToken } from '@/auth';
import Form from '@/components/Form/Form';
import useCreateSubscription from '@/components/Modals/CreateAccount/hooks/useCreateSubscription';
import usePricing from '@/components/Modals/CreateAccount/hooks/usePricing';
import { AccountType } from '@/components/Modals/CreateAccount/types';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { useAppState } from '@/hooks/useAppState';
import { useCountries } from '@/hooks/useCountries';
import { useCurrency } from '@/hooks/useCurrency';
import useRecaptcha, { RecaptchaVersion } from '@/hooks/useRecaptcha';
import { useSettings } from '@/hooks/useSettings';
import { planData } from '@/plan-data';
import { UserStateContext } from '@/providers/UserStateProvider';
import { stripe as stripePromise } from '@/stripe';
import { trackPurchase } from '@/utils/analytics';
import { addGAEvent } from '@/utils/analytics/google/addGAEvent';

import BillingFrequency from './billingFrequency';
import CardForm from './CardForm';
import ExpressCheckout from './ExpressCheckout';
import PendingPayment from './PendingPayment';

import type {
  Conversion,
  CreateSubscriptionRequest,
} from '@/components/Modals/CreateAccount/hooks/useCreateSubscription';
import type { CreateAccountModalScreenName } from '@/components/Modals/CreateAccount/types';
import type { GoPremiumModalScreenName } from '@/components/Modals/GoPremium/types';
import type { SubmitHandler } from 'react-hook-form';

enum SubscriptionStatusCode {
  ACTIVE = 1,
  DORMANT = 2,
  SUSPENDED = 3,
  TRIAL = 4,
  INITIATED = 5,
  FAILED_INITIATION = 6,
  PAUSED = 7,
  CHURNED_VOLUNTARY = 8,
  CHURNED_INVOLUNTARY = 9,
}

enum IntentStatusCodes {
  INITIALISED = 0,
  SUCCESSFUL = 1,
  FAILED = 2,
}

interface ErrorMessage {
  message: string;
  code: string;
}

const schema = z.object({
  nameOnCard: z.string().min(1, { message: 'Name on card is required' }),
  country: z.string().min(1, { message: 'Country is required' }),
});

type FormData = z.infer<typeof schema>;

interface CheckoutFormProps {
  accountType: AccountType;
  nextScreenName: CreateAccountModalScreenName | GoPremiumModalScreenName;
}

const getReadableError = (error: string) => {
  switch (error) {
    case 'Plan Not Found.':
      return 'Something went wrong. Please try again.';
    default:
      return error;
  }
};

const CheckoutForm = ({ accountType, nextScreenName }: CheckoutFormProps) => {
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const { handleSubmit } = methods;

  // Hooks
  const stripe = useStripe();
  const elements = useElements();
  const { setScreen } = useScreenContext();
  const { settings, invalidateSettings } = useSettings();
  const createSubscriptionMutation = useCreateSubscription();
  const { countries } = useCountries();
  const { pricingTag } = usePricing({ accountType });
  const { userState } = useContext(UserStateContext);
  const { recaptchaVersion, setRecaptchaVersion, recaptchaToken } =
    useRecaptcha('checkout');

  const queryClient = useQueryClient();

  const footerPlayer = useAppState('footerPlayer');
  const advertActive = !isEmpty(footerPlayer?.secondaryTrack);
  const subscriptionCart = useAppState('subscriptionCart');

  const router = useRouter();
  const isExpressCheckoutEnabled = useFeatureIsOn('express-checkout');

  const { paymentStatus, setupIntentId, type } = router.query;
  const paymentType = type ?? 'card';
  const { business: businessPlanData, premium: premiumPlanData } = planData;

  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const setError = (error: string) => {
    toast.error(getReadableError(error), {
      id: 'checkout-error',
    });
  };

  const periodId =
    userState.selectedBillingMode === 'monthly'
      ? Number(process.env.NEXT_PUBLIC_PERIOD_ID_MONTHLY)
      : Number(process.env.NEXT_PUBLIC_PERIOD_ID_YEARLY);

  const subscribedToPremium = (planName: string) => {
    return (
      fetch(`${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          requesttype: 'subscriptiontype',
          eventinfo: planName,
        }),
      })
        .then((res) => res.json())
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .catch((err) => err)
    );
  };

  const purchaseSuccessActions = (planType: AccountType) => {
    localStorage.removeItem('conversion_source');

    // Clear any currently playing adverts
    if (advertActive) {
      flushSecondaryTrack();
      playerInActive();
    }

    const subscriptionData = {
      isActive: true,
      isExpired: false,
      isFreeTrial: false,
      deal: false,
      plan: 1,
      period: subscriptionCart?.period,
    };

    // Set site subscriptionData
    loadSubscriptionData(subscriptionData);

    // Empty cart
    emptySubscriptionCart();

    invalidateSettings();
    void queryClient.invalidateQueries({ queryKey: ['customerPayments'] });

    // Track PartnerStack referral registration
    trackReferralRegistration();

    if (planType) {
      void subscribedToPremium(planType);
    }

    try {
      let subscriptionLength: 'Annual' | 'Monthly' = 'Annual';

      if (userState.selectedBillingMode === 'monthly') {
        subscriptionLength = 'Monthly';
      }

      let subscriptionType: 'Premium' | 'Business' = 'Premium';

      if (planType === 'premium') {
        subscriptionType = 'Premium';
      } else if (planType === 'business') {
        subscriptionType = 'Business';
      }

      trackPurchase(subscriptionType, subscriptionLength);

      addGAEvent(`purchase-${paymentType}`);
    } catch (e) {
      console.warn('Error tracking purchase', e);
    }

    setScreen(nextScreenName);
  };

  const resetQueryParams = () => {
    const cleanedPath = router.asPath.split('?')[0];

    // Replace the current URL with the cleaned path, to prevent users opening the modal again.
    void router.replace(cleanedPath, undefined, { shallow: true });
  };

  const checkSubscriptionStatus = async (count = 0) => {
    let status: {
      code?: SubscriptionStatusCode;
      intentStatus?: IntentStatusCodes;
      error?: ErrorMessage;
    } = {};

    if (paymentType === 'paypal') {
      // create slug with query params
      const slug = `v2/subscription/SetupIntent/getintent?setupIntentId=${setupIntentId}`;

      const res = await axios.get<{
        status: IntentStatusCodes;
        error?: ErrorMessage;
      }>(slug, {
        baseURL: process.env.NEXT_PUBLIC_API,
        withCredentials: true,
      });

      status = {
        intentStatus: res.data.status,
        error: res.data.error,
      };
    } else {
      const formData = new FormData();

      formData.append('token', getAuthToken() ?? '');

      // eslint-disable-next-line no-console
      console.log(`Checking subscription status count: ${count + 1}`);

      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API}subscription/checkStatus`,
        {
          method: 'POST',
          body: formData,
        },
      );

      status = (await res.json()) as {
        code: SubscriptionStatusCode;
        error?: {
          message: string;
          code: string;
        };
      };
    }

    if (status.error || status.intentStatus === IntentStatusCodes.FAILED) {
      setError(
        status.error?.message ?? 'An error occurred. Please try again later.',
      );
      resetQueryParams();

      return;
    }

    // If the subscription is active, we can send the user to the next screen
    if (
      status.code === SubscriptionStatusCode.ACTIVE ||
      // TODO: We should probably handle this case differently
      status.code === SubscriptionStatusCode.DORMANT ||
      status.intentStatus === IntentStatusCodes.SUCCESSFUL
    ) {
      resetQueryParams();
      purchaseSuccessActions(accountType);
    }

    // If we have reached the max count, assume failure
    if (count > 20) {
      setError(
        'We are having trouble processing your payment. Please try again later.',
      );

      return;
    }

    // If the subscription is initiated, we need to keep requesting the status until it is active
    if (
      status.code === SubscriptionStatusCode.INITIATED ||
      status.intentStatus === IntentStatusCodes.INITIALISED
    ) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        void checkSubscriptionStatus(count + 1);
      }, 1000);
    }
  };

  // Clean up the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const sendOrderFailedReason = async (reason: string) => {
    try {
      const authToken = getAuthToken();

      await api('subscription/updatePaymentIntentFailedStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: `reason=${reason}&token=${authToken}`,
      });
    } catch (err) {
      console.warn('Failed to send order failed reason', err);
    }
  };

  const purchaseSubscription = async (cartData: {
    currency: number;
    period: number;
    recaptchaVersion: RecaptchaVersion;
    recaptchaToken: string;
    stripeToken: string;
    paymentMethod: string;
    organisation: string;
    addressLine1: string;
    country: number;
    vatNumber: string;
    plan: number;
    planData: unknown;
  }) => {
    const parseConversions = (): Conversion[] | undefined => {
      const conversionSource = localStorage.getItem('conversion_source');

      if (!conversionSource) {
        return undefined;
      }

      const _conversions = JSON.parse(conversionSource) as Conversion[];

      // Get rid of conversions where tags are not a number or undefined
      const filtered_conversions = _conversions.filter(
        (conversion) =>
          typeof conversion.conversion_tag === 'number' ||
          typeof conversion.conversion_tag === 'undefined',
      );

      return filtered_conversions;
    };

    const conversions = parseConversions();
    const conversion_source = Array.isArray(conversions)
      ? conversions[0].conversion_event
      : typeof conversions === 'string'
      ? conversions
      : '';

    const shouldForceFail = (): string | undefined => {
      const forceFail = localStorage.getItem('force_fail_recaptcha');

      return forceFail ? '1' : undefined;
    };

    const force_fail_recaptcha = shouldForceFail();

    const body: CreateSubscriptionRequest = {
      currency: cartData.currency,
      paymentMethod: cartData.paymentMethod,
      stripe_token: cartData.stripeToken,
      force_fail_recaptcha,
      plan: cartData.plan,
      period: cartData.period,
      country: cartData.country,
      recaptcha_version: cartData.recaptchaVersion,
      recaptcha_token: cartData.recaptchaToken,
      conversions,
      conversion_source,
      deal: '',
      banner_id: '',
      channel: '',
      channelName: '',
      channelLocation: '',
      enteredComp: '',
      agreedToCommit: '',
      poNumber: '',
    };

    try {
      const res = await createSubscriptionMutation.mutateAsync(body);

      updateActiveCampaignContact();

      if (res.success) {
        const clientSecret = res.clientSecret ?? false;

        if (clientSecret) {
          const paymentIntent = await stripe?.confirmCardPayment(clientSecret);

          if (!paymentIntent || paymentIntent?.error) {
            const errorMsg = paymentIntent?.error?.message ?? 'Unknown error';

            console.warn('THERE WAS A PAYMENT INTENT ERROR', errorMsg);
            setError(errorMsg);
            void sendOrderFailedReason(errorMsg);

            return;
          }
        }

        if (res.order_id.toString()) {
          await checkSubscriptionStatus();
        }
      } else {
        // ActiveCampaign - failed payment.
        trackEvent(
          'checkoutpaymentattemptunsuccessful',
          'Checkout payment attempt unsuccessful',
        );
        let errorMsg = res.error?.message ?? '';

        if (errorMsg.includes('An exception occurred')) {
          errorMsg =
            'An internal error occurred. Please try again later, or contact us if the issue persists.';
        }

        console.warn('THERE WAS A RES ERROR', errorMsg);
        setError(errorMsg);

        if (res.error?.showRecaptcha) {
          setRecaptchaVersion(RecaptchaVersion.V2);
        }

        void sendOrderFailedReason(errorMsg);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }

      console.error('Error creating subscription 2:', err);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!stripe || !elements) {
      return;
    }

    const country = countries.find((c) => c.value === Number(data.country));

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return;
    }

    const { token, error: stripeError } = await stripe.createToken(
      cardNumberElement,
      {
        name: data.nameOnCard,
        address_country: country?.iso_code_2 ?? 'GB',
      },
    );

    if (stripeError) {
      console.warn('THERE WAS A STRIPE ERROR', stripeError);
      setError(stripeError.message ?? 'An error occurred');

      return;
    }

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: data.nameOnCard,
          address: {
            country: country?.iso_code_2 ?? 'GB',
          },
        },
      });

    if (paymentMethodError) {
      console.warn('THERE WAS A PAYMENT METHOD ERROR', paymentMethodError);
      setError(paymentMethodError.message ?? 'An error occurred');

      return;
    }

    if (!token || !paymentMethod) {
      console.warn('THERE WAS A TOKEN ERROR', token);
      setError('Please check your card details and try again.');

      return;
    }

    try {
      await purchaseSubscription({
        currency: settings?.currency.id ?? 1,
        period: periodId,
        recaptchaVersion,
        recaptchaToken: recaptchaToken ?? '',
        stripeToken: token.id,
        paymentMethod: paymentMethod.id,
        organisation: '',
        addressLine1: '',
        country: settings?.country.id ?? 238,
        vatNumber: '',
        ...(accountType === AccountType.Premium
          ? { plan: premiumPlanData.id ?? 1, planData: premiumPlanData }
          : { plan: businessPlanData.id ?? 15, planData: businessPlanData }),
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }

      console.error('Error creating subscription 1:', err);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BillingFrequency accountType={accountType} />

        {paymentStatus === 'pending' ? (
          <PendingPayment
            checkSubscriptionStatus={() => checkSubscriptionStatus(0)}
          />
        ) : (
          <>
            <CardForm defaultOpen={!isExpressCheckoutEnabled}>
              {pricingTag}
            </CardForm>

            {isExpressCheckoutEnabled && (
              <ExpressCheckout
                setError={setError}
                accountType={accountType}
                periodId={periodId}
              />
            )}
          </>
        )}

        {recaptchaVersion === RecaptchaVersion.V2 && <div id="recaptcha-v2" />}
      </Form>
    </FormProvider>
  );
};

const Checkout = ({ accountType, nextScreenName }: CheckoutFormProps) => {
  const { userState } = useContext(UserStateContext);
  const currency = useCurrency();

  if (
    (accountType === AccountType.Premium ||
      accountType === AccountType.Business) &&
    userState.selectedBillingMode &&
    currency?.code
  ) {
    const amount =
      planData?.[accountType]?.[userState.selectedBillingMode][currency.code];

    return (
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'payment',
          amount: Math.round(Number(amount) * 100),
          currency: currency.code.toLowerCase(),
        }}
      >
        <CheckoutForm
          accountType={accountType}
          nextScreenName={nextScreenName}
        />
      </Elements>
    );
  }

  return null;
};

export default Checkout;
