import SidebarHeader from '@/components/Modals/Sidebar/components/SidebarHeader';
import useDynamicSidebarHeader from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

import { goPremiumModalTabs } from './tabs';

const GoPremiumModalHeader = () => {
  // Used for controlling the sidebar header when specific screens are active
  useDynamicSidebarHeader({
    dynamicTabs: [...goPremiumModalTabs],
  });

  return <SidebarHeader />;
};

export { GoPremiumModalHeader };
