const INITIAL_STATE = {
  adminData: false,
  editData: false,
  showRelevancy: false,
  assetCount: false,
  lastRequested: [],
};

const adminBar = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_EDIT_DATA':
      return Object.assign({}, state, {editData: action.editData});
    case 'CLEAR_EDIT_DATA':
      return Object.assign({}, state, {editData: false});
    case 'SET_ADMIN_ASSET_COUNT':
      return Object.assign({}, state, {assetCount: action.assetCount});
    case 'SET_LAST_REQUESTED_ASSET_COUNT':
      return Object.assign({}, state, {lastRequested: action.lastRequested});
    case 'CLEAR_ADMIN_ASSET_COUNT':
      return Object.assign({}, state, {assetCount: false});
    case 'LOAD_ADMIN_DATA':
      return Object.assign({}, state, {adminData: action.adminData, showRelevancy: action.showRelevancy});
    case 'TOGGLE_RELEVANCY':
      let showRelevancy;
      if (state.showRelevancy) {
        showRelevancy = false;
        sessionStorage.setItem("showDebugRData", "0");
      } else {
        showRelevancy = true;
        sessionStorage.setItem("showDebugRData", "1");
      }
      return Object.assign({}, state, { showRelevancy });
    default:
      return state;
  }
};

export default adminBar;
