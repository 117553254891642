import type { GenericSearchResult } from '@/features/SearchPage/types';
import type { SearchResponse } from 'typesense/lib/Typesense/Documents';

const collectionsToMerge = [
  {
    name: 'Popular Music Searches',
    collections: ['trackqualifiedkeywords', 'tracktags'],
    limit: 6,
  },
  {
    name: 'Popular SFX Searches',
    collections: ['sfxqualifiedkeywords', 'sfxtags'],
    limit: 6,
  },
  {
    name: 'Playlists',
    collections: ['collections', 'trackstyles'],
    limit: 12,
  },
  {
    name: 'SFX Collections',
    collections: ['sfxcollections', 'sfxstyles'],
    limit: 12,
  },
  {
    name: 'Artists',
    collections: ['artists'],
  },
  {
    name: 'Tracks',
    collections: ['tracks'],
  },
];

export const mergeCollectionHits = (
  sortedResults: SearchResponse<GenericSearchResult>[],
): SearchResponse<GenericSearchResult>[] => {
  const mergedResults: SearchResponse<GenericSearchResult>[] = [];

  collectionsToMerge.forEach((item) => {
    const toMerge = sortedResults.filter((result) => {
      const collectionName = result.request_params?.collection_name;

      return collectionName && item.collections.includes(collectionName);
    });

    if (toMerge.length === 0) {
      return;
    }

    // Merge hits and rename collection.
    const mergedHits = toMerge.reduce((acc, curr) => {
      const hits = [...(curr.hits ?? []), ...(acc.hits ?? [])];

      const uniqueHits = [
        ...new Map(
          hits.map((v) => [v.document.name?.toLowerCase(), v]),
        ).values(),
      ];

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return {
        ...acc,
        hits: [...uniqueHits],
      };
    });

    const sortedHits = mergedHits.hits?.sort((a, b) => {
      return b.text_match - a.text_match;
    });

    if (sortedHits && item.limit && sortedHits.length > item.limit) {
      sortedHits.length = item.limit;
    }

    const newHits = {
      ...mergedHits,
      hits: sortedHits,
      request_params: {
        ...mergedHits.request_params,
        collection_name: item.name,
      },
    };

    mergedResults.push(newHits);
  });

  const fieldsToRemove = collectionsToMerge.reduce<string[]>(
    (acc, curr) => [...acc, ...(curr.collections ?? [])],
    [],
  );

  const filteredResults = mergedResults.filter((sortedResult) => {
    const name = sortedResult.request_params?.collection_name;

    return name && !fieldsToRemove.includes(name);
  });

  return filteredResults;
};
