import { createContext, useContext } from 'react';

interface SidebarModalContextType {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const SidebarModalContext = createContext<SidebarModalContextType>({
  open: false,
  onOpenChange: () => {},
});

export const useSidebarModalContext = () => {
  const context = useContext(SidebarModalContext);

  if (!context) {
    throw new Error(
      'useSidebarModalContext must be used within an SidebarModalProvider',
    );
  }

  return context;
};

interface SidebarModalProviderProps {
  children: React.ReactNode;
  value: SidebarModalContextType;
}

export const SidebarModalProvider = ({
  children,
  value,
}: SidebarModalProviderProps) => {
  return (
    <SidebarModalContext.Provider value={value}>
      {children}
    </SidebarModalContext.Provider>
  );
};
