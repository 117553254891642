/**
 * A provider for local settings.
 * These are stored on the client, and are persisted between sessions.
 * */

import React, { createContext, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

import { useIsClient } from '@/hooks/useIsClient';

export interface ILocalSettingsContext {
  localSettings: Record<string, unknown>;
  setLocalSettings: (value: Record<string, unknown>) => void;
}

interface LocalSettingsProviderProps {
  children: React.ReactNode;
}

const LocalSettingsContext = createContext<ILocalSettingsContext>({
  localSettings: {},
  setLocalSettings: () => {},
});

export const LocalSettingsProvider: React.FC<LocalSettingsProviderProps> = ({
  children,
}) => {
  const isClient = useIsClient();

  const [localSettings, setLocalSettings] = useLocalStorage(
    'local-settings',
    {},
    {
      raw: false,
      deserializer: (e: string) => {
        return JSON.parse(window.atob(e)) as Record<string, unknown>;
      },
      serializer: (e: Record<string, unknown>) => {
        return window.btoa(JSON.stringify(e));
      },
    },
  );

  const value = useMemo(
    () => ({ localSettings: localSettings ?? {}, setLocalSettings }),
    [localSettings, setLocalSettings],
  );

  if (!isClient) {
    return children;
  }

  return (
    <LocalSettingsContext.Provider value={value}>
      {children}
    </LocalSettingsContext.Provider>
  );
};

export const useLocalSettings = () => {
  return useContext(LocalSettingsContext);
};
