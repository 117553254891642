import { memo } from 'react';

import type { TProps as TogleeTProps } from '@tolgee/react';

export const T: React.FC<TogleeTProps> = memo(({ children, ...props }) => {
  // If we don't have a keyName/defaultValue, we use the children.
  let defaultValue = children;

  if ('defaultValue' in props) {
    defaultValue = props.defaultValue;
  }

  if (!defaultValue && 'keyName' in props) {
    defaultValue = props.keyName;
  }

  return defaultValue;
});

T.displayName = 'T';
