import api from '../api/legacyApi';
import { getAuthToken } from '../auth';
import { trackPlayEngagement } from '../utils/analytics';
import { trackInternalEvent } from '../utils/tracking';

export function toggleFavouriteTrackVersion(trackVersionId, contextId) {
  return async (dispatch) => {
    const authToken = getAuthToken();

    dispatch({
      type: 'TOGGLE_FAVORITES',
      trackId: parseInt(trackVersionId),
    });

    return dispatch(() => {
      fetch(
        `${process.env.NEXT_PUBLIC_API}favourites/${trackVersionId}/tracks/${contextId}/version/context/toggle`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'X-Auth-Token': authToken || null,
          },
        },
      );
    });
  };
}

export function addNewEngagement(
  type,
  trackId,
  contextId,
  versionId = false,
  browseType,
) {
  /*
      DR - Workaround to stop plays counting multiple times. Should also stop people spamming play/pause.
     */
  const lastEngagement = sessionStorage.getItem('last_sfx_enagement');
  const authKey = getAuthToken();
  const newEngagement = JSON.stringify({ type, trackId, contextId, versionId });
  let eventName;
  let endPoint;

  sessionStorage.setItem(
    'last_sfx_enagement',
    JSON.stringify({ type, trackId, contextId, versionId }),
  );
  if (lastEngagement === newEngagement || contextId === -1) {
    return;
  }
  /*
      DR - End of workaround.
     */
  switch (type) {
    case 'play':
      eventName = 'engagementPlay';
      break;
    default:
      eventName = '';
  }

  try {
    trackPlayEngagement();
  } catch (e) {}

  let isSfx = typeof contextId === 'string';

  // Looks like trackId is an object since the conversion to Next JS.
  // Checking for an object and pulling the ID from it seems like
  // the safest fix for now.
  if (typeof trackId === 'object') {
    trackId = trackId.id;
  }

  if (isSfx) {
    contextId = contextId.replace('sfx-', '');

    endPoint = `${process.env.NEXT_PUBLIC_API}sfxes/${type}/types/${trackId}/sfxvariants/${contextId}/contexts/new`;
  } else {
    endPoint = `${process.env.NEXT_PUBLIC_API}favourites/${type}/types/${trackId}/tracks/${contextId}/contexts/new`;
  }
  // auth_token
  return fetch(endPoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'X-Auth-Token': authKey || null,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function removeTrack(track, playlistId) {
  return function (dispatch) {
    const authKey = getAuthToken();
    // If there is no playlistId, presume it's a favourite track
    dispatch({
      type: 'DELETE_PLAYLIST_REQUEST',
      trackId: track.id,
    });
    if (playlistId) {
      return fetch(
        `${process.env.NEXT_PUBLIC_API}playlist/delete-playlist-track/${playlistId}/${track.id}`,
        {
          method: 'GET',
          headers: {
            'X-Auth-Token': authKey || null,
          },
          credentials: 'include',
        },
      )
        .then(
          (response) => response.json(),
          (error) => console.log('An error occurred.', error),
        )
        .then(() => {
          dispatch(deleteTrack(track.id));
        });
    }
    const contextId = track.current_context.id;
    const versionId = track.track_version?.[0].id;

    return fetch(
      `${process.env.NEXT_PUBLIC_API}favourites/${versionId}/tracks/${contextId}/version/context/toggle`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-Auth-Token': authKey || null,
        },
      },
    )
      .then(
        (response) => response.json(),
        (error) => console.log('An error occurred.', error),
      )
      .then(() => {
        dispatch(deleteTrack(track.id));
      });
  };
}
export const removeTrackStyle = (trackId, styles) => (dispatch) =>
  new Promise((resolve) => {
    const authKey = getAuthToken();
    dispatch({
      type: 'REMOVE_TRACK_FROM_STYLE_REQUEST',
      trackId,
    });
    return fetch(
      `${process.env.NEXT_PUBLIC_API}remove_style_from_track/${trackId}/[${styles}]`,
      {
        method: 'GET',
        headers: {
          'X-Auth-Token': authKey || null,
        },
        credentials: 'include',
      },
    )
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          resolve(res);
          dispatch({
            type: 'REMOVE_TRACK_FROM_STYLE',
            trackId,
          });
        }
      })
      .catch((error) => console.log('An error occurred.', error));
  });
function deleteTrack(trackId) {
  return {
    type: 'DELETE_PLAYLIST_TRACK',
    trackId,
  };
}

export function titleEdit(playlistId, title) {
  return function () {
    const authKey = getAuthToken();
    return fetch(
      `${process.env.NEXT_PUBLIC_API}playlists/${playlistId}/titles/${btoa(
        title,
      )}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-Auth-Token': authKey || null,
        },
      },
    ).then(
      (response) => response.json(),
      (error) => console.log('An error occurred.', error),
    );
  };
}

export function addExistingPlaylistTrack(
  trackId,
  versionId,
  playlistId,
  contextId,
) {
  const authKey = getAuthToken();

  if (playlistId === 'Select A Playlist') {
    return new Promise((_resolve, reject) => {
      reject(new Error('Playlist not selected'));
    });
  }

  try {
    trackInternalEvent({
      type: 'playlist',
    });
  } catch (e) {}

  return fetch(
    `${process.env.NEXT_PUBLIC_API}playlists/${trackId}/tracks/${versionId}/versions/${playlistId}/contexts/${contextId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'X-Auth-Token': authKey || null,
      },
    },
  ).then(
    (response) => response.json(),
    (error) => console.log('An error occurred.', error),
  );
}

export function addNewPlaylistTrack(trackId, versionId, title, contextId) {
  const authKey = getAuthToken();

  // We need to encode playlist titles as Symfony can't handle encoded forward slashes
  // https://github.com/laravel/framework/issues/22125
  const encodedTitle = btoa(encodeURIComponent(title));
  const playlistIsPublic = 1;

  try {
    trackInternalEvent({
      type: 'playlist',
    });

    window.dataLayer.push({
      event: 'engagementCreatePlaylist',
    });
    window.dataLayer.push({
      event: 'engagementAddToPlaylist',
      engagementTrackId: trackId,
    });
  } catch (e) {}

  const res = api('admin/playlists/new', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: {
      track_id: trackId,
      track_version_id: versionId,
      playlist_title: title,
    },
  });

  return res;
}
