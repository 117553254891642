import clsx from 'clsx';

import Icons from '@/components/Modals/Sidebar/components/Icons';
import Tab from '@/components/Modals/Sidebar/components/Tab';
import { useSidebarHeaderContext } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';

import { SignUpModalScreenName } from './types';

import type { dynamicSidebarHeaderTab } from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

const CreatorTab = () => {
  const { currentScreen, setScreen } = useScreenContext();
  const { disabled, triggerCooldown } = useSidebarHeaderContext();

  const isActive = currentScreen === SignUpModalScreenName.SignUpCreator;
  const isDisabled = disabled || isActive;

  const handleClick = () => {
    if (!isDisabled) {
      setScreen(SignUpModalScreenName.SignUpCreator);
      // Disables all tabs for 350ms (the length of the animation)
      triggerCooldown();
    }
  };

  return (
    <button
      className={clsx(
        'relative flex justify-center items-center h-16 p-4 -ml-4',
        'after:block after:w-full after:h-full after:transform after:translate-x-6 after:absolute after:top-0 after:left-0 after:rounded-none after:content-[""] after:transition-tab transition-tab duration-300 after:duration-300 ease-out after:ease-out will-change-auto focus:outline-none focus-visible:underline focus-visible:decoration-uppbeat',
        {
          'bg-gray-light-200 after:bg-gray-light-200 z-10 pr-0 after:skew-x-[15deg] after:rounded-tr-xl dark:bg-gray-dark-200 dark:after:bg-gray-dark-200':
            isActive,
          'bg-gray-dark-300 after:bg-gray-dark-300 pr-12 cursor-pointer dark:bg-gray-dark-100 dark:after:bg-gray-dark-100 hover:bg-gray-dark-200 hover:after:bg-gray-dark-200 dark:hover:bg-gray-medium-400 dark:hover:after:bg-gray-medium-400':
            !isActive,
        },
      )}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <span
        className={clsx(
          'z-10 relative font-semibold text-sm flex justify-center items-center gap-1 leading-none',
          {
            'text-white': !isActive,
            'text-black dark:text-white': isActive,
          },
        )}
      >
        <Icons.Free /> Creator
      </span>
    </button>
  );
};

const BusinessTab = () => {
  const { currentScreen, setScreen } = useScreenContext();
  const { disabled, triggerCooldown } = useSidebarHeaderContext();

  const isActive = currentScreen === SignUpModalScreenName.SignUpBusiness;
  const isDisabled = disabled || isActive;

  const handleClick = () => {
    if (!isDisabled) {
      setScreen(SignUpModalScreenName.SignUpBusiness);
      // Disables all tabs for 350ms (the length of the animation)
      triggerCooldown();
    }
  };

  return (
    <button
      className={clsx(
        'relative flex justify-start items-center h-16 p-4',
        'after:block after:w-full after:h-full after:transform after:-translate-x-6 after:absolute after:top-0 after:left-0 after:rounded-none after:content-[""] after:transition-tab transition-tab duration-300 after:duration-300 ease-out after:ease-out flex-grow -mr-4 will-change-auto focus:outline-none focus-visible:underline focus-visible:decoration-uppbeat',
        {
          'bg-gray-light-200 after:bg-gray-light-200 z-10 pl-0 after:-skew-x-[15deg] after:rounded-tl-xl dark:bg-gray-dark-200 dark:after:bg-gray-dark-200':
            isActive,
          'bg-gray-dark-300 after:bg-gray-dark-300 dark:bg-gray-dark-100 dark:after:bg-gray-dark-100 pl-12 cursor-pointer hover:bg-gray-dark-200 hover:after:bg-gray-dark-200 dark:hover:bg-gray-medium-400 dark:hover:after:bg-gray-medium-400':
            !isActive,
        },
      )}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <span
        className={clsx(
          'z-10 relative font-semibold text-sm flex justify-center items-center gap-1 leading-none',
          {
            'text-white': !isActive,
            'text-black dark:text-white': isActive,
          },
        )}
      >
        <Icons.Business /> Business
      </span>
    </button>
  );
};

const SignUpFreeTab = () => (
  <Tab>
    <Icons.Free /> Free
  </Tab>
);

const SignUpPremiumTab = () => (
  <Tab>
    <Icons.Premium /> Premium
  </Tab>
);

export const signUpModalTabs: dynamicSidebarHeaderTab[] = [
  {
    tabs: [
      <CreatorTab key="creator-tab" />,
      <BusinessTab key="business-tab" />,
    ],
    screenNames: [
      SignUpModalScreenName.SignUpCreator,
      SignUpModalScreenName.SignUpBusiness,
    ],
  },
  {
    tabs: [<SignUpFreeTab key="free-tab" />],
    screenNames: [SignUpModalScreenName.SignUpFree],
  },
  {
    tabs: [<SignUpPremiumTab key="premium-tab" />],
    screenNames: [SignUpModalScreenName.SignUpPremium],
  },
];
