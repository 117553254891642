const INITIAL_STATE = {
	isFetching: undefined,
};

const tracks = (state = INITIAL_STATE, action) => {
  const results = [];
  switch (action.type) {
    case 'SHOW_MORE_TRACK_HIDE':
      return {
        ...state,
        hideShowMore: true,
      };
    case 'SHOW_MORE_TRACKS_LOADING':
      return Object.assign({}, state, {
        isShowFetchingMore: action.isShowFetchingMore,
      });
    case 'MORE_TRACKS_LOADING':
      return Object.assign({}, state, {
        isFetchingMore: action.isFetchingMore,
      });
    case 'SET_LATEST_CALL_TIMESTAMP':
      return Object.assign({}, state, {
        timestamp: action.timestamp,
      });
    case 'REQUEST_TRACKS':
      return Object.assign({}, state, {
        isFetching: true,
        page: { ...state.page, results: [] },
      });
    case 'RECEIVE_TRACKS':
      if (action.timestamp === state.timestamp || !action.timestamp) {
        return Object.assign({}, state, {
          isFetching: false,
          isCleared: false,
          page: { ...state.page, ...action.tracks },
        });
      } else {
        return state;
      }
    case 'RECEIVE_SHOW_MORE_TRACKS':
      return Object.assign({}, state, {
        page: {
          ...state.page,
          results: state.page?.results?.concat(action.tracks) ?? [],
        },
      });
    case 'RECEIVE_SIMILAR_TRACKS':
      if (state.page.results[0] && Array.isArray(action.tracks.results)) {
        action.tracks.results.unshift(state.page.results[0]);
      }

      return Object.assign({}, state, {
        isFetching: false,
        isCleared: false,
        page: action.tracks,
      });
    case 'ADD_PAGE_TRACKS':
      results.push(action.track);
      return Object.assign({}, state, {
        isFetching: true,
        page: {
          pagination: false,
          results,
        },
      });
    case 'DELETE_PLAYLIST_REQUEST':
      return {
        ...state,
        isDeleting: action.trackId,
      };
    case 'DELETE_PLAYLIST_TRACK':
      return {
        ...state,
        isDeleting: false,
        page: {
          ...state.page,
          results: state.page.results.filter(
            (track) => track.id !== action.trackId
          ),
        },
      };
    case 'REMOVE_TRACK_FROM_STYLE_REQUEST':
      return {
        ...state,
        isRemoveLoading: {
          isDeleting: true,
          trackId: action.trackId,
        },
      };
    case 'REMOVE_TRACK_FROM_STYLE':
      return {
        ...state,
        isRemoveLoading: {
          isDeleting: false,
          trackId: action.trackId,
        },
        page: {
          ...state.page,
          results: state.page.results.filter(
            (track) => track.id !== action.trackId
          ),
        },
      };
    case 'CLEAR_TRACKS':
      return {
        isCleared: true,
      };
    default:
      return state;
  }
};

export default tracks;
