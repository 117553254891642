import * as React from 'react';

const _TikTok = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M34.353 17.327a18.724 18.724 0 0010.952 3.517v-7.887c-.773 0-1.544-.08-2.3-.241v6.208a18.727 18.727 0 01-10.952-3.517v16.095c0 8.051-6.504 14.578-14.526 14.578a14.42 14.42 0 01-8.087-2.466A14.457 14.457 0 0019.826 48c8.023 0 14.527-6.527 14.527-14.578V17.327zM37.19 9.37a11.012 11.012 0 01-2.837-6.436V1.92h-2.18a11.043 11.043 0 005.017 7.45zM14.514 37.436a6.657 6.657 0 01-1.355-4.038c0-3.682 2.975-6.668 6.645-6.668.684 0 1.364.105 2.015.313V18.98c-.761-.105-1.53-.15-2.299-.133v6.276a6.63 6.63 0 00-2.016-.313c-3.67 0-6.645 2.986-6.645 6.669a6.67 6.67 0 003.655 5.957z"
        fill="#FF004F"
      />
      <path
        d="M32.053 15.407a18.727 18.727 0 0010.952 3.517v-6.208A10.984 10.984 0 0137.19 9.37a11.043 11.043 0 01-5.017-7.45h-5.725v31.501c-.013 3.673-2.983 6.646-6.645 6.646a6.627 6.627 0 01-5.29-2.631 6.67 6.67 0 01-3.655-5.957c0-3.683 2.975-6.668 6.645-6.668.703 0 1.381.11 2.017.312v-6.276C11.638 19.01 5.3 25.473 5.3 33.42c0 3.968 1.578 7.565 4.14 10.193a14.421 14.421 0 008.087 2.466c8.022 0 14.526-6.527 14.526-14.578V15.407z"
        className="text-black dark:text-white fill-current"
      />
      <path
        d="M43.005 12.716v-1.679A10.921 10.921 0 0137.19 9.37a10.975 10.975 0 005.815 3.346zM32.173 1.92c-.052-.3-.092-.602-.12-.906V0h-7.905v31.502c-.013 3.671-2.982 6.645-6.645 6.645a6.598 6.598 0 01-2.99-.711 6.627 6.627 0 005.29 2.631c3.662 0 6.632-2.973 6.645-6.646V1.92h5.725zM19.52 18.847V17.06a14.62 14.62 0 00-1.993-.136C9.504 16.924 3 23.451 3 31.502c0 5.047 2.556 9.495 6.44 12.112A14.555 14.555 0 015.3 33.421c0-7.948 6.339-14.41 14.22-14.574z"
        fill="#00F2EA"
      />
    </svg>
  );
};

const TikTok = React.memo(_TikTok);

export default TikTok;
