import { createContext, useContext, useMemo } from 'react';

import { SettingsContext } from '@/providers/SettingsProvider';
import { ThemeContext } from '@/providers/ThemeProvider';
import { UserStateContext } from '@/providers/UserStateProvider';

import type { ISettingsContext } from '@/providers/SettingsProvider';
import type { IThemeContext } from '@/providers/ThemeProvider';
import type { IUserStateContext } from '@/providers/UserStateProvider';
import type { Settings } from '@/types/settings';
import type { ReactNode } from 'react';

interface IClassContext {
  settingsContext?: ISettingsContext;
  themeContext?: IThemeContext;
  userStateContext?: IUserStateContext;
}

interface ClassProviderProps {
  settings?: Settings;
  children: ReactNode;
}

export const ClassContext = createContext<IClassContext>({});

/**
 * A provider for global context for React classes.
 *
 * This exists to pull together the context provided by, for example, the theme,
 * settings and user state providers. React classes can only make use of a
 * single context. This could go if everything was refactored to use functional
 * components (which would be quite a large task at this stage)!
 */
export const ClassProvider: React.FC<ClassProviderProps> = ({ children }) => {
  const settingsContext = useContext(SettingsContext);
  const themeContext = useContext(ThemeContext);
  const userStateContext = useContext(UserStateContext);

  const ctx = useMemo(
    (): IClassContext => ({
      settingsContext,
      themeContext,
      userStateContext,
    }),
    [settingsContext, themeContext, userStateContext],
  );

  return <ClassContext.Provider value={ctx}>{children}</ClassContext.Provider>;
};
