import { toggleRelevancy } from '@/actions/adminAction';
import Switch from '@/components/Switch';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppState } from '@/hooks/useAppState';

const ToggleRelevancy = () => {
  const adminBar = useAppState('adminBar');
  const dispatch = useAppDispatch();

  const { showRelevancy } = adminBar;

  const onToggleRelevancy = () => {
    dispatch(toggleRelevancy());
  };

  return (
    <Switch
      onCheckedChange={onToggleRelevancy}
      checked={showRelevancy}
      id="admin-relevancy"
      label="Show Relevancy"
    />
  );
};

export default ToggleRelevancy;
