const optionallySkipRecaptcha = () => {
  if (
    process.env.NEXT_PUBLIC_SKIP_RECAPTCHA !== 'true' ||
    typeof process.env.NEXT_PUBLIC_SKIP_RECAPTCHA_KEY !== 'string' ||
    typeof process.env.NEXT_PUBLIC_SKIP_RECAPTCHA_VALUE !== 'string'
  ) {
    return {};
  }

  return {
    [process.env.NEXT_PUBLIC_SKIP_RECAPTCHA_KEY]: String(
      process.env.NEXT_PUBLIC_SKIP_RECAPTCHA_VALUE,
    ),
  };
};

export default optionallySkipRecaptcha;
