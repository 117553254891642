import { twMerge } from 'tailwind-merge';

import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface SelectProps<T extends FieldValues>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: Path<T>;
  register: UseFormRegister<T>;
  hasError?: boolean;
}

const Select = <T extends FieldValues>({
  className,
  name,
  register,
  hasError = false,
  onChange,
  ...rest
}: SelectProps<T>) => {
  return (
    <div className="relative after:absolute after:right-4 after:top-1/2 after:transform after:-translate-y-1/2 after:w-0 after:h-0 after:content-[''] after:border-x-[5px] after:border-t-[5px] after:border-b-0 after:border-x-transparent after:border-t-black dark:after:border-t-white after:border-solid after:border-t-solid after:pointer-events-none after:select-none">
      <select
        className={twMerge(
          'h-10 antialiased text-sm pl-4 pr-8 appearance-none outline-0 font-sans shadow-none touch-manipulation text-gray-dark-400 bg-gray-light-200 border-2 border-solid border-gray-light-200 rounded-[.625rem] enabled:hover:bg-gray-light-300 enabled:hover:border-gray-light-300 focus:bg-gray-light-300 focus:border-uppbeat transition duration-75 w-full dark:bg-gray-dark-200 dark:border-gray-dark-200 dark:text-white dark:enabled:hover:bg-gray-dark-100 dark:enabled:hover:border-gray-dark-100 dark:focus:bg-gray-dark-100 dark:focus:border-uppbeat placeholder-gray-dark-400/70 dark:placeholder-gray-light-400/50',
          hasError
            ? 'border-red dark:border-red enabled:hover:border-red dark:enabled:hover:border-red'
            : '',
          className,
        )}
        {...rest}
        {...register(name, {
          onChange,
        })}
      />
    </div>
  );
};

export const SelectSkeleton = () => (
  <div className="h-10 antialiased text-sm pl-4 pr-8 appearance-none outline-0 font-sans shadow-none touch-manipulation text-gray-dark-400 bg-gray-light-200 border-2 border-solid border-gray-light-200 rounded-[.625rem] enabled:hover:bg-gray-light-300 enabled:hover:border-gray-light-300 focus:bg-gray-light-300 focus:border-uppbeat transition duration-75 w-full dark:bg-gray-dark-200 dark:border-gray-dark-200 dark:text-white dark:enabled:hover:bg-gray-dark-100 dark:enabled:hover:border-gray-dark-100 dark:focus:bg-gray-dark-100 dark:focus:border-uppbeat placeholder-gray-dark-400/70 dark:placeholder-gray-light-400/50 animate-pulse" />
);

export default Select;
