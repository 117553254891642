import * as Sentry from '@sentry/nextjs';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { withRouter } from 'next/router';
import { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'sonner';
import { loadAdminData } from '../../actions/adminAction';
import {
  SignUpSuccess,
  addModal,
  animateInTooltip,
  animateOutTooltip,
  checkRecaptchaV3,
  clearGoogleLoginFlag,
  hideNotificationSuccess,
  hideSigninSuccess,
  makeLoginAttempt,
  removeModal,
  setEnteredProfession,
  setGoogleLoginFailure,
  setGoogleLoginSuccess,
  setLoginStatus,
  setPremiumStatus,
  showNotificationSuccess,
  showSigninSuccess,
  showSignupError,
  updateCreditsCurrent,
  updateRemainingCredits,
} from '../../actions/authenticationAction';
import { addSubscriptionToCart } from '../../actions/cartAction';
import { acquisitionClick } from '../../actions/checkoutAction';
import { loadCountry } from '../../actions/countryAction';
import { loadCurrency } from '../../actions/currencyAction';
import {
  disableKeyboardControls,
  enableKeyboardControls,
} from '../../actions/footerPlayerAction';
import {
  addDownloadIntent,
  addLicense,
  addPremiumDownloadIntent,
  clearDownloadIntent,
  clearPremiumDownloadIntent,
  downloadTrackInfo,
  generateLicenseCode,
  generateLicenseCodeError,
  generateLicenseCodeStart,
  removeLicense,
  requestSfxLicense,
  requestSubscriptionLicense,
  sfxDownload,
  trackDownload,
} from '../../actions/licenseAction';
import { setLoginFinish } from '../../actions/loginFlowAction';
import { removePlaylist } from '../../actions/playerAction';
import { framelessPlaylist } from '../../actions/playlistAction';
import { hideSidebar, showSidebar } from '../../actions/sidebarAction';
import { loadSubscriptionData } from '../../actions/subscriptionAction';
import {
  accountTerm,
  accountTypePremium,
  buttonToModal,
  chooseLogin,
  forgotPassword,
  increaseCurrentStep,
  pricingPagePath,
  resetCurrentStep,
} from '../../actions/ubModalAction';
import { trackEvent } from '../../api/activecampaign/update-contact';
import api from '../../api/legacyApi';
import { getAuthToken, removeAuthToken, setAuthToken } from '../../auth';
import { userMetadataQueryKey } from '../../hooks/markers/useUserMetadata';
import { ClassContext } from '../../providers/ClassProvider';
import {
  trackDownload as trackDownloadEvent,
  trackSFXDownload,
} from '../../utils/analytics';
import { addConversionPoint } from '../../utils/helpers';
import { isSafari } from '../../utils/is-safari';
import { trackInternalEvent } from '../../utils/tracking';
import { AccountModalNames } from '../Modals/Account';
import PlaylistModal from '../PlaylistModal';
import RemoveStyleModal from '../RemoveStyleModal';
import SideDrawer from '../SideDrawer';
import { T } from '../T';
import UBPopupDownload from '../UBPopup/UBPopupDownload';
import UBPopupRecaptcha from '../UBPopup/UBPopupRecaptcha';
import UBTrackModal from '../UBTrack/UBTrackModal';
import { TopBarHeader } from './TopBarHeader';
import { FileFormat } from '@/types/file-format';

const TopBarContent = dynamic(
  () => import('./TopBarContent').then((mod) => mod.TopBarContent),
  { ssr: false },
);

class TopBar extends Component {
  static contextType = ClassContext;

  constructor(props) {
    super(props);
    this.state = {
      authToken: null,
      isFreeUserAccessingPremiumTrack: false,
      isFreeUserAccessingPremiumSfx: false,
      isUnVerifiedUserDownloadTrack: false,
      isFreeUserReachedDownloadLimit: false,
      lastAccessedTrackId: -1,
      siteVersion: '',
      fetchingVersion: false,
      showNewVersionPrompt: false,
      showCookieNotice: false,
      downloadMp3: false,
      showNotificationTimer: false,
      upsellGoogleCounter: 0,
    };

    this.showNotification = this.showNotification.bind(this);

    this.mediaQuery =
      typeof window !== 'undefined' && window.matchMedia('(min-width : 600px)');
  }

  async componentDidUpdate(prevProps) {
    if (
      isEmpty(this.props.router.query) ||
      isEqual(prevProps.router.query, this.props.router.query)
    ) {
      return;
    }

    const psCode = this.props.router.query.ps;
    const rtCode = this.props.router.query.rt;
    const asCode = this.props.router.query.as;
    const emCode = this.props.router.query.em;
    const urCode = this.props.router.query.referral;
    const irclickid = this.props.router.query.irclickid;

    const hasExistingProfitShare = !!localStorage.getItem('partner_code');
    const hasExistingAffiliate = !!localStorage.getItem('as_code');
    const rsRsCode = process.env.NEXT_PUBLIC_REFERRAL_SCHEME;
    const psRsCode = process.env.NEXT_PUBLIC_PROFIT_SHARE_SCHEME;
    const asRsCode = process.env.NEXT_PUBLIC_AFFILIATE_SCHEME;
    const imRsCode = process.env.NEXT_PUBLIC_IMPACT_SCHEME;

    let referralCode = false;
    let referralType = false;

    // Referral tracking
    if (rtCode) {
      localStorage.setItem('rt_code', rtCode);
      referralCode = rtCode;
      referralType = 'referralSource';
    }

    // Profit share partners
    if (psCode) {
      localStorage.setItem('partner_code', psCode);
      localStorage.setItem('rt_code', psRsCode);
      referralCode = psCode;
      referralType = 'profitSharePartner';

      if (hasExistingAffiliate) {
        localStorage.removeItem('as_code');
      }
    }

    // Affiliate Scheme
    if (asCode) {
      localStorage.setItem('as_code', asCode);
      localStorage.setItem('rt_code', asRsCode);
      referralCode = asCode;
      referralType = 'affiliatesPartner';

      if (hasExistingProfitShare) {
        localStorage.removeItem('partner_code');
      }
    }

    // User Referral Scheme
    if (urCode) {
      localStorage.setItem('user_referral_code', urCode);

      if (rtCode !== process.env.NEXT_PUBLIC_MINI_SPONSOR) {
        localStorage.setItem('rt_code', rsRsCode);
        referralCode = rsRsCode;
        referralType = 'referralSource';
      }
    }

    // Impact tracking
    if (irclickid) {
      localStorage.setItem('irclickid', irclickid);
      referralCode = rtCode;
      referralType = 'impactPartner';

      localStorage.setItem('rt_code', imRsCode);

      if (hasExistingAffiliate) {
        localStorage.removeItem('as_code');
      }

      if (hasExistingProfitShare) {
        localStorage.removeItem('partner_code');
      }
    }

    // Email template tracking
    if (emCode) {
      this.props.onAcquisitionClick(emCode, 'email');
    }

    if (referralCode) {
      this.props.onAcquisitionClick(referralCode, referralType);
    }
  }

  async componentDidMount() {
    // Safari gives us some... unique styling challenges. This helps to filter them out
    // https://stackoverflow.com/a/23522755/1482673
    if (isSafari()) {
      document.body.classList.add('is-safari');
    }

    window.addEventListener('focus', this.onFocus);

    // Setup Site
    this.onSetupSite();

    this.setState({
      isFreeUserAccessingPremiumTrack: false,
      isFreeUserAccessingPremiumSfx: false,
      isFreeUserReachedDownloadLimit: false,
    });

    // Check for deals

    if (this.showNotification(this.state.isHomepage)) {
      this.props.showNotificationSuccess(true);
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      onRemoveLicense,
      license,
      userAuth,
      onAddDownloadIntent,
      onAddPremiumDownloadIntent,
      onAddLicense,
      downloadTrackInfo,
      updateDownloadData,
    } = nextProps;

    const isNonLoggedInUserDownloadTrack =
      userAuth && !userAuth.isLoggedIn && license && license.isDownload;
    const isNonLoggedInUserDownloadPremiumTrack =
      userAuth &&
      !userAuth.isLoggedIn &&
      license &&
      license.isDownload &&
      license &&
      license.isDownload &&
      license.track &&
      license.track.is_premium;
    const isNonLoggedInUserDownloadSfx =
      userAuth &&
      !userAuth.isLoggedIn &&
      license &&
      license.track &&
      license.track.is_sfx;
    const isUnVerifiedUserDownloadTrack =
      userAuth &&
      userAuth.isLoggedIn &&
      !userAuth.isEmailVerified &&
      license &&
      license.isDownload;
    const isFreeUserAccessingPremiumTrack =
      userAuth &&
      userAuth.isLoggedIn &&
      !userAuth.isPremium &&
      license &&
      license.isDownload &&
      license.track &&
      license.track.is_premium;
    const isFreeUserAccessingPremiumSfx =
      userAuth &&
      userAuth.isLoggedIn &&
      !userAuth.isPremium &&
      license &&
      license.isDownload &&
      license.track &&
      license.track.is_premium &&
      license.track.is_sfx;

    const premiumTrackUpsellHeading = {
      text: ['Access premium', 'tracks with'],
      showPremiumStar: true,
      note: false,
    };

    const soundEffectsUpsellHeading = {
      text: ['Access premium sound effects with'],
      showPremiumStar: true,
      note: false,
    };

    if (isNonLoggedInUserDownloadPremiumTrack) {
      this.setState({ isFreeUserAccessingPremiumTrack });
      this.props.setCurrentModal(AccountModalNames.GoPremiumTrackDownload);

      onAddPremiumDownloadIntent(license.track);
      onRemoveLicense();
      addConversionPoint(
        'goPremiumDlPremium',
        'goPremium_premiumTrack',
        license.track.id,
      );
    } else if (isFreeUserAccessingPremiumSfx) {
      this.setState({ isFreeUserAccessingPremiumSfx });
      this.props.setCurrentModal(AccountModalNames.GoPremiumSFXDownload);
      onAddPremiumDownloadIntent(license.track);
      onRemoveLicense();
      addConversionPoint('goPremiumDlSfx', 'goPremium_sfxDl', license.track.id);
    } else if (isNonLoggedInUserDownloadTrack || isNonLoggedInUserDownloadSfx) {
      const redirectModal = this.context.userStateContext.userState.hasLoggedIn
        ? AccountModalNames.Login
        : AccountModalNames.SignUp;
      if (!license?.track?.isGoogle) {
        addConversionPoint('downloadTrack', 'downloadTrack');
        this.props.setCurrentModal(redirectModal);
        onAddDownloadIntent(license.track);
        onRemoveLicense();
      }
    } else if (
      isUnVerifiedUserDownloadTrack
    ) {
      this.setState({ isUnVerifiedUserDownloadTrack });
      this.props.setCurrentModal(AccountModalNames.VerifyEmail);
      onAddDownloadIntent(license.track);
      onRemoveLicense();
    } else if (
      isFreeUserAccessingPremiumTrack
    ) {
      this.setState({
        isFreeUserAccessingPremiumTrack,
        lastAccessedTrackId: license.track.id,
      });
      this.props.setCurrentModal(AccountModalNames.GoPremiumTrackDownload);
      onAddPremiumDownloadIntent(license.track);
      onRemoveLicense();
      addConversionPoint(
        'goPremiumDlPremium',
        'goPremium_premiumTrack',
        license.track.id,
      );
    }
    else if (
      license &&
      license.track &&
      license.track.is_sfx &&
      !license.active
    ) {
      if (userAuth.isPremium || userAuth.creditsCurrent > 0) {
        if (isFreeUserAccessingPremiumSfx) {
          this.setState({
            isFreeUserAccessingPremiumSfx,
            lastAccessedTrackId: license.track.id,
          });
          this.props.setCurrentModal(AccountModalNames.GoPremiumSFXDownload);
          onAddPremiumDownloadIntent(license.track);
          onRemoveLicense();
        } else {
          onAddLicense(
            license.track,
            false,
            license.viewCredit,
            license.recaptchaVersion,
            license.recaptchaToken,
          );

          requestSfxLicense(
            license.track.variant_id ?? license.track.id,
            1,
            license.track.current_context.id,
            getAuthToken(),
          )
            .then(async (data) => {
              if (data && data.remaining_credits !== undefined) {
                this.props.updateRemainingCredits(data.remaining_credits);
              }

              if (data && data.success) {
                let downloadMp3 = '';

                if (data && data.download && data.download[0]) {
                  if (this.props.shouldUseFastlyDownload) {
                    const { sfx_license_id, sfx_variant_id, format } =
                      data.download[0];

                    const searchParams = new URLSearchParams({
                      sfx_license_id,
                      sfx_variant_id,
                      format: this.props.fileFormat === FileFormat.WAV ? 'wav' : 'mp3'
                    });

                    // Get the CDN download URL
                    const res = await api(
                      `authentication/asset/sfx/GetAssetAuthorisation?${searchParams.toString()}`,
                      {
                        method: 'GET',
                        credentials: 'include',
                      },
                      {
                        skipCdn: true,
                      },
                    );

                    if (!res.auth_token) {
                      toast.error(
                        'Download failed. Please contact our support team.',
                        {
                          action: {
                            label: 'Contact Us',
                            onClick: () => {
                              this.props.router.push('/help-center/contact-us');
                            },
                          },
                        },
                      );
                      Sentry.captureException(
                        'SFX download auth token not set: ',
                        res,
                      );
                      return;
                    }

                    downloadMp3 = `${res.asset_url}?auth=${res.auth_token}`;
                  }

                  if (!downloadMp3) {
                    // Get the CDN download URL
                    downloadMp3 = data.download[0].download_mp3;
                  }

                  this.setState({ downloadMp3 });
                  window.open(downloadMp3, '_self');
                  this.props.setFileFormat(null);
                }

                trackSFXDownload();

                trackInternalEvent({
                  type: 'download',
                });
              } else {
                this.props.generateLicenseCodeError(
                  data?.error?.message,
                  data?.error?.code,
                );
              }
              this.props.downloadTrackInfo({});
              void this.props.queryClient?.refetchQueries({
                queryKey: userMetadataQueryKey,
              });
            })
            .catch((err) => console.error(err));

          sfxDownload(license.track.variant_id ?? license.track.id);
        }
      } else {
        let isFreeUserReachedDownloadLimit = true;
        addConversionPoint('goPremiumDlLimit', 'goPremium_downloadLimit');
        this.setState({ isFreeUserReachedDownloadLimit });
        this.props.setCurrentModal(AccountModalNames.GoPremiumNoCredits);
        onRemoveLicense();
      }
    } else if (
      license &&
      license.track &&
      license.isDownload &&
      !license.active
    ) {
      if (userAuth.isPremium || userAuth.creditsCurrent > 0) {
        onAddLicense(
          license.track,
          false,
          license.viewCredit,
          license.recaptchaVersion,
          license.recaptchaToken,
        );

        requestSubscriptionLicense(
          license.track.id,
          1,
          license.track.current_context.id,
          getAuthToken(),
          license.recaptchaVersion, // version
          license.recaptchaToken,
        )
          .then(async (data) => {
            if (data && data.remaining_credits !== undefined) {
              this.props.updateRemainingCredits(data.remaining_credits);
            }

            if (data && data.success) {
              let downloadMp3 = '';

              if (data && data.download && data.download[0]) {
                if (this.props.shouldUseFastlyDownload) {
                  const { license_id, track_version_id, format } =
                    data.download[0];

                  const searchParams = new URLSearchParams({
                    license_id,
                    track_version_id,
                    format:
                      this.props.fileFormat === FileFormat.WAV ? 'wav' : 'mp3',
                  });

                  // Get the CDN download URL
                  const res = await api(
                    `authentication/asset/track/getassetauthorisation?${searchParams.toString()}`,
                    {
                      credentials: 'include',
                      method: 'GET',
                    },
                    {
                      skipCdn: true,
                    },
                  );

                  if (!res.auth_token || !res.asset_url) {
                    toast.error(
                      'Download failed. Please contact our support team.',
                      {
                        action: {
                          label: 'Contact Us',
                          onClick: () => {
                            this.props.router.push('/help-center/contact-us');
                          },
                        },
                      },
                    );
                    Sentry.captureException(
                      'Download auth token or asset URL not set: ',
                      res,
                    );
                  } else {
                    // If there's no error, set the download URL to the CDN download URL.
                    downloadMp3 = `${res.asset_url}?auth=${res.auth_token}`;
                  }
                }

                // If downloadMp3 is not set, get the CDN download URL.
                // Remove this once we're confident in Fastly downloads.
                if (!downloadMp3) {
                  // Get the CDN download URL
                  downloadMp3 = data.download[0].download_mp3;
                }

                if (data.needsDownloadCode && data.licenseId) {
                  await this.getGeneratedLicenseCode(data.licenseId);
                }

                this.setState({ downloadMp3 });
                window.open(downloadMp3, '_self');
                this.props.setFileFormat(null);;
              }

              trackInternalEvent({
                type: 'download',
              });

              if (!downloadMp3 || downloadMp3 === '') {
                console.debug(data);
                Sentry.captureException(
                  `Download URL not set in RSL: ${JSON.stringify(data)}`,
                );
              }

              trackDownloadEvent();

              downloadTrackInfo(data);
              updateDownloadData(data);
            } else {
              this.props.generateLicenseCodeError(
                data?.error?.message,
                data?.error?.code,
              );
            }
          })
          .catch((err) => console.error(err));
        trackDownload(license.track.id);
      } else {
        let isFreeUserReachedDownloadLimit = true;
        addConversionPoint('goPremiumDlLimit', 'goPremium_downloadLimit');
        this.setState({ isFreeUserReachedDownloadLimit });
        this.props.setCurrentModal(AccountModalNames.GoPremiumNoCredits);
        onRemoveLicense();
      }
    }
  }

  getGeneratedLicenseCode = async (licenseId) => {
    this.props.generateLicenseCodeStart();
    return generateLicenseCode(licenseId)
      .then((response) => {
        if (response.success) {
          void this.props.queryClient?.refetchQueries({
            queryKey: userMetadataQueryKey,
          });
          if (isSafari() && this.state.downloadMp3) {
            if (!this.state.downloadMp3 || this.state.downloadMp3 === '') {
              Sentry.captureException(`Download URL not set in GGLC`);
            }

            window.open(this.state.downloadMp3, '_self');
          }
          return this.props.downloadTrackInfo(response);
        } else if (!response.success) {
          if (response.isCalling) {
            this.getGeneratedLicenseCode(licenseId);
          } else {
            return this.props.generateLicenseCodeError(response);
          }
        }
      })
      .catch((err) => console.error(err));
  };

  componentWillUnmount() {
    clearInterval(this.versionChecker);
    window.removeEventListener('focus', this.onFocus);
  }

  handleAddModal = (event) => {
    // this.props.disableKeyboardControls(); // Keyboard-control logic
    // event && event.preventDefault();

    let modalType;
    if (typeof event === 'object') {
      modalType = event.target.id;
      this.props.buttonToModal(event.target.id);
    } else if (typeof event === 'string') {
      modalType = event;
      this.props.buttonToModal(event);
    } else {
      return;
    }

    this.props.onAddModal(modalType);
  };

  handleRemoveModal = async () => {
    const {
      userAuth,
      downloadIntent,
      premiumDownloadIntent,
      ubModalStep,
      router,
    } = this.props;

    const isUnVerifiedUserDownloadTrack =
      userAuth && userAuth.isLoggedIn && !userAuth.isEmailVerified;

    // this.props.enableKeyboardControls() // Keyboard-control logic

    this.props.onRemoveModal();
    this.props.setLoginFinish(false);
    this.props.showSignupError(false);
    this.props.setGoogleLoginFailure(false);
    this.props.forgotPassword(false);
    this.props.chooseLogin(false);
    this.props.accountTerm('yearly');
    this.props.pricingPagePath(undefined);
    this.props.resetCurrentStep();
    this.props.accountTypePremium();
    this.props.framelessPlaylist();
    this.props.buttonToModal(undefined);

    let recaptchaToken = await checkRecaptchaV3('trackdownload');

    // if any free or premium user is logged and has a free or premium track queued to download, run this block
    if (isUnVerifiedUserDownloadTrack) {
      this.props.onClearDownloadIntent();
      this.props.onClearPremiumDownloadIntent();
    } else if (
      userAuth.isLoggedIn &&
      (downloadIntent.track || premiumDownloadIntent.track)
    ) {
      if (userAuth.isPremium && premiumDownloadIntent.track) {
        this.props.onAddLicense(
          premiumDownloadIntent.track,
          true,
          false,
          3,
          recaptchaToken,
        );
        this.props.onClearPremiumDownloadIntent();
      } else if (!userAuth.isPremium && premiumDownloadIntent.track) {
        this.props.onClearPremiumDownloadIntent();
      } else {
        this.props.onAddLicense(
          downloadIntent.track,
          true,
          false,
          3,
          recaptchaToken,
        );
        this.props.onClearDownloadIntent();
      }
    } else {
      this.props.onClearDownloadIntent();
      this.props.onClearPremiumDownloadIntent();
    }

    // this.props.clearGoogleLoginFlag() // TODO: Fix this
    this.setState({
      isFreeUserAccessingPremiumTrack: false,
      isFreeUserAccessingPremiumSfx: false,
      isFreeUserReachedDownloadLimit: false,
    });
  };

  showNotification() {
    // Currently no notifications to display.
    return false;

    // Uncomment when new notification in place
    // return localStorage.getItem('header_alert_dismissed') !== this.notificationVersion;
  }

  onSetupSite = () => {
    const data = this.context.settingsContext?.settings;

    if (!data) {
      return;
    }

    if (data.status === 500) {
      removeAuthToken();
      this.props.onSetLoginStatus(false);
      return;
    }

    if (data.status !== 'success') {
      return;
    }

    if (data?.subscriptionData?.isActive) {
      sessionStorage.setItem('is_premium_track_list', '1');
    } else {
      sessionStorage.setItem('is_premium_track_list', '0');
    }

    if (data.siteVersion) {
      this.setState({ siteVersion: data.siteVersion });
    }

    this.props.setPremiumStatus(data && data.isPremium);

    if (data.credits) {
      if (data.credits.ab_seed) {
        this.context.userStateContext.setUserState(
          'abSeed',
          data.credits.ab_seed,
        );
      }

      if (data.credits.ab_timestamp) {
        let savedTs = Math.floor(
          new Date(data.credits.ab_timestamp).getTime() / 1000,
        );
        this.context.userStateContext.setUserState('abTimestamp', savedTs);
      }
    }

    if (data.user.is_authenticated) {
      this.context.userStateContext.setUserState('hasLoggedIn', true);

      if (typeof vgo === 'function' && data?.user?.email) {
        vgo('setEmail', data.user.email);
        vgo('process');
      }
    }

    this.props.addSubscriptionToCart(data.plan_data);

    // this.props.determinePlanDataCurrency(this.props.currency.code, data.plan_data);
    // if (data?.chatgpt?.remaining < 0) {
    //     data.chatgpt.remaining = 0;
    // }

    // // Add user data to redux store.
    // this.props.loadUserData({
    //     ...data.user,
    //     isPaymentTabDisplay: data.isPaymentTabDisplay,
    //     isSubscriptionTabDisplay: !isEmpty(data.subscriptionData),
    //     isPremium: data.isPremium,
    //     isBusiness: data.isBusiness,
    //     credits: data.credits,
    //     planData,
    //     fullName,
    //     aiCredits: data.chatgpt,
    // });

    this.props.downloadTrackInfo({
      download_count: data.user.download_count,
      remaining_downloads: data.user.credits?.credits_current,
      total_downloads: data.user.total_downloads,
      creditsCurrent: data.user.credits?.credits_current,
    });

    if (data.user.hasAccountAdmin && data.admin) {
      this.props.loadAdminData(data.admin);
    }

    if (data.user.is_authenticated) {
      this.setState({
        userAuthKey: data.user.auth_token,
        userDisplayName: data.user.company
          ? data.user.company
          : data.user.firstName,
        userHasAdminAccount: data.user.hasAccountAdmin,
        userHasCustomerAccount: data.user.hasAccountCustomer,
        userHasArtistAccount: data.user.hasAccountArtist,
      });

      this.props.onSetLoginStatus(true);
      this.props.setEnteredProfession(data.enteredProfession);

      // Not too sure why we need to set the auth token here?
      if (data.user.auth_token) {
        setAuthToken(data.user.auth_token);
      }
    } else {
      this.props.onSetLoginStatus(false);
      sessionStorage.removeItem('user_is_authenticated');
    }

    const goPremium = this.props.router.query.gopremium;
    const deal = this.props.router.query.deal;

    if (goPremium && !data.subscriptionData.isActive) {
      addConversionPoint('goPremiumExternal', 'goPremium_external');

      const { onAddModal } = this.props;

      onAddModal('premium-checkout');
    } else if (deal && !data.subscriptionData.deal) {
      // addAnalytics('goPremiumExternal', 'goPremium_external')

      const { onAddModal } = this.props;

      onAddModal('deals-overview');
    }

    return data;
  };

  handleLogin = (requestIntent) => {
    makeLoginAttempt({ requestIntent }).then((response) => {
      this.onSetupSite();

      if (this.props.loginFlow.finishAction) {
        this.handleAddModal(this.props.loginFlow.finishAction);
      } else {
        this.handleRemoveModal();
        this.props.showSigninSuccess(this.state.userDisplayName);
      }
    });
  };

  onAbandonCheckout = () => {
    // Following the logic inside UBModalPremium.
    // Can be either step 3 or 5.
    const isAtCheckout =
      (this.props.ubModalStep.currentStep === 3 ||
        this.props.ubModalStep.currentStep === 5) &&
      !this.props.subscriptionData?.overduePayment &&
      !this.props.ubModalStep.forgotPassword;

    if (isAtCheckout) {
      trackEvent('checkoutabandonment', 'Checkout Abandonment');
    }
  };

  render() {
    const { onRemoveLicense, license, userAuth, router } = this.props;
    const {
      isFreeUserAccessingPremiumTrack,
      isFreeUserAccessingPremiumSfx,
      lastAccessedTrackId,
      isUnVerifiedUserDownloadTrack,
      isFreeUserReachedDownloadLimit,
    } = this.state;

    let NewVersionPromptMsg = '';

    return (
      <>
        <TopBarHeader>
          <TopBarContent isFree={this.props.isFree} />
          {this.props.headerNotifications.track && (
            <div id="playlist-tooltip" className="playlist-success_tooltip">
              <div className="playlist-success_left">
                <Image
                  src={
                    this.props.headerNotifications.track.artist.avatar_image
                      .image_aws_url
                  }
                  width={80}
                  height={80}
                  sizes="80px"
                  alt="Hunt"
                />
              </div>
              <div className="playlist-success_right">
                <div className="playlist-success_right--inner">
                  <h3>
                    <T>{this.props.headerNotifications.track.name}</T>
                  </h3>
                  <p className="playlist-success_added">
                    <T>
                      {this.props.headerNotifications.newPlaylist
                        ? 'Added to new playlist'
                        : 'Added to playlist'}
                    </T>
                    :
                  </p>
                  <p className="playlist-success_playlist">
                    {this.props.headerNotifications.playlistName}
                  </p>
                </div>
              </div>
            </div>
          )}
        </TopBarHeader>

        {license.active &&
          userAuth?.isLoggedIn &&
          !isFreeUserAccessingPremiumTrack && (
            <>
              {license.isGenerateLicenseCodeHasErrorCode === 10 ||
              license.isGenerateLicenseCodeHasErrorCode === 11 ||
              license.isGenerateLicenseCodeHasErrorCode === 13 ? (
                <UBPopupRecaptcha
                  onRemoveModal={onRemoveLicense}
                  onAddLicense={this.props.onAddLicense}
                />
              ) : (
                <UBPopupDownload onRemoveModal={onRemoveLicense} />
              )}
            </>
          )}

        {this.props.playlist.active && this.props.playlistFrame.framed && (
          <SideDrawer
            isPlayListModal
            isCloseButton
            onRemoveModal={this.handleRemoveModal}
          >
            <PlaylistModal
              onRemoveModal={this.handleRemoveModal}
              playlist={this.props.playlist}
              t={this.props.t}
            />
          </SideDrawer>
        )}

        {this.props.modal.type === 'removeStyle' && (
          <RemoveStyleModal
            track={this.props.modal.intent}
            onRemoveModal={this.handleRemoveModal}
          />
        )}

        {this.props.modal.type == 'track-menu' && (
          <SideDrawer onRemoveModal={this.handleRemoveModal}>
            <UBTrackModal
              onRemoveModal={this.handleRemoveModal}
              onAddModal={this.handleAddModal}
            />
          </SideDrawer>
        )}
        {NewVersionPromptMsg}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCreditsCurrent: (creditsCurrent) =>
    dispatch(updateCreditsCurrent(creditsCurrent)),
  onAddModal: (modal, intent, heading) =>
    dispatch(addModal(modal, intent, heading)),
  onAddDownloadIntent: (track) => dispatch(addDownloadIntent(track)),
  onAddPremiumDownloadIntent: (track) =>
    dispatch(addPremiumDownloadIntent(track)),
  onRemoveModal: () => dispatch(removeModal()),
  enableKeyboardControls: () => dispatch(enableKeyboardControls()),
  disableKeyboardControls: () => dispatch(disableKeyboardControls()),
  showSigninSuccess: (firstName) => {
    let timer = setTimeout(() => {
      dispatch(showSigninSuccess(firstName));
      timer = setTimeout(() => {
        dispatch(animateInTooltip());
        timer = setTimeout(() => {
          dispatch(animateOutTooltip());
          timer = setTimeout(() => {
            dispatch(hideSigninSuccess());
          }, 400);
        }, 4000);
      }, 10);
    }, 400);
  },
  showNotificationSuccess: (timer) => {
    if (timer) {
      [this || {}].timer = setTimeout(() => {
        dispatch(showNotificationSuccess(timer));
        [this || {}].timer = setTimeout(() => {
          dispatch(animateInTooltip());
        }, 10);
      }, 4000);
    } else {
      dispatch(showNotificationSuccess());
      dispatch(animateInTooltip());
    }
  },
  hideNotificationSuccess: () => {
    dispatch(animateOutTooltip());
    dispatch(hideNotificationSuccess());
  },
  addSubscriptionToCart: (plan) => dispatch(addSubscriptionToCart(plan)),
  loadAdminData: (adminData) => dispatch(loadAdminData(adminData)),
  loadSubscriptionData: (subscriptionData, isLoggedIn) =>
    dispatch(loadSubscriptionData(subscriptionData, isLoggedIn)),
  loadCurrency: (currency) => dispatch(loadCurrency(currency)),
  loadCountry: (country) => dispatch(loadCountry(country)),
  onSetLoginStatus: (status) => dispatch(setLoginStatus(status)),
  setPremiumStatus: (status) => dispatch(setPremiumStatus(status)),
  setEnteredProfession: (status) => dispatch(setEnteredProfession(status)),
  onRemovePlaylist: () => dispatch(removePlaylist()),
  setGoogleLoginSuccess: (status) => dispatch(setGoogleLoginSuccess(status)),
  clearGoogleLoginFlag: () => dispatch(clearGoogleLoginFlag()),
  SignUpSuccess: (status) => dispatch(SignUpSuccess(status)),
  onRemoveLicense: () => dispatch(removeLicense()),
  onAddLicense: (
    track,
    isDownload,
    viewCredit,
    recaptchaVersion,
    recaptchaToken,
  ) => {
    dispatch(
      addLicense(
        track,
        isDownload,
        viewCredit,
        recaptchaVersion,
        recaptchaToken,
      ),
    );
  },
  downloadTrackInfo: (data) => dispatch(downloadTrackInfo(data)),
  generateLicenseCodeError: (error, code) =>
    dispatch(generateLicenseCodeError(error, code)),
  generateLicenseCodeStart: () => dispatch(generateLicenseCodeStart()),
  setLoginFinish: (finishAction) => dispatch(setLoginFinish(finishAction)),
  onClearDownloadIntent: () => dispatch(clearDownloadIntent()),
  onClearPremiumDownloadIntent: () => dispatch(clearPremiumDownloadIntent()),
  resetCurrentStep: () => {
    dispatch(resetCurrentStep());
  },
  accountTypePremium: () => {
    dispatch(accountTypePremium());
  },
  showSignupError: (error) => dispatch(showSignupError(error)),
  setGoogleLoginFailure: (message) => dispatch(setGoogleLoginFailure(message)),
  forgotPassword: (boolean) => dispatch(forgotPassword(boolean)),
  chooseLogin: (boolean) => dispatch(chooseLogin(boolean)),
  accountTerm: (string) => dispatch(accountTerm(string)),
  increaseCurrentStep: (number) => {
    dispatch(increaseCurrentStep(number));
  },
  pricingPagePath: (string) => {
    dispatch(pricingPagePath(string));
  },
  onAcquisitionClick: (code, type) => dispatch(acquisitionClick(code, type)),
  hideSidebar: () => dispatch(hideSidebar()),
  showSidebar: () => dispatch(showSidebar()),
  framelessPlaylist: () => dispatch(framelessPlaylist()),
  updateRemainingCredits: (creditsCurrent) =>
    dispatch(updateRemainingCredits(creditsCurrent)),
  buttonToModal: (state) => dispatch(buttonToModal(state)),
});

const mapStateToProps = (state) => ({
  userAuth: state.userAuth,
  modal: state.modal,
  license: state.license,
  playlist: state.playlist,
  playlistFrame: state.playlistFrame,
  signup: state.signup,
  loginFlow: state.loginFlow,
  sidebarCollapsed: state.sidebarCollapsed,
  headerNotifications: state.headerNotifications,
  downloadIntent: state.downloadIntent,
  premiumDownloadIntent: state.premiumDownloadIntent,
  ubModalStep: state.ubModalStep,
  currency: state.currency,
  sidebarCollapsed: state.sidebarCollapsed,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
