export enum CreateAccountModalScreenName {
  Free = 'create-account-free',
  Premium = 'create-account-premium',
  Business = 'create-account-business',
  CheckoutPremium = 'create-account-checkout-premium',
  CheckoutBusiness = 'create-account-checkout-business',
  WhatsYourThemeFree = 'create-account-whats-your-theme-free',
  WhatsYourThemePremium = 'create-account-whats-your-theme-premium',
  WhatsYourThemeBusiness = 'create-account-whats-your-theme-business',
  WhatsYourVibeFree = 'create-account-whats-your-vibe-free',
  WhatsYourVibePremium = 'create-account-whats-your-vibe-premium',
  WhatsYourVibeBusiness = 'create-account-whats-your-vibe-business',
  WelcomeToUppbeat = 'create-account-welcome-to-uppbeat',
}

export enum AccountType {
  Free = 'free',
  Premium = 'premium',
  Business = 'business',
}

export interface ContentType {
  name: string;
  id: number;
  icon: string;
  rank: number;
}

export interface VibeType {
  name: string;
  id: number;
  icon: string;
  rank: number;
}
