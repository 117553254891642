import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

import SteppedDialog from '@/components/SteppedDialog';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';

import type { HTMLMotionProps } from 'framer-motion';

/*
 * Modal Screen component
 * This component is used to define a screen within a modal, it is used to
 * register the screen with the ScreenContext and conditionally render the
 * screen based on the current screen.
 * @param {string} screenName - The name of the screen
 * @param {HTMLMotionProps<'div'>} props - Props to pass to the motion.div component
 * @returns {JSX.Element} Screen component
 * @example
 * <Modal.Screen screenName="screen-1">Screen 1</Modal.Screen>
 * @see /src/components/SteppedDialog/index.tsx
 */
const Screen = ({
  screenName,
  children,
  ...props
}: { screenName: string } & HTMLMotionProps<'div'>) => {
  const { currentScreen, registerScreen } = useScreenContext();

  useEffect(() => {
    registerScreen(screenName);
  }, [screenName, registerScreen]);

  const isVisible = currentScreen === screenName;

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {isVisible && (
        <motion.div
          key={screenName}
          data-dialog-screen={screenName}
          data-state={isVisible ? 'open' : 'closed'}
          {...props}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Modal = {
  ...SteppedDialog,
  Screen,
};

export default Modal;
