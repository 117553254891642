import { useEffect, useState } from 'react';

import { FacebookPixel } from './FacebookPixel';
import { GTag } from './GTag';
import { Hotjar } from './Hotjar';
import { MicrosoftUET } from './MicrosoftUET';
import { TikTokPixel } from './TikTokPixel';

export const Tracking = () => {
  const [allowsTracking, setAllowsTracking] = useState(false);

  useEffect(() => {
    const onAccept = () => {
      if (window.Cookiebot?.consent.marketing) {
        setAllowsTracking(true);

        if (window.fbq) {
          window.fbq('consent', 'grant');
        }

        // UET
        window.uetq = window.uetq ?? [];
        window.uetq.push('consent', 'update', {
          ad_storage: 'granted',
        });
      }
    };

    const onDecline = () => {
      if (!window.Cookiebot?.consent.marketing) {
        setAllowsTracking(false);
      }
    };

    window.addEventListener('CookiebotOnAccept', onAccept);
    window.addEventListener('CookiebotOnDecline', onDecline);

    return () => {
      window.removeEventListener('CookiebotOnAccept', onAccept);
      window.removeEventListener('CookiebotOnDecline', onDecline);
    };
  }, []);

  return (
    <>
      <GTag />
      <FacebookPixel />
      <MicrosoftUET />
      {allowsTracking && <Hotjar />}
      {allowsTracking && <TikTokPixel />}
    </>
  );
};
