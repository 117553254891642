import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '@/api/legacyApi';
import { fallbackSettings } from '@/api/setup/setup-frontend';
import { getAuthToken } from '@/auth';

import { CACHE_KEY, useSettings } from './useSettings';

import type { Country } from '@/types/countries';
import type { Settings } from '@/types/settings';
import type { UseMutationResult } from '@tanstack/react-query';

interface MappedCountry {
  key: string;
  text: Country['name'];
  value: Country['id'];
  vat_rate: Country['vat_rate'];
  iso_code_2: Country['iso_code2'];
  currency: Country['currency'];
}

interface ReturnType<T> {
  countries: T;
  error: unknown;
  isFetching: boolean;
  mutation: UseMutationResult<void, unknown, MappedCountry | Country>;
}

const updateCountry = async (country: MappedCountry | Country) => {
  const apiUrl = `${process.env.NEXT_PUBLIC_API}api-customer/set-country`;
  const formData = new FormData();

  if ('value' in country) {
    formData.append('country', country.value.toString());
  } else {
    formData.append('country', country.id.toString());
  }

  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      token: getAuthToken() ?? '',
    },
    body: formData,
  });

  const { success, message } = (await response.json()) as {
    success: boolean;
    message: string;
  };

  if (!success) {
    throw new Error(message);
  }
};

export const useCountries = <
  T extends 'raw' | undefined = undefined,
  R = T extends null | undefined
    ? ReturnType<MappedCountry[]>
    : ReturnType<Country[]>,
>(
  type?: T,
): R => {
  const queryClient = useQueryClient();
  const { invalidateSettings } = useSettings();

  const {
    data: countries,
    error,
    isFetching,
  } = useQuery<MappedCountry[] | Country[]>({
    queryKey: ['countries', type],
    queryFn: async () => {
      const res = await api<Country[]>('countries');

      if (type === 'raw') {
        return res;
      }

      // Map the countries to the format needed for the select component.
      return res?.map((country) => ({
        key: `country-${country.id}`,
        text: country.name,
        value: country.id,
        vat_rate: country.vat_rate / 100,
        iso_code_2: country.iso_code2,
        currency: country.currency,
      })) as MappedCountry[];
    },
  });

  const mutation = useMutation({
    mutationFn: updateCountry,
    onMutate: async (newCountry: MappedCountry | Country) => {
      // Optimistically update the cache
      await queryClient.cancelQueries({ queryKey: [CACHE_KEY] });

      const isMappedCountry = 'value' in newCountry;

      const country = {
        id: isMappedCountry ? newCountry.value : newCountry.id,
        iso_code_2: isMappedCountry
          ? newCountry.iso_code_2
          : newCountry.iso_code2,
        vat_rate: newCountry.vat_rate,
      } satisfies Settings['country'];

      queryClient.setQueryData<Settings>(CACHE_KEY, (oldSettings) => ({
        ...(oldSettings ?? fallbackSettings),
        country,
      }));
    },
    onSettled: invalidateSettings,
  });

  return {
    countries: countries ?? [],
    error,
    isFetching,
    mutation,
  } as R;
};
