import { ReactQueryDevtoolsPanel } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { Suspense } from 'react';

const ReactQueryDevtoolsPanelProduction = dynamic(
  () =>
    // eslint-disable-next-line import/extensions
    import('@tanstack/react-query-devtools/build/modern/production.js').then(
      (d) => d.ReactQueryDevtoolsPanel,
    ),
  { ssr: false },
);

export const ReactQueryDevTools = ({ onClose }: { onClose: () => void }) => {
  if (process.env.NODE_ENV === 'production') {
    return (
      <Suspense
        fallback={<div className="w-full h-full bg-red-500">Loading...</div>}
      >
        <ReactQueryDevtoolsPanelProduction />
      </Suspense>
    );
  }

  return <ReactQueryDevtoolsPanel onClose={onClose} />;
};
