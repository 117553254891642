import { useCallback, useEffect, useState } from 'react';

export enum RecaptchaVersion {
  V2 = '2',
  V3 = '3',
}

const useRecaptcha = (action: string) => {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState<boolean>(false);
  const [recaptchaVersion, setRecaptchaVersion] = useState<RecaptchaVersion>(
    RecaptchaVersion.V3,
  );

  const [recaptchaV3Token, setRecaptchaV3Token] = useState<string | null>(null);
  const [recaptchaV2Token, setRecaptchaV2Token] = useState<string | null>(null);

  // Check if reCAPTCHA is loaded
  useEffect(() => {
    setRecaptchaLoaded(!!window.grecaptcha);

    // Load reCAPTCHA if it's not loaded
    if (!window.grecaptcha) {
      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}`;
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);

      script.onload = () => {
        setRecaptchaLoaded(true);
      };
    }
  }, []);

  // Check reCAPTCHA v3
  const checkRecaptchaV3 = useCallback(() => {
    return new Promise<string>((resolve) => {
      if (!recaptchaLoaded) {
        resolve('');
      }

      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(
          process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY,
          { action },
        );

        setRecaptchaV3Token(token);
        resolve(token);
      });
    });
  }, [action, recaptchaLoaded]);

  // Check reCAPTCHA v2
  const checkRecaptchaV2 = useCallback(() => {
    return new Promise<string>((resolve) => {
      if (!recaptchaLoaded) {
        resolve('');
      }

      window.grecaptcha.ready(() => {
        window.grecaptcha.render('recaptcha-v2', {
          sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY,
          callback: (token: string) => {
            setRecaptchaV2Token(token);
            resolve(token);
          },
        });
      });
    });
  }, [recaptchaLoaded]);

  useEffect(() => {
    if (recaptchaVersion === RecaptchaVersion.V3) {
      void checkRecaptchaV3();
    } else if (recaptchaVersion === RecaptchaVersion.V2) {
      void checkRecaptchaV2();
    }
  }, [recaptchaVersion, checkRecaptchaV3, checkRecaptchaV2]);

  const recaptchaToken =
    recaptchaVersion === RecaptchaVersion.V3
      ? recaptchaV3Token
      : recaptchaV2Token;

  const setRecaptchaToken =
    recaptchaVersion === RecaptchaVersion.V3
      ? setRecaptchaV3Token
      : setRecaptchaV2Token;

  return {
    recaptchaVersion,
    setRecaptchaVersion,
    recaptchaToken,
    setRecaptchaToken,
  };
};

export default useRecaptcha;
