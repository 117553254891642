import type { GetPageType, TrackEventParams } from './types';

const SESSION_STORAGE_KEY = 'sessionId';
const SESSION_UUID_KEY = 'sessionUuid';

/**
 * Creates a session and stores the session ID in the session storage.
 * @returns {boolean} Returns true if the session was successfully created, false otherwise.
 */
export const createTrackingSession = () => {
  let cryptoUUID;

  // If dev mode, use a pseudo-random UUID.
  // This is due to the fact that the crypto.randomUUID() function will error if https is not used.
  if (process.env.NODE_ENV === 'development') {
    const id = Math.random().toString(36).substring(2, 15);

    return `dev-uuid-${id}`;
  }

  if (typeof window !== 'undefined' && typeof crypto !== 'undefined') {
    // Will error if https is not used.
    try {
      cryptoUUID = crypto.randomUUID();
    } catch (e) {
      console.warn(e);
    }

    return cryptoUUID;
  }

  return null;
};
/**
 * Gets the session ID from the session storage.
 * @returns {string | null} Returns the session ID if it exists, null otherwise.
 */

export const getSessionId = () => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(SESSION_STORAGE_KEY);
  }

  return null;
};

/**
 * Sets the UUID in the session storage.
 * @param uuid - The UUID to be set.
 */
export const setUUID = (uuid: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(SESSION_UUID_KEY, uuid);
  }
};

/**
 * Gets the UUID from the session storage.
 * @returns {string | null} Returns the session ID if it exists, null otherwise.
 */

export const getUUID = () => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem('uuid');
  }

  return null;
};

/**
 * Clears the UUID from the session storage.
 */
export const clearUUID = () => {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(SESSION_UUID_KEY);
  }
};

export const getPageType = (slug: string): GetPageType | null => {
  // SFX Tags
  if (slug.includes('/sfx/tag/')) {
    return {
      assetType: 'sfx',
      contentType: 'Tag',
    };
  }

  // Individual track.
  if (slug.startsWith('/track/')) {
    return {
      assetType: 'music',
      contentType: 'Track',
    };
  }

  // Music Tags
  if (
    slug.includes('/music/tag/') ||
    slug.includes('/music/genre/') ||
    slug.includes('/music/mood/') ||
    slug.includes('/music/theme/') ||
    slug.includes('/music/instrument/')
  ) {
    return {
      assetType: 'music',
      contentType: 'Tag',
    };
  }

  // SFX Collections
  if (slug.includes('/sfx/collection/')) {
    return {
      assetType: 'sfx',
      contentType: 'Collection',
    };
  }

  // SFX Styles
  if (slug.includes('/browse/sfx/')) {
    return {
      assetType: 'sfx',
      contentType: 'Style',
    };
  }

  // Music Styles
  if (slug.includes('/browse/music/')) {
    return {
      assetType: 'music',
      contentType: 'Style',
    };
  }

  // Artist page.
  if (slug.includes('/browse/artist/')) {
    return {
      assetType: 'music',
      contentType: 'Artist',
    };
  }

  // Music Collections
  if (slug.includes('/browse/collection/')) {
    return {
      assetType: 'music',
      contentType: 'Collection',
    };
  }

  // Search page.
  if (slug.includes('/browse/search')) {
    return {
      assetType: 'general',
      contentType: 'Search',
    };
  }

  // Search page.
  if (slug.includes('/playlist/')) {
    return {
      assetType: 'music',
      contentType: 'Playlist',
    };
  }

  // SFX Page
  if (slug.includes('/sfx/')) {
    return {
      assetType: 'sfx',
      contentType: 'Track',
    };
  }

  if (slug.includes('/similar/')) {
    return {
      assetType: 'music',
      contentType: 'Similar',
    };
  }

  if (slug.includes('/ai-playlist-generator')) {
    return {
      assetType: 'music',
      contentType: 'AIPG',
    };
  }

  if (slug.includes('/browse/trending')) {
    return {
      assetType: 'general',
      contentType: 'Trending',
    };
  }

  return null;
};

/**
 * Tracks an event.
 * @param {TrackEventParams} params - The parameters for tracking the event.
 * @returns {Promise<void>} - A promise that resolves when the event is tracked successfully.
 */
export const trackInternalEvent = async ({
  eventId,
  type,
}: TrackEventParams): Promise<void> => {
  const uuid = getUUID();

  // If there is no session ID, do not track.
  if (!uuid) {
    return;
  }

  try {
    await fetch(`${process.env.NEXT_PUBLIC_API}tracking/incrementresolve`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid,
        tracking_event_id: eventId ?? null,
        event_type: type,
      }),
    });
  } catch (e) {
    console.warn(e);
  }
};
