import { createContext, useMemo, useRef } from 'react';

import type { ReactNode, RefObject } from 'react';

interface IPortalContext {
  portalContainerRef?: RefObject<HTMLDivElement>;
}

interface PortalProviderProps {
  children: ReactNode;
}

export const PortalContext = createContext<IPortalContext>({});

/**
 * A provider for attaching portals to in a consistent way.
 */
export const PortalProvider: React.FC<PortalProviderProps> = ({ children }) => {
  const portalContainerRef = useRef<HTMLDivElement>(null);

  const ctx = useMemo(
    (): IPortalContext => ({
      portalContainerRef,
    }),
    [],
  );

  return (
    <PortalContext.Provider value={ctx}>
      {children}
      <div ref={portalContainerRef} />
    </PortalContext.Provider>
  );
};
