import Modal from '@/components/Modals/Core';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';

import { useAddToPlaylist } from './context';
import { AddToPlaylistModalHeader } from './header';
import AddToPlaylistModalScreens from './screens';
import { AddToPlaylistModalScreenName } from './types';

const AddToPlaylistModal = () => {
  const { open, onOpenChange } = useAddToPlaylist();

  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer
          initial={AddToPlaylistModalScreenName.AddToPlaylist}
        >
          <AddToPlaylistModalHeader />
          <AddToPlaylistModalScreens />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default AddToPlaylistModal;
