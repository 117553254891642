import React from 'react';

import { useCountries } from '@/hooks/useCountries';

import Select, { SelectSkeleton } from './Select';

import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface CountrySelectProps<T extends FieldValues>
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: Path<T>;
  register: UseFormRegister<T>;
  hasError?: boolean;
}

const CountrySelect = <T extends FieldValues>({
  name,
  register,
  hasError,
  ...props
}: CountrySelectProps<T>) => {
  const {
    countries,
    mutation: updateCountryMutation,
    isFetching,
  } = useCountries();

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const country = countries.find((c) => c.value === Number(e.target.value));

    if (country) {
      updateCountryMutation.mutate(country);
    }
  };

  if (isFetching) {
    return <SelectSkeleton />;
  }

  return (
    <Select
      name={name}
      register={register}
      hasError={hasError}
      {...props}
      onChange={handleOnChange}
    >
      {countries.map((country) => (
        <option key={country.key} value={country.value}>
          {country.text}
        </option>
      ))}
    </Select>
  );
};

export default CountrySelect;
