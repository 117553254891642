import { MoonStar, SunIcon } from 'lucide-react';
import { useContext } from 'react';

import Tooltip from '@/components/Tooltip';
import { Button } from '@/components/ui/button';
import { ThemeContext } from '@/providers/ThemeProvider';

import ThemeIcon from './ThemeIcon';

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Tooltip
      label={theme === 'dark' ? 'Switch to light mode' : 'Switch to dark mode'}
    >
      <Button
        variant="transparent"
        size="icon"
        borderRadius="full"
        aria-label="Toggle theme"
        onClick={toggleTheme}
        className="relative flex-shrink-0 overflow-hidden active:!bg-transparent active:!border-transparent"
      >
        <ThemeIcon className="opacity-100 -translate-y-1/2 dark:opacity-10 dark:translate-y-12">
          <SunIcon className="size-4 fill-none" />
        </ThemeIcon>
        <ThemeIcon className="opacity-10 translate-y-12 dark:opacity-100 dark:-translate-y-1/2">
          <MoonStar className="size-4 fill-none" />
        </ThemeIcon>
      </Button>
    </Tooltip>
  );
};

export default ThemeToggle;
