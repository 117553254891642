import { useQuery } from '@tanstack/react-query';

import { updateActiveCampaignContact } from '@/api/activecampaign/update-contact';

export const useUpdateActiveCampaign = ({
  authToken,
  refreshInterval,
  refetchOnWindowFocus,
}: {
  authToken?: string;
  refreshInterval: number;
  refetchOnWindowFocus: boolean;
}) => {
  useQuery({
    queryKey: ['auth', authToken],
    queryFn: (): true => {
      updateActiveCampaignContact();

      return true;
    },
    // 30 minutes.
    refetchInterval: refreshInterval * 3,
    // 10 minutes.
    staleTime: refreshInterval * 2,
    refetchOnWindowFocus,
    refetchIntervalInBackground: false,
  });
};
