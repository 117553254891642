import { twMerge } from 'tailwind-merge';

import { Input as NewInput } from '@/components/ui/input';
import { useTranslate } from '@/hooks/useTranslate';

import type { InputProps as NewInputProps } from '@/components/ui/input';
import type {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';

interface InputProps<T extends FieldValues>
  extends Omit<NewInputProps, 'name'> {
  name: Path<T>;
  register?: UseFormRegister<T>;
  hasError?: boolean;
  options?: RegisterOptions<T>;
}

/**
 * @deprecated This component is deprecated. Please use the new Input component from '@/components/ui/input' instead.
 * See the migration guide for more details on how to update your code.
 */
const Input = <T extends FieldValues>({
  className,
  name,
  register,
  hasError = false,
  placeholder = '',
  options,
  ...rest
}: InputProps<T>) => {
  const { t } = useTranslate();

  const { ref, ...registerProps } = register
    ? register(name, options)
    : { ref: undefined };

  return (
    <NewInput
      className={twMerge(
        hasError
          ? 'border-red dark:border-red enabled:hover:border-red dark:enabled:hover:border-red'
          : '',
        className,
      )}
      {...rest}
      {...registerProps}
      ref={ref}
      placeholder={t(placeholder)}
    />
  );
};

export default Input;
