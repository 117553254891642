const INITIAL_STATE = {
	currentStep: 0,
	currentModal: 1,
	signUpType: 'google', // Most users sign up with google so this is default
	accountType: 'premium', // Want people to sign up as premium so this is default
	accountTerm: 'yearly', // Want people to sign up for longer so yearly is default
	checkoutType: 'default',
	userDataContentTypes: [],
	userDataVibeTypes: [],
	chooseLogin: false, // For upsell flow
	forgotPassword: false, // For upsell flow
	chooseSignUp: false, // For upsell flow
	googleUpsellSignin: false, // For upsell flow
	pricingPagePath: undefined, // To determine if premium or free card is selected
	buttonToModal: undefined, // To determine which button the user clicked which opened the first modal
	cancelSubscription: false,
	cancelSubscriptionSecond: false
};

function ubModalStep(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'INCREMENT':
			return Object.assign({}, state, {
				currentStep: state.currentStep + action.number
			});
		case 'DECREMENT':
			return Object.assign({}, state, {
				currentStep: state.currentStep - action.number
			});
		case 'SET_CURRENT_STEP':
			return {
				...state,
				currentStep: action.number
			};
		case 'RESET':
			return Object.assign({}, state, {
				currentStep: 0,
				currentModal: 1,
			});
		case 'GOOGLE_SIGNUP':
			return Object.assign({}, state, {
				signUpType: 'google'
			});
		case 'EMAIL_SIGNUP':
			return Object.assign({}, state, {
				signUpType: 'email'
			});
		case 'PREMIUM_SIGNUP':
			return Object.assign({}, state, {
				accountType: 'premium'
			});
		case 'FREE_SIGNUP':
			return Object.assign({}, state, {
				accountType: 'free'
			});
		case 'TERM':
			return Object.assign({}, state, {
				accountTerm: action.string
			})
		case 'MODAL_NUMBER':
			return Object.assign({}, state, {
				currentModal: action.number
			});
		case 'DEFAULT_CHECKOUT':
			return Object.assign({}, state, {
				checkoutType: 'default',
				accountTerm: 'yearly'
			});
		case 'DEALS_CHECKOUT':
			return Object.assign({}, state, {
				checkoutType: 'deals'
			});
		case 'SET_DATA_CAPTURE_CONTENT_TYPES':
			return Object.assign({}, state, {
				userDataContentTypes: action.contentTypes,
			});
		case 'SET_DATA_CAPTURE_VIBE_TYPES':
			return Object.assign({}, state, {
				userDataVibeTypes: action.vibeTypes,
			});
		case 'FORGOT_PASSWORD':
			return Object.assign({}, state, {
				forgotPassword: action.boolean,
			});
		case 'CHOOSE_LOGIN':
			return Object.assign({}, state, {
				chooseLogin: action.boolean,
			});
		case 'CHOOSE_SIGNUP':
			return Object.assign({}, state, {
				chooseSignUp: action.boolean,
			});
		case 'GOOGLE_UPSELL':
			return Object.assign({}, state, {
				googleUpsellSignin: action.boolean,
			});
		case 'PRICING_PATH':
			return Object.assign({}, state, {
				pricingPagePath: action.string,
			});
		case 'BUTTON_TO_MODAL':
			return Object.assign({}, state, {
				buttonToModal: action.string,
			});
		case 'CANCEL_SUBSCRIPTION':
			return Object.assign({}, state, {
				cancelSubscription: action.boolean,
			});
		case 'CANCEL_SUBSCRIPTION_2':
			return Object.assign({}, state, {
				cancelSubscriptionSecond: action.boolean,
			});
		default:
			return state;
	}
}

export default ubModalStep;
