const playlistFrame = (state = { framed: false }, action) => {
    switch (action.type) {
      case 'FRAMELESS_PLAYLIST':
        return Object.assign({}, state, {
          framed: false
        });
      case 'FRAMED_PLAYLIST':
        return Object.assign({}, state, {
          framed: true
        });
      default:
        return state;
    }
  };

  export default playlistFrame;