import Head from 'next/head';
import { useState } from 'react';

import styles from './styles.module.css';

import type { CSSProperties } from 'react';

type VideoType = 'youtube';

interface FacadeProps {
  id: string;
  videoType: VideoType;
  onClick: () => void;
  length: string;
  title?: string;
  hideTitle?: boolean;
  thumbnailStyle?: CSSProperties;
}

const getPreviewData = (type: VideoType, id: string) => {
  const iframeData = {
    youtube: {
      imageUrl: `https://i.ytimg.com/vi/${id}/sddefault.jpg`,
      preconnect: [
        'https://i.ytimg.com/',
        'https://s.ytimg.com',
        'https://www.youtube.com',
        'https://www.google.com',
      ],
    },
  };

  return iframeData[type] ?? iframeData.youtube;
};

export const Facade: React.FC<FacadeProps> = ({
  videoType = 'youtube',
  id,
  onClick,
  length,
  title,
  hideTitle,
  thumbnailStyle,
}) => {
  const [showPreconnect, setShowPreconnect] = useState(false);

  const { preconnect, imageUrl } = getPreviewData(videoType, id);

  return (
    <div
      className={styles.facadeContainer}
      onPointerOver={() => setShowPreconnect(true)}
      onClick={onClick}
    >
      {showPreconnect && (
        <Head>
          {preconnect.map((url) => (
            <link key={url} rel="preconnect" href={url} />
          ))}
        </Head>
      )}
      <div className={styles.imageContainer}>
        {hideTitle
          ? null
          : title !== undefined && (
              <h3 className={styles.videoTitle}>{title}</h3>
            )}
        <button
          className={styles.playButton}
          type="button"
          aria-label="Play"
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
          </svg>
        </button>

        <div
          className={styles.image}
          style={{ backgroundImage: `url(${imageUrl})`, ...thumbnailStyle }}
        />

        {length !== undefined && (
          <span className={styles.videoLength}>{length}</span>
        )}
      </div>
    </div>
  );
};
