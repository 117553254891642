import * as RadixToolbar from '@radix-ui/react-toolbar';
import { LayoutGroup } from 'framer-motion';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Tooltip from '@/components/Tooltip';

const buttonClassName =
  'relative rounded-full size-8 p-1.5 flex items-center justify-center data-[state=on]:bg-gray-dark-100 aspect-square text-sm font-bold tracking-tighter hover:bg-gray-dark-100 transition-colors';

const TooltipWrapper = ({
  children,
  label,
}: {
  children: React.ReactNode;
  label?: string;
}) => {
  if (!label) {
    return <div>{children}</div>;
  }

  return (
    <Tooltip label={label} delayDuration={200} hideOnFocus={false}>
      <div>{children}</div>
    </Tooltip>
  );
};

const Root = (props: RadixToolbar.ToolbarProps) => {
  const { children } = props;

  return (
    <RadixToolbar.Root {...props}>
      <LayoutGroup>{children}</LayoutGroup>
    </RadixToolbar.Root>
  );
};

const Separator = ({ className }: { className?: string }) => {
  return (
    <RadixToolbar.Separator
      className={twMerge('w-px bg-gray-dark-100 my-2', className)}
    />
  );
};

const ToggleGroup = (
  props:
    | RadixToolbar.ToolbarToggleGroupSingleProps
    | RadixToolbar.ToolbarToggleGroupMultipleProps,
) => {
  const { children, type } = props;

  const asChild = type === 'single';

  return (
    <RadixToolbar.ToggleGroup asChild={asChild} {...props}>
      {children}
    </RadixToolbar.ToggleGroup>
  );
};

const ToggleItem = forwardRef<
  HTMLButtonElement,
  RadixToolbar.ToggleGroupItemProps & { label?: string }
>((props, ref) => {
  const { className, children, label } = props;

  return (
    <TooltipWrapper label={label}>
      <RadixToolbar.ToggleItem
        {...props}
        ref={ref}
        className={twMerge(buttonClassName, className)}
      >
        {children}
      </RadixToolbar.ToggleItem>
    </TooltipWrapper>
  );
});

ToggleItem.displayName = 'ToggleItem';

const Button = (
  props: RadixToolbar.ToolbarButtonProps & { label?: string },
) => {
  const { className, children, label } = props;

  return (
    <TooltipWrapper label={label}>
      <RadixToolbar.Button
        {...props}
        className={twMerge(buttonClassName, className)}
      >
        {children}
      </RadixToolbar.Button>
    </TooltipWrapper>
  );
};

export default {
  Root,
  ToggleGroup,
  ToggleItem,
  Separator,
  Button,
  Link: RadixToolbar.Link,
};
