import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar7Pricing extends React.Component {
    render() {
        const Sidebar7Pricing = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
<g>
\t<path d="M7.27,3.04L5.01,2.81L4.09,0.73c-0.04-0.08-0.15-0.08-0.19,0L2.99,2.81L0.73,3.04
\t\tC0.64,3.05,0.6,3.16,0.67,3.22l1.69,1.52L1.88,6.96C1.87,7.04,1.96,7.11,2.03,7.07L4,5.92l1.97,1.14c0.08,0.04,0.17-0.02,0.15-0.11
\t\tL5.64,4.74l1.69-1.52C7.4,3.16,7.36,3.05,7.27,3.04z"/>
</g>
</svg>
`;
        return (
            <InlineSVG src={Sidebar7Pricing} />
        );
    }
}

export default Sidebar7Pricing;
