import { captureException } from '@sentry/nextjs';
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';

interface Markers {
  track_downloads: number[];
  sfx_variant_downloads: number[];
  favourites_data: number[];
  error: {
    code: number;
    message: string;
    details: string;
  } | null;
}

// 10 seconds stale.
const REFETCH_INTERVAL = 10000;

export const userMetadataQueryKey = ['userMetadata'];

export const getUserMetadata = () => {
  return fetchJson<Markers>('page/user_metadata', {
    cache: 'no-store',
    skipCDN: true,
  });
};

export const useUserMetadata = () => {
  const queryClient = useQueryClient();
  const { settings } = useSettings();

  const userAuth = useAppState('userAuth');

  const {
    data: userMetadata,
    refetch,
    isLoading,
  } = useQuery<Markers>({
    queryKey: [...userMetadataQueryKey, settings?.user?.id],
    queryFn: getUserMetadata,
    refetchOnMount: true,
    staleTime: REFETCH_INTERVAL,
    placeholderData: keepPreviousData,
    enabled: userAuth?.isLoggedIn,
  });

  const { mutate: toggleFavourite } = useMutation({
    mutationFn: async ({
      trackId,
      contextId,
      isFavourite,
    }: {
      trackId: number;
      contextId: number;
      isFavourite: boolean;
    }) => {
      await queryClient.cancelQueries({ queryKey: userMetadataQueryKey });

      const favouritesRef = userMetadata?.favourites_data ?? [];

      const currentFavorites = [...favouritesRef];

      if (!isFavourite) {
        currentFavorites.push(trackId);
      } else {
        const index = currentFavorites.indexOf(trackId);

        if (index !== -1) {
          currentFavorites.splice(index, 1);
        }
      }

      queryClient.setQueryData([...userMetadataQueryKey, settings?.user?.id], {
        ...userMetadata,
        favourites_data: currentFavorites,
      });

      const token = getAuthToken();

      return fetchJson(
        `favourites/${trackId}/tracks/${contextId}/version/context/toggle`,
        {
          token,
        },
      );
    },
    // make sure to _return_ the Promise from the query invalidation
    // so that the mutation stays in `pending` state until the refetch is finished
    onSettled: async () => {
      return refetch();
    },
  });

  if (userMetadata?.error) {
    captureException(userMetadata.error?.details);
  }

  return {
    favouritesData: userMetadata?.favourites_data,
    trackDownloaded: userMetadata?.track_downloads,
    sfxDownloaded: userMetadata?.sfx_variant_downloads,
    toggleFavourite,
    isLoading: isLoading && userAuth?.isLoggedIn,
  };
};
