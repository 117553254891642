import {
  AnimatePresence,
  MotionConfig,
  useAnimation,
  useMotionValue,
} from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

import Modal from '@/components/Modals/Core';
import useTWScreens from '@/hooks/useTWScreens';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';

import Overlay from './components/Overlay';
import SidebarContainer from './components/SidebarContainer';
import { SidebarModalProvider } from './context/SidebarModalContext';

interface SidebarModalProps {
  trigger?: React.ReactNode;
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const SidebarModal = ({
  trigger,
  children,
  open,
  onOpenChange,
}: SidebarModalProps) => {
  const useInternalState = open === undefined || onOpenChange === undefined;

  const [internalOpen, internalOnOpenChange] = useState(false);
  const _open = useInternalState ? internalOpen : open;
  const _onOpenChange = useInternalState ? internalOnOpenChange : onOpenChange;

  const { isSm } = useTWScreens();

  // Control for animating the sidebar
  const controls = useAnimation();
  const x = useMotionValue(0); // Position of the sidebar

  const handleCloseSidebar = (velocity = 0) => {
    void controls
      .start({
        x: '100%',
        transition: {
          duration: Math.min(0.5, Math.max(0.1, Math.abs(velocity / 1000))),
          ease: 'linear',
        },
      })
      .then(() => {
        _onOpenChange(false);
        x.set(0); // Reset the position after closing
      });
  };

  useEffect(() => {
    if (_open) {
      void controls.start({ x: 0 }); // Open the sidebar
    }
  }, [_open, controls]);

  const sidebarModalContextValue = useMemo(
    () => ({
      open: _open,
      onOpenChange: _onOpenChange,
    }),
    [_open, _onOpenChange],
  );

  return (
    <SidebarModalProvider value={sidebarModalContextValue}>
      <Modal.Root open={_open} onOpenChange={_onOpenChange}>
        {!!trigger && <Modal.Trigger asChild>{trigger}</Modal.Trigger>}
        <TNamespaceProvider value="modal">
          <AnimatePresence>
            {_open && (
              <Modal.Portal forceMount>
                <MotionConfig
                  // Apple-like transition
                  transition={{
                    duration: 0.35,
                    ease: [0.25, 0.1, 0.25, 1.0],
                  }}
                  // Honor reduced motion
                  reducedMotion="user"
                >
                  <Overlay />
                  <SidebarContainer
                    handleCloseSidebar={handleCloseSidebar}
                    interactive={!isSm}
                  >
                    {children}
                  </SidebarContainer>
                </MotionConfig>
              </Modal.Portal>
            )}
          </AnimatePresence>
        </TNamespaceProvider>
      </Modal.Root>
    </SidebarModalProvider>
  );
};

export default SidebarModal;
