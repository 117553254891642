import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
  channelWhitelisted,
  updateActiveCampaignContact,
} from '@/api/activecampaign/update-contact';
import { whitelistYouTubeChannel } from '@/components/UBWhitelist/api';
import { useSettings } from '@/hooks/useSettings';

import type {
  YouTubeChannel,
  YouTubeChannelRequest,
  YouTubeChannelSnippet,
} from '@/components/UBWhitelist/types';

export const useWhitelistChannel = () => {
  const [error, setError] = useState<string | undefined>();
  const queryClient = useQueryClient();
  const { settings } = useSettings();

  const queryKey = ['channelWhitelist', settings?.user.id ?? ''];

  const { mutateAsync: whitelistChannel, isPending: isWhitelisting } =
    useMutation({
      mutationFn: (newChannel: YouTubeChannelRequest | YouTubeChannelSnippet) =>
        whitelistYouTubeChannel(
          newChannel.channelId,
          newChannel.title ?? 'undefined',
        ),
      onMutate: async (
        newChannel: YouTubeChannelRequest | YouTubeChannelSnippet,
      ) => {
        setError(undefined);

        // Cancel running queries.
        await queryClient.cancelQueries({ queryKey });

        // Get the previous value, in case of error.
        const previousWhitelist = await queryClient.getQueryData([queryKey]);

        if (newChannel.channelId && newChannel.channelTitle) {
          // Optimistically update to the new value.
          queryClient.setQueryData(
            queryKey,
            (old: YouTubeChannel[] | undefined = []) => {
              return [
                ...old,
                {
                  channel_id: newChannel.channelId,
                  channel_name: newChannel.channelTitle,
                },
              ];
            },
          );
        }

        void channelWhitelisted(newChannel.title ?? '', newChannel.channelId);

        return {
          previousWhitelist,
        };
      },
      onError: () => {
        setError('Something went wrong while safelisting your channel.');
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey,
        });
        updateActiveCampaignContact();
      },
      onSuccess: (ctx) => {
        if (!ctx.success) {
          setError('Something went wrong while safelisting your channel.');
        }
      },
    });

  return {
    whitelistChannel,
    isWhitelisting,
    error,
  };
};
