import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import UBButton from '@/components/UBButton';
import { useIsClient } from '@/hooks/useIsClient';

import styles from './styles.module.css';

import type { ReactEventHandler, ReactNode } from 'react';

interface ModalProps {
  children: ReactNode;
  onClose?: ReactEventHandler<HTMLDialogElement>;
}

export const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const isClient = useIsClient();

  useEffect(() => {
    const modal = modalRef?.current;

    if (isClient) {
      modal?.showModal();
    }
  }, [isClient]);

  if (!isClient) {
    return null;
  }

  return createPortal(
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <dialog
      ref={modalRef}
      className={styles.modalContainer}
      aria-modal="true"
      onClose={onClose}
      onClick={onClose}
    >
      <UBButton
        variant="contrast"
        menu="close"
        onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
          e?.stopPropagation();

          if (onClose) {
            onClose(e as unknown as React.MouseEvent<HTMLDialogElement>);
          }
        }}
        borderRadius="full"
        className={styles.closeButton}
      />
      <div className={styles.modalContent}>{children}</div>
    </dialog>,
    document.body,
  );
};
