const INITIAL_STATE = {
  hasLoaded: false,
  isLoggedIn: false,
  hasEnteredProfession: false,
  isAdmin: false,
  isCustomer: false,
  isArtist: false,
  googleLoginSuccess: false,
  googleLoginFailure: false,
  isNewUserGoogleSignUp: false,
  isPremium: null,
  isBusiness: null,
  isGoogleUser: false,
  isGoogleCloseModal: false,
  creditsCurrent: -1,
  creditsMax: 3,
  creditsPerMonth: -1,
  planData: false,
  planDataCurrency: undefined,
  planDataMonthlyPrice: undefined,
  planDataYearlyPrice: undefined,
  planDataYearlyMonthlyPrice: undefined,
  aiCredits: {
    allocation: -1,
    remaining: -1,
    used: -1,
  },
  showEmail: false,
};

const userAuth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_LOGIN_STATUS':
      return Object.assign({}, state, { isLoggedIn: action.isLoggedIn });
    case 'SET_HAS_ENTERED_PROFESSION':
      return Object.assign({}, state, { hasEnteredProfession: action.hasEnteredProfession });
    case 'LOAD_USER_DATA':
      return Object.assign({}, state, action.userData);
    case 'SET_GOOGLE_LOGIN_SUCCESS':
      return {
        ...state,
        googleLoginSuccess: action.status,
        googleLoginFailure: false,
        isNewUserGoogleSignUp: action.isNewUser,
        isGoogleCloseModal: action.closeModal
      };
    case 'UPDATE_REMAINING_CREDITS':
      return {
        ...state,
        creditsCurrent: action.creditsCurrent
      };
    case 'SET_REMAINING_AI_USAGES':
      return {
        ...state,
        aiCredits: {
          ...state.aiCredits,
          remaining: action.newUsages
        }
      };
    case 'SET_GOOGLE_LOGIN_FAILURE':
      return {
        ...state,
        googleLoginSuccess: false,
        googleLoginFailure: action.message
      };

    case 'UPDATE_CREDITS_CURRENT':
      return {
        ...state,
        creditsCurrent: action.creditsCurrent,
      }

    case 'CLEAR_GOOGLE_LOGIN_FLAG':
      return {
        ...state,
        googleLoginSuccess: false,
        googleLoginFailure: false
      }

    case 'SET_PREMIUM_STATUS':
      return {
        ...state,
        isPremium: action.payload
      }

    case 'SET_BUSINESS_STATUS':
      return {
        ...state,
        isPremium: action.payload,
        isBusiness: action.payload
      }

    case 'SHOW_EMAIL':
      return {
        ...state,
				showEmail: true
			}

    case 'HIDE_EMAIL':
      return {
        ...state,
        showEmail: false
      }

    default:
      return state;
  }
};

export default userAuth;
