import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { removeWhitelistedYouTubeChannel } from '@/components/UBWhitelist/api';
import { useSettings } from '@/hooks/useSettings';

import type { YouTubeChannel } from '@/components/UBWhitelist/types';

export const useRemoveChannel = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();

  const { settings } = useSettings();

  const queryKey = ['channelWhitelist', settings?.user.id];

  const { mutateAsync: removeChannel, isPending: isRemoving } = useMutation({
    mutationFn: (channelId: number) =>
      removeWhitelistedYouTubeChannel(channelId),
    onMutate: async (channelId) => {
      setError(undefined);

      // Cancel running queries.
      await queryClient.cancelQueries({
        queryKey,
      });

      // Get the previous value, in case of error.
      const previousWhitelist =
        queryClient.getQueryData<YouTubeChannel[]>(queryKey);

      // Optimistically update to the new value.
      queryClient.setQueryData<YouTubeChannel[]>(
        queryKey,
        (old: YouTubeChannel[] | undefined) => {
          if (!old) {
            return undefined;
          }

          return old.filter((item) => !item || item.id !== channelId);
        },
      );

      return {
        previousWhitelist,
      };
    },
    // If the mutation fails, use the context returned from onMutate to roll back.
    onError: (_err: unknown, _newTodo: unknown, context) => {
      queryClient.setQueryData(queryKey, context?.previousWhitelist);
      setError('Something went wrong while removing the channel.');
    },
    // Invalidate and refetch on success or error.
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
    onSuccess: (ctx: { success: boolean }) => {
      if (!ctx?.success) {
        setError('Something went wrong while removing the channel.');
      } else {
        setError(undefined);
      }
    },
  });

  return {
    removeChannel,
    isRemoving,
    error,
  };
};
