const INITIAL_STATE = {
  type: false,
  content: false,
};

const jumpBackInContext = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_JBI_CONTEXT':
      return Object.assign({}, state, action.context);
    case 'RESET_JBI_CONTEXT':
      return {
        type: false,
        content: false,
      };
    default:
      return state;
  }
};

export default jumpBackInContext;

