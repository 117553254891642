import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { setThemePreference } from '@/api/theme/set-theme-preference';
import { SettingsContext } from '@/providers/SettingsProvider';

import type { Theme } from '@/types/theme';

export interface IThemeContext {
  toggleTheme: () => void;
  theme: Theme;
}

interface ThemeProviderProps {
  children: React.ReactNode;
}

const DEFAULT_THEME: Theme = undefined;

export const ThemeContext = React.createContext<IThemeContext>({
  toggleTheme: () => null,
  theme: DEFAULT_THEME,
});

const getInitialTheme = (themePreference?: number): Theme => {
  if (themePreference === -1) {
    return DEFAULT_THEME;
  }

  return themePreference === 0 ? 'light' : 'dark';
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { settings } = useContext(SettingsContext);
  const themePreference = settings?.theme_preference;
  const [currentTheme, setCurrentTheme] = useState<Theme>(
    getInitialTheme(themePreference),
  );

  const updateTheme = useCallback(
    (newTheme: Theme) => {
      if (newTheme === undefined || currentTheme === newTheme) {
        return;
      }

      // Remove any existing theme classes, and add the new one.
      if (newTheme === 'dark') {
        document.documentElement.classList.remove('theme-light');
        document.documentElement.classList.add('theme-dark');
      } else {
        document.documentElement.classList.remove('theme-dark');
        document.documentElement.classList.add('theme-light');
      }

      setCurrentTheme(newTheme);

      void setThemePreference(newTheme);
      localStorage.setItem('theme', newTheme);
    },
    [currentTheme],
  );

  const loadInitialTheme = useCallback(() => {
    const theme = localStorage.getItem('theme');
    const isSystemDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;

    if ((!theme && isSystemDark) || theme === 'dark') {
      updateTheme('dark');

      return;
    }

    updateTheme('light');
  }, [updateTheme]);

  const toggleTheme = useCallback(() => {
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';

    updateTheme(newTheme);
  }, [currentTheme, updateTheme]);

  const ctx = useMemo(
    (): IThemeContext => ({
      toggleTheme,
      theme: currentTheme,
    }),
    [toggleTheme, currentTheme],
  );

  useEffect(() => {
    // A theme was set according to the user's preferences.
    if (themePreference && themePreference !== -1) {
      return;
    }

    loadInitialTheme();
  }, [themePreference, loadInitialTheme]);

  return <ThemeContext.Provider value={ctx}>{children}</ThemeContext.Provider>;
};
