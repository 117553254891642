import cleanDeep from 'clean-deep';
import timestring from 'timestring';

import { AUTH_TOKEN_COOKIE_NAME } from './constants/cookies';
import {
  getUniversalCookie,
  removeUniversalCookie,
  setUniversalCookie,
} from './cookies';

import type { NextIncomingMessage } from './types/request';

export const getAuthToken = (req?: NextIncomingMessage) =>
  getUniversalCookie<string>(AUTH_TOKEN_COOKIE_NAME, cleanDeep({ req }));

export const setAuthToken = (value: string, req?: NextIncomingMessage) =>
  setUniversalCookie(
    AUTH_TOKEN_COOKIE_NAME,
    value,
    cleanDeep({
      req,
      options: {
        maxAge: timestring('180d'),
      },
    }),
  );

export const removeAuthToken = (req?: NextIncomingMessage) =>
  removeUniversalCookie(AUTH_TOKEN_COOKIE_NAME, cleanDeep({ req }));
