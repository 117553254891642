import { twMerge } from 'tailwind-merge';

import { useTranslate } from '@/hooks/useTranslate';

import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';

interface TextAreaProps<T extends FieldValues>
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: Path<T>;
  register: UseFormRegister<T>;
  hasError?: boolean;
}

const TextArea = <T extends FieldValues>({
  className,
  name,
  register,
  hasError = false,
  placeholder = '',
  ...rest
}: TextAreaProps<T>) => {
  const { t } = useTranslate();

  return (
    <textarea
      className={twMerge(
        'antialiased text-sm px-4 py-3 outline-0 font-sans shadow-none touch-manipulation text-gray-dark-400 bg-gray-light-200 border-2 border-solid border-gray-light-200 rounded-[.625rem] enabled:hover:bg-gray-light-300 enabled:hover:border-gray-light-300 focus:bg-gray-light-300 focus:border-uppbeat transition duration-75 w-full dark:bg-gray-dark-200 dark:border-gray-dark-200 dark:text-white dark:enabled:hover:bg-gray-dark-100 dark:enabled:hover:border-gray-dark-100 dark:focus:bg-gray-dark-100 dark:focus:border-uppbeat placeholder-gray-dark-400/70 dark:placeholder-gray-light-400/50 disabled:bg-gray-light-400 disabled:border-gray-light-400 disabled:text-gray-dark-400/70 disabled:cursor-not-allowed dark:disabled:bg-gray-dark-300 dark:disabled:border-gray-dark-300 dark:disabled:text-gray-light-400/50 dark:disabled:cursor-not-allowed',
        hasError
          ? 'border-red dark:border-red enabled:hover:border-red dark:enabled:hover:border-red'
          : '',
        className,
      )}
      {...rest}
      {...register(name)}
      placeholder={t(placeholder)}
    />
  );
};

export default TextArea;
