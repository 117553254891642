import React, { useEffect, useMemo, useState } from 'react';

export interface ISidebarContext {
  toggleSidebar: (newValue?: boolean, device?: 'mobile') => void;
  toggleSearch: (newValue?: boolean) => void;
  isSidebarToggled: boolean;
  isSearchVisible: boolean;
}

interface SidebarProps {
  children: React.ReactNode;
}

export const SidebarContext = React.createContext<ISidebarContext>({
  toggleSidebar: () => {},
  toggleSearch: () => {},
  isSidebarToggled: false,
  isSearchVisible: false,
});

const desktop = '(min-width: 992px)';
const tablet = '(min-width: 600px)';

const breakpointsToToggle = [desktop, tablet];

export const SidebarProvider: React.FC<SidebarProps> = ({ children }) => {
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const [isSearchVisible, setIsSearchVisible] = useState<boolean>(false);

  const resetSidebar = () => {
    setIsToggled(false);
    setIsSearchVisible(false);
  };

  // Add event listeners to reset sidebar on resize.
  useEffect(() => {
    const mediaQueryLists = breakpointsToToggle.map((breakpoint) =>
      window.matchMedia(breakpoint),
    );

    mediaQueryLists.forEach((mediaQueryList) =>
      mediaQueryList.addEventListener('change', resetSidebar),
    );

    return () => {
      mediaQueryLists.forEach((mediaQueryList) =>
        mediaQueryList.removeEventListener('change', resetSidebar),
      );
    };
  }, []);

  const ctx = useMemo(
    (): ISidebarContext => ({
      toggleSidebar: (newValue, device) => {
        if (device === 'mobile') {
          if (window.matchMedia(tablet).matches) {
            return;
          }
        }

        const val = newValue ?? !isToggled;

        if (!val && !window.matchMedia(tablet).matches) {
          setIsSearchVisible(false);
        }

        setIsToggled(val);
      },
      toggleSearch: (newValue?: boolean) => {
        // If the user on mobile, we want to toggle the sidebar with the search.
        if (!window.matchMedia(tablet).matches) {
          setIsToggled(newValue ?? !isSearchVisible);
        }

        setIsSearchVisible(newValue ?? !isSearchVisible);
      },
      isSearchVisible,
      isSidebarToggled: isToggled,
    }),
    [setIsToggled, isToggled, isSearchVisible],
  );

  return (
    <SidebarContext.Provider value={ctx}>{children}</SidebarContext.Provider>
  );
};
