import * as React from 'react';

const _ArrowRight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M13.681 6.359a1.073 1.073 0 010 1.526l-5.844 5.799a1.094 1.094 0 01-1.539 0 1.073 1.073 0 010-1.526l3.964-3.932h-9.09a1.08 1.08 0 010-2.159h9.13L6.044 1.842a1.073 1.073 0 010-1.526 1.094 1.094 0 011.538 0l5.845 5.8c.037.037.072.076.103.118.053.036.104.078.151.125z" />
    </svg>
  );
};

const ArrowRight = React.memo(_ArrowRight);

export default ArrowRight;
