import React from 'react';

const IconCopy = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <g fill="#AAA">
                <path d="M6.25 14h7V5.25h-7V14zM13.6 3.5H5.9c-.773 0-1.4.61-1.4 1.361v9.528c0 .752.627 1.361 1.4 1.361h7.7c.774 0 1.4-.61 1.4-1.361V4.86c0-.752-.626-1.361-1.4-1.361zM11.427 0H2.49C1.667 0 1 .636 1 1.422v9.953h1.49V1.422h8.937V0z"/>
            </g>
        </g>
    </svg>
);

export default IconCopy;
