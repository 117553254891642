import React from 'react';

const IconPlus = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 8"
      className={className}
    >
      <path
        d="M6.5 3.5H4.6V1.6A.65.65 0 0 0 4 1a.65.65 0 0 0-.6.6v1.9H1.5a.65.65 0 0 0-.6.6.65.65 0 0 0 .6.6h1.9v1.9c0 .4.3.7.6.7a.65.65 0 0 0 .6-.6v-2h1.9a.65.65 0 0 0 .6-.6c0-.3-.2-.6-.6-.6z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconPlus;
