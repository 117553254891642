/*
 * useDynamicSidebarHeader()
 *
 * This hook is used for controlling the sidebar header when specific
 * screens are active. It takes in the setTabs function, the currentScreen
 * and an array of dynamicTabs. The dynamicTabs array is an array of objects
 * that contain the tabs to render and the screenNames that the tabs should
 * be rendered on. By default, the hook will render the DefaultTab component
 * if the currentScreen is not found in the dynamicTabs array. This just
 * displays the Uppbeat logo and works as a sensible default.
 *
 * NOTE: You MUST provide a key prop to all tabs to prevent rendering issues.
 */

import React, { useEffect, useMemo } from 'react';

import {
  DefaultTab,
  type Tab,
  useSidebarHeaderContext,
} from '@/components/Modals/Sidebar/context/SidebarHeaderContext';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';

export interface dynamicSidebarHeaderTab {
  tabs: Tab[];
  screenNames: string[];
}

interface useDynamicSidebarHeaderProps {
  dynamicTabs: dynamicSidebarHeaderTab[];
}

const tabsAreTheSame = (tabs1: Tab[], tabs2: Tab[]) => {
  if (tabs1.length !== tabs2.length) {
    return false;
  }

  for (let i = 0; i < tabs1.length; i + 1) {
    // Check if the tabs have a key prop
    if (React.isValidElement(tabs1[i]) && React.isValidElement(tabs2[i])) {
      if (tabs1[i].key === tabs2[i].key) {
        return true;
      }
    }

    if (tabs1[i] !== tabs2[i]) {
      return false;
    }
  }

  return true;
};

const useDynamicSidebarHeader = ({
  dynamicTabs,
}: useDynamicSidebarHeaderProps) => {
  const { currentScreen } = useScreenContext();
  const { tabs, setTabs } = useSidebarHeaderContext();

  const newTabs = useMemo(() => {
    let _tabs: Tab[] = [];

    dynamicTabs.forEach((tabSet) => {
      if (tabSet.screenNames.includes(currentScreen)) {
        _tabs = tabSet.tabs;
      }
    });

    if (_tabs.length === 0) {
      _tabs = [<DefaultTab />];
    }

    return _tabs;
  }, [currentScreen, dynamicTabs]);

  useEffect(() => {
    // Compare new and old tabs
    if (!tabsAreTheSame(newTabs, tabs)) {
      setTabs(newTabs);
    }
  }, [newTabs, setTabs, tabs]);

  // Check if all tabs have a key prop
  useEffect(() => {
    dynamicTabs.forEach((dynamicTab) => {
      dynamicTab.tabs.forEach((tab) => {
        if (!React.isValidElement(tab) || !tab.key) {
          throw new Error('All tabs must have a key prop');
        }
      });
    });
  }, [dynamicTabs]);
};

export default useDynamicSidebarHeader;
