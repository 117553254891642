import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar2Trending extends React.Component {
    render() {
        const Sidebar2Trending = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
<g>
\t<g>
\t\t<path d="M6.39,7.03H1.43c-0.22,0-0.4-0.18-0.4-0.4v0c0-0.22,0.18-0.4,0.4-0.4h4.95c0.22,0,0.4,0.18,0.4,0.4v0
\t\t\tC6.79,6.85,6.61,7.03,6.39,7.03z"/>
\t</g>
\t<path d="M6.83,1.16H5.48c-0.07,0-0.1,0.09-0.05,0.13l0.41,0.4L4.09,3.45L3.03,2.39L1.26,4.16
\t\tc-0.16,0.16-0.16,0.43,0,0.59s0.43,0.16,0.59,0l1.18-1.18l1.06,1.06l2.36-2.36L6.47,2.3l0.37,0.35C6.89,2.7,6.97,2.67,6.97,2.6V1.3
\t\tC6.97,1.22,6.91,1.16,6.83,1.16z"/>
</g>
</svg>`;
        return (
            <InlineSVG src={Sidebar2Trending} />
        );
    }
}

export default Sidebar2Trending;
