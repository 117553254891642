const carousels = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_STYLES':
      return action.styles;

    default:
      return state;
  }
};

export default carousels;

