/*
 * A simple wrapper around the fetch API that adds the base URL to the API.
 * It also adds the Content-Type header to all requests.
 * The api function returns a promise that resolves to the JSON response of the
 * request. If the response status is not 2xx, the promise rejects with an
 * Error object that contains the error message from the response.
 */

import { isExcludedFromCdn, shouldAvoidCdn } from './cdn';

interface ApiOptions {
  skipCdn?: boolean;
  baseUrl?: string;
  defaultContentType?: false | string;
}

interface ApiRequestInit extends Omit<RequestInit, 'body'> {
  body?: object | BodyInit;
}

const api = async <T extends object | string>(
  endpoint: string,
  { body, ...customConfig }: ApiRequestInit = {},
  { skipCdn, baseUrl, defaultContentType }: ApiOptions = {
    skipCdn: false,
    defaultContentType: 'application/json',
  },
): Promise<T> => {
  // Assume all requests are JSON unless explicitly set
  const headers: HeadersInit = defaultContentType
    ? { 'Content-Type': defaultContentType }
    : {};

  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  const customHeaders = (customConfig.headers as Record<string, string>) ?? {};

  // Automatially stringify the body if we're sending json
  if (body) {
    config.body =
      customHeaders?.['Content-Type'] === 'application/json' &&
      typeof body === 'object'
        ? JSON.stringify(body)
        : (body as BodyInit);
  }

  /*
   * Sometimes we want to skip the CDN:
   * - If the request is authenticated
   * - If the request is not a GET request
   * - If the request URL is excluded from the CDN
   * - If the avoidCdn cookie is set
   */
  const useCdn =
    !skipCdn &&
    !customHeaders?.token &&
    !isExcludedFromCdn(endpoint) &&
    !shouldAvoidCdn() &&
    config.method === 'GET';

  // Allow overriding the base URL for the API
  const _baseUrl =
    baseUrl ??
    (useCdn ? process.env.NEXT_PUBLIC_CDN_API : process.env.NEXT_PUBLIC_API);

  const response = await fetch(`${_baseUrl}${endpoint}`, config);

  if (response.ok) {
    return (await response.json()) as T;
  }

  const errorMessage = await response.text();

  return Promise.reject(new Error(errorMessage));
};

export default api;
