import Modal from '@/components/Modals/Core';
import CreateAccountModalScreens from '@/components/Modals/CreateAccount/screens';
import SidebarModal from '@/components/Modals/Sidebar';
import { SidebarHeaderProvider } from '@/components/Modals/Sidebar/context/SidebarHeaderContext';
import SignupModalScreens from '@/components/Modals/SignUp/screens';

import { LoginModalHeader } from './header';
import LoginModalScreens from './screens';

interface LoginModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  initial?: string;
}

const LoginModal = ({
  open,
  onOpenChange,
  children,
  initial,
}: LoginModalProps) => {
  return (
    <SidebarHeaderProvider>
      <SidebarModal open={open} onOpenChange={onOpenChange} trigger={children}>
        {/*
         * Initial screen can be inferred, but setting it
         * explicitly prevents framer motion from animating
         * the first screen in.
         */}
        <Modal.ScreenContainer initial={initial ?? 'log-in'}>
          <LoginModalHeader />
          <LoginModalScreens />
          <SignupModalScreens />
          <CreateAccountModalScreens />
        </Modal.ScreenContainer>
      </SidebarModal>
    </SidebarHeaderProvider>
  );
};

export default LoginModal;
