import clsx from 'clsx';
import { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { AccountType, useAccountType } from '@/hooks/useAccountType';
import { useAppState } from '@/hooks/useAppState';

import { ChannelWhitelist } from './ChannelWhitelist';
import { ChannelWhitelistInner } from './ChannelWhitelistInner';
import { useRemoveChannel } from './hooks/useRemoveChannel';
import { useWhitelistChannel } from './hooks/useWhitelistChannel';
import { useWhitelistedChannels } from './hooks/useWhitelistedChannels';
import styles from './styles.module.css';

import type { YouTubeChannelRequest, YouTubeChannelSnippet } from './types';

interface UBWhitelistProps {
  maxChannels?: number;
  fullWidth?: boolean;
  relativePosition?: boolean;
  compact?: boolean;
}

export const UBWhitelist: React.FC<UBWhitelistProps> = ({
  maxChannels,
  fullWidth,
  relativePosition,
  compact,
}) => {
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined,
  );

  const accountType = useAccountType();
  const userAuth = useAppState('userAuth');

  const { data, isLoading } = useWhitelistedChannels();

  const {
    removeChannel,
    isRemoving,
    error: removeChannelError,
  } = useRemoveChannel();

  const {
    whitelistChannel,
    isWhitelisting,
    error: whitelistChannelError,
  } = useWhitelistChannel();

  const error = removeChannelError ?? whitelistChannelError;

  const channelList = data ?? [];

  const handleRemoveChannel = useCallback(
    async (whitelistId: number) => {
      await removeChannel(whitelistId);
    },
    [removeChannel],
  );

  const handleWhitelistChannel = async (
    channelData: YouTubeChannelRequest | YouTubeChannelSnippet,
  ) => {
    setEditingIndex(undefined);

    if (channelData) {
      await whitelistChannel(channelData);
    }
  };

  const channelAddsToShow = accountType === AccountType.Free ? 3 : 1;

  const channelsForAccount = accountType === AccountType.Business ? 10 : 3;
  // Clamp the number of channels to show to the maxChannels prop, if it exists.
  const channelsToShow = Math.min(
    channelList.length + channelAddsToShow,
    maxChannels ?? channelsForAccount,
  );

  return (
    <>
      <div
        className={clsx(
          'grid gap-4 my-8 grid-cols-1 @sm:grid-cols-2 @md:grid-cols-3',
          fullWidth && 'grid-cols-1',
          'ub-account-page_whitelisting',
          userAuth?.isBusiness && 'business',
          compact && '!my-0 !gap-2',
          accountType === AccountType.Free && 'opacity-50 !pointer-events-none',
        )}
      >
        {[...Array(channelsToShow)].map((_channel, i) => {
          const isEditing = editingIndex === i;

          const currentChannel = channelList[i] ?? {};

          const hasChannel =
            !!currentChannel.channel_id && !!currentChannel.channel_name;

          return (
            <OutsideClickHandler
              onOutsideClick={() => {
                if (isEditing && typeof channelList !== undefined) {
                  setEditingIndex(undefined);
                }
              }}
            >
              <div
                className={clsx(
                  relativePosition && styles.relativePosition,
                  styles.whitelistItem,
                  (hasChannel || isEditing) && styles.active,
                  compact && '!min-h-16 !bg-transparent',
                )}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                <ChannelWhitelistInner
                  handleEdit={() => {
                    if (userAuth?.isPremium) {
                      if (!hasChannel) {
                        setEditingIndex(i);
                      }
                    }
                  }}
                  hasChannel={hasChannel}
                  isEditing={isEditing}
                  isLoading={isLoading || !userAuth}
                  isFetching={isLoading || isWhitelisting || isRemoving}
                >
                  <ChannelWhitelist
                    key={currentChannel?.channel_id}
                    compact={compact}
                    isEditing={isEditing}
                    whitelistChannel={handleWhitelistChannel}
                    handleRemoveChannel={handleRemoveChannel}
                    selectedChannel={currentChannel?.channel_id}
                    whitelistId={currentChannel?.id}
                    isFetching={isLoading || isWhitelisting || isRemoving}
                  />
                </ChannelWhitelistInner>
              </div>
            </OutsideClickHandler>
          );
        })}
      </div>

      {error !== undefined && (
        <div className="alert-general-error">{error}</div>
      )}
    </>
  );
};
