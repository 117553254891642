import { useMemo } from 'react';

import { useSettings } from './useSettings';

export enum AccountType {
  Business = 'business',
  Premium = 'premium',
  Free = 'free',
}

export const useAccountType = () => {
  const { settings } = useSettings();

  const isBusiness = settings?.isBusiness;
  const isPremium = settings?.isPremium;

  const accountType = useMemo(() => {
    if (isBusiness) {
      return AccountType.Business;
    }

    if (isPremium) {
      return AccountType.Premium;
    }

    return AccountType.Free;
  }, [isBusiness, isPremium]);

  return accountType;
};
