const INITIAL_STATE = {
  isActive: false,
  isFreeTrial: false,
  period: false,
  permittedContent: [],
  permittedDistribution: [],
  hasLoaded: false,
  overduePayment: null,
  hasExpiredNotice: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'LOAD_SUBSCRIPTION_DATA':
      action.subscriptionData.hasLoaded = true;
      return {...action.subscriptionData};
    case  'SET_HAS_EXPIRED_NOTICE':
      return Object.assign({}, state, { hasExpiredNotice: action.hasExpiredNotice });
    case 'LOAD_SUBSCRIPTION_PERMISSION':
      return {
        ...state,
        permittedContent: action.payload.permitted_content,
        permittedDistribution: action.payload.permittedDistribution,
      };
    default: return state;
  }
};
