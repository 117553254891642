import { conditionallyGetCdnApi } from '@/api/cdn';

function sanitizePath(path: string) {
  try {
    const searchParamWhitelist = ['type', 'query'];

    const url = new URL(path, 'https://temp');

    url.searchParams.forEach((_value, key) => {
      if (!searchParamWhitelist.includes(key)) {
        url.searchParams.delete(key);
      }
    });

    return `${url.pathname}${url.search}`;
  } catch (e) {
    return path;
  }
}

export function logSearch(
  searchTerm: string,
  pageCurrent: string,
  pageNext: string,
  actionType: string,
) {
  const url = new URL(`${conditionallyGetCdnApi()}suggestion_record`);

  url.searchParams.append('search_term', searchTerm);
  url.searchParams.append('page_curr', sanitizePath(pageCurrent));
  url.searchParams.append('page_next', pageNext);
  url.searchParams.append('action_type', actionType);

  return fetch(url)
    .then((res) => res.json())
    .catch((err: unknown) => err);
}
