import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar12AI extends React.Component {
    render() {
        const Sidebar12AI = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.20233 8.63821L6.4274 6.35901L5.72085 8.63821H7.20233Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.61259 9.8006H5.17384L4.67242 11.0086H3.12256L5.72085 4.53564H7.08837L9.66387 11.0086H8.11401L7.61259 9.8006ZM11.897 11.0086V4.53564H10.4155V11.0086H11.897Z" />
        </svg>

`;
        return (
            <InlineSVG src={Sidebar12AI} />
        );
    }
}

export default Sidebar12AI;
