import { useContext } from 'react';

import { getAnnualDiscountPercentage } from '@/actions/cartAction';
import usePricing from '@/components/Modals/CreateAccount/hooks/usePricing';
import { AccountType } from '@/components/Modals/CreateAccount/types';
import { T } from '@/components/T';
import { useAppState } from '@/hooks/useAppState';
import { useSettings } from '@/hooks/useSettings';
import { planData } from '@/plan-data';
import { UserStateContext } from '@/providers/UserStateProvider';

interface BillingFrequencyProps {
  accountType: AccountType;
}

const BillingFrequency = ({ accountType }: BillingFrequencyProps) => {
  const { settings } = useSettings();
  const pricing = usePricing({ accountType });
  const { userState, setUserState } = useContext(UserStateContext);
  const subscriptionCart = useAppState('subscriptionCart');

  const { premium: premiumPlanData } = planData;

  return (
    <div className="ub-modal-toggle ub-modal-pricing-toggle">
      <label
        htmlFor="checkout-monthly"
        className={`ub-modal-toggle-option ${
          userState.selectedBillingMode === 'monthly' ? 'active' : ''
        }`}
      >
        <div>
          <input
            id="checkout-monthly"
            className="checkbox-round"
            type="checkbox"
            checked={userState.selectedBillingMode === 'monthly'}
            onChange={() => {
              setUserState('selectedBillingMode', 'monthly');
            }}
          />
          <div>
            <span className="duration_tag">
              <T>Monthly</T>
            </span>
            <span className="cancel-tag">
              <T>Cancel anytime</T>
            </span>
          </div>
        </div>
        <p>
          <sup className="currency-tag">{settings?.currency.symbol_left}</sup>
          <span className="pricing-tag">
            {accountType === AccountType.Premium
              ? pricing.premium.monthly.monthlyInteger
              : pricing.business.monthly.monthlyInteger}
            <sup>
              {accountType === AccountType.Premium
                ? pricing.premium.monthly.monthlyDecimals
                : pricing.business.monthly.monthlyDecimals}
            </sup>
          </span>
          <span className="currency-tag">
            <T>/m</T>
          </span>
        </p>
      </label>
      <label
        htmlFor="checkout-yearly"
        className={`ub-modal-toggle-option ${
          userState.selectedBillingMode !== 'monthly' ? 'active' : ''
        } ${premiumPlanData?.monthly ? '' : 'ab-test'}`}
      >
        <div>
          <input
            id="checkout-yearly"
            className="checkbox-round"
            type="checkbox"
            checked={userState.selectedBillingMode !== 'monthly'}
            onChange={() => {
              setUserState('selectedBillingMode', 'yearly');
            }}
          />
          <div>
            <span className="duration_tag">
              <T>Yearly</T>
            </span>
            <span className="discount_tag">
              <T>Save</T>{' '}
              {getAnnualDiscountPercentage(subscriptionCart?.plan?.id)}
            </span>
          </div>
        </div>
        <div className="premium-checkout_duration-tag-pricing">
          <p>
            <sup className="currency-tag">{settings?.currency.symbol_left}</sup>
            <span className="pricing-tag">
              {accountType === AccountType.Premium
                ? pricing.premium.yearly.monthlyEquivalent
                    .monthlyEquivalentInteger
                : pricing.business.yearly.monthlyEquivalent
                    .monthlyEquivalentInteger}
              <sup>
                {accountType === AccountType.Premium
                  ? pricing.premium.yearly.monthlyEquivalent
                      .monthlyEquivalentDecimals
                  : pricing.business.yearly.monthlyEquivalent
                      .monthlyEquivalentDecimals}
              </sup>
            </span>
            <span className="currency-tag">/m</span>
          </p>
          <span className="sub-text">
            <T>(billed annually)</T>
          </span>
        </div>
      </label>
    </div>
  );
};

export default BillingFrequency;
