const initialState = false;

const searchVisible = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_SEARCH':
			return true;
		case 'HIDE_SEARCH':
			return false;
		default:
			return state;
	}
};

export default searchVisible;
