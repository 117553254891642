import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import ShieldTick from '@/components/Icons/ShieldTick';
import Warning from '@/components/Icons/Warning';
import { useYoutubeChannelData } from '@/components/UBWhitelist/hooks/useYoutubeChannelData';
import { AccountType, useAccountType } from '@/hooks/useAccountType';

import type { YouTubeChannel } from '@/components/UBWhitelist/types';

const iconClassName = 'absolute bottom-0 -right-1 size-1/2';

const YouTubeChannelImage = ({ channel }: { channel: YouTubeChannel }) => {
  const { data: channelData } = useYoutubeChannelData(channel.channel_id);
  const [isLoading, setIsLoading] = useState(true);

  const accountType = useAccountType();

  const isSafelisted = accountType !== AccountType.Free;

  return (
    <div className="relative h-full w-auto aspect-square">
      <div
        className={clsx(
          'z-0 h-full w-full aspect-square bg-gray-light-400 dark:bg-gray-dark-100 rounded-full transition-opacity',
          isLoading ? 'animate-pulse' : 'opacity-0',
        )}
      />
      {channelData && (
        <Image
          key={channel.channel_id}
          onLoad={() => {
            setIsLoading(false);
          }}
          unoptimized
          className={twMerge(
            'absolute top-0 left-0 rounded-full object-center w-full h-auto aspect-square object-cover transition-opacity duration-700 pointer-events-none',
            !isSafelisted && 'opacity-60',
          )}
          fill
          src={channelData?.thumbnails?.default?.url}
          alt={channel.channel_name}
        />
      )}
      {isSafelisted ? (
        <ShieldTick hasStroke className={iconClassName} />
      ) : (
        <Warning className={iconClassName} />
      )}
    </div>
  );
};

export default YouTubeChannelImage;
