import dynamic from 'next/dynamic';
import Image from 'next/image';
import React, { useState } from 'react';

import { Portal } from '@/components/Portal';
import UBButton from '@/components/UBButton';
import { useAppState } from '@/hooks/useAppState';

import type { Track } from '@/types/tracks';

const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'), {
  ssr: false,
});

interface UBPopupRecaptchaProps {
  onAddLicense: (
    track: Track,
    isDownload: boolean,
    viewCredit: boolean,
    recaptchaVersion: 2 | 3,
    recaptchaToken: string | null,
  ) => void;
  onRemoveModal: () => void;
}

const UBPopupRecaptcha = ({
  onAddLicense,
  onRemoveModal,
}: UBPopupRecaptchaProps) => {
  const [recaptchaV2Token, setRecaptchaV2Token] = useState<string | null>(null);

  const license = useAppState('license');

  const captchaOnChange = (token: string | null) => {
    setRecaptchaV2Token(token);
  };

  return (
    <Portal>
      <div className="ub-popup">
        <div className="ub-popup-bottom ub-popup-recaptcha">
          <UBButton
            className="popup-close-btn"
            onClick={onRemoveModal}
            variant="primary"
            borderRadius="full"
            menu="close"
          />
          <h2>Before your download&nbsp;starts</h2>
          <p>Are you a human?</p>
          <div className="ub-popup-content-wrapper">
            <ReCAPTCHA
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_V2_KEY}
              onChange={captchaOnChange}
              size="normal"
              badge="inline"
            />
          </div>
          <UBButton
            className="track-download-btn"
            variant="uppbeat"
            borderRadius="full"
            label="Download"
            icon={
              <Image
                unoptimized
                src="https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Download.svg"
                alt="Download"
                width={16}
                height={16}
              />
            }
            iconPosition="right"
            size="small"
            disabled={!recaptchaV2Token}
            padding="0 0.4375rem 0 0.625rem"
            onClick={() => {
              onAddLicense(license.track, true, false, 2, recaptchaV2Token);
            }}
            // ariaLabel={ariaLabel}
          />
        </div>
      </div>
    </Portal>
  );
};

export default UBPopupRecaptcha;
