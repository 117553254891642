import { useRef } from 'react';

import AdminBarContainer from './AdminBarContainer';
import Toolbar from './Toolbar';
import AdminSettings from './tools/AdminSettings';
import EditMode from './tools/EditMode';
import TanstackQuery from './tools/TanstackQuery';

export const AdminBar = () => {
  const boundsRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="absolute w-full h-full bottom-0 left-0 right-0 pointer-events-none z-50"
      ref={boundsRef}
    >
      <AdminBarContainer>
        <TanstackQuery />
        <Toolbar.Separator />
        <EditMode />
        <AdminSettings />
      </AdminBarContainer>
    </div>
  );
};

export default AdminBar;
