import { clsx } from 'clsx';
import Link from 'next/link';

import type { LinkProps } from 'next/link';
import type { CSSProperties, FC, ReactNode } from 'react';

type AProps = LinkProps & {
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
};

const A: FC<AProps> = ({ children, className, ...restProps }: AProps) => (
  <Link
    prefetch={false}
    className={clsx('cursor-pointer', className)}
    {...restProps}
  >
    {children}
  </Link>
);

export default A;
