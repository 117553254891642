import { useContext } from 'react';
import { Portal as ReactPortal } from 'react-portal';

import { PortalContext } from '@/providers/PortalProvider';

import type { ReactNode } from 'react';

interface PortalProps {
  children: ReactNode;
}

export const Portal = ({ children }: PortalProps) => {
  const { portalContainerRef } = useContext(PortalContext);

  return (
    <ReactPortal node={portalContainerRef?.current}>{children}</ReactPortal>
  );
};
