const collections = (state = [], action) => {
  switch (action.type) {
    case 'REQUEST_COLLECTIONS':
      return Object.assign({}, state, {
        isFetching: true,
        page: { results: false },
      });
    case 'RECEIVE_COLLECTIONS':
      return Object.assign({}, state, {
        isFetching: false,
        page: action.collections,
      });
    case 'ADD_COLLECTIONS':
      return action.collections;
    default:
      return state;
  }
};

export default collections;

