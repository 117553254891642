import * as React from 'react';
import { twMerge } from 'tailwind-merge';

const _Shield = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 8 8"
      className={twMerge('w-[18px] h-[18px] text-white', className)}
      {...props}
    >
      <path
        d="M4 .5c-.8.7-1.9 1.1-3 1.1 0 0 0 1.3.1 2.7C1.3 6.1 4 7.5 4 7.5s2.7-1.4 2.9-3.3C7 2.9 7 1.6 7 1.6c-1.1 0-2.2-.4-3-1.1zm1.6 2.9L3.7 5.3l-1-.9c-.1-.2-.1-.3 0-.4.1-.1.3-.2.4 0l.6.6L5.2 3c.1-.1.3-.1.4 0 .1.1.1.3 0 .4z"
        className="fill-current"
      />
    </svg>
  );
};

const Shield = React.memo(_Shield);

export default Shield;
