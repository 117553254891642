import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import Loader from '../../styles/svg-components/Loader';
import { PLACEHOLDER_IMG_URL } from '../../constants/images';
import {
  addNewPlaylistTrack,
  addExistingPlaylistTrack,
} from '../../actions/favouriteAction';
import {
  showPlaylistSuccess,
  hidePlaylistSuccess,
} from '../../actions/playlistAction';
import { playlistCreated } from '../../actions/generalAction';
import { disableKeyboardControls } from '../../actions/footerPlayerAction';
import { InputField } from '../Helper/InputField';
import UBButton from '../UBButton';
import UBModalHeader from '../UBModal/UBModalHeader';
import Image from 'next/image';
import { ClassContext } from '../../providers/ClassProvider';
import { T } from '../T';

class PlaylistModal extends React.Component {
  static contextType = ClassContext;

  constructor(props) {
    super(props);
    let versionId = 0;

    if (this.props.playlist.version) {
      versionId = this.props.playlist.version.id;
    }

    this.state = {
      versionId,
      playlists: [],
      playlistType: null,
      isLoading: false,
      loading: false,
      newPlaylistName: null,
    };

    // Animation timers
    this.timer = false;

    this.onSubmitNew = this.onSubmitNew.bind(this);
    this.onSubmitExisiting = this.onSubmitExisiting.bind(this);
    this.updatePlaylistType = this.updatePlaylistType.bind(this);
  }

  componentDidMount() {
    const playlists =
      this.context.settingsContext.settings?.playlists_data ?? [];
    const newPlaylistName = `${this?.props?.playlist?.track?.artist?.name} - ${this.props?.playlist?.track?.name}`;

    this.setState({
      playlists,
      newPlaylistName,
      playlistType: playlists.length > 0 ? 'existing' : 'new',
    });
  }

  onSubmitNew(event) {
    event.preventDefault();
    if (event.target.title.value !== '') {
      this.setState({ isLoading: true });
      let playlistName = event.target.title.value;
      addNewPlaylistTrack(
        this.props.playlist.track.id,
        this.state.versionId,
        event.target.title.value,
        this.props.playlist.track.current_context.id,
      )
        .then((data) => {
          this.context.settingsContext.invalidateSettings?.();

          if (data && typeof data === 'object') {
            this.setState({ playlists: data });
            this.props.onRemoveModal();
            this.props.showPlaylistSuccess(
              this.props.playlist.track,
              playlistName,
              true,
            );
          } else {
            this.setState({
              warning: 'Sorry, something went wrong. Please try again later.',
            });
          }
          this.setState({ isLoading: false });
          playlistCreated(playlistName);
        })
        .catch((err) => err);
    } else {
      this.setState({
        warning: 'Enter a title',
      });
    }
  }

  onSubmitExisiting(event) {
    event.preventDefault();
    if (
      event.target.existingId.value !== 'Select Playlist' &&
      event.target.existingId.value.length > 0
    ) {
      this.setState({ isLoading: true });

      // Find playlist name
      const selectedPlaylist = this.state.playlists.find(
        (playlist) => playlist.uuid === event.target.existingId.value,
      );
      let playlistName = '';
      if (selectedPlaylist) {
        playlistName = selectedPlaylist.name;
      }

      addExistingPlaylistTrack(
        this.props.playlist.track.id,
        this.state.versionId,
        event.target.existingId.value,
        this.props.playlist.track.current_context.id,
      )
        .then((data) => {
          if (data) {
            this.setState({
              isLoading: false,
            });
            this.props.onRemoveModal();
            this.props.showPlaylistSuccess(
              this.props.playlist.track,
              playlistName,
              false,
            );
          }
        })
        .catch((err) => err);
    }
  }

  updatePlaylistType(event) {
    const playlistType = event.currentTarget.getAttribute('data-playlist-type');
    this.setState({ playlistType });
  }

  handleChange = (e) => {
    this.setState({ newPlaylistName: e.target.value });
  };
  handleFocus = (event) => event.target.select();
  render() {
    const artist = this.props.playlist.track.artist;
    const artistThumb = (
      <Image
        alt={artist?.name}
        src={artist?.avatar_image.image_aws_url ?? PLACEHOLDER_IMG_URL}
        width={60}
        height={60}
        sizes="60px"
      />
    );

    const hasExisting = this.state.playlists.length > 0;
    const isPlaylistExists =
      this.state.playlistType !== 'new' &&
      hasExisting &&
      this.state.playlistType === 'existing';

    return (
      <>
        {this.props.playlistFrame.framed ? (
          <div className="ub-modal">
            <UBModalHeader
              tabs={[
                <div className="ub-modal-header_tab">
                  <span>
                    <img
                      src="https://cdn.uppbeat.io/images/logo/logo-marque.svg"
                      alt="Uppbeat logo"
                      className="uppbeat-logo "
                    />
                  </span>
                </div>,
              ]}
              onClose={this.props.onRemoveModal}
            />
            <div
              className={`ub-modal-container-wrapper ub-modal-container-wrapper-1 modal-1`}
            >
              <div
                className="ub-modal-container playlist-modal-container"
                style={{ width: `100%` }}
              >
                <h2 className="ub-modal-title">
                  <T keyName={'addToPlaylist'} ns="common">
                    Add to playlist
                  </T>
                </h2>
                <div className="playlist-modal__create">
                  <div>
                    <div className="playlist-image">{artistThumb}</div>
                    <div className="playlist-name">
                      <span className="track-name">
                        {this.props.playlist.track.name}
                      </span>
                      {artist && (
                        <span className="artist-name">{artist.name}</span>
                      )}
                    </div>
                  </div>
                  <form
                    onSubmit={
                      isPlaylistExists
                        ? this.onSubmitExisiting
                        : this.onSubmitNew
                    }
                  >
                    <div className="col-margin">
                      {isPlaylistExists ? (
                        <>
                          {this.state.loading && (
                            <div className="input-wrapper select-wrapper">
                              <div className="">
                                <InputField
                                  type="single-select"
                                  className="input playlist-input-field"
                                  hasChildrenAsOption
                                >
                                  <option className="placeholder-text">
                                    Loading playlists...
                                  </option>
                                </InputField>
                                <span className="select-icon">
                                  <span />
                                </span>
                              </div>
                            </div>
                          )}
                          {!this.state.loading && (
                            <div className="input-wrapper select-wrapper">
                              <InputField
                                type="single-select"
                                name="existingId"
                                id="existingId"
                                className="input playlist-input-field"
                                hasChildrenAsOption
                              >
                                <option className="placeholder-text">
                                  <T>Select Playlist</T>
                                </option>
                                {orderBy(
                                  this.state.playlists,
                                  ['name'],
                                  ['asc'],
                                ).map((playlist, index) => (
                                  <option
                                    key={index}
                                    value={playlist.uuid}
                                    title={playlist.name}
                                  >
                                    <T>{playlist.name.substring(0, 100)}</T>
                                    {playlist.name.length > 100 ? '...' : ''}
                                  </option>
                                ))}
                              </InputField>
                              <span className="select-icon">
                                <span />
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="input-wrapper">
                          <InputField
                            type="text"
                            name="title"
                            id="title"
                            className="input playlist-input-field"
                            placeholder="Playlist Name"
                            autoComplete={'off'}
                            value={this.state.newPlaylistName}
                            onFocus={this.handleFocus}
                            onChange={this.handleChange}
                            autoFocus={true}
                          />
                          {this.state.warning && (
                            <span className={'warning'}>
                              <T>{this.state.warning}</T>
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {isPlaylistExists && (
                      <span className="playlist-toggle-text">
                        <T>Or</T>{' '}
                        <button
                          className="link"
                          onClick={this.updatePlaylistType}
                          data-playlist-type={'new'}
                        >
                          <T>create a new playlist</T>
                        </button>
                      </span>
                    )}
                    {!isPlaylistExists && this.state.playlists.length > 0 && (
                      <span className="playlist-toggle-text">
                        Or{' '}
                        <button
                          className="link"
                          onClick={this.updatePlaylistType}
                          data-playlist-type={'existing'}
                        >
                          <T>choose an existing playlist</T>
                        </button>
                      </span>
                    )}
                    <UBButton
                      className="modal-btn-submit playlist-btn"
                      variant="uppbeat"
                      width="100%"
                      disabled={isPlaylistExists ? this.state.loading : false}
                      type={this.state.isLoading ? 'button' : 'submit'}
                      label={
                        !this.state.isLoading ? this.props.t('Add To Playlist') : null
                      }
                      icon={this.state.isLoading ? <Loader /> : null}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h2 className="ub-modal-title">
              <T keyName={'addToPlaylist'} ns="common">
                Add to playlist
              </T>
            </h2>
            <div className="playlist-modal__create">
              <div>
                <div className="playlist-image">{artistThumb}</div>
                <div className="playlist-name">
                  <span className="track-name">
                    {this.props.playlist.track.name}
                  </span>
                  {artist && <span className="artist-name">{artist.name}</span>}
                </div>
              </div>
              <form
                onSubmit={
                  isPlaylistExists ? this.onSubmitExisiting : this.onSubmitNew
                }
              >
                <div className="col-margin">
                  {isPlaylistExists ? (
                    <>
                      {this.state.loading && (
                        <div className="input-wrapper select-wrapper">
                          <div className="">
                            <InputField
                              type="single-select"
                              className="input playlist-input-field"
                              hasChildrenAsOption
                            >
                              <option className="placeholder-text">
                                <T>Loading playlists...</T>
                              </option>
                            </InputField>
                            <span className="select-icon">
                              <span />
                            </span>
                          </div>
                        </div>
                      )}
                      {!this.state.loading && (
                        <div className="input-wrapper select-wrapper">
                          <InputField
                            type="single-select"
                            name="existingId"
                            id="existingId"
                            className="input playlist-input-field"
                            hasChildrenAsOption
                          >
                            <option className="placeholder-text">
                              <T>Select Playlist</T>
                            </option>
                            {orderBy(
                              this.state.playlists,
                              ['name'],
                              ['asc'],
                            ).map((playlist, index) => (
                              <option
                                key={index}
                                value={playlist.uuid}
                                title={playlist.name}
                              >
                                <T>{playlist.name.substring(0, 100)}</T>
                                {playlist.name.length > 100 ? '...' : ''}
                              </option>
                            ))}
                          </InputField>
                          <span className="select-icon">
                            <span />
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="input-wrapper">
                      <InputField
                        type="text"
                        name="title"
                        id="title"
                        className="input playlist-input-field"
                        placeholder="Playlist Name"
                        autoComplete={'off'}
                        value={this.state.newPlaylistName}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        autoFocus={true}
                      />
                      {this.state.warning && (
                        <span className={'warning'}>
                          <T>{this.state.warning}</T>
                        </span>
                      )}
                    </div>
                  )}
                </div>
                {isPlaylistExists && (
                  <span className="playlist-toggle-text">
                    <T>Or</T>{' '}
                    <button
                      className="link"
                      onClick={this.updatePlaylistType}
                      data-playlist-type={'new'}
                    >
                      <T>create a new playlist</T>
                    </button>
                  </span>
                )}
                {!isPlaylistExists && this.state.playlists.length > 0 && (
                  <span className="playlist-toggle-text">
                    Or{' '}
                    <button
                      className="link"
                      onClick={this.updatePlaylistType}
                      data-playlist-type={'existing'}
                    >
                      <T>choose an existing playlist</T>
                    </button>
                  </span>
                )}
                <UBButton
                  className="modal-btn-submit playlist-btn"
                  variant="uppbeat"
                  width="100%"
                  disabled={isPlaylistExists ? this.state.loading : false}
                  type={this.state.isLoading ? 'button' : 'submit'}
                  label={!this.state.isLoading ? this.props.t('Add To Playlist') : null}
                  icon={this.state.isLoading ? <Loader /> : null}
                />
              </form>
            </div>
          </>
        )}
      </>
    );
  }
}
// Map redux actions to local props.
const mapDispatchToProps = (dispatch) => ({
  showPlaylistSuccess: (track, playlistName, newPlaylist) => {
    setTimeout(() => {
      dispatch(showPlaylistSuccess(track, playlistName, newPlaylist));
      setTimeout(() => {
        dispatch(hidePlaylistSuccess());
      }, 4000);
    }, 400);
  },
  disableKeyboardControls: () => dispatch(disableKeyboardControls()),
});

const mapStateToProps = (state) => ({
  playlistFrame: state.playlistFrame,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistModal);
