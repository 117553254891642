import { createContext, useMemo } from 'react';

import type { ReactNode, RefObject } from 'react';

interface IScrollContext {
  scrollContainerRef: RefObject<HTMLDivElement>;
}

interface ScrollProviderProps {
  children: ReactNode;
  scrollContainerRef: RefObject<HTMLDivElement>;
}

export const ScrollContext = createContext<IScrollContext>({
  scrollContainerRef: { current: null },
});

/**
 * A provider creating a scrollable container and making the ref available for
 * child components to use.
 */
export const ScrollProvider = ({
  children,
  scrollContainerRef,
}: ScrollProviderProps) => {
  const ctx = useMemo(
    (): IScrollContext => ({
      scrollContainerRef,
    }),
    [scrollContainerRef],
  );

  return (
    <ScrollContext.Provider value={ctx}>{children}</ScrollContext.Provider>
  );
};
