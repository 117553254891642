import { STATIC_PAGE_PATH_PREFIX } from '@/constants/routes';

import type { NextRouter } from 'next/router';

/**
 * Strip any static path prefix identified for the current route.
 *
 * If we have rewritten /my-page to /static/my-page this should happen entirely
 * transparently. As far as any subsequent page logic is concerned we should
 * consider ourselves as still being at /my-page.
 */
export const stripStaticPrefix = (value: string) =>
  value
    .replace(new RegExp(`^${STATIC_PAGE_PATH_PREFIX}`), '/')
    .replace(/\/\//g, '/');

// eslint-disable-next-line no-restricted-properties
export const asPath = (router: NextRouter) => stripStaticPrefix(router.asPath);

export const pathname = (router: NextRouter) =>
  // eslint-disable-next-line no-restricted-properties
  stripStaticPrefix(router.pathname);
