import { useEffect, useState } from 'react';

export function useIsClient() {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setClient(true);
    }
  }, []);

  return isClient;
}
