import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react';
import * as Select from '@radix-ui/react-select';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { connect } from 'react-redux';
import { addModal } from '../../actions/authenticationAction';
import { getAuthToken } from '../../auth';
import { useTranslate } from '../../hooks/useTranslate';
import { parseEmoji } from '../../utils/helpers';
import A from '../A';
import IconCopy from '../IconCopy';
import ChevronDown from '../Icons/ChevronDown';
import Facebook from '../Icons/Social/Facebook';
import Instagram from '../Icons/Social/Instagram';
import TikTok from '../Icons/Social/TikTok';
import Twitch from '../Icons/Social/Twitch';
import Twitter from '../Icons/Social/Twitter';
import YouTube from '../Icons/Social/YouTube';
import { AccountModalNames, useAccountModals } from '../Modals/Account';
import { Portal } from '../Portal';
import { T } from '../T';
import UBButton from '../UBButton';
import { useWhitelistedChannels } from '../UBWhitelist/hooks/useWhitelistedChannels';
import styles from './styles.module.css';

const platforms = [
  {
    name: 'YouTube',
    icon: <YouTube className={styles.SelectSocialIcon} />,
    description:
      "Show the artist some love and avoid claims on YouTube by pasting the credit below into your video's description.",
    descriptionPremium:
      "Show the artist some love by pasting the credit below into your video's description.",
    banner: 'Each credit is valid for a single video only',
    bannerPremium:
      'Crediting is optional when using tracks on your safelisted channels',
    bannerBusiness:
      'Crediting is optional when using tracks on your safelisted channels',
  },
  {
    name: 'Instagram',
    icon: <Instagram className={styles.SelectSocialIcon} />,
    description:
      'Show the artist some love by pasting the credit below into your post caption.',
    descriptionPremium:
      'Show the artist some love by pasting the credit below into your post caption.',
    banner: 'Each credit is valid for a single post only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
  {
    name: 'TikTok',
    icon: <TikTok className={styles.SelectSocialIcon} />,
    description:
      "Show the artist some love by pasting the credit below into your video's description.",
    descriptionPremium:
      "Show the artist some love by pasting the credit below into your video's description.",
    banner: 'Each credit is valid for a single video only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
  {
    name: 'Facebook',
    icon: <Facebook className={styles.SelectSocialIcon} />,
    description:
      'Show the artist some love by pasting the credit below into your post caption.',
    descriptionPremium:
      'Show the artist some love by pasting the credit below into your post caption.',
    banner: 'Each credit is valid for a single post only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
  {
    name: 'X',
    icon: <Twitter className={styles.SelectSocialIcon} />,
    description:
      'Show the artist some love by pasting the credit below into your post or thread.',
    descriptionPremium:
      'Show the artist some love by pasting the credit below into your post or thread.',
    banner: 'Each credit is valid for a single post only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
  {
    name: 'Twitch',
    icon: <Twitch className={styles.SelectSocialIcon} />,
    description:
      'Show the artist some love by pasting the credit below into your About section or comment section.',
    descriptionPremium:
      'Show the artist some love by pasting the credit below into your About section or comment section.',
    banner: 'Each credit is valid for 1 stream/VOD only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
  {
    name: 'Other',
    icon: <span className={styles.SelectSocialIcon}>🎮</span>,
    description:
      'Show the artist some love by pasting the credit in a visible place near your content or credits section.',
    descriptionPremium:
      'Show the artist some love by pasting the credit in a visible place near your content or credits section.',
    banner: 'Each credit is valid for one use only',
    bannerPremium: 'As a Premium user, crediting the track is optional',
    bannerBusiness: 'As a Business user, crediting the track is optional',
  },
];

const CreditInformation = ({ platform, accountType }) => {
  let bannerText = '';

  switch (accountType) {
    case 'premium':
      bannerText = platform.bannerPremium;
      break;
    case 'business':
      bannerText = platform.bannerBusiness;
      break;
    case 'free':
    default:
      bannerText = platform.banner;
      break;
  }

  return (
    <div
      className={
        accountType === 'free'
          ? 'ub-popup-download-content-banner free'
          : 'ub-popup-download-content-banner'
      }
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          marginRight: '6px',
          marginTop: '3px',
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.68684 7.67042C6.72368 7.70329 6.7636 7.71972 6.80658 7.71972H7.19342C7.2364 7.71972 7.27325 7.70329 7.30395 7.67042C7.34079 7.63756 7.36228 7.60141 7.36842 7.56197L7.81974 4.79155V3.68732C7.81974 3.64131 7.80132 3.59859 7.76447 3.55916C7.73377 3.51972 7.69386 3.5 7.64474 3.5H6.35526C6.30614 3.5 6.26316 3.51972 6.22632 3.55916C6.19561 3.59859 6.18026 3.64131 6.18026 3.68732V4.81127L6.63158 7.56197C6.63772 7.60141 6.65614 7.63756 6.68684 7.67042ZM6.38289 8.91268C6.21097 9.09671 6.125 9.3169 6.125 9.57324C6.125 9.82301 6.21097 10.0399 6.38289 10.2239C6.55482 10.408 6.75746 10.5 6.99079 10.5C7.23026 10.5 7.43596 10.408 7.60789 10.2239C7.78596 10.0399 7.875 9.82301 7.875 9.57324C7.875 9.3169 7.78596 9.09671 7.60789 8.91268C7.43596 8.72206 7.23026 8.62676 6.99079 8.62676C6.75746 8.62676 6.55482 8.72206 6.38289 8.91268Z"
          fill="currentColor"
        />
      </svg>
      <span>
        <T>{bannerText}</T>
      </span>
    </div>
  );
};

const UBPopupDownload = (props) => {
  const { t } = useTranslate();
  const { locale } = useRouter();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [emailButtonLabel, setEmailButtonLabel] = useState('Email me a copy');
  const [platformIndex, setPlatformIndex] = useState(0);
  const { setCurrentModal } = useAccountModals();
  const { data: whitelistedChannels } = useWhitelistedChannels();
  const showIssueBanner = useFeatureIsOn('issue-banner');
  const issueBannerCopy = useFeatureValue(
    'issue-banner-text',
    "We're working to resolve an issue with Uppbeat Credits & safelisting channels. Thanks for your patience.",
  );

  let hasChannels = whitelistedChannels.length > 0;

  const { onRemoveModal, userAuth, license } = props;

  const [isWarningVisible, setIsWarningVisible] = useState(true);
  const showWarning = !!license?.downloadedTrackInfo?.showWarning;

  useEffect(() => {
    parseEmoji();
  }, []);

  const getErrorMessageByCode = (code) => {
    let errorBlock = '';

    switch (code) {
      case 4:
        errorBlock = (
          <p style={{ textAlign: 'center' }}>
            <T>You've hit the fair usage limit. Please get in touch.</T>
          </p>
        );
        break;
      case 10:
        errorBlock = (
          <p style={{ textAlign: 'center' }}>
            <T>You failed the reCAPTCHA.</T>
          </p>
        );
        break;
      case 11:
        errorBlock = (
          <p style={{ textAlign: 'center' }}>
            <T>Please complete the reCAPTCHA.</T>
          </p>
        );
        break;
      default:
        errorBlock = (
          <p style={{ textAlign: 'center' }}>
            Error code {code}. Sorry, something went wrong. Please wait a few
            minutes, and then check your{' '}
            <A
              href="/my/downloads"
              className="download-text"
              onClick={onRemoveModal}
            >
              Downloads
            </A>{' '}
            page for your Uppbeat credit.
          </p>
        );
        break;
    }

    return errorBlock;
  };

  const copyToClipboard = () => {
    const licenseCode = document.getElementById('license-code');

    // execCommand is deprecated so this block runs navigator.clipboard unless not available
    if (!navigator.clipboard) {
      document.execCommand('copy');
    } else {
      navigator.clipboard.writeText(licenseCode.value);
    }

    setCopiedToClipboard(true);

    window.setTimeout(() => {
      setCopiedToClipboard(false);
    }, 5000);
  };

  const handleUpgradeNow = () => {
    setCurrentModal(AccountModalNames.GoPremium);
  };

  const getTrackModalContent = () => {
    if (creditReady) {
      creditArea = (
        <>
          <textarea
            className={clsx('ub-popup-download-content', {
              free: !(userAuth.isPremium || userAuth.isBusiness),
            })}
            id="license-code"
            name="otherPlatformDetails"
            value={licenseText}
            readOnly
          />
          <UBButton
            className={copiedToClipboard ? 'copied' : ''}
            borderRadius="full"
            variant={copiedToClipboard ? 'success' : 'transparent'}
            size="xsmall"
            ariaLabel="copy"
            icon={
              copiedToClipboard ? (
                <img src="https://cdn.uppbeat.io/images/icons/UppBeat_Icons_Tick.svg" />
              ) : (
                <IconCopy />
              )
            }
            onClick={copyToClipboard}
          />
        </>
      );
    } else if (notReadyNeedsCode) {
      creditArea = (
        <div
          className={clsx('ub-popup-download-content loading', {
            free: !(userAuth.isPremium || userAuth.isBusiness),
          })}
        >
          {license.isGenerateLicenseCodeHasError ? (
            <>
              {getErrorMessageByCode(license.isGenerateLicenseCodeHasErrorCode)}
            </>
          ) : (
            <>
              {!errorMessage && (
                <div className="circle-loader">
                  <div className="bounce1" />
                  <div className="bounce2" />
                  <div className="bounce3" />
                </div>
              )}
              <p className="circle-loader-text text-center">
                {errorMessage || warningMessage || (
                  <>We're just getting your Uppbeat Credit ready...</>
                )}
              </p>
            </>
          )}
        </div>
      );
    }

    return (
      <>
        {isWarningVisible && showWarning && (
          <FocusLock
            className={styles.warningContainer}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                setIsWarningVisible(false);
              }
            }}
          >
            <div className={styles.warningContent}>
              <h3 className={styles.warningTitle}>
                <T>Heads-up!</T>
              </h3>
              <p className={styles.warningBody}>
                <T>
                  Looks like you've generated this credit previously. Remember,
                  each credit is valid for a single video only.
                </T>
              </p>
              <div className={styles.warningActions}>
                <UBButton
                  onClick={() => setIsWarningVisible(false)}
                  variant="uppbeat"
                  size="small"
                >
                  <T>View credit</T>
                </UBButton>
              </div>
            </div>
          </FocusLock>
        )}
        <h2
          className={clsx(styles.sayThanksTitle, {
            'text-balance w-5/6 mx-auto': locale !== 'en',
          })}
        >
          <T>
            {userAuth.isPremium && hasChannels
              ? 'Say thanks'
              : 'Say thanks, avoid claims'}
          </T>
          {userAuth.isPremium && hasChannels && (
            <span className="text-inherit hover:text-inherit text-[1.25rem]">
              {' '}
              (optional)
            </span>
          )}
          <span> 🙌</span>
        </h2>
        <Select.Root
          defaultValue={platforms[0].name}
          onValueChange={(value) => {
            setPlatformIndex(
              platforms.findIndex((platform) => platform.name === value),
            );
          }}
        >
          <Select.Trigger
            className={clsx(styles.SelectTrigger, 'group flex-shrink-0')}
          >
            <Select.Value placeholder={t('Select a platform...')} />
            <Select.Icon asChild>
              <ChevronDown
                className={clsx(
                  styles.SelectIcon,
                  'group-data-[state=open]:rotate-180 transform',
                )}
              />
            </Select.Icon>
          </Select.Trigger>

          <Select.Portal>
            <Select.Content
              className={styles.SelectContent}
              position="popper"
              sideOffset={5}
              side="bottom"
              align="center"
            >
              <Select.ScrollUpButton className={styles.SelectScrollButton} />
              <Select.Viewport className={styles.SelectViewport}>
                <Select.Group className={styles.SelectGroup}>
                  {platforms.map((platform) => (
                    <Select.Item
                      key={platform.name}
                      value={platform.name}
                      className={clsx(
                        styles.SelectItem,
                        'focus:outline-none focus-visible:ring-2 focus-visible:ring-uppbeat focus-visible:ring-opacity-50',
                      )}
                    >
                      <Select.ItemText>
                        {platform.icon}
                        <span>{platform.name}</span>
                      </Select.ItemText>
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Viewport>
              <Select.ScrollDownButton className={styles.SelectScrollButton} />
              <Select.Arrow className={styles.SelectArrow} />
            </Select.Content>
          </Select.Portal>
        </Select.Root>
        <p className={styles.sayThanksBody}>
          <T>
            {userAuth.isPremium && hasChannels
              ? platforms[platformIndex].descriptionPremium
              : platforms[platformIndex].description}
          </T>
        </p>
        <div className="ub-popup-content-wrapper">
          <CreditInformation
            platform={platforms[platformIndex]}
            accountType={
              userAuth.isPremium
                ? 'premium'
                : userAuth.isBusiness
                ? 'business'
                : 'free'
            }
          />
          {creditArea}
        </div>
        {creditReady && (
          <div
            className={clsx('ub-popup-download-footer', {
              '!flex-col': locale !== 'en',
            })}
          >
            {!license.viewCredit && (
              <UBButton
                className={`${sendingEmail ? 'sending' : ''} ${
                  sentEmail ? 'sent' : ''
                }`}
                variant={sentEmail ? 'success' : 'uppbeat'}
                disabled={
                  !license.downloadedTrackInfo.licenseCode ||
                  sendingEmail ||
                  sentEmail
                }
                borderRadius="full"
                padding="0 1rem"
                label={t(emailButtonLabel)}
                size="small"
                icon={
                  sentEmail ? (
                    <Image
                      unoptimized
                      src="https://cdn.uppbeat.io/icons/adaptive-icons/check.svg"
                      alt="Send"
                      width={16}
                      height={16}
                    />
                  ) : (
                    <Image
                      unoptimized
                      src="https://cdn.uppbeat.io/icons/adaptive-icons/send.svg"
                      alt="Send"
                      width={16}
                      height={16}
                    />
                  )
                }
                onClick={() => sendEmail()}
              />
            )}
            {!userAuth.isPremium && !userAuth.isBusiness && (
              <div className={clsx('ub-popup-download-footer-downloads-left')}>
                <p>{`${userAuth.creditsCurrent} downloads remaining`}</p>
                <p>
                  <span onClick={handleUpgradeNow}>Upgrade now</span> for
                  unlimited downloads
                </p>
              </div>
            )}
          </div>
        )}
        {showIssueBanner && (
          <div className="bg-red text-white p-2 text-xs rounded-btn text-center">
            {issueBannerCopy}
          </div>
        )}
      </>
    );
  };

  const getSfxModalContent = () => {
    const { track } = license;

    return (
      <>
        <h2>
          {!license.isGenerateLicenseCodeHasErrorCode ? (
            <>
              <T>Your download is starting</T>{' '}
              <span className="download-token">
                <img src="https://uppbeat.imgix.net/icons/adaptive-icons/uppbeat.svg" />
              </span>
            </>
          ) : (
            <>
              <T>Sorry, something went wrong. Error code</T>:{' '}
              {license.isGenerateLicenseCodeHasErrorCode}
            </>
          )}
        </h2>
        <div className="ub-popup-content-wrapper">
          <CreditInformation
            platform={platforms[platformIndex]}
            accountType={
              userAuth.isPremium
                ? 'premium'
                : userAuth.isBusiness
                ? 'business'
                : 'free'
            }
          />
          <div
            className={clsx('ub-popup-download-content sfx', {
              free: !(userAuth.isPremium || userAuth.isBusiness),
            })}
          >
            <div className="ub-track-avatar">
              <img
                src={track?.sfx_classification_id?.preview_image}
                alt={track?.sfx_classification_id?.name}
              />
            </div>
            <div className="ub-track-info">
              <div className="ub-track-info-title-left">{track?.name}</div>
              <div className="ub-track-info-subtitle">
                {track?.artist?.name}
              </div>
            </div>
          </div>
        </div>
        <p>
          💡 <T>You don't need to include a credit when using sound effects.</T>
        </p>
        {!userAuth.isPremium && (
          <div className="ub-popup-download-footer">
            <p>{`${userAuth.creditsCurrent} downloads remaining`}</p>
            <p>
              <T>Upgrade for unlimited.</T>{' '}
              <span onClick={handleUpgradeNow}>
                <T>View pricing</T>
              </span>
            </p>
          </div>
        )}
      </>
    );
  };

  const sendUppbeatCreditEmail = () => {
    return fetch(`${process.env.NEXT_PUBLIC_API}uppbeat-credit/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: getAuthToken() || null,
      },
      body: JSON.stringify({
        license_id: license.downloadedTrackInfo.licenseId,
      }),
    })
      .then((res) => res.json())
      .catch((err) => err);
  };

  const sendEmail = () => {
    setSendingEmail(true);
    setEmailButtonLabel('Sending email...');
    window.setTimeout(() => {
      setSendingEmail(false);
      sendUppbeatCreditEmail();
      setSentEmail(true);
      setEmailButtonLabel('Email sent');
    }, 4500);
  };

  let licenseText = 'Music from #Uppbeat';

  if (!userAuth.isPremium) {
    licenseText += ' (free for Creators!):';
  }

  if (license.downloadedTrackInfo && license.downloadedTrackInfo.download) {
    licenseText += `\n${license.downloadedTrackInfo.download[0].track_page_url}`;

    // Only add license code if it's for YouTube
    if (
      license.downloadedTrackInfo.licenseCode &&
      platforms[platformIndex].name === 'YouTube'
    ) {
      licenseText += `\nLicense code: ${license.downloadedTrackInfo.licenseCode}`;
    }
  }

  let creditArea = (
    <div
      className={clsx('ub-popup-download-content loading', {
        free: !(userAuth.isPremium || userAuth.isBusiness),
      })}
    />
  );
  let creditReady =
    license.downloadedTrackInfo &&
    license.downloadedTrackInfo.download &&
    (license.downloadedTrackInfo.needsDownloadCode
      ? license.downloadedTrackInfo.licenseCode
      : !license.downloadedTrackInfo.needsDownloadCode);

  let notReadyNeedsCode =
    !license.downloadedTrackInfo ||
    !license.downloadedTrackInfo.download ||
    (license.downloadedTrackInfo.needsDownloadCode &&
      !license.downloadedTrackInfo.licenseCode);

  const [warningMessage, setWarningMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Timeout for warning and error messages when we're waiting exceptionally
  // long for the license code to be generated
  useEffect(() => {
    let warningTimer;
    let errorTimer;

    if (notReadyNeedsCode) {
      warningTimer = setTimeout(() => {
        setWarningMessage(
          'Generating your license is taking longer than usual',
        );
      }, 10000);

      errorTimer = setTimeout(() => {
        setErrorMessage(
          'Unable to generate your license code at this time, please try again later from your "Download History" page',
        );
      }, 30000);
    }

    return () => {
      clearTimeout(warningTimer);
      clearTimeout(errorTimer);

      setWarningMessage('');
      setErrorMessage('');
    };
  }, [notReadyNeedsCode]);

  return (
    <Portal>
      {/* TODO: Refactor / improve this when modal rework is started */}
      {license.track.is_sfx &&
      !license?.isGenerateLicenseCodeHasErrorCode ? null : (
        <div className="ub-popup">
          <div
            className={clsx('ub-popup-bottom ub-popup-download', {
              // Enforce max height for bussiness / premium users
              'lg:max-h-[460px] !overflow-auto':
                userAuth.isPremium || userAuth.isBusiness,
            })}
          >
            <UBButton
              className="popup-close-btn"
              onClick={onRemoveModal}
              variant="primary"
              borderRadius="full"
              menu="close"
            />
            {license?.isGenerateLicenseCodeHasErrorCode === 4 && (
              <>
                <div className="ub-popup-alert-emoji">⚠️</div>
                <h2>
                  <T>You’ve hit the fair usage checkpoint</T>
                </h2>
                <p>
                  To keep your downloads going, please{' '}
                  <A href="/help-center/contact-us">contact us</A> and we'll
                  quickly check your use aligns with our terms.
                </p>
                <p className="ub-popup-checkpoint">
                  <strong>Why this checkpoint?</strong> With Uppbeat Premium,
                  we're dedicated to providing all the downloads you need, as
                  long as they align with our terms. This measure helps
                  safeguard our artists against any misuse or fraudulent
                  activities - we appreciate your understanding.
                </p>
              </>
            )}
            {license?.isGenerateLicenseCodeHasErrorCode !== 4 && (
              <>
                {license.track.is_sfx
                  ? getSfxModalContent()
                  : getTrackModalContent()}
              </>
            )}
          </div>
        </div>
      )}
    </Portal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAddModal: (modal, intent, heading) =>
    dispatch(addModal(modal, intent, heading)),
});

const mapStateToProps = (state) => ({
  userAuth: state.userAuth,
  license: state.license,
});

export default connect(mapStateToProps, mapDispatchToProps)(UBPopupDownload);
