import * as Sentry from '@sentry/nextjs';
import { updateActiveCampaignContact } from '../api/activecampaign/update-contact';
import { getAuthToken } from '../auth';
import { trackDownload as trackDownloadEvent } from '../utils/analytics';
import optionallySkipRecaptcha from '../utils/optionallySkipRecaptcha';

export function fetchLicenses() {
  return fetch(`${process.env.NEXT_PUBLIC_API}licenses/`, {
    method: 'GET',
    // mode: 'CORS',
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function addToCart(cartItem) {
  //return { type: 'ADD_TO_CART', cartItem };
}

export function addDownloadIntent(track) {
  return { type: 'ADD_DOWNLOAD_INTENT', track };
}

export function clearDownloadIntent() {
  return { type: 'CLEAR_DOWNLOAD_INTENT' };
}

export function addPremiumDownloadIntent(track) {
  return { type: 'ADD_PREMIUM_DOWNLOAD_INTENT', track };
}

export function clearPremiumDownloadIntent() {
  return { type: 'CLEAR_PREMIUM_DOWNLOAD_INTENT' };
}

export function addLicense(
  track,
  isDownload = true,
  viewCredit = false,
  recaptchaVersion,
  recaptchaToken,
) {
  return {
    type: 'ADD_LICENSE',
    track,
    isDownload,
    viewCredit,
    recaptchaVersion,
    recaptchaToken,
  };
}

export function downloadTrackInfo(info) {
  return { type: 'DOWNLOAD_TRACK_INFO', downloadedTrackInfo: info };
}

export function generateLicenseCodeStart() {
  return { type: 'GENERATE_LICENSE_CODE' };
}

export function generateLicenseCodeError(error, code = -1) {
  return {
    type: 'GENERATE_LICENSE_CODE_ERROR',
    payload: error,
    errorCode: code,
  };
}

export function removeLicense() {
  return { type: 'REMOVE_LICENSE' };
}

export function requestSubscriptionLicense(
  trackId,
  edits,
  contextId,
  token,
  recaptchaVersion,
  recaptchaToken,
) {
  let formData = new FormData();
  formData.append('track', trackId);
  formData.append('edits', edits);
  formData.append('contextId', contextId);
  formData.append('token', token);
  formData.append('recaptcha_version', recaptchaVersion);
  formData.append('recaptcha_token', recaptchaToken);

  // Force fail recaptcha if value set in localstorage
  if (localStorage.getItem('force_fail_recaptcha')) {
    formData.append('force_fail_recaptcha', '1');
  }

  return fetch(`${process.env.NEXT_PUBLIC_API}subscription/generatelicense`, {
    method: 'POST',
    body: formData,
    headers: optionallySkipRecaptcha(),
  })
    .then(async (res) => {
      updateActiveCampaignContact();
      const json = await res.json();

      if (
        json.error ||
        !json.success ||
        !json.licenseId ||
        !json?.download[0] ||
        !json?.download[0]?.download_mp3
      ) {
        Sentry.captureException(
          new Error(
            `License generation: ${JSON.stringify({
              ...json,
              download: json.download,
              youtube_channel: json.youtube_channel,
            })}`,
          ),
        );
      }
      return json;
    })
    .catch((err) => err);
}

export function generateLicenseCode(licenseId) {
  let formData = new FormData();
  formData.append('licenseId', licenseId);
  formData.append('token', getAuthToken());
  return fetch(
    `${process.env.NEXT_PUBLIC_API}subscription/generatelicensecode`,
    {
      method: 'POST',
      body: formData,
    },
  )
    .then(async (res) => {
      updateActiveCampaignContact();

      const json = await res.json();

      console.debug('generateLicenseCode:', json);

      return json;
    })
    .catch((err) => err);
}
export function requestSfxLicense(trackId, edits, contextId, token) {
  let formData = new FormData();
  let isString = typeof contextId === 'string';
  if (isString) {
    contextId = contextId.replace('sfx-', '');
  }
  formData.append('sfxVariant', trackId);
  formData.append('edits', edits);
  formData.append('contextId', contextId);
  formData.append('token', token);
  return fetch(`${process.env.NEXT_PUBLIC_API}sfx/generatelicense`, {
    method: 'POST',
    body: formData,
  })
    .then((res) => {
      updateActiveCampaignContact();
      return res.json();
    })
    .catch((err) => err);
}

export function getRemainingDownloads() {
  return fetch(`${process.env.NEXT_PUBLIC_API}get_remaining_downloads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: getAuthToken() || null,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
}

export function trackDownload(trackId) {
  try {
    trackDownloadEvent();
  } catch (e) {
    console.warn('Error tracking download event', e);
  }
  return fetch(
    `${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        requesttype: 'track',
        eventinfo: `${trackId}`,
      }),
    },
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function sfxDownload(trackId) {
  return fetch(
    `${process.env.NEXT_PUBLIC_API}activecampaign/Event/Trackevent`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        requesttype: 'sfx',
        eventinfo: `${trackId}`,
      }),
    },
  )
    .then((res) => res.json())
    .catch((err) => err);
}
