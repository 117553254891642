import Icons from '@/components/Modals/Sidebar/components/Icons';
import Tab from '@/components/Modals/Sidebar/components/Tab';

import { CreateAccountModalScreenName } from './types';

import type { dynamicSidebarHeaderTab } from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

const CreateAccountFreeTab = () => (
  <Tab>
    <Icons.Free /> Free
  </Tab>
);

const CreateAccountPremiumTab = () => (
  <Tab>
    <Icons.Premium /> Premium
  </Tab>
);

const CreateAccountBusinessTab = () => (
  <Tab>
    <Icons.Business /> Business
  </Tab>
);

export const createAccountModalTabs: dynamicSidebarHeaderTab[] = [
  {
    tabs: [<CreateAccountFreeTab key="create-account-free-tab" />],
    screenNames: [
      CreateAccountModalScreenName.Free,
      CreateAccountModalScreenName.WhatsYourThemeFree,
      CreateAccountModalScreenName.WhatsYourVibeFree,
    ],
  },
  {
    tabs: [<CreateAccountPremiumTab key="create-account-premium-tab" />],
    screenNames: [
      CreateAccountModalScreenName.Premium,
      CreateAccountModalScreenName.CheckoutPremium,
      CreateAccountModalScreenName.WhatsYourThemePremium,
      CreateAccountModalScreenName.WhatsYourVibePremium,
    ],
  },
  {
    tabs: [<CreateAccountBusinessTab key="create-account-business-tab" />],
    screenNames: [
      CreateAccountModalScreenName.Business,
      CreateAccountModalScreenName.CheckoutBusiness,
      CreateAccountModalScreenName.WhatsYourThemeBusiness,
      CreateAccountModalScreenName.WhatsYourVibeBusiness,
    ],
  },
];
