import SidebarHeader from '@/components/Modals/Sidebar/components/SidebarHeader';
import useDynamicSidebarHeader from '@/components/Modals/Sidebar/hooks/useDynamicSidebarHeader';

import { addToPlaylistModalTabs } from './tabs';

const AddToPlaylistModalHeader = () => {
  // Used for controlling the sidebar header when specific screens are active
  useDynamicSidebarHeader({
    dynamicTabs: [...addToPlaylistModalTabs],
  });

  return <SidebarHeader />;
};

export { AddToPlaylistModalHeader };
