const modal = (state = false, action) => {
  switch (action.type) {
    case 'REMOVE_MODAL':
      return false;
    case 'ADD_MODAL':
      return {
        type: action.modal,
        intent: action.intent,
        heading: action.heading
      };
    default:
      return state;
  }
};

export default modal;
