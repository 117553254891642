const license = (state = {
  active: false,
  isDownload: false,
  isCalling: false,
  downloadedTrackInfo: {},
  isGenerateLicenseCodeHasError: false,
  isGenerateLicenseCodeHasErrorMsg: false,
  isGenerateLicenseCodeHasErrorCode: false,
  viewCredit: false,
  track: false,
}, action) => {

  switch (action.type) {
    case 'ADD_LICENSE':
      return {
        ...state,
        active: !action.isDownload,
        track: action.track,
        isDownload: action.isDownload,
        viewCredit: action.viewCredit,
        isCalling: true,
        recaptchaVersion: action.recaptchaVersion,
        recaptchaToken: action.recaptchaToken,
        isGenerateLicenseCodeHasError: false,
        isGenerateLicenseCodeHasErrorMsg: false,
        isGenerateLicenseCodeHasErrorCode: false,
        downloadedTrackInfo: {
          ...state.downloadedTrackInfo,
          download: false
        },
      };

    case 'REMOVE_LICENSE':
      return {
        ...state,
        active: false,
        isDownload: false,
        viewCredit: false,
        isCalling: false,
        track: false,
        downloadedTrackInfo: {
          ...state.downloadedTrackInfo,
          download: false,
          licenseCode: ""
        },
      };

    case 'DOWNLOAD_TRACK_INFO':
      return {
        ...state,
        isCalling: false,
        isGenerateLicenseCodeHasError: false,
        isGenerateLicenseCodeHasErrorMsg: false,
        isGenerateLicenseCodeHasErrorCode: false,
        downloadedTrackInfo: {
          ...state.downloadedTrackInfo,
          ...action.downloadedTrackInfo
        }
      }

    case 'GENERATE_LICENSE_CODE':
      return {
        ...state,
        isGenerateLicenseCodeHasError: false,
        isGenerateLicenseCodeHasErrorMsg: false,
        isGenerateLicenseCodeHasErrorCode: false,
        downloadedTrackInfo: {
          ...state.downloadedTrackInfo,
          licenseCode: ""
        }
      }

    case 'GENERATE_LICENSE_CODE_ERROR':
      return {
        ...state,
        isGenerateLicenseCodeHasError: true,
        isGenerateLicenseCodeHasErrorMsg: action.payload,
        isGenerateLicenseCodeHasErrorCode: action.errorCode,
      }

    default:
      return state;
  }
};

export default license;
