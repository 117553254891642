import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar8Sfx extends React.Component {
    render() {
        const Sidebar8Sfx = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
<g>
\t<path d="M1.5,5.26L1.5,5.26c-0.21,0-0.39-0.17-0.39-0.39V3.32c0-0.21,0.17-0.39,0.39-0.39h0
\t\tc0.21,0,0.39,0.17,0.39,0.39v1.55C1.88,5.08,1.71,5.26,1.5,5.26z"/>
\t<path d="M4,6.14L4,6.14c-0.21,0-0.39-0.17-0.39-0.39V2.44c0-0.21,0.17-0.39,0.39-0.39h0c0.21,0,0.39,0.17,0.39,0.39
\t\tv3.31C4.39,5.97,4.21,6.14,4,6.14z"/>
\t<path d="M2.75,6.85L2.75,6.85c-0.21,0-0.39-0.17-0.39-0.39V1.73c0-0.21,0.17-0.39,0.39-0.39h0
\t\tc0.21,0,0.39,0.17,0.39,0.39v4.74C3.14,6.68,2.96,6.85,2.75,6.85z"/>
\t<path d="M6.5,5.26L6.5,5.26c-0.21,0-0.39-0.17-0.39-0.39V3.32c0-0.21,0.17-0.39,0.39-0.39h0
\t\tc0.21,0,0.39,0.17,0.39,0.39v1.55C6.89,5.08,6.71,5.26,6.5,5.26z"/>
\t<path d="M5.25,6.85L5.25,6.85c-0.21,0-0.39-0.17-0.39-0.39V1.73c0-0.21,0.17-0.39,0.39-0.39h0
\t\tc0.21,0,0.39,0.17,0.39,0.39v4.74C5.64,6.68,5.46,6.85,5.25,6.85z"/>
</g>
</svg>
`;
        return (
            <InlineSVG src={Sidebar8Sfx} />
        );
    }
}

export default Sidebar8Sfx;
