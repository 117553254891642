import { AnimatePresence, motion } from 'framer-motion';
import { lazy, Suspense, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import CountrySelect from '@/components/Form/CountrySelect';
import Error from '@/components/Form/Error';
import Input from '@/components/Form/Input';
import Label from '@/components/Form/Label';
import Card from '@/components/Icons/Card';
import Shield from '@/components/Icons/Shield';
import { T } from '@/components/T';
import { Button, Loading } from '@/components/ui/button';
import { useSettings } from '@/hooks/useSettings';
import Spinner from '@/styles/svg-components/Spinner';

const StripeCard = lazy(async () => {
  const { default: StripeCardComponent } = await import('../../../Form/Stripe');

  return { default: StripeCardComponent };
});

const CardForm = ({
  children,
  defaultOpen,
}: {
  children: React.ReactNode;
  defaultOpen?: boolean;
}) => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<{
    country: string;
    nameOnCard: string;
  }>();

  const { settings } = useSettings();

  const [showCardForm, setShowCardForm] = useState(defaultOpen);

  return (
    <>
      <div className="relative overflow-hidden">
        <AnimatePresence initial={false} mode="popLayout">
          {showCardForm && (
            <motion.div
              key="card-form"
              className="flex flex-col gap-4 overflow-hidden"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                type: 'spring',
                duration: 0.3,
                bounce: 0,
              }}
            >
              <div>
                <Label htmlFor="country" srOnly>
                  <T>Country</T>
                </Label>
                <CountrySelect
                  name="country"
                  register={register}
                  hasError={!!errors.country}
                  defaultValue={settings?.country.id}
                />

                {!!errors.country?.message && (
                  <Error>
                    <T>{errors.country.message}</T>
                  </Error>
                )}
              </div>
              <div>
                <Label htmlFor="nameOnCard" srOnly>
                  <T>Card Holder Name</T>
                </Label>
                <Input
                  placeholder="Card Holder Name"
                  register={register}
                  name="nameOnCard"
                  hasError={!!errors.nameOnCard}
                />
                {!!errors.nameOnCard?.message && (
                  <Error>
                    <T>{errors.nameOnCard.message}</T>
                  </Error>
                )}
              </div>
              <div>
                <Label srOnly>
                  <T>Card Details</T>
                </Label>
                <Suspense fallback={<Spinner />}>
                  <StripeCard />
                </Suspense>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {children}
      {showCardForm ? (
        <Button
          key="submit"
          variant="uppbeat"
          disabled={isSubmitting}
          className="flex-1"
          type="submit"
        >
          <Loading visible={isSubmitting} />
          {!isSubmitting && <Shield />} Subscribe Now
        </Button>
      ) : (
        <Button
          key="pay-with-card"
          onClick={() => setShowCardForm(true)}
          variant="uppbeat"
          className="!mb-0 w-full"
        >
          <Card /> Pay with card
        </Button>
      )}
    </>
  );
};

export default CardForm;
