import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar1Browse extends React.Component {
    render() {
        const Sidebar1Browse = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
\t viewBox="0 0 8 8" style="enable-background:new 0 0 8 8;" xml:space="preserve">
<path d="M6.66,5.58V1.12c0-0.12-0.12-0.21-0.24-0.18L2.24,2.06C2.12,2.09,2.03,2.2,2.03,2.33v2.86
\tC1.89,5.16,1.74,5.16,1.59,5.2C1.23,5.3,0.96,5.59,0.9,5.95c-0.1,0.6,0.36,1.11,0.94,1.11c0.3,0,0.58-0.14,0.75-0.37
\tc0.11-0.14,0.19-0.32,0.2-0.51h0v0c0-0.02,0-0.04,0-0.06s0-0.04,0-0.06v-2.6c0-0.05,0.03-0.1,0.08-0.11l2.89-0.78
\tC5.83,2.54,5.9,2.6,5.9,2.67v2.05C5.85,4.71,5.79,4.71,5.74,4.71c-0.51,0-0.92,0.41-0.92,0.92s0.41,0.92,0.92,0.92
\tc0.49,0,0.89-0.38,0.92-0.87h0v0c0-0.02,0-0.03,0-0.05S6.66,5.6,6.66,5.58z"/>
</svg>
`;
        return (
            <InlineSVG src={Sidebar1Browse} />
        );
    }
}

export default Sidebar1Browse;
