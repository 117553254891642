const INITIAL_STATE = {
	trackData: []
};

function mobileTrackMenu(state = INITIAL_STATE, action) {
	switch (action.type) {
		case 'ADD_DATA':
			return Object.assign({}, state, {
				trackData: action.array
			});
		default:
			return state;
	}
}

export default mobileTrackMenu;
