import clsx from 'clsx';
import { PlusIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { useAppState } from '@/hooks/useAppState';

import styles from './styles.module.css';

interface ChannelWhitelistInnerProps {
  children: React.ReactNode;
  isLoading?: boolean;
  hasChannel?: boolean;
  isEditing?: boolean;
  isFetching?: boolean;
  handleEdit?: () => void;
}

export const ChannelWhitelistInner = ({
  children,
  isLoading,
  hasChannel,
  isEditing,
  isFetching,
  handleEdit,
}: ChannelWhitelistInnerProps) => {
  const userAuth = useAppState('userAuth');

  if (isLoading || !userAuth) {
    return (
      <div className="circle-loader">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }

  if (hasChannel || isEditing) {
    return (
      <div
        className={clsx(styles.whitelistPopup, isFetching && styles.loading)}
      >
        {children}
      </div>
    );
  }

  return (
    <div onClick={handleEdit} className={styles.addChannelContainer}>
      <Button
        size="icon"
        borderRadius="full"
        variant="uppbeat"
        aria-label="Add channel"
      >
        <PlusIcon />
      </Button>
    </div>
  );
};
