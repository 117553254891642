import React, { Fragment } from 'react';
import UBButton from '../UBButton';

const UBModalHeader = (props) => {
    const {
        onClose,
        tabs,
    } = props;

    return (
        <div className='ub-modal-header'>
            {tabs.length > 1 ?
                <div className='ub-modal-header_tabs'>
                    {tabs.map((tab, i) => {
                        return (
                            <Fragment key={i}>
                                {tab}
                            </Fragment>
                        )
                    })}
                </div>
            :
                tabs[0]
            }
            <UBButton
                menu="close"
                variant="secondary"
                onClick={onClose}
                borderRadius="full"
            />
        </div>
    )
};

export default UBModalHeader;
