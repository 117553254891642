import React from 'react';
import { twMerge } from 'tailwind-merge';

const IconInfo = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={twMerge(
      'text-[#F33D76] dark:bg-white dark:text-black',
      className,
    )}
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M10.4 2.577c0 .434-.148.805-.446 1.11-.297.306-.655.459-1.074.459-.42 0-.778-.153-1.079-.46-.3-.305-.45-.675-.45-1.11 0-.433.15-.804.45-1.113.3-.309.66-.463 1.08-.463.418 0 .776.155 1.073.463.298.309.446.68.446 1.114m-.162 10.466l-.17.771c-.513.224-.922.394-1.227.51-.304.117-.659.176-1.063.176-.62 0-1.103-.168-1.447-.5-.344-.336-.516-.76-.516-1.274 0-.2.012-.404.039-.613.026-.209.067-.445.124-.708l.64-2.503c.058-.239.106-.466.145-.68.04-.215.058-.41.058-.587 0-.32-.06-.544-.179-.67-.12-.125-.347-.188-.685-.188-.165 0-.335.029-.509.085-.174.057-.323.112-.448.163l.171-.772c.42-.188.82-.35 1.204-.483.384-.135.746-.203 1.089-.203.616 0 1.09.165 1.424.494.334.329.501.756.501 1.281 0 .11-.01.3-.034.575-.023.274-.066.526-.128.754l-.638 2.493c-.052.2-.099.43-.14.687-.042.257-.062.452-.062.583 0 .33.067.557.2.677.135.12.368.18.698.18.155 0 .331-.03.527-.09.195-.06.338-.112.426-.158" />
      </g>
    </g>
  </svg>
);

export default IconInfo;
