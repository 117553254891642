import React from "react";
import InlineSVG from 'svg-inline-react';

class Sidebar11Blog extends React.Component {
    render() {
        const Sidebar11Blog = `<svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<ellipse cx="8" cy="7" rx="8" ry="7" />
		<path d="M16 14L10.5 11.5L13.5 9L16 14Z" />
		</svg>`;

        return (
            <InlineSVG src={Sidebar11Blog} />
        );
    }
}

export default Sidebar11Blog;
