
const INITIAL_STATE = {
  modes: {
    autoprogress: true,
    shuffle: false,
    repeat: false,
    firstFifteen: false,
    current: false,
  },
  activeMode: "autoprogress",
  playing: false,
  trackPlaying: null,
  playingAdvert: false,
  tracks: [],
  activeTrack: {},
  playerActive: false,
  primaryTrack: {},
  secondaryTrack: {},
  isAudioReady: false,
  playerTrack: {
    duration: 0,
  },
  trackWaves: [],
  newTrackWaves: [],
  position: 0,
  disabled: false,
  disabledPlaying: false,
  countPlayedTrack: 0,
  isCompleted: false,
  lastPlayedAdvertTime: 0,
  volume: typeof window === 'undefined' ? '1' : (localStorage.getItem("volume") || "1"),
  isMute: false,
  isEnableKeyboardControls: true,
  clicked: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      const modes = state.modes.reduce(
        (mode, value, key) => {
          if (action.mode === key) {
            mode[action.mode] = true;
          } else {
            mode[key] = false;
          }
          return mode;
        },
        {}
      );
      return {
        ...state,
        modes,
        activeMode: Object.keys(modes)[Object.values(modes).indexOf((mode) => mode)],
      };
    case "TOGGLE_PLAY":
      return {
        ...state,
        playing: !action.playing,
        activeTrack: {
          ...state.activeTrack,
          playing: !action.playing,
        },
      };
    case "PLAY_ACTIVE_TRACK":
      return {
        ...state,
        playing: true,
        activeTrack: {
          ...state.activeTrack,
          playing: true,
        },
      };
    case "PAUSED_ACTIVE_TRACK":
      return {
        ...state,
        playing: false,
        activeTrack: {
          ...state.activeTrack,
          playing: false,
        },
      };
    case "ON_CHANGE_TRACK":
      return {
        ...state,
        primaryTrack: action.track,
        activeTrack: {
          ...state.activeTrack,
          trackID: action.track.trackId,
          versionID: action.track.versionId,
          isAudioReady: false,
          play_order: action.track.play_order || false,
          currentContext: action.track.current_context.id,
          progress: 0,
        },
        position: 0,
      };
    case "PLAYER_ACTIVE":
      return {
        ...state,
        playerActive: true,
      };
    case "PLAYER_IN_ACTIVE":
      return {
        ...state,
        primaryTrack: {},
        playerActive: false,
        activeTrack: {},
        playing: false,
        position: 0,
        isAudioReady: false,
        playerTrack: {
          duration: 0,
        },
      };
    case "ADD_ACTIVE_TRACK":
      return {
        ...state,
        activeTrack: {
          ...action.track,
          playing: state.playing,
        },
      };
    case "ADD_PRIMARY_TRACK":
      return {
        ...state,
        primaryTrack: action.track,
      };
    case "PLAYER_ACTIVE_TRACK":
      return {
        ...state,
        playerTrack: action.track,
      };
    case "IS_AUDIO_READY":
      return {
        ...state,
        isAudioReady: action.isReady,
        activeTrack: {
          ...state.activeTrack,
          isAudioReady: action.isReady,
          playing: state.playing,
        },
      };
    case "ADD_TRACK_WAVE":
      return {
        ...state,
        storePage: action.page || 1,
        trackWaves:
          state.trackWaves.length === 1
            ? [...state.trackWaves, ...action.tracks]
            : action.tracks,
      };
    case "MERGE_TRACK_WAVE":
      return {
        ...state,
        storePage: action.page || 1,
        trackWaves: state.trackWaves.concat(action.tracks)
      };
    case "ADD_PAGE_TRACK_WAVE":
      return {
        ...state,
        newTrackWaves: action.tracks,
      };
    case "FLUSH_NEW_TRACK_WAVE":
      return {
        ...state,
        newTrackWaves: [],
      };
    case "ADD_TRACK_PAGE_WAVE":
      return {
        ...state,
        trackWaves: state.trackWaves.concat({
          ...action.track,
          trackIndex: state.trackWaves.length,
        }),
      };
    case "SET_PLAYER_POSITION":
      return {
        ...state,
        position: action.position,
        activeTrack: {
          ...state.activeTrack,
          position: action.position,
          playing: state.playing,
        },
      };
    case "FLUSH_TRACK_WAVE":
      return {
        ...state,
        trackWaves: [],
      };
    case "SET_PLAYER_VOLUME":
      return {
        ...state,
        volume: action.volume,
      };
    case "SET_TRACK_POSTION":
      return {
        ...state,
        position: action.position,
        activeTrack: {
          ...state.activeTrack,
          isAudioReady: state.isAudioReady,
          position: action.position,
          playing: true,
        },
      };
    case "UPDATE_TRACK_PROGRESS":
      return {
        ...state,
        activeTrack: {
          ...state.activeTrack,
          progress: action.progress,
        },
      };
    case "TOGGLE_MUTE":
      return {
        ...state,
        isMute: !state.isMute,
        volume: localStorage.getItem("volume"),
      };

    case "ENABLE_KEYBOARD_CONTROLS":
      return {
        ...state,
        isEnableKeyboardControls: true,
      };

    case "DISABLE_KEYBOARD_CONTROLS":
      return {
        ...state,
        isEnableKeyboardControls: false,
      };

    case "COUNT_PLAYED_TRACKS":
      return {
        ...state,
        countPlayedTrack: state.countPlayedTrack + 1,
      };
    case "START_ADVERT_TIMER":
      return {
        ...state,
        lastPlayedAdvertTime: parseInt( String(Date.now()).slice(0, -3) ) + ( Number(process.env.NEXT_PUBLIC_ADS_PERIOD_MINUTES) * 60 ),
      };
    case "FLUSH_ADVERT_TIMER":
      return {
        ...state,
        lastPlayedAdvertTime: 0,
      };
    case "TOGGLE_DISABLED_CONTROLS":
      return {
        ...state,
        lastPlayedAdvertTime: 0,
        disabledPlaying: Number(state.volume) === 0,
        playingAdvert: Number(state.volume) > 0,
        disabled: action.payload,
      };
    case "TOGGLE_PLAY_ADVERT":
      return {
        ...state,
        playingAdvert: !state.playingAdvert,
      };
    case "PLAY_ADVERT_VOLUME": {
      return {
        ...state,
        disabledPlaying: false,
        playingAdvert: true,
      };
    }
    case "PAUSE_ADVERT_VOLUME": {
      return {
        ...state,
        disabledPlaying: true,
        playingAdvert: false,
      };
    }
    case "SET_SECONDARY_TRACK":
      return {
        ...state,
        playing: false,
        trackPlaying: state.playing,
        activeTrack: {
          ...state.activeTrack,
          playing: false,
        },
        secondaryTrack: action.payload,
      };
    case "FLUSH_SECONDARY_TRACK":
      return {
        ...state,
        secondaryTrack: {},
        lastPlayedAdvertTime: parseInt( String(Date.now()).slice(0, -3) ) + ( Number(process.env.NEXT_PUBLIC_ADS_PERIOD_MINUTES) * 60 ),
        countPlayedTrack: 1,
        isCompleted: false,
        playingAdvert: false,
        trackPlaying: null,
        disabled: false,
        clicked: false,
        disabledPlaying: false,
      };
    case "CLICKED_CONTROLLED_ACTION":
      return {
        ...state,
        clicked: true,
      };
    case "TOGGLE_UNMUTE_PLAY_ADVERT":
      return {
        ...state,
        disabledPlaying: !state.disabledPlaying,
        playingAdvert: !state.clicked
          ? !state.playingAdvert
          : state.playingAdvert,
      };
    case "ADVERT_TIMER_COMPLETED":
      return {
        ...state,
        isCompleted: true,
      };
    default:
      return state;
  }
};
