import 'sanitize.css/sanitize.css';
import '@/styles/css/fonts.css';
import '@/styles/sass/main.scss';
// Tailwind CSS
import '@/styles/css/main.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SpeedInsights } from '@vercel/speed-insights/next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useMemo } from 'react';
import { Provider as StoreProvider } from 'react-redux';

import { fallbackSettings } from '@/api/setup/setup-frontend';
import { getAuthToken } from '@/auth';
import { AppContainer } from '@/components/AppContainer';
import {
  AccountModals,
  AccountModalsProvider,
} from '@/components/Modals/Account';
import AddToPlaylistModal from '@/components/Modals/AddToPlaylist';
import { AddToPlaylistProvider } from '@/components/Modals/AddToPlaylist/context';
import { Toaster } from '@/components/Toast';
import { Tracking } from '@/components/Tracking';
import { VideoFromQuery } from '@/components/Video/VideoFromQuery';
import { getUniversalCookie } from '@/cookies';
import { useIsClient } from '@/hooks/useIsClient';
import { ABProvider } from '@/providers/ABProvider';
import { ClassProvider } from '@/providers/ClassProvider';
import { FileFormatProvider } from '@/providers/FileFormatProvider';
import { LocalSettingsProvider } from '@/providers/LocalSettingsProvider';
import { PortalProvider } from '@/providers/PortalProvider';
import { SettingsProvider } from '@/providers/SettingsProvider';
import { SidebarProvider } from '@/providers/SidebarProvider';
import { ThemeProvider } from '@/providers/ThemeProvider';
import { TrackingProvider } from '@/providers/TrackingProvider';
import { UserStateProvider } from '@/providers/UserStateProvider';
import { storeWrapper } from '@/store';

import type { UserState } from '@/providers/UserStateProvider';
import type { Settings } from '@/types/settings';
import type { AppProps } from 'next/app';

const getInitialSettings = () => {
  try {
    const userSettings = localStorage.getItem('settings');
    const initialSettings: Settings = userSettings
      ? JSON.parse(userSettings)
      : fallbackSettings;

    const authToken = getAuthToken();

    initialSettings.user.auth_token = authToken;

    const userState = localStorage.getItem('user');
    const initialUserState: UserState = userState
      ? JSON.parse(userState)
      : {
          hasLoggedIn: false,
        };

    return { initialUserState, initialSettings };
  } catch (e) {
    return {};
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = ({ Component, ...restProps }: AppProps) => {
  const { initialSettings, initialUserState } = getInitialSettings();

  const { store, props } = storeWrapper.useWrappedStore(restProps);

  const router = useRouter();

  const { asPath } = router;

  const canonicalLink = useMemo(() => {
    let pathname = asPath.split('?')[0];

    if (pathname === '/index') {
      pathname = '/';
    }

    // Force lowercase to prevent issues like "VHS"
    pathname = pathname.toLowerCase();

    return new URL(pathname, process.env.NEXT_PUBLIC_FE_BASE_URL ?? null).href;
  }, [asPath]);

  const isClient = useIsClient();

  if (!isClient && process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return null;
  }

  const bypassMaintenance = getUniversalCookie('BYPASS_MAINTENANCE');

  // Disable normal app if we're in maintenance mode.
  if (
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true' &&
    bypassMaintenance !==
      process.env.NEXT_PUBLIC_MAINTENANCE_BYPASS_COOKIE_VALUE
  ) {
    return (
      <>
        <script
          id="initial-theme"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(){try{const theme=localStorage.getItem('theme');const prefersDarkMode=window.matchMedia('(prefers-color-scheme: dark)',).matches;
        if(!theme){if(prefersDarkMode){document.documentElement.classList.add('theme-dark')}else{document.documentElement.classList.add('theme-light')} return}
        document.documentElement.classList.add('theme-'+theme)}catch(e){}})()`,
          }}
        />
        <QueryClientProvider client={queryClient}>
          <StoreProvider store={store}>
            <Component {...props.pageProps} />
          </StoreProvider>
        </QueryClientProvider>
      </>
    );
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#F23D75" />

        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0"
        />
        <meta
          name="description"
          content="Download the best copyright free music from the world's hottest beatmakers and indie artists. Create your free account & start downloading now."
        />

        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="141409067700997" />
        <meta
          key="og:title"
          property="og:title"
          content="Free Music For Creators"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Create your free Uppbeat account & start downloading the best copyright-free music from the world's hottest beatmakers."
        />
        <meta
          property="og:image"
          content="https://cdn.uppbeat.io/images/opengraph-20210118.jpg"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:url" content="https://uppbeat.io/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@UppbeatOfficial" />
        <meta
          key="twitter:title"
          name="twitter:title"
          content="Free Music For Creators"
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content="Create your free Uppbeat account & start downloading the best copyright-free music from the world's hottest beatmakers."
        />
        <meta
          name="twitter:image"
          content="https://cdn.uppbeat.io/images/uppbeat-social-share-20210118.jpg"
        />
        <meta name="twitter:image:alt" content="Uppbeat" />

        <title key="title">
          Free Music For YouTube Videos & Creators • Uppbeat
        </title>

        {!!canonicalLink && (
          <link key="canonical" rel="canonical" href={canonicalLink} />
        )}
      </Head>
      <SpeedInsights sampleRate={0.1} />

      {/* Ads/Analytics/Pixels */}
      <Tracking />

      {!!process.env.NEXT_PUBLIC_VGO_ACCOUNT_ID && (
        <Script id="visitor-global-object" strategy="afterInteractive">
          {`
          (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode?.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
          vgo('setAccount', '${process.env.NEXT_PUBLIC_VGO_ACCOUNT_ID}');
          vgo('setTrackByDefault', true);
        `}
        </Script>
      )}

      {/* Polyfill for container query */}
      {isClient && !CSS.supports('container-type: inline-size') && (
        <Script src="https://cdn.jsdelivr.net/npm/container-query-polyfill@1/dist/container-query-polyfill.modern.js" />
      )}

      <QueryClientProvider client={queryClient}>
        <StoreProvider store={store}>
          <LocalSettingsProvider>
            <SettingsProvider initialSettings={initialSettings}>
              <SidebarProvider>
                <ThemeProvider>
                  <PortalProvider>
                    <UserStateProvider initialUserState={initialUserState}>
                      <ClassProvider>
                        <TrackingProvider>
                          <ABProvider>
                            <AccountModalsProvider>
                              <AddToPlaylistProvider>
                                <FileFormatProvider>
                                  <AppContainer>
                                    <Component {...props.pageProps} />
                                    <AccountModals />
                                    <AddToPlaylistModal />
                                    <Toaster />
                                  </AppContainer>
                                </FileFormatProvider>
                              </AddToPlaylistProvider>
                            </AccountModalsProvider>
                          </ABProvider>
                        </TrackingProvider>
                      </ClassProvider>
                    </UserStateProvider>
                  </PortalProvider>
                </ThemeProvider>
              </SidebarProvider>
            </SettingsProvider>
          </LocalSettingsProvider>
        </StoreProvider>
      </QueryClientProvider>
      <VideoFromQuery />

      <Script
        id="stripe-js"
        src="https://js.stripe.com/v3"
        strategy="lazyOnload"
      />

      {!!process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY && (
        <Script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_V3_KEY}`}
          strategy="lazyOnload"
        />
      )}
    </>
  );
};

export default App;
