import { useCallback } from 'react';

import { useTNamespace } from '@/providers/TNamespaceProvider';

import type {
  CombinedOptions,
  DefaultParamType,
  ReactOptions,
  TranslationKey,
} from '@tolgee/react';

export const useTranslate = (
  ns?: string[] | string,
  options?: ReactOptions,
) => {
  const currentNamespace = useTNamespace();

  // If ns is not provided, we try use the provider namespace.
  const internalT = useCallback(
    (
      key?: TranslationKey,
      defaultValue?: string | undefined,
      _settings?: CombinedOptions<DefaultParamType> | undefined,
    ) => {
      if (!key) {
        return '';
      }

      if (!defaultValue) {
        return key;
      }

      return defaultValue;
    },
    [],
  );

  return {
    t: internalT,
    isLoading: false,
  };
};
