import React from "react";
import InlineSVG from 'svg-inline-react';

class Twitter extends React.Component {
    render() {
        const assetTwitter = `<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.0887 35.0996L69.9141 8H64.2683L43.5806 31.5302L27.0575 8H8L32.9862 43.5819L8 72H13.6462L35.4929 47.1513L52.9425 72H72L46.0873 35.0996H46.0887ZM38.3555 43.8954L35.8238 40.3522L15.6806 12.159H24.3528L40.6086 34.9118L43.1403 38.455L64.2709 68.0302H55.5987L38.3555 43.8967V43.8954Z" fill="#969696"/>
        </svg>`;

        return (
            <InlineSVG src={assetTwitter} />
        );
    }
}

export default Twitter;
