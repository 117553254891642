import axios from 'axios';

export const trackReferralRegistration = () => {
  void axios.post(
    'admin/user/partnerstack/customer/create',
    {},
    {
      withCredentials: true,
      baseURL: process.env.NEXT_PUBLIC_API,
    },
  );
};
