import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useState } from 'react';

import { getAuthToken } from '@/auth';
import DeferredComponent from '@/components/DeferredComponent';
import PremiumStar from '@/components/Icons/PremiumStar';
import Tick from '@/components/Icons/Tick';
import Modal from '@/components/Modals/Core';
import Checkout from '@/components/Modals/CreateAccount/checkout';
import { AccountType } from '@/components/Modals/CreateAccount/types';
import StackedTitles from '@/components/Modals/Sidebar/components/StackedTitles';
import { useSidebarModalContext } from '@/components/Modals/Sidebar/context/SidebarModalContext';
import useDirectionalAnimation from '@/components/Modals/Sidebar/hooks/useDirectionalAnimation';
import { useScreenContext } from '@/components/SteppedDialog/context/ScreenContext';
import { UBWhitelist } from '@/components/UBWhitelist';
import { useWhitelistedChannels } from '@/components/UBWhitelist/hooks/useWhitelistedChannels';
import { Button } from '@/components/ui/button';
import useRemainingCredits from '@/hooks/useRemainingCredits';
import { useSettings } from '@/hooks/useSettings';
import { useTranslate } from '@/hooks/useTranslate';
import { planData } from '@/plan-data';
import Checked from '@/styles/svg-components/Checked';

import { GoPremiumModalScreenName, GoPremiumModalVariants } from './types';

const Video = dynamic(() => import('../../Video'), { ssr: false });

interface UpgradeReason {
  label: string;
  id: string;
  icon: string;
}

const reasons: UpgradeReason[] = [
  {
    label: 'Unlimited downloads',
    id: '1',
    icon: 'https://cdn.uppbeat.io/icons/static-icons/infinite_loop.svg',
  },
  {
    label: 'Safelist YouTube channel',
    id: '2',
    icon: 'https://cdn.uppbeat.io/icons/adaptive-icons/youtube.svg',
  },
  {
    label: 'Access to Premium tracks',
    id: '3',
    icon: 'https://cdn.uppbeat.io/icons/static-icons/premium_star.svg',
  },
  {
    label: 'Unlock sound effects',
    id: '4',
    icon: 'https://cdn.uppbeat.io/icons/static-icons/bang.svg',
  },
  {
    label: 'Tired of ads',
    id: '5',
    icon: 'https://cdn.uppbeat.io/icons/static-icons/tired_emoji.svg',
  },
];

interface WhyDidYouUpgradeProps {
  selectedReason: UpgradeReason | null;
  setSelectedReason: (reason: UpgradeReason) => void;
}

const WhyDidYouUpgrade = ({
  selectedReason,
  setSelectedReason,
}: WhyDidYouUpgradeProps) => {
  return (
    <div className="space-y-4 px-4 py-6 rounded-lg border border-dashed border-gray-medium-100 dark:border-gray-medium-400 mt-4 mb-6">
      <div>
        <h3 className="text-lg font-semibold">Can we get your take?</h3>
        <p className="text-sm">What's the main reason you upgraded?</p>
      </div>
      <div className="space-y-2">
        {reasons.map((reason) => {
          return (
            <Button
              className={clsx('justify-start w-full', {
                'dark:hover:border-gray-dark-200':
                  selectedReason?.id !== reason.id,
                'pointer-events-none': selectedReason?.id === reason.id,
              })}
              key={reason.id}
              variant={
                selectedReason?.id === reason.id ? 'success' : 'secondary'
              }
              onClick={() => setSelectedReason(reason)}
            >
              <Image
                unoptimized
                src={reason.icon}
                width={16}
                height={16}
                alt={`Option ${reason.id}`}
                className={clsx(
                  reason.id === '2' &&
                    selectedReason?.id !== reason.id &&
                    'brightness-0 dark:brightness-100',
                )}
              />{' '}
              {reason.label}
              {selectedReason?.id === reason.id && (
                <div className="flex justify-end flex-1 pl-2">
                  <Checked />
                </div>
              )}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

const UpgradePrice = ({ accountType }: { accountType: AccountType }) => {
  const { settings } = useSettings();
  const currency = settings?.currency;

  const price = String(
    planData[accountType === AccountType.Business ? 'business' : 'premium']
      .monthly[currency?.code ?? 'GBP'],
  );

  const pointIndex = price?.indexOf('.');
  const integer = price?.slice(0, pointIndex);
  const decimals = price?.slice(pointIndex);

  return (
    <p className="my-6 text-black dark:text-white font-futura font-bold">
      <sup className="text-lg inline-block !text-inherit">
        {currency?.symbol_left}
      </sup>
      <span className="text-4xl leading-none mr-1 !text-inherit">
        {integer}
        <sup className="text-lg !text-inherit">{decimals}</sup>
      </span>
      <span className="text-sm -ml-8 mr-1 !text-inherit font-normal">
        /month
      </span>
      <span className="!text-gray-medium-200 font-normal !dark:text-gray-medium-400 text-sm">
        (cancel anytime)
      </span>
    </p>
  );
};

const SafeListFirstChannel = ({
  accountType,
}: {
  accountType: AccountType;
}) => (
  <>
    <Modal.Title className="text-gray-medium-100 dark:text-gray-medium-400 mb-6 text-xl">
      Checkout
    </Modal.Title>
    <hr className="border-0 border-b border-solid border-gray-medium-100 dark:border-gray-medium-400 mb-6" />

    <div className="bg-green rounded-lg p-4 mb-6">
      <h2 className="font-medium mb-4 text-white font-futura">
        <Image
          src="https://cdn.uppbeat.io/images/UppBeat_Icons_Tick_White.svg"
          alt=""
          className="-mt-1 mr-2 w-6"
          unoptimized
          width={24}
          height={24}
        />
        Payment successful
      </h2>
      <p className="text-base p-0 mb-0 text-white font-futura">
        Nice! You now have access to thousands of premium tracks and sound
        effects.
      </p>
    </div>

    <h3 className="mb-6 text-xl">
      Safelist your first channel{' '}
      <span className="!text-gray-medium-100 dark:!text-gray-medium-400 text-lg">
        (Optional)
      </span>
    </h3>

    <Modal.Description className="text-sm">
      {accountType === AccountType.Premium ? (
        <strong>
          As an Uppbeat Premium subscriber,{' '}
          <b>you can safelist up to 3 YouTube channels</b> to prevent copyright
          claims on any videos you publish using Uppbeat tracks.
        </strong>
      ) : (
        <strong>
          As an Uppbeat Business subscriber,{' '}
          <b>you can safelist up to 10 YouTube channels</b> to prevent copyright
          claims on any videos you publish using Uppbeat tracks.
        </strong>
      )}
    </Modal.Description>

    <UBWhitelist fullWidth maxChannels={1} relativePosition />
  </>
);

const GoPremiumModalScreens = ({
  variant = GoPremiumModalVariants.Default,
}: {
  variant: GoPremiumModalVariants;
}) => {
  const { setScreen } = useScreenContext();
  const animationProps = useDirectionalAnimation();
  const [error, setError] = useState<string | null>(null);
  const { data } = useWhitelistedChannels();
  const { t } = useTranslate('modal');
  const [selectedReason, setSelectedReason] = useState<UpgradeReason | null>(
    null,
  );

  const { onOpenChange } = useSidebarModalContext();

  const hasChannels = data && data.length > 0;

  const handleReason = async () => {
    const authToken = getAuthToken();

    await fetch(
      `${process.env.NEXT_PUBLIC_API}set_conversion_reason?reason_id=${selectedReason?.id}&reason_rank=${selectedReason?.id}`,
      {
        method: 'GET',
        ...(authToken && {
          headers: {
            token: authToken,
          },
        }),
      },
    )
      .then((res) => res.text())
      // eslint-disable-next-line no-console
      .then(console.log);
  };

  const { remainingCredits, daysUntilRenewal } = useRemainingCredits();

  const showNoDownloads =
    variant === GoPremiumModalVariants.NoCredits &&
    daysUntilRenewal !== null &&
    remainingCredits !== undefined &&
    remainingCredits < 1;

  return (
    <>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoPremium}
        className="px-6 py-12"
        {...animationProps}
      >
        {showNoDownloads && (
          <p className="alert-general-error">
            You have no downloads remaining.{' '}
            {`Next top-up in ${
              daysUntilRenewal === 1 ? '1 day' : `${daysUntilRenewal} days`
            }`}
          </p>
        )}
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          {variant === GoPremiumModalVariants.Default && (
            <strong>
              Unleash your creativity with Uppbeat{' '}
              <span className="!text-uppbeat">Premium</span>
            </strong>
          )}
          {variant === GoPremiumModalVariants.TrackDownload && (
            <>
              <PremiumStar
                className="size-6 inline-block text-uppbeat mr-1"
                showBackground={false}
              />
              <strong>
                Access premium tracks with Uppbeat{' '}
                <span className="!text-uppbeat">Premium</span>
              </strong>
            </>
          )}
          {variant === GoPremiumModalVariants.SFXDownload && (
            <>
              <PremiumStar
                className="size-6 inline-block text-uppbeat mr-1"
                showBackground={false}
              />
              <strong>
                Access premium sound effects with Uppbeat{' '}
                <span className="!text-uppbeat">Premium</span>
              </strong>
            </>
          )}
          {variant === GoPremiumModalVariants.NoCredits && (
            <strong>
              Get unlimited downloads with Uppbeat{' '}
              <span className="!text-uppbeat">Premium</span>
            </strong>
          )}
        </Modal.Title>
        <ul className="list-none mt-6 space-y-2 text-gray-medium-400 dark:text-gray-medium-200 text-pretty">
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Unlimited downloads
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> 100% catalog access
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Safelist up to 3 YouTube
            channels
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Premium tracks & sound effects
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Ad-free
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> For creators & micro
            organizations
          </li>
        </ul>
        <UpgradePrice accountType={AccountType.Premium} />
        <Button
          className="w-full mt-6"
          variant="uppbeat"
          onClick={() => setScreen(GoPremiumModalScreenName.GoPremiumCheckout)}
        >
          Start now
        </Button>
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoBusiness}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          <strong>
            The ultimate creative subscription for your{' '}
            <span className="!text-business">Business</span>
          </strong>
        </Modal.Title>
        <ul className="list-none mt-6 space-y-2 text-gray-medium-400 dark:text-gray-medium-200 text-pretty">
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> License for organizations of
            any scale
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Covers digital ads & client
            content
          </li>
          <li className="flex justify-start items-center gap-1">
            <Tick className="w-3.5 text-green" /> Safelist up to 10 YouTube
            channels
          </li>
        </ul>
        <DeferredComponent
          fallback={
            <div className="aspect-video bg-gray-medium-100 dark:bg-gray-dark-100 mt-6 rounded-2xl" />
          }
        >
          <Video type="premium-vs-business" className="mt-6" />
        </DeferredComponent>
        <UpgradePrice accountType={AccountType.Business} />
        <Button
          className="w-full mt-6"
          variant="uppbeat"
          onClick={() => setScreen(GoPremiumModalScreenName.GoBusinessCheckout)}
        >
          Start now
        </Button>
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoPremiumCheckout}
        className="px-6 py-12"
        {...animationProps}
      >
        <StackedTitles
          title="Checkout"
          subtitle={
            <>
              Uppbeat <span className="!text-uppbeat">Premium</span>
            </>
          }
        />
        <Checkout
          accountType={AccountType.Premium}
          nextScreenName={GoPremiumModalScreenName.GoPremiumSafelist}
        />
        {process.env.NODE_ENV !== 'production' && (
          <Button
            variant="secondary"
            className="w-full mt-8"
            onClick={() => {
              setScreen(GoPremiumModalScreenName.GoPremiumSafelist);
            }}
          >
            Skip
          </Button>
        )}
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoBusinessCheckout}
        className="px-6 py-12"
        {...animationProps}
      >
        <StackedTitles
          title="Checkout"
          subtitle={
            <>
              Uppbeat <span className="!text-business">Business</span>
            </>
          }
        />
        <Checkout
          accountType={AccountType.Business}
          nextScreenName={GoPremiumModalScreenName.GoBusinessSafelist}
        />
        {process.env.NODE_ENV !== 'production' && (
          <Button
            variant="secondary"
            className="w-full"
            onClick={() => {
              setScreen(GoPremiumModalScreenName.GoBusinessSafelist);
            }}
          >
            Skip
          </Button>
        )}
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoPremiumSafelist}
        className="px-6 py-12"
        {...animationProps}
      >
        <SafeListFirstChannel accountType={AccountType.Premium} />
        {!!error && !hasChannels && (
          <div className="alert-general-error">{error}</div>
        )}
        <div className="flex flex-col gap-4">
          <Button
            variant="uppbeat"
            onClick={() => {
              if (!hasChannels) {
                setError(
                  t(
                    'modal.whitelist.addChannelError',
                    'Please add a channel to continue',
                  ),
                );

                return;
              }

              setScreen(GoPremiumModalScreenName.GoPremiumWelcome);
            }}
            className="w-full"
          >
            Confirm channel
          </Button>
          <Button
            variant="transparent"
            onClick={() => setScreen(GoPremiumModalScreenName.GoPremiumWelcome)}
            className="w-full"
            disabled={hasChannels}
          >
            Skip
          </Button>
        </div>
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoBusinessSafelist}
        className="px-6 py-12"
        {...animationProps}
      >
        <SafeListFirstChannel accountType={AccountType.Business} />
        {!!error && !hasChannels && (
          <div className="alert-general-error">{error}</div>
        )}
        <div className="flex flex-col gap-4">
          <Button
            variant="uppbeat"
            onClick={() => {
              if (!hasChannels) {
                setError(
                  t(
                    'modal.whitelist.addChannelError',
                    'Please add a channel to continue',
                  ),
                );

                return;
              }

              setScreen(GoPremiumModalScreenName.GoBusinessWelcome);
            }}
            className="w-full"
          >
            Confirm channel
          </Button>
          <Button
            variant="transparent"
            onClick={() =>
              setScreen(GoPremiumModalScreenName.GoBusinessWelcome)
            }
            className="w-full"
            disabled={hasChannels}
          >
            Skip
          </Button>
        </div>
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoPremiumWelcome}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          <strong>
            Welcome to
            <br /> Uppbeat Premium!
          </strong>
        </Modal.Title>
        <Modal.Description>
          It's time to take your content to the next level with unlimited and
          uninterrupted access to Uppbeat!
        </Modal.Description>

        <DeferredComponent
          fallback={
            <div className="aspect-video bg-gray-medium-100 dark:bg-gray-dark-100 mt-6 rounded-2xl" />
          }
        >
          <Video type="welcome-to-premium" />
        </DeferredComponent>

        <WhyDidYouUpgrade
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
        />

        <Button
          variant="uppbeat"
          onClick={() => {
            void handleReason();
            onOpenChange(false);
          }}
          className="w-full"
        >
          Let's get started!
        </Button>
      </Modal.Screen>
      <Modal.Screen
        screenName={GoPremiumModalScreenName.GoBusinessWelcome}
        className="px-6 py-12"
        {...animationProps}
      >
        <Modal.Title className="text-black dark:text-white mb-6 text-2xl">
          <strong>
            Welcome to
            <br /> Uppbeat Business!
          </strong>
        </Modal.Title>
        <Modal.Description>
          It's time to take your content to the next level with unlimited and
          uninterrupted access to Uppbeat!
        </Modal.Description>

        <WhyDidYouUpgrade
          selectedReason={selectedReason}
          setSelectedReason={setSelectedReason}
        />

        <Button
          variant="uppbeat"
          onClick={() => {
            void handleReason();
            onOpenChange(false);
          }}
          className="w-full"
        >
          Let's get started!
        </Button>
      </Modal.Screen>
    </>
  );
};

export default GoPremiumModalScreens;
